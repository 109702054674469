import React, { MouseEventHandler, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Link, To } from 'react-router-dom';
import { useAuth } from '@digital-gov/auth-apollo-store';
import { useClickOutside } from '@digital-gov/ui-utils';
import { BaseLink } from 'componentsL/BaseLink/BaseLink';
import { Icon, IconChevronDown, IconSize, IconUser } from 'componentsL/Icon';
import { Tooltip } from 'componentsL/Tooltip';
import { useProfile } from 'store/profile/useProfile';
import s from './UserMenu.module.scss';

export const UserMenu = () => {
  const { logOut } = useAuth();
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [avatarNode, setAvatarNode] = useState<HTMLDivElement | null>(null);
  const { profile } = useProfile();

  useClickOutside(userMenuRef.current, () => setMenuOpen(false));

  const links = useMemo(() => {
    return [{ text: 'Выйти', onClick: () => logOut() }] as { text: string; to?: To; onClick?: MouseEventHandler }[];
  }, [logOut]);

  return (
    <div ref={userMenuRef} className={s.UserMenu}>
      <div className={s.User} onClick={() => setMenuOpen((prevState) => !prevState)}>
        <div className={s.User__info}>
          <div className={s.User__name}>{profile?.fullName}</div>
          <div className={s.User__department}>{profile?.email}</div>
        </div>
        <div ref={setAvatarNode} className={s.User__content}>
          <div className={s.Avatar}>
            <Icon icon={IconUser} size={IconSize.Small} />
          </div>
          <Icon
            className={clsx(s.User__menuArrow, {
              [s.User__menuArrow_active]: menuOpen
            })}
            icon={IconChevronDown}
            size={IconSize.Small}
          />
        </div>
      </div>

      {menuOpen && (
        <Tooltip
          classes={{
            content: s.Tooltip__content
          }}
          targetNode={avatarNode}
          showArrow={false}
          tooltipOptions={{
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4]
                }
              }
            ]
          }}>
          <div className={s.Tooltip__links}>
            {links.map((link, iLink) => (
              <BaseLink key={iLink} className={s.Tooltip__link} Component={link.to ? Link : 'button'} {...link}>
                {link.text}
              </BaseLink>
            ))}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
