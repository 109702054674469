import { RatingCommentScopeEnum } from 'store/graphql';

export const isDepartmentScope = (scope: RatingCommentScopeEnum) =>
  scope === RatingCommentScopeEnum.Department || scope === RatingCommentScopeEnum.DepartmentIndicator;

export const isRegionScope = (scope: RatingCommentScopeEnum) =>
  scope === RatingCommentScopeEnum.Region || scope === RatingCommentScopeEnum.RegionIndicator;

export const isIndicatorScope = (scope: RatingCommentScopeEnum) =>
  scope === RatingCommentScopeEnum.DepartmentIndicator || scope === RatingCommentScopeEnum.RegionIndicator;
