import React from 'react';
import s from './ModalFooter.module.scss';

export interface ModalFooterProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const ModalFooter = ({ left, right }: ModalFooterProps) => {
  return (
    <div className={s.ModalFooter}>
      <div className={s.ModalFooter__leftSide}>{left}</div>

      <div className={s.ModalFooter__rightSide}>{right}</div>
    </div>
  );
};
