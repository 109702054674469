import React from 'react';
import { Button, ButtonType } from 'components/Button';
import { Modal } from './Modal';
import s from './ActionModal.module.scss';

export interface Action {
  name: string;
  onAction?: () => void;
}

export interface ActionModalProps {
  title: string;
  message: string;
  onClose?: () => void;
  actions: [Action, Action?];
  width?: number;
}

export function ActionModal({ title, message, onClose, actions, width }: ActionModalProps) {
  const [action, secondaryAction] = actions;
  return (
    <Modal width={width ?? 456} title={title} onClose={onClose}>
      <p className={s.ActionModal__message}>{message}</p>
      <div className={s.ActionModal__actions}>
        {secondaryAction && (
          <Button type={ButtonType.Secondary} onClick={secondaryAction.onAction}>
            {secondaryAction.name}
          </Button>
        )}

        <Button onClick={action.onAction} style={{ marginLeft: 'auto' }}>
          {action.name}
        </Button>
      </div>
    </Modal>
  );
}
