import React from 'react';
import clsx from 'clsx';
import s from './ChartTick.module.scss';

export interface ChartTickProps {
  className?: string;
  children?: React.ReactNode;
}

export const ChartTick = ({ className, children }: ChartTickProps) => {
  return <div className={clsx(s.ChartTick, className)}>{children}</div>;
};
