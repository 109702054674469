import React from 'react';
import s from './ExcelLoader.module.scss';

export function ExcelLoader() {
  return (
    <div className={s.ExcelLoader}>
      <div className={s.ExcelLoader__spinner} />
    </div>
  );
}
