import { RatingIndicatorProgressGroupEnum } from 'store/graphql';

export type IndicatorsTableRow = {
  indicatorName: string;
  value: number;
  valueDynamic?: number | null;
  valueMax: number;
  alertMark?: boolean;
  successMark?: boolean;
  indicatorProgressGroup: RatingIndicatorProgressGroupEnum;
  linkTo?: string;
  availableForDetails?: boolean;
  hasFeedback?: boolean;
  hasRecommendations?: boolean;
};

export enum IndicatorsTableHeaderFields {
  Idx = 'idx',
  IndicatorName = 'indicatorName',
  Value = 'value',
  ValueDynamic = 'valueDynamic',
  ValueMax = 'valueMax'
}

export interface ISortedColumn {
  field: IndicatorsTableHeaderFields;
  sort: 'asc' | 'desc';
}
