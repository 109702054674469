import React from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal, ModalFooter } from 'componentsL/Modal';

export interface SuccessModalProps {
  onClose: () => void;
  onContinue: () => void;
}

export const SuccessModal = ({ onContinue, onClose }: SuccessModalProps) => {
  const continueButton = (
    <Button variant={ButtonVariant.Primary} onClick={onContinue}>
      Продолжить
    </Button>
  );

  return (
    <Modal title="Спасибо" width={456} onClose={onClose}>
      Заявка успешно отправлена
      <ModalFooter right={continueButton} />
    </Modal>
  );
};
