import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@digital-gov/ui-utils';
import { BaseLink } from 'componentsL/BaseLink/BaseLink';
import { ExcelLoader } from 'componentsL/ExcelLoader';
import { Icon, IconChevronDown, IconSize } from 'componentsL/Icon';
import { Tooltip } from 'componentsL/Tooltip';
import { ExcelGenerator } from 'features/ExcelGenerator';
import { ExcelDataSourceEnum } from 'store/graphql';
import s from './DownloadsBlock.module.scss';

type Props = {
  links: { text: string; href: string }[];
  excel: { dataSource: ExcelDataSourceEnum; entityId?: string; text: string };
};

export const DownloadsBlock = ({ links, excel }: Props) => {
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [targetNode, setTargetNode] = useState<HTMLDivElement | null>(null);
  useClickOutside(userMenuRef.current, () => setMenuOpen(false));

  return (
    <div ref={userMenuRef} className={s.DownloadsBlock}>
      <div
        ref={setTargetNode}
        className={clsx(s.DownloadsBlock__row, menuOpen ? s.DownloadsBlock__open : null)}
        onClick={() => setMenuOpen((prevState) => !prevState)}>
        Скачать
        <Icon
          className={clsx(s.DownloadsBlock__menuArrow, {
            [s.DownloadsBlock__menuArrow_active]: menuOpen
          })}
          icon={IconChevronDown}
          size={IconSize.Small}
        />
      </div>
      {menuOpen && (
        <Tooltip
          classes={{ content: s.Tooltip__content }}
          showArrow={false}
          targetNode={targetNode}
          tooltipOptions={{
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4]
                }
              }
            ]
          }}>
          <div className={s.Tooltip__links}>
            <ExcelGenerator dataSource={excel.dataSource} entityId={excel.entityId}>
              {({ download, loading }) =>
                loading ? (
                  <ExcelLoader />
                ) : (
                  <div className={s.Tooltip__link} onClick={download}>
                    {excel.text}
                  </div>
                )
              }
            </ExcelGenerator>
            {links.map((link, iLink) => (
              <BaseLink key={iLink} className={s.Tooltip__link} href={link.href} download>
                {link.text}
              </BaseLink>
            ))}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
