import React, { useCallback, useState } from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal, ModalFooter } from 'componentsL/Modal';

interface ConfirmModalProps {
  onBack: () => void;
  onConfirm: () => void;
}

export const ConfirmSendModal = ({ onBack, onConfirm }: ConfirmModalProps) => {
  const [isWaining, setWaiting] = useState<boolean>(false);

  const handleConfirm = useCallback(() => {
    setWaiting(true);
    onConfirm();
    // Состояние не сбрасываем, т.к. после завершения окно закрывается
  }, [onConfirm, setWaiting]);

  const backButton = (
    <Button variant={ButtonVariant.Secondary} onClick={onBack}>
      Назад
    </Button>
  );
  const confirmButton = (
    <Button variant={ButtonVariant.Primary} loading={isWaining} onClick={handleConfirm}>
      Да
    </Button>
  );

  return (
    <Modal title="Внимание!" width={456} onClose={isWaining ? false : onBack}>
      В рамках периода заявку по показателю можно подать только один раз. Вы уверены, что хотите подать заявку?
      <ModalFooter left={backButton} right={confirmButton} />
    </Modal>
  );
};
