import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { IconChevronLeft, IconChevronRight } from 'componentsL/Icon';
import { IconButton, IconButtonKind } from 'componentsL/IconButton';
import { CommonMviType, MviType } from '../../types';
import { GaugeCard, GaugeCardSize } from '../GaugeCard/GaugeCard';
import s from './GaugeCardsCarousel.module.scss';

type Props = {
  list: CommonMviType[];
  listType: MviType;
  onSelect: (item?: CommonMviType) => void;
  selected: CommonMviType;
  onClose?: () => void;
};

type Direction = 'left' | 'right';

export const ANIMATION_DURATION = 750;

const revertAnimation = (direction: Direction): Direction => (direction === 'left' ? 'right' : 'left');

const getSlot = (side: Direction, list: CommonMviType[], selectedIdx: number) => {
  if (side === 'left') {
    return list[selectedIdx - 1] || list[list.length - 1];
  }

  return list[selectedIdx + 1] || list[0];
};

export function GaugeCardsCarousel({ list, selected, listType, onSelect, onClose }: Props) {
  const [animation, setAnimation] = useState<Direction | null>(null);
  const selectedIdx = useMemo(() => list.findIndex((el) => el.id === selected.id), [list, selected]);

  const leftSlot = getSlot('left', list, selectedIdx);
  const rightSlot = getSlot('right', list, selectedIdx);

  const renderSideSlot = (slotSide: Direction, slot: CommonMviType) => {
    return (
      <GaugeCard
        classes={{
          root: clsx(
            s.GaugeCardsCarousel__card,
            animation && s.GaugeCardsCarousel__card_animation,
            s[`GaugeCardsCarousel__card_${slotSide}`],
            animation === slotSide ? s.GaugeCardsCarousel__card_selected : animation && s.GaugeCardsCarousel__card_back
          ),
          name: s.GaugeCardsCarousel__name,
          gridItemValue: s.GaugeCardsCarousel__gridItemValue,
          speedometer: {
            cell: s.GaugeCardsCarousel__speedometerCell
          }
        }}
        startAnimation={animation === slotSide}
        size={GaugeCardSize.Large}
        selected={animation === slotSide}
        cardType={listType}
        item={slot}
        onSelect={!animation ? () => onChangeSlot(slotSide) : undefined}
        disabled={animation !== slotSide}
      />
    );
  };

  const onChangeSlot = useCallback(
    (direction: Direction) => {
      setAnimation(direction);
      const item = direction === 'right' ? rightSlot : leftSlot;

      setTimeout(() => {
        onSelect(item);
        setAnimation(null);
      }, ANIMATION_DURATION);
    },
    [leftSlot, onSelect, rightSlot]
  );

  const renderSelectedSlot = () => {
    return (
      <GaugeCard
        classes={{
          root: clsx(
            s.GaugeCardsCarousel__card,
            animation && s.GaugeCardsCarousel__card_animation,
            !animation
              ? s.GaugeCardsCarousel__card_selected
              : s[`GaugeCardsCarousel__card_${revertAnimation(animation)}`]
          ),
          name: s.GaugeCardsCarousel__name,
          gridItemValue: s.GaugeCardsCarousel__gridItemValue,
          speedometer: {
            cell: s.GaugeCardsCarousel__speedometerCell
          }
        }}
        startAnimation={true}
        size={GaugeCardSize.Large}
        cardType={listType}
        item={selected}
        selected={!animation}
        disabled={!!animation}
        onSelect={!animation ? () => onChangeSlot('right') : undefined}
        onClose={!animation ? onClose : undefined}
      />
    );
  };

  const renderBackSlot = (animation: Direction | null) => {
    const item =
      animation === 'left'
        ? getSlot('left', list, list[selectedIdx - 1] ? selectedIdx - 1 : list.length - 1)
        : getSlot('right', list, list[selectedIdx + 1] ? selectedIdx + 1 : 0);

    return (
      <GaugeCard
        classes={{
          root: clsx(
            s.GaugeCardsCarousel__card,
            animation && s.GaugeCardsCarousel__card_animation,
            !animation ? s.GaugeCardsCarousel__card_back : s[`GaugeCardsCarousel__card_${animation}`]
          ),
          name: s.GaugeCardsCarousel__name,
          gridItemValue: s.GaugeCardsCarousel__gridItemValue,
          speedometer: {
            cell: s.GaugeCardsCarousel__speedometerCell
          }
        }}
        size={GaugeCardSize.Large}
        cardType={listType}
        item={item}
        disabled
      />
    );
  };

  const renderLeftButton = () => {
    if (!leftSlot) return <div />;
    return (
      <IconButton
        className={s.GaugeCardsCarousel__button}
        kind={IconButtonKind.Round}
        onClick={!animation ? () => onChangeSlot('left') : undefined}>
        <IconChevronLeft />
      </IconButton>
    );
  };

  const renderRightButton = () => {
    if (!rightSlot) return <div />;
    return (
      <IconButton
        className={s.GaugeCardsCarousel__button}
        kind={IconButtonKind.Round}
        onClick={!animation ? () => onChangeSlot('right') : undefined}>
        <IconChevronRight />
      </IconButton>
    );
  };

  return (
    <>
      <div className={s.GaugeCardsCarousel}>
        {renderLeftButton()}
        <div className={s.GaugeCardsCarousel__container}>
          {renderBackSlot(animation)}
          {renderSideSlot('left', leftSlot)}
          {renderSideSlot('right', rightSlot)}
          {renderSelectedSlot()}
        </div>
        {renderRightButton()}
      </div>
    </>
  );
}
