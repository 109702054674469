import React from 'react';
import clsx from 'clsx';
import s from './Loader.module.scss';

export interface LoaderProps {
  className?: string;
  spinnerClassName?: string;
  spinnerWidth?: number;
  spinnerHeight?: number;
  transparentBgColor?: boolean;
}

export function Loader({ className, spinnerClassName, spinnerWidth, spinnerHeight, transparentBgColor }: LoaderProps) {
  return (
    <div
      className={clsx(s.Loader, className)}
      style={{
        backgroundColor: transparentBgColor ? 'transparent' : undefined
      }}>
      <div
        className={clsx(s.Loader__spinner, spinnerClassName)}
        style={{
          width: spinnerWidth ?? undefined,
          height: spinnerHeight ?? undefined
        }}
      />
    </div>
  );
}
