import { createContext } from 'react';
import { RatingCommentScopeEnum } from 'store/graphql';

export interface ICommentContext {
  scope: RatingCommentScopeEnum;
  scopeId: number;
  scopeIndicatorId?: number | null;
  commentId?: number | null;
  commentText?: string;
  initialDraftText?: string;
  departmentRefetch: () => void;
  regionRefetch: () => void;
}

export const CommentContext = createContext<ICommentContext>({
  scope: RatingCommentScopeEnum.Department,
  scopeId: -1,
  scopeIndicatorId: undefined,
  commentText: undefined,
  commentId: undefined,
  initialDraftText: undefined,
  departmentRefetch: () => {},
  regionRefetch: () => {}
});
