import React from 'react';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const digitalServicesShare = components[0]?.value;
  const epguServices = components[1]?.value;
  const planningEpguServices = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={digitalServicesShare} unit={'%'} description={'Доля услуг в электронном виде'}>
        <Symbol sub={'УСЛ_ЕПГУ'}>Д</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={epguServices}
        roundUp
        description={
          'Количество услуг и функций, оказываемых в электронном виде посредством ЕПГУ, с учетом вывода всех подуслуг'
        }>
        <Symbol sub={'ЕПГУ'}>N</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={planningEpguServices}
        roundUp
        description={
          'Общее количество услуг и функций, запланированных к выводу на ЕПГУ, в соответствии с планами на отчетный год'
        }>
        <Symbol sub={'общ'}>N</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'Доля государственных услуг ФОИВ, доступных в электронном виде, составляет 0–20,9%']} />
      <TableRow
        values={['0,5', 'Доля государственных услуг ФОИВ, доступных в электронном виде, составляет 21,0–40,9%']}
      />
      <TableRow
        values={['1,0', 'Доля государственных услуг ФОИВ, доступных в электронном виде, составляет 41,0–60,9%']}
      />
      <TableRow
        values={['1,5', 'Доля государственных услуг ФОИВ, доступных в электронном виде, составляет 61,0–80,9%']}
      />
      <TableRow
        values={['2,0', 'Доля государственных услуг ФОИВ, доступных в электронном виде, составляет 81,0–100%']}
      />
    </Table>
  );
}

export function DigitalServicesOnEpgu() {
  return (
    <IndicatorPage
      download={'Доля услуг в электронном виде.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
    />
  );
}
