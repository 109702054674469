import React from 'react';
import { formatNumber } from '@digital-gov/ui-utils';
import {
  Icon,
  IconAlert,
  IconChangeDown,
  IconChangeUp,
  IconGoblet,
  IconNotification,
  IconSize
} from 'componentsL/Icon';
import { IndicatorsTableRow } from '../types';
import { DynamicsMark } from './DynamicsMark';
import { RecommendationIcon } from './RecommendationIcon';
import { TableCell } from './TableCell';
import s from './TableRow.module.scss';

type Props = IndicatorsTableRow & { idx: number };

export const TableRow = ({
  idx,
  indicatorName,
  value,
  valueDynamic,
  valueMax,
  indicatorProgressGroup,
  successMark,
  alertMark,
  linkTo,
  availableForDetails,
  hasFeedback,
  hasRecommendations
}: Props) => {
  const renderStarIcon = () => {
    if (!successMark) return null;
    return <Icon className={s.TableRow__star} icon={IconGoblet} size={IconSize.XMedium} />;
  };

  const renderAlertIcon = () => {
    if (!alertMark) return null;
    return <Icon className={s.TableRow__alert} icon={IconAlert} size={IconSize.XMedium} />;
  };

  const renderDynamicsIcon = () => {
    if (!valueDynamic) return null;
    if (valueDynamic > 0) {
      return <Icon className={s.TableRow__changeUp} icon={IconChangeUp} size={IconSize.Small} />;
    } else {
      return <Icon className={s.TableRow__changeDown} icon={IconChangeDown} size={IconSize.Small} />;
    }
  };

  const renderValueDymanics = () => {
    return formatNumber(valueDynamic);
  };

  return (
    <React.Fragment>
      <TableCell linkTo={linkTo} linkAvailable={availableForDetails} className={s.TableRow__cellId}>
        {idx}
      </TableCell>
      <TableCell linkTo={linkTo} linkAvailable={availableForDetails}>
        <DynamicsMark progressGroup={indicatorProgressGroup} />
        {indicatorName}
        <div className={s.TableRow__icons}>
          {hasFeedback && <Icon icon={IconNotification} />}
          {hasRecommendations && <RecommendationIcon indicatorProgressGroup={indicatorProgressGroup} />}
        </div>
      </TableCell>
      <TableCell linkTo={linkTo} linkAvailable={availableForDetails} className={s.TableRow__cellValue}>
        {formatNumber(value)}
        {renderStarIcon()}
        {renderAlertIcon()}
      </TableCell>
      <TableCell linkTo={linkTo} linkAvailable={availableForDetails} className={s.TableRow__cellValue}>
        {renderValueDymanics()}
        {renderDynamicsIcon()}
      </TableCell>
      <TableCell linkTo={linkTo} linkAvailable={availableForDetails} className={s.TableRow__cellValue}>
        {formatNumber(valueMax)}
      </TableCell>
    </React.Fragment>
  );
};
