import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { PageLoader } from 'components/PageLoader';
import { useRctListQuery } from 'store/graphql';
import { useLinkRctToScope } from '../_hooks';
import { useRctControlContext } from '../_store/useRctControlContext';
import { RctControlScopeEnum, RctType } from '../_utils';
import { Divider, Rct, RctEditor, RctList, Scope } from './_components';
import s from './ChangeRct.module.scss';

export interface ChangeRegionRctProps {
  scope: RctControlScopeEnum;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
  initViewMode?: ChangeRctViewMode;
}

enum ChangeRctViewMode {
  'base' = 'base',
  'edit' = 'edit',
  'add' = 'add'
}

export function ChangeRct({
  initViewMode = ChangeRctViewMode.base,
  scope,
  onClose,
  onSuccess,
  onError
}: ChangeRegionRctProps) {
  // Query загрузки списка ФРЦТ/РРЦТ в зависимости от scope
  const {
    loading: rctListLoading,
    data,
    error,
    refetch
  } = useRctListQuery({
    variables: { isFrct: scope === RctControlScopeEnum.DepartmentRct }
  });

  if (error) onError();

  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState<ChangeRctViewMode>(initViewMode);
  const { currentRct, currentScopeObject, setRctList, setCurrentRct } = useRctControlContext();
  const context = useRctControlContext();

  const rctList = useMemo(() => {
    return scope === RctControlScopeEnum.DepartmentRct
      ? (data?.rating.frctList ?? [])
      : scope === RctControlScopeEnum.RegionRct
        ? (data?.rating.rrctList ?? [])
        : [];
  }, [scope, data]);

  useEffect(() => {
    setLoading(rctListLoading);
  }, [rctListLoading]);

  useEffect(() => {
    setRctList(rctList);
  }, [rctList, setRctList]);

  useEffect(() => {
    if (!currentRct) {
      return;
    }
    const rct = rctList.find((r) => r.userId === currentRct?.userId);
    if (rct) {
      setCurrentRct(rct);
    }
  }, [rctList, setCurrentRct, currentRct]);

  // сохранение возможности перезапроса списка РЦТ (понадобится после обновлений)
  context.refetchRctList = refetch;

  // хендлеры переключения режима редактирования РЦТ
  const handleSwitchToEditMode = () => setViewMode(ChangeRctViewMode.edit);
  const handleSwitchToAddMode = () => setViewMode(ChangeRctViewMode.add);
  const handleSwitchToBaseMode = () => setViewMode(ChangeRctViewMode.base);
  const sideControlsDisabled = viewMode === ChangeRctViewMode.edit || viewMode === ChangeRctViewMode.add;

  // хендлер обновления данных из editor
  const handleRctUpdate = useCallback(
    (rct: RctType, mode: 'add' | 'edit') => {
      if ((mode === 'add' && !currentRct) || mode === 'edit') {
        setCurrentRct(rct);
      }
    },
    [currentRct, setCurrentRct]
  );

  // Хендлер изменения РЦТ ФОИВ-а / РОИВ-а (данные берутся из контекста)
  const { linkRctToScope } = useLinkRctToScope({
    onError,
    onSuccess
  });
  const handleLinkRctToScope = useCallback(async () => {
    setLoading(true);
    await linkRctToScope();
    setLoading(false);
  }, [linkRctToScope, setLoading]);

  return (
    <Modal title={'Изменение РЦТ'} onClose={onClose} width={624}>
      {loading && <PageLoader />}
      {!loading && (
        <div className={s.ChangeRct}>
          <Scope name={currentScopeObject.name} />
          <Divider />
          <div className={s.ChangeRct__controlGroup}>
            <RctList disabled={sideControlsDisabled} />
            {viewMode === ChangeRctViewMode.base && <Rct handleSwitchToEditMode={handleSwitchToEditMode} />}
            {viewMode === ChangeRctViewMode.edit && (
              <RctEditor
                mode={'edit'}
                currentRct={currentRct ?? undefined}
                onError={onError}
                onSuccess={handleSwitchToBaseMode}
                onBack={handleSwitchToBaseMode}
                handleUpdate={handleRctUpdate}
              />
            )}
            {viewMode === ChangeRctViewMode.add && (
              <RctEditor
                mode={'add'}
                onError={onError}
                onSuccess={handleSwitchToBaseMode}
                onBack={handleSwitchToBaseMode}
                handleUpdate={handleRctUpdate}
              />
            )}
            <Button
              disabled={sideControlsDisabled}
              className={s.ChangeRct__createButton}
              onClick={handleSwitchToAddMode}>
              Создать +
            </Button>
          </div>
          <Button className={s.ChangeRct__addButton} disabled={sideControlsDisabled} onClick={handleLinkRctToScope}>
            Сохранить
          </Button>
        </div>
      )}
    </Modal>
  );
}
