import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import s from './ButtonLink.module.scss';

export enum ButtonLinkType {
  Primary = 'primary',
  Secondary = 'secondary',
  Bright = 'bright'
}

export interface ButtonLinkProps {
  type?: ButtonLinkType;
  disabled?: boolean;
  activated?: boolean;
  to?: string;
  stateTo?: object;
  preventScrollReset?: boolean;
  href?: string;
  download?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
  children: string;
  className?: string;
}

export function ButtonLink({
  type = ButtonLinkType.Primary,
  disabled,
  activated,
  to,
  stateTo,
  preventScrollReset,
  href,
  download,
  onClick,
  iconLeft: IconLeft,
  iconRight: IconRight,
  children,
  className
}: ButtonLinkProps) {
  const buttonLinkClassName = clsx(
    s.ButtonLink,
    s[`ButtonLink_${type}`],
    disabled && s.ButtonLink_disabled,
    !disabled && activated && s.ButtonLink_activated,
    className
  );

  const buttonLinkContent = (
    <React.Fragment>
      {IconLeft && <IconLeft />}
      {children}
      {IconRight && <IconRight />}
    </React.Fragment>
  );

  if (to && !disabled && !activated) {
    return (
      <Link className={buttonLinkClassName} to={to} state={stateTo} preventScrollReset={preventScrollReset}>
        {buttonLinkContent}
      </Link>
    );
  } else if (href && !disabled && !activated) {
    return (
      <a href={href} target={'_blank'} rel={'noreferrer'} className={buttonLinkClassName} download={download}>
        {buttonLinkContent}
      </a>
    );
  } else {
    const handleClick = !disabled ? onClick : undefined;
    return (
      <button disabled={disabled} onClick={handleClick} className={buttonLinkClassName}>
        {buttonLinkContent}
      </button>
    );
  }
}
