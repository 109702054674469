import React, { useState } from 'react';
import { tryParseJSON, useQueryParams } from '@digital-gov/ui-utils';
import { RatingGroupType } from '../types';
import { URL_KEY_DEP_CHART_PARAMS } from '../urlKeys';
import { DepartmentChartContext } from './DepartmentChartContext';

export interface DepartmentChartProviderProps {
  children?: React.ReactNode;
}

export const DepartmentChartProvider = ({ children }: DepartmentChartProviderProps) => {
  const [viceChairman, setViceChairman] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [group, setGroup] = useQueryParams<RatingGroupType | null>(URL_KEY_DEP_CHART_PARAMS, null, {
    getter: (value) => {
      return tryParseJSON(value) || null;
    },
    setter: (value) => {
      return value ? JSON.stringify(value) : '';
    }
  });

  return (
    <DepartmentChartContext.Provider
      value={{
        group,
        setGroup,
        searchValue,
        setSearchValue,
        viceChairman,
        setViceChairman
      }}>
      {children}
    </DepartmentChartContext.Provider>
  );
};
