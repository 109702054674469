import React from 'react';
import { formatNumber } from '@digital-gov/ui-utils';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as FormulaPrimary } from './formula.svg';
import { ReactComponent as FormulaSecond } from './responsesTimelinessFormula.svg';
import { ReactComponent as FormulaThird } from './satisfactionLevelFormula.svg';
import s from './FeedbackPlatform.module.scss';

function IndicatorFormula() {
  return <FormulaPrimary />;
}

export function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const appealsIntegration = components[0]?.value;
  const surveyAndVoteIntegration = components[1]?.value;
  const responsesTimeliness = components[2]?.value;
  const satisfactionLevel = components[3]?.value;
  return (
    <SymbolsGrid columns={4}>
      <div className={s.FeedbackPlatform__column}>
        <p className={s.FeedbackPlatform__column_h}>{'Внедрение компонента обращений'}</p>
        <SymbolDetails value={appealsIntegration} description={''}>
          <Symbol>OO</Symbol>
        </SymbolDetails>
        <p className={s.FeedbackPlatform__column_p}>{'На сайте ФОИВ размещен виджет ПОС'}</p>
        <p className={s.FeedbackPlatform__column_p}>{'Осуществляется прием и обработка поступающих обращений'}</p>
      </div>
      <div className={s.FeedbackPlatform__column}>
        <p className={s.FeedbackPlatform__column_h}>{'Внедрение компонента опросов и голосований'}</p>
        <SymbolDetails value={surveyAndVoteIntegration} description={''}>
          <Symbol>ОГ</Symbol>
        </SymbolDetails>
        <p className={s.FeedbackPlatform__column_p}>{'Баллы проставляются при одновременном выполнении условий:'}</p>
        <p className={s.FeedbackPlatform__column_p}>
          1. В предыдущем месяце в ОГ ПОС размещен новый активный опрос (федеральный опрос <br />в статусе «в процессе
          сбора обратной связи»)
        </p>
        <p className={s.FeedbackPlatform__column_p}>{'2. На официальном сайте ФОИВ размещен виджет ОГ ПОС'}</p>
      </div>

      <div className={s.FeedbackPlatform__column}>
        <p className={s.FeedbackPlatform__column_h}>{'Своевременность ответов'}</p>
        <div style={{ display: 'grid', gridTemplateColumns: '[c0] 1fr [c1] 1fr [end]' }}>
          <div style={{ gridColumn: 'c0 / c1' }}>
            <FormulaSecond className={s.FeedbackPlatform__column_svg} />
          </div>
          <div style={{ gridColumn: 'c1 / end' }} className={s.FeedbackPlatform__customValue}>
            {formatNumber(responsesTimeliness)}
          </div>
        </div>
        <div style={{ marginBottom: '8px' }}></div>
        <p className={s.FeedbackPlatform__column_p}>
          <i className={s.FeedbackPlatform__column_gold}>Асв</i>&nbsp; – показатель современности ответов
        </p>
        <p className={s.FeedbackPlatform__column_p}>
          <i className={s.FeedbackPlatform__column_white}>Котр</i>&nbsp; – количество обращений, регламентный срок
          ответа на которые входит в отчетный период и обработанных в установленный срок
        </p>
        <p className={s.FeedbackPlatform__column_p}>
          <i className={s.FeedbackPlatform__column_white}>Кпл</i>&nbsp;
          {'– количество обращений, регламентный срок ответа на которые входит в отчетный период'}
        </p>
      </div>

      <div className={s.FeedbackPlatform__column}>
        <p className={s.FeedbackPlatform__column_h}>{'Уровень удовлетворенности'}</p>
        <div style={{ display: 'grid', gridTemplateColumns: '[c0] 1fr [c1] 1fr [end]' }}>
          <div style={{ gridColumn: 'c0 / c1' }}>
            <FormulaThird className={s.FeedbackPlatform__column_svg} />
          </div>
          <div style={{ gridColumn: 'c1 / end' }} className={s.FeedbackPlatform__customValue}>
            {formatNumber(satisfactionLevel)}
          </div>
        </div>
        <div style={{ marginBottom: '8px' }}></div>
        <p className={s.FeedbackPlatform__column_p}>
          <i className={s.FeedbackPlatform__column_gold}>Ауд</i>&nbsp;
          {'– показатель уровня удовлетворенности'}
        </p>
        <p className={s.FeedbackPlatform__column_p}>
          <i className={s.FeedbackPlatform__column_white}>Кпол</i>&nbsp; – количество обращений обработанных <br />в
          отчетном периоде, в которых уровень удовлетворенности граждан ответами <br />
          не ниже «4»
        </p>
        <p className={s.FeedbackPlatform__column_p}>
          <i className={s.FeedbackPlatform__column_white}>Коц</i>&nbsp; – количество обращений, обработанных <br />в
          отчетном периоде и получивших оценку
        </p>
      </div>
    </SymbolsGrid>
  );
}

export function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'ПОС не внедрен, соглашение о взаимодействии не заключено']} />
      <TableRow values={['0,5', 'Внедрение компонента обращений']} />
      <TableRow values={['0,5', 'Внедрение компонента опросов и голосований']} />
      <TableRow values={['до 0,5', 'Своевременность ответов']} />
      <TableRow values={['до 0,5', 'Уровень удовлетворенности']} />
      <TableRow values={['', 'Дополнительные баллы за внедрение ПОС']} isHeader />
      <TableRow
        values={[
          '0,5',
          <>
            Внедрен компонент обращений ПОС в территориальных подразделениях и подведомственных организациях
            <br />
            (при наличии): в ПОС функционируют ЛКО всех территориальных органов и подведомственных организаций ФОИВ,
            которые могут обрабатывать обращения
          </>
        ]}
      />
      <TableRow values={['0,5', 'В опросе федерального уровня приняли участие более 1000 человек']} />
      <TableRow
        values={[
          '0,5',
          'На базе компонента опросов и голосований проведено крупное социально-значимое мероприятие (более 100 тыс. человек)'
        ]}
      />
      <TableRow values={['0,5', 'ФОИВ в отчетном периоде обработано в ПОС более 1000 обращений']} />
      <TableRow values={['', 'Понижающие коэффициенты']} isHeader />
      <TableRow values={['- 0,25', 'Не заключено соглашение о взаимодействии для внедрения ПОС']} />
      <TableRow values={['- 0,25', 'Не обеспечена реализация существенных условий соглашения по ПОС']} />
      <TableRow values={['', 'Количество баллов по показателю ПОС']} isHeader />
      <TableRow values={['до 2', 'Внедрение ПОС в ФОИВ']} />
      <TableRow
        values={['до 4', 'Максимальное значение показателя при выполнении условий получения дополнительных баллов']}
      />
    </Table>
  );
}

export function FeedbackPlatform() {
  return (
    <IndicatorPage
      download={'Платформа обратной связи.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      dynamicChartPreventGrow
    />
  );
}
