import { useRef, useState } from 'react';
import clsx from 'clsx';
import { formatNumber, useClickOutside } from '@digital-gov/ui-utils';
import { ButtonLink, ButtonLinkType } from 'components/Button';
import { Tooltip } from 'componentsL/Tooltip';
import { IndicatorDetalizationCellType, useGetDetalizationUrlLazyQuery } from 'store/graphql';
import s from './DetalizationCell.module.scss';

export interface DetalizationCellProps {
  cell: IndicatorDetalizationCellType;
  indicatorId: number;
}

export function DetalizationCell({ cell, indicatorId }: DetalizationCellProps) {
  const [getDownloadUrl] = useGetDetalizationUrlLazyQuery({
    fetchPolicy: 'network-only'
  });

  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [targetNode, setTargetNode] = useState<HTMLDivElement | null>(null);

  // загрузка или Preview файла
  const handleDownload = async (cell: IndicatorDetalizationCellType, preview: boolean) => {
    const { calculationId, indicatorTimePeriodId, vpctPeriod } = cell.detalizationFileMetadata!;
    const linkNode = document.createElement('a');
    const { data } = await getDownloadUrl({
      variables: {
        input: {
          indicatorId: indicatorId,
          calculationId: calculationId,
          indicatorTimePeriodId: indicatorTimePeriodId,
          vpctPeriod: vpctPeriod,
          preview
        }
      }
    });
    const downloadedURL = data?.detalizationPresignedUrl;
    if (!downloadedURL) {
      console.error('Ошибка запроса URL на скачивание файла');
      return;
    }
    linkNode.href = downloadedURL;
    if (preview) {
      linkNode.target = '_blank';
    }
    linkNode.click();
    setMenuOpen(false);
  };

  useClickOutside(userMenuRef.current, () => setMenuOpen(false));

  return (
    <div ref={userMenuRef} className={clsx(s.DetalizationCell, menuOpen && s.DetalizationCell__tooltipOpen)}>
      <div ref={setTargetNode} onClick={() => setMenuOpen((prevState) => !prevState)}>
        <button className={s.DetalizationCell__valueButton}>{formatNumber(+cell.value, 2)}</button>
      </div>
      {menuOpen && (
        <Tooltip
          classes={{ content: s.Tooltip__content }}
          showArrow={false}
          targetNode={targetNode}
          tooltipOptions={{
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4]
                }
              }
            ]
          }}>
          <div className={s.Tooltip__links}>
            <ButtonLink
              className={s.Tooltip__link}
              type={ButtonLinkType.Bright}
              onClick={() => handleDownload(cell, true)}>
              Просмотр
            </ButtonLink>
            <ButtonLink
              className={s.Tooltip__link}
              type={ButtonLinkType.Bright}
              onClick={() => handleDownload(cell, false)}>
              Скачать
            </ButtonLink>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
