import React, { useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { Button, ButtonVariant } from 'componentsL/Button';
import { IconDismiss } from 'componentsL/Icon';
import { SearchInput } from 'componentsL/Input/SearchInput/SearchInput';
import { Select } from 'componentsL/Select/Select';
import { fullNameInitials } from 'utils/fullNameInitials';
import { useDepartmentChartContext } from '../ChartProvider/useDepartmentChartContext';
import s from './ChartHeader.module.scss';

export interface ChartHeaderProps {
  availableViceChairmen: string[];
}

export const ChartHeader = ({ availableViceChairmen }: ChartHeaderProps) => {
  const { searchValue, setSearchValue, viceChairman, setViceChairman } = useDepartmentChartContext();

  const handleResetClick = () => {
    setSearchValue('');
    setViceChairman('');
  };

  const viceChairmenSelectOptions = useMemo(() => {
    if (!availableViceChairmen.length) return [];
    const _availableViceChairmen: {
      fullNameInitials: string;
      initials: string;
      family: string;
    }[] = availableViceChairmen.map((viceChairman) => {
      const [initials, family] = viceChairman.split(' ');
      return {
        fullNameInitials: fullNameInitials(viceChairman),
        initials,
        family
      };
    });

    return [
      ...orderBy(_availableViceChairmen, ['family'], ['asc']).map((v) => ({
        label: v.fullNameInitials,
        value: `${v.initials} ${v.family}`
      }))
    ];
  }, [availableViceChairmen]);

  const isFiltered = searchValue || viceChairman;

  return (
    <div className={s.ChartHeader}>
      <div className={s.ChartHeader__filters}>
        <SearchInput
          className={s.ChartHeader__search}
          placeholder={'Поиск'}
          value={searchValue}
          onChange={setSearchValue}
        />
        <Select
          className={s.ChartHeader__filter}
          placeholder={'Вице-премьер'}
          options={viceChairmenSelectOptions}
          value={viceChairman}
          onChange={(v) => setViceChairman(v || '')}
        />
        {isFiltered && (
          <Button iconLeft={IconDismiss} variant={ButtonVariant.LinkSecondary} onClick={handleResetClick}>
            Сбросить
          </Button>
        )}
      </div>
    </div>
  );
};
