import React from 'react';
import { DATE_FULL_DAY_SHORT, formatDate } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'componentsL/Button';
import { ExcelLoader } from 'componentsL/ExcelLoader';
import { IconDownload } from 'componentsL/Icon';
import { PageHeader } from 'componentsL/PageHeader';
import { ScopeSwitch } from 'componentsL/ScopeSwitch';
import { ScopeEnum } from 'componentsL/ScopeSwitch/ScopeSwitch';
import { ExcelGenerator } from 'features/ExcelGenerator';
import { ExcelDataSourceEnum, useDepartmentsPageQuery } from 'store/graphql';
import { DepartmentsChart } from './DepartmentsChart';
import s from './DepartmentsPage.module.scss';

export function DepartmentsPage() {
  const { data, loading } = useDepartmentsPageQuery();
  const departments = data?.rating.departmentRating.departments;

  const lastSubmitDate = data?.rating.departmentRating.lastUpdateDate;

  return (
    <>
      <PageHeader
        className={s.DepartmentsPageHeader__root}
        title={'Оперативный рейтинг ФРЦТ'}
        slotRight={
          <div className={s.DepartmentsPageHeader}>
            {lastSubmitDate && (
              <p className={s.DepartmentsPageHeader__date}>
                Обновление: {formatDate(lastSubmitDate, DATE_FULL_DAY_SHORT)}
              </p>
            )}
            <ExcelGenerator dataSource={ExcelDataSourceEnum.DepartmentsChart}>
              {({ download, loading }) =>
                loading ? (
                  <ExcelLoader />
                ) : (
                  <div className={s.DepartmentsPageHeader__excel}>
                    <Button
                      Component={'a'}
                      variant={ButtonVariant.LinkPrimary}
                      iconLeft={IconDownload}
                      onClick={download}
                      download>
                      Скачать
                    </Button>
                  </div>
                )
              }
            </ExcelGenerator>
            <ScopeSwitch scope={ScopeEnum.Frct} />
          </div>
        }
      />
      <DepartmentsChart departments={departments} loading={loading} />
    </>
  );
}
