import { ScopeEnum, ScopeTabType } from 'componentsL/ScopeSwitch/ScopeSwitch';

export enum MviType {
  Frct = 'frct',
  Rrct = 'rrct'
}

export const MviTabs: ScopeTabType<ScopeEnum>[] = [
  {
    label: 'ФРЦТ',
    value: ScopeEnum.Frct,
    path: '/mvi/frct'
  },
  {
    label: 'РРЦТ',
    value: ScopeEnum.Rrct,
    path: '/mvi/rrct'
  }
];

export type ViewMode = 'carousel' | 'list';

export type CommonMviType = {
  name: string;
  averageDegree: number;
  pointsMax: number;
  pointsAverage: number;
  dynamic?: number | null;
  quantity: number;
  minShare: number;
  maxShare: number;
  id: number;
};
