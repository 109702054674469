import { ActionModal } from 'components/Modal';

export interface RctControlErrorProps {
  errorTitle: string;
  errorMessage?: string;
  handleBack: () => void;
  handleClose: () => void;
}

export function RctControlError({
  errorTitle,
  errorMessage = 'Произошла ошибка. Попробуйте повторить операцию позднее.',
  handleBack,
  handleClose
}: RctControlErrorProps) {
  return (
    <ActionModal
      title={errorTitle}
      message={errorMessage}
      width={624}
      onClose={handleClose}
      actions={[{ name: 'Назад', onAction: handleBack }]}
    />
  );
}
