import React, { useCallback } from 'react';
import { ExcelDataSourceEnum, useExcelDownloadUrlLazyQuery } from 'store/graphql';

/**
 * Хелперы - вспомогательные функции для дочерних элементов
 * - к примеру если у элемента есть атрибут onClick - ему можно присвоить одну из функций в хелперах
 * (в данном случае download)
 */
export type ExcelGeneratorHelpersType = {
  loading: boolean;
  download: () => void;
};

/**
 * Пропсы принимаемые генератором
 *
 * - dataSource - тип генерируемого Excel документа, обязательный параметр
 * - entityId - опциональный параметр, необходим для генерации Excel по конкретным РОИВ / ФОИВ
 * - children - дочерний элемент ExcelGenerator - это функция, которая возвращает дочерний компонент
 * с переданными обработчиками из helpers
 */
export interface ExcelGeneratorProps {
  dataSource: ExcelDataSourceEnum;
  entityId?: string;
  children?: (helpers: ExcelGeneratorHelpersType) => React.ReactNode;
}

export function ExcelGenerator({ entityId, dataSource, children }: ExcelGeneratorProps) {
  const [getDownloadUrl, { loading }] = useExcelDownloadUrlLazyQuery({ fetchPolicy: 'network-only' });

  /**
   * Обработчик для download (здесь могли бы быть ещё другие обработчики):
   * - пока loading - ничего не происходит
   * - иначе возврат данных генерация 'a' переход по ней
   * - пока происходит получение ссылки -
   */
  const downloadExcel = useCallback(async () => {
    try {
      if (loading) return;

      const { data } = await getDownloadUrl({ variables: { dataSource, id: entityId } });
      const downloadUrl = data?.url;

      if (!downloadUrl) {
        console.error('Ошибка запроса URL на скачивание файла');
        return;
      }
      const linkNode = document.createElement('a');
      linkNode.href = downloadUrl;
      linkNode.click();
    } catch (e) {
      console.error(e);
    }
  }, [getDownloadUrl, dataSource, entityId, loading]);

  /**
   * Хелперу download, которая может быть установлена в onClick
   * присваивается downloadExcel
   */
  const content = children?.({
    loading: loading,
    download: downloadExcel
  });

  return <>{content}</>;
}
