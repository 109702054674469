import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { RatingFeedbackScope, useSendFeedbackMutation } from 'store/graphql';
import { FeedbackFormController } from '../controllers/feedbackFormController';
import { ProposalFormController } from '../controllers/proposalFormController';
import { FeedbackContext } from '../feedback-context';

export interface SendersHookOptions {
  scope: RatingFeedbackScope;
  form: FeedbackFormController<number> | ProposalFormController<number>;
  context: FeedbackContext;
  onSuccess: (indicatorId: number | void) => void;
  onFailed: () => void;
}

export function useSender({ scope, form, context, onSuccess, onFailed }: SendersHookOptions) {
  const apolloClient = useApolloClient();
  const [sendFeedback] = useSendFeedbackMutation();
  const { scopeId, selectedIndicatorId } = context;

  return useCallback(async () => {
    try {
      const data = await sendFeedback({
        variables: {
          input: {
            scope,
            scopeId: scopeId,
            indicatorId: selectedIndicatorId,
            attachmentIds: Array.from(form.files.values()).map((uploader) => uploader.id!),
            commentary: form.comment,
            subject: form instanceof FeedbackFormController ? void 0 : form.subject
          }
        }
      });

      await apolloClient.resetStore();

      // Здесь данные должны быть, т.к. отсутствовать они могут только в случае ошибки (обрабатывается в catch)
      const id = data!.data!.result;

      // Тут profile всегда не пуст, т.к. доступ сюда есть только авторизованным
      onSuccess(selectedIndicatorId);
    } catch {
      onFailed();
    }
  }, [scope, scopeId, selectedIndicatorId, form, onSuccess, onFailed, sendFeedback, apolloClient]);
}
