import React from 'react';
import clsx from 'clsx';
import { DATE_FULL, formatDate } from '@digital-gov/ui-utils';
import { Icon, IconSize } from 'componentsL/Icon';
import { PublishedIcon, UnpublishedIcon } from '../icons';
import s from './PeriodItem.module.scss';

export interface IPeriodItemProps {
  dateSubmit: string;
  // флаг опубликован-ли период или нет, по нему определяется иконка
  published: boolean;
  // доступность для выбора - если false такой период надо отобразить в списке но нельзя выбрать и его как
  available: boolean;
}

export function PeriodItem({ published, available, dateSubmit }: IPeriodItemProps) {
  return (
    <div className={s.PeriodItem}>
      <Icon
        icon={published ? PublishedIcon : UnpublishedIcon}
        size={IconSize.Small}
        className={clsx({
          [s.PeriodItem__icon]: true,
          [s.PeriodItem__icon_inactive]: !published || !available
        })}
      />
      <div
        className={clsx({
          [s.PeriodItem_date]: true,
          [s.PeriodItem__date_inactive]: !available
        })}>
        {formatDate(dateSubmit, DATE_FULL)}
      </div>
    </div>
  );
}
