import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { DATETIME_FULL, EMDASH, formatDate } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'componentsL/Button';
import { ErrorMessage } from 'componentsL/ErrorMessage';
import { TextArea } from 'componentsL/Input';
import { Loader } from 'componentsL/Loader';
import { Modal } from 'componentsL/Modal';
import { useUpsertCommentDraftMutation } from 'store/graphql';
import { isDepartmentScope, isIndicatorScope, isRegionScope } from '../_utils/helpers';
import { CommentType } from '../_utils/types';
import { useCommentContext } from '../context/useCommentContext';
import s from './CommentSection.module.scss';

export interface CommentSectionProps {
  onBack: () => void;
  onError: () => void;
  onDraftAlert: () => void;
  onConfirmPublishing: () => void;
  entityName?: string | null;
  comments?: CommentType[] | null;
}

enum SubmitType {
  'save',
  'publish'
}

const isUndefinedOrEmpty = (text?: string) => text === undefined || text === '';

export function CommentSection({
  entityName,
  comments,
  onBack,
  onError,
  onDraftAlert,
  onConfirmPublishing
}: CommentSectionProps) {
  const context = useCommentContext();
  const [submitType, setSubmitType] = useState(SubmitType.save);
  const [comment, setComment] = useState(context.commentText ?? undefined);
  const [upsertCommentDraftMutation, { loading }] = useUpsertCommentDraftMutation();
  const [refetchLoading, setRefetchLoading] = useState(false);

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.currentTarget.value);
      context.commentText = event.currentTarget.value;
    },
    [context]
  );

  const handleSaveDraftButton = () => {
    setSubmitType(SubmitType.save);
  };

  const handlePublishCommentButton = () => {
    setSubmitType(SubmitType.publish);
  };

  const saveDraft = useCallback(async () => {
    if (context.commentText !== undefined) {
      try {
        await upsertCommentDraftMutation({
          variables: {
            scope: context.scope,
            scopeId: context.scopeId,
            scopeIndicatorId: context.scopeIndicatorId,
            commentId: context.commentId ?? null,
            commentText: context.commentText
          }
        });
        // перезапрос данных для обновления счётчика и черновика
        setRefetchLoading(true);
        if (isDepartmentScope(context.scope) && context.departmentRefetch) {
          await context.departmentRefetch();
        }
        if (isRegionScope(context.scope) && context.regionRefetch) {
          await context.regionRefetch();
        }
        setRefetchLoading(false);
      } catch (e) {
        setRefetchLoading(false);
        onError();
      }
    } else {
      onError();
    }
  }, [upsertCommentDraftMutation, context, onError]);

  const handleFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (submitType === SubmitType.save) {
        await saveDraft();
      } else {
        onConfirmPublishing();
      }
    },
    [submitType, onConfirmPublishing, saveDraft]
  );

  // если комментарий был изменён - нужно его сохранить
  const handleBack = () => {
    if (
      context.initialDraftText === context.commentText ||
      (isUndefinedOrEmpty(context.initialDraftText) && isUndefinedOrEmpty(context.commentText))
    ) {
      onBack();
    } else {
      onDraftAlert();
    }
  };

  const publishButtonDisabled = loading || refetchLoading || comment === undefined || comment?.length === 0;
  const saveButtonDisabled = loading || refetchLoading || comment === undefined;

  const title = isIndicatorScope(context.scope) ? (
    <>
      Рекомендация по показателю:
      <br />
      {entityName}
    </>
  ) : (
    `Комментарий${entityName ? ': ' + entityName : ''} `
  );

  const haveComments = comments && comments.length > 0;

  return (
    <Modal title={title} width={624} onClose={handleBack}>
      <div className={s.CommentSection}>
        <form onSubmit={handleFormSubmit}>
          <TextArea
            label={isIndicatorScope(context.scope) ? 'Рекомендация' : 'Комментарий'}
            placeholder={isIndicatorScope(context.scope) ? 'Введите рекомендацию' : 'Введите комментарий'}
            classes={{ input: s.CommentSection__input }}
            value={comment}
            onChange={handleCommentChange}
          />
          <div className={s.CommentSection__buttonGroup}>
            <Button
              variant={ButtonVariant.LinkSecondary}
              type="submit"
              disabled={saveButtonDisabled}
              onClick={handleSaveDraftButton}
              className={clsx(s.CommentSection__saveDraftButton, { [s.Button_disabled]: saveButtonDisabled })}>
              {loading || refetchLoading ? (
                <Loader spinnerWidth={20} spinnerHeight={20} transparentBgColor={true} />
              ) : (
                <div>Сохранить</div>
              )}
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              type="submit"
              disabled={publishButtonDisabled}
              onClick={handlePublishCommentButton}>
              Опубликовать
            </Button>
          </div>
        </form>
        <div className={s.CommentSection__history}>
          <div className={s.CommentSection__historyLabel}>История {haveComments ? `(${comments?.length})` : ''}</div>
          {(!comments || comments.length === 0) && (
            <ErrorMessage
              className={s.CommentSection__infoMessage}
              description={
                isIndicatorScope(context.scope)
                  ? 'Нет ранее опубликованных рекомендаций'
                  : 'Нет ранее опубликованных комментариев'
              }
              errorType={'info'}
            />
          )}
          {comments && comments.length > 0 && (
            <div className={s.CommentSection__historyItems}>
              {comments.map((comment, index) => {
                const lastComment = index + 1 === comments?.length;
                return (
                  <div key={index} className={!lastComment ? s.CommentSection__historyItem : undefined}>
                    <div className={s.CommentSection__historyItemDate}>
                      {comment.publishDate && comment.publishDate !== ''
                        ? formatDate(comment.publishDate, DATETIME_FULL).replace(',', ' |')
                        : EMDASH}
                    </div>
                    <div className={s.CommentSection__baseText}>{comment.commentText}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
