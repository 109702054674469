import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const indicatorsSum = components[0]?.value;
  const programApproval = components[1]?.value;
  const dataTimelinessAndCompleteness = components[2]?.value;
  const softwareIndependenceEnrichment = components[3]?.value;
  const radioElectricProductsEnrichment = components[4]?.value;
  const otherIndicatorsEnrichment = components[5]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={indicatorsSum}
        unit={'%'}
        description={'Сумма рассчитанных значений показателей по блокам оценок для соответствующего ФОИВ'}>
        <Symbol sub={'ФОИВ'}>ДП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={programApproval}
        unit={'%'}
        description={'Утверждение (актуализация) стратегии (программы) цифровой трансформации'}>
        <Symbol sub={'1'}>ДП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={dataTimelinessAndCompleteness}
        unit={'%'}
        description={
          'Своевременность и полнота предоставления сведений согласно отчетным формам о реализации стратегии (программы) цифровой трансформации'
        }>
        <Symbol sub={'2'}>ДП</Symbol>
      </SymbolDetails>

      <SymbolDetails
        value={softwareIndependenceEnrichment}
        unit={'%'}
        description={'Достижение показателей по импортозамещению ПО'}>
        <Symbol sub={'3'}>ДП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={radioElectricProductsEnrichment}
        unit={'%'}
        description={
          'Достижение показателей по минимальной доле закупок радиоэлектронной продукции российского происхождения'
        }>
        <Symbol sub={'4'}>ДП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={otherIndicatorsEnrichment}
        unit={'%'}
        description={'Достижение иных ключевых показателей эффективности стратегии (программы) цифровой трансформации'}>
        <Symbol sub={'5'}>ДП</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Менее 20%']} />
      <TableRow values={['0,5', 'От 20% до 40%']} />
      <TableRow values={['1,0', 'От 40% до 60%']} />
      <TableRow values={['1,5', 'От 60% до 80%']} />
      <TableRow values={['2,0', 'Более 80%']} />
    </Table>
  );
}

export function GovCompaniesStrategy() {
  return (
    <IndicatorPage
      download={'Стратегии госкомпаний.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
