import React from 'react';
import clsx from 'clsx';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './GovSignatureIntegration.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const govSignature = components[0]?.value;
  // TODO временно на 04.09.2024 - после раскомментировать
  // const kPilot = components[1]?.value;
  // const kIntegration = components[2]?.value;
  const kAccessibility = components[3]?.value;
  const kCertificates = components[4]?.value;
  const kUnep = components[5]?.value;
  const kUkep = components[6]?.value;
  const kUkepfk = components[7]?.value;

  return (
    <SymbolsGrid columns={3} largeGap>
      <SymbolDetails
        value={govSignature}
        description={
          'Значение показателя «Внедрение технологии мобильной электронной подписи «Госключ»» складывается из суммы субпоказателей'
        }>
        <Symbol sub={'Госключ'}>В</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={kAccessibility}
        description={
          <>
            Количество МФЦ в субъекте РФ, участвующих в пилотном проекте и обеспечивающих проведение процедуры очной
            идентификации в продуктивной среде на последнюю дату отчетного периода
          </>
        }>
        <Symbol sub={'Доступность'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={kCertificates}
        description={
          <>
            Количество сертификатов УКЭП, полученных в мобильном приложении «Госключ» в рамках очной идентификации на
            базе МФЦ соответствующего субъекта РФ – участника пилотного проекта за отчетный период (не нарастающим
            итогом, без учета повторной очной идентификации одного и того физического лица в течение 365 дней)
          </>
        }>
        <Symbol sub={'Сертификаты'}>К</Symbol>
      </SymbolDetails>
      {/* TODO временно на 04.09.2024 - после раскомментировать */}
      {/*<SymbolDetails*/}
      {/*  value={kPilot}*/}
      {/*  description={*/}
      {/*    <>*/}
      {/*      Участие субъекта РФ в пилотном проекте по очной идентификации. В случае, если субъект РФ участвует в*/}
      {/*      пилотном проекте, соответствующий субпоказатель не учитывается.*/}
      {/*    </>*/}
      {/*  }>*/}
      {/*  <Symbol sub={'Пилот'}>К</Symbol>*/}
      {/*</SymbolDetails>*/}
      <SymbolDetails
        value={kUnep}
        description={
          <>
            Наличие в продуктивной среде региональных услуг и сервисов, реализованных в государственных и иных ИС, на
            ЕПГУ и предусматривающих подписание УНЭП в мобильном приложении «Госключ»
          </>
        }>
        <Symbol sub={'УНЭПГосключ'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={kUkep}
        description={
          <>
            Наличие в продуктивной среде региональных услуг и сервисов, реализованных в государственных и иных ИС, на
            ЕПГУ и предусматривающих подписание УКЭП в мобильном приложении «Госключ»
          </>
        }>
        <Symbol sub={'УКЭПГосключ'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={kUkepfk}
        description={
          <>
            Наличие в продуктивной среде региональных услуг и сервисов, реализованных в государственных и иных ИС, на
            ЕПГУ и предусматривающих подписание УКЭП ФК в мобильном приложении «Госключ»
          </>
        }>
        <Symbol sub={'УКЭПФКГосключ'}>К</Symbol>
      </SymbolDetails>
      {/* TODO временно на 04.09.2024 - после раскомментировать */}
      {/*<SymbolDetails*/}
      {/*  value={kIntegration}*/}
      {/*  description={*/}
      {/*    <>*/}
      {/*      Наличие в продуктивной среде региональных услуг и сервисов с подписанием документов с помощью мобильного*/}
      {/*      приложения «Госключ»*/}
      {/*    </>*/}
      {/*  }>*/}
      {/*  <Symbol sub={'Внедрение'}>К</Symbol>*/}
      {/*</SymbolDetails>*/}
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  const textWithInterlinearText = (mainText: string, interlinearText: string) => {
    return (
      <>
        <span>{mainText}</span>
        <span
          style={{
            fontSize: 8,
            alignContent: 'end',
            verticalAlign: 'sub',
            lineHeight: '8px'
          }}>
          {interlinearText}
        </span>
      </>
    );
  };
  return (
    <div className={s.IndicatorTable}>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_header)}>Оценка, балл</div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_header)}>
        {textWithInterlinearText('Значение В', 'Госключ')}
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,0</div>
      <div className={s.IndicatorTable__cell}>
        Внедрение технологии мобильной электронной подписи «Госключ» в субъекте Российской Федерации не обеспечено
      </div>
      <div className={s.IndicatorTable__cell} style={{ gridColumn: '1/3' }}>
        <div style={{ paddingLeft: 94 }}>
          {textWithInterlinearText(
            'Субпоказатель 1. Участие субъекта Российской Федерации в пилотном проекте К',
            'ПилотГосключ'
          )}
        </div>
      </div>
      <div className={s.IndicatorTable__cell}></div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_header)}>
        {textWithInterlinearText('Значение К', 'Доступность')}
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,0</div>
      <div className={s.IndicatorTable__cell}>
        Нет МФЦ, обеспечивающих проведение процедуры очной идентификации в продуктивной среде на последнюю дату
        отчетного периода
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,1</div>
      <div className={s.IndicatorTable__cell}>
        1 окно или 1 отделение МФЦ обеспечивает проведение процедуры очной идентификации в продуктивной среде <br />
        на последнюю дату отчетного периода
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,5</div>
      <div className={s.IndicatorTable__cell}>
        Несколько отделений МФЦ, но не все обеспечивают проведение процедуры очной идентификации в продуктивной среде на
        последнюю дату отчетного периода
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>1,0</div>
      <div className={s.IndicatorTable__cell}>
        Все отделения МФЦ обеспечивают проведение процедуры очной идентификации в продуктивной среде на последнюю дату
        отчетного периода
      </div>
      <div className={s.IndicatorTable__cell}></div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_header)}>
        {textWithInterlinearText('Значение К', 'Сертификаты')}
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,0</div>
      <div className={s.IndicatorTable__cell}>Количество сертификатов УКЭП 0 за отчетный период</div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,1</div>
      <div className={s.IndicatorTable__cell}>
        {
          'Количество сертификатов УКЭП за отчетный период > 0 и ≤ 0,003% численности населения субъекта Российской Федерации на 1 января предшествующего календарного года'
        }
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,5</div>
      <div className={s.IndicatorTable__cell}>
        {
          'Количество сертификатов УКЭП за отчетный период > 0,003% и ≤ 0,01% численности населения субъекта Российской Федерации на 1 января предшествующего календарного год'
        }
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>1,0</div>
      <div className={s.IndicatorTable__cell}>
        {
          'Количество сертификатов УКЭП за отчетный период > 0,01% и ≤ 0,02% численности населения субъекта Российской Федерации на 1 января предшествующего календарного года'
        }
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>1,5</div>
      <div className={s.IndicatorTable__cell}>
        {
          'Количество сертификатов УКЭП за отчетный период > 0,02% численности населения субъекта Российской Федерации на 1 января предшествующего календарного года'
        }
      </div>
      <div className={clsx(s.IndicatorTable__cell)} style={{ gridColumn: '1/3' }}>
        <div style={{ paddingLeft: 94 }}>
          {textWithInterlinearText('Субпоказатель 2. Внедрение Госключа К', 'ВнедрениеГосключ')}
        </div>
      </div>
      <div className={s.IndicatorTable__cell}></div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_header)}>
        {textWithInterlinearText('Значение К', 'ВнедрениеГосключ')}
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,5</div>
      <div className={s.IndicatorTable__cell}>
        {textWithInterlinearText('К', 'УНЭПГосключ')} наличие услуг/сервисов с УНЭП Госключа
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,5</div>
      <div className={s.IndicatorTable__cell}>
        {textWithInterlinearText('К', 'УКЭПГосключ')} наличие услуг/сервисов с УКЭП Госключа
      </div>
      <div className={clsx(s.IndicatorTable__cell, s.IndicatorTable__cell_value)}>0,5</div>
      <div className={s.IndicatorTable__cell}>
        {textWithInterlinearText('К', 'УКЭПФКГосключ')} наличие услуг/сервисов с УКЭП ФК Госключа
      </div>
    </div>
  );
}

export function GovSignatureIntegration() {
  return (
    <IndicatorPage
      regionScope
      download={'Внедрение Госключа.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      additionalInfo={
        <>
          Для участников пилота макс. - 4 б.
          <br />
          Для остальных субъектов РФ макс. - 1,5 б. (субпоказатель 1 не учитывается)
        </>
      }
      dynamicChartPreventGrow
    />
  );
}
