import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const kObr = components[0]?.value; // 269 - КачОбр
  const k1 = components[1]?.value; // 270 - K1
  const k2 = components[2]?.value; // 271 - K2
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={kObr}
        description={<>Качество обращений сотрудников ФОИВ в СТП системы «Электронный бюджет»</>}>
        <Symbol color={'#55D666'} square sub={'Обр'}>
          Кач
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k1}
        roundUp
        description={
          <>
            Количество обращений сотрудников ФОИВ в СТП системы «Электронный бюджет» по вопросам, предмет которых
            раскрыт и изложен в руководстве пользователя системы «Электронный бюджет» и содержится в обучающих курсах,
            опубликованных на портале обучения системы «Электронный бюджет, относящихся к типу «Консультация»
          </>
        }>
        <Symbol sub={'1'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k2}
        roundUp
        description={<>Общее количество обращений сотрудников в СТП, относящихся к типу «Консультация»</>}>
        <Symbol sub={'2'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow
        values={[
          '0',
          'Доля обращений сотрудников ФОИВ в СТП системы «Электронный бюджет» по вопросам, предмет которых раскрыт и изложен в руководстве пользователя и/или содержится в опубликованных на портале обучения системы «Электронный бюджет» курсах составляет 81,00 – 100%'
        ]}
      />
      <TableRow
        values={[
          '0,5',
          'Доля обращений сотрудников ФОИВ в СТП системы «Электронный бюджет» по вопросам, предмет которых раскрыт и изложен в руководстве пользователя и/или содержится в опубликованных на портале обучения системы «Электронный бюджет» курсах составляет 61,00 – 80,99%'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          'Доля обращений сотрудников ФОИВ в СТП системы «Электронный бюджет» по вопросам, предмет которых раскрыт и изложен в руководстве пользователя и/или содержится в опубликованных на портале обучения системы «Электронный бюджет» курсах составляет 41,00 – 60,99%'
        ]}
      />
      <TableRow
        values={[
          '1,5',
          'Доля обращений сотрудников ФОИВ в СТП системы «Электронный бюджет» по вопросам, предмет которых раскрыт и изложен в руководстве пользователя и/или содержится в опубликованных на портале обучения системы «Электронный бюджет» курсах составляет 21,00 – 40,99%'
        ]}
      />
      <TableRow
        values={[
          '2,0',
          'Доля обращений сотрудников ФОИВ в СТП системы «Электронный бюджет» по вопросам, предмет которых раскрыт и изложен в руководстве пользователя и/или содержится в опубликованных на портале обучения системы «Электронный бюджет» курсах составляет 0 – 20,99%'
        ]}
      />
    </Table>
  );
}

export function GiisEbAppealsQuality() {
  return (
    <IndicatorPage
      download={'Качество обращений сотрудников ФОИВ в СТП ГИИС ЭБ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      dynamicChartPreventGrow
    />
  );
}
