import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const transferringValue = components[0]?.value;
  const transferredServicesCount = components[1]?.value;
  const servicesToTransferCount = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={transferringValue} unit={'%'} description={'Удельный вес перевода услуг в режим онлайн'}>
        <Symbol>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={transferredServicesCount}
        description={
          'Фактическое количество услуг, перевод которых в режим онлайн был осуществлен в текущем году в соответствии с Планом'
        }>
        <Symbol sub={'1'}>Q</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={servicesToTransferCount}
        description={
          'Плановое значение услуг, необходимых к переводу в режим онлайн в текущем году в соответствии с Планом'
        }>
        <Symbol sub={'2'}>Q</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'F < 25%']} />
      <TableRow values={['1,0', '25% ≤ F < 100%']} />
      <TableRow values={['2,0', 'F = 100%']} />
    </Table>
  );
}

export function OnlineServices() {
  return (
    <IndicatorPage
      download={'Перевод услуг в режим онлайн.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
