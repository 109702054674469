import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const eisuksAppealsQuality = components[0]?.value;
  const appealsWithConsequences = components[1]?.value;
  const appeals = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={eisuksAppealsQuality}
        unit={'%'}
        description={
          <>
            Значение показателя «Качество обращений уполномоченных сотрудников федерального органа исполнительной власти{' '}
            <br />в службу технической поддержки ФГИС ЕИСУКС»
          </>
        }>
        <Symbol>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={appealsWithConsequences}
        roundUp
        unit={'шт.'}
        description={
          'Количество обращений уполномоченных сотрудников конкретного ФОИВ в СТП ФГИС ЕИСУКС по вопросам, по которым проводилось обучение и были проведены проверки полученных знаний по итогам обучения'
        }>
        <Symbol sub={'1'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={appeals}
        roundUp
        unit={'шт.'}
        description={'Общее количество обращений уполномоченных сотрудников ФОИВ в СТП ФГИС ЕИСУКС'}>
        <Symbol sub={'2'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow
        values={[
          '0,0',
          'Доля обращений уполномоченных сотрудников ФОИВ в СТП ФГИС ЕИСУКС по вопросам, предмет которых раскрыт и изложен в инструкции пользователя ФГИС ЕИСУКС и в обучающих материалах, размещенных на официальном портале составляет 81,0-100 %'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          'Доля обращений уполномоченных сотрудников ФОИВ в СТП ФГИС ЕИСУКС по вопросам, предмет которых раскрыт и изложен в инструкции пользователя ФГИС ЕИСУКС и в обучающих материалах, размещенных на официальном портале составляет 61,0-80,9 %'
        ]}
      />
      <TableRow
        values={[
          '2,0',
          'Доля обращений уполномоченных сотрудников ФОИВ в СТП ФГИС ЕИСУКС по вопросам, предмет которых раскрыт и изложен в инструкции пользователя ФГИС ЕИСУКС и в обучающих материалах, размещенных на официальном портале составляет 41,0-60,9 %'
        ]}
      />
      <TableRow
        values={[
          '3,0',
          'Доля обращений уполномоченных сотрудников ФОИВ в СТП ФГИС ЕИСУКС по вопросам, предмет которых раскрыт и изложен в инструкции пользователя ФГИС ЕИСУКС и в обучающих материалах, размещенных на официальном портале составляет 21,0-40,9 %'
        ]}
      />
      <TableRow
        values={[
          '4,0',
          'Доля обращений уполномоченных сотрудников ФОИВ в СТП ФГИС ЕИСУКС по вопросам, предмет которых раскрыт и изложен в инструкции пользователя ФГИС ЕИСУКС и в обучающих материалах, размещенных на официальном портале составляет 0-20,9 %'
        ]}
      />
    </Table>
  );
}

export function AppealsQuality() {
  return (
    <IndicatorPage
      download={'ФГИС ЕИСУКС.pdf'}
      formula={IndicatorFormula}
      indicatorTable={IndicatorTable}
      indicatorComponents={IndicatorComponents}
    />
  );
}
