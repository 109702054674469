import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Spinner, SpinnerKind } from 'components/Spinner';
import s from './Button.module.scss';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary'
}

export interface ButtonProps {
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  to?: string;
  stateTo?: object;
  preventScrollReset?: boolean;
  replace?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
  children: string;
  className?: string;
  style?: React.CSSProperties;
}

export function Button({
  type = ButtonType.Primary,
  disabled,
  loading,
  to,
  stateTo,
  preventScrollReset,
  replace,
  href,
  onClick,
  iconLeft: IconLeft,
  iconRight: IconRight,
  children,
  className,
  style
}: ButtonProps) {
  const interactive = !disabled && !loading;
  const buttonClassName = clsx(
    s.Button,
    s[`Button_${type}`],
    disabled && s.Button_disabled,
    loading && s.Button_loading,
    interactive && s.Button_interactive,
    className
  );

  const buttonContent = loading ? (
    <Spinner kind={SpinnerKind.Inline} />
  ) : (
    <React.Fragment>
      {IconLeft && <IconLeft />}
      {children}
      {IconRight && <IconRight />}
    </React.Fragment>
  );

  if (to && interactive) {
    return (
      <Link
        to={to}
        state={stateTo}
        preventScrollReset={preventScrollReset}
        replace={replace}
        className={buttonClassName}
        style={style}>
        {buttonContent}
      </Link>
    );
  } else if (href && interactive) {
    return (
      <a href={href} target={'_blank'} rel={'noreferrer'} className={buttonClassName} style={style}>
        {buttonContent}
      </a>
    );
  } else {
    const handleClick = interactive ? onClick : undefined;
    return (
      <button disabled={disabled} onClick={handleClick} className={buttonClassName} style={style}>
        {buttonContent}
      </button>
    );
  }
}
