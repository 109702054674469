export const LAQUO = '\u00AB'; // «
export const RAQUO = '\u00BB'; // »
export const EMDASH = '\u2014'; // —
export const THINSP = '\u2009'; //  
export const QUARTERSP = '\u2005'; //	 
export const THIRDSP = '\u2004'; //  
export const ENSP = '\u2002'; //  
export const EMSP = '\u2001'; //  
export const NBSP = '\u00A0'; //  
export const HELLIP = '\u2026'; // …
export const LSAQUO = '\u2039'; // ‹
export const RSAQUO = '\u203A'; // ›
export const RUBLE = '\u20BD'; // ₽
export const LEQ = '\u2264'; // ≤
export const NEQ = '\u2260'; // ≠
export const GEQ = '\u2265'; // ≥
export const ARROW_LEFT = '\u2190'; // ←
export const ARROW_RIGHT = '\u2192'; // →
export const MAC_OPTION = '\u2325'; // ⌥
export const BULL = '\u2022'; // •
