import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const socialSignificantDocumentsScore = components[0]?.value;
  const socialSignificantDocumentsCount = components[1]?.value;
  const workingAndOlderPopulation = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={socialSignificantDocumentsScore}
        unit={'%'}
        description={'Значение показателя «Доля выданных в МФЦ социально-значимых документов с ЕПГУ» в субъекте i'}>
        <Symbol sub={'i'}>{'D'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={socialSignificantDocumentsCount}
        unit={'чел.'}
        roundUp
        description={
          <>
            Количество уникальных пользователей, распечатавших <br />в субъекте i социально-значимые документы с ЕПГУ по
            услугам из Перечня социально-значимых документов за отчетный период
          </>
        }>
        <Symbol sub={'1'}>{'Q'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={workingAndOlderPopulation}
        unit={'чел.'}
        roundUp
        description={'Общая численность населения в субъекте i трудоспособного и старше трудоспособного возраста'}>
        <Symbol sub={'2'}>{'Q'}</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,5', '0 < Pi ≤ N/4']} />
      <TableRow values={['1,0', 'N/4 < Pi ≤ N/2']} />
      <TableRow values={['1,5', 'N/2 < Pi ≤ 3 N/4']} />
      <TableRow values={['2,0', '3 N/4 < Pi ≤ N']} />
    </Table>
  );
}

export function SocialSignificantDocuments() {
  return (
    <IndicatorPage
      regionScope
      download={'Доля выданных в МФЦ СЗН документов.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
