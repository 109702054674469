import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import s from './TableCell.module.scss';

type Props = PropsWithChildren & {
  linkTo?: string;
  linkAvailable?: boolean;
  className?: string;
};

export const TableCell = ({ linkTo, linkAvailable, children, className }: Props) => {
  const style = clsx(s.TableCell, className ? className : null);
  if (linkTo && linkAvailable) {
    return (
      <Link to={linkTo} className={style}>
        {children}
      </Link>
    );
  } else {
    return <div className={style}>{children}</div>;
  }
};
