import React from 'react';
import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const iGisogd = components[0]?.value;
  const iFact = components[1]?.value;
  const iPlan = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={iGisogd}
        unit={'%'}
        description={'Значение показателя «Доля своевременно переданной в ГИСОГД РФ информации»'}>
        <Symbol sub={'ГИСОГД'}>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={iFact}
        roundUp
        description={
          <>
            Количество полных, достоверных и своевременно переданных <br />в ГИСОГД РФ обязательных для заполнения полей
            данных, заполненных в соответствии с нормативными и Техническими требованиями за расчетный период
          </>
        }>
        <Symbol sub={'факт'}>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={iPlan}
        roundUp
        description={
          <>
            Общее количество обязательных для заполнения полей данных, переданных субъектом Российской Федерации в
            ГИСОГД РФ <br />
            за расчетный период без учета соблюдения требований к их заполнению и своевременности их передачи.
          </>
        }>
        <Symbol sub={'план'}>И</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <AdvancedTable
      rows={[
        { cells: ['Оценка, балл', 'Значение'], isHeader: true },
        { cells: ['0,0', ['И', { text: 'ГИСОГД', align: 'sub' }, ' < 50%']] },
        { cells: ['0,5', ['50% ≤ И', { text: 'ГИСОГД', align: 'sub' }, ' < 70%']] },
        { cells: ['1,0', ['70% ≤ И', { text: 'ГИСОГД', align: 'sub' }, ' < 80%']] },
        { cells: ['1,5', ['80% ≤ И', { text: 'ГИСОГД', align: 'sub' }, ' < 90%']] },
        { cells: ['2,0', ['90% ≤ И', { text: 'ГИСОГД', align: 'sub' }, ' < 100%']] }
      ]}
    />
  );
}

export function Gisogd() {
  return (
    <IndicatorPage
      regionScope
      download={'ГИСОГД.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
