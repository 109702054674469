import React, { ElementType, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { WrapperComponentProps } from '@digital-gov/ui-utils';
import { Icon, IconSize } from 'componentsL/Icon';
import { Loader } from 'componentsL/Loader';
import s from './Button.module.scss';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  LinkPrimary = 'linkPrimary',
  LinkSecondary = 'linkSecondary'
}

export interface ButtonProps<ComponentType extends ElementType = 'button'> {
  onClick?: MouseEventHandler<ComponentType>;
  variant?: ButtonVariant;
  loading?: boolean;
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
}

export const Button = React.forwardRef(
  <ComponentType extends ElementType = 'button'>(
    {
      className,
      children,
      Component = 'button',
      disabled,
      loading,
      variant = ButtonVariant.Primary,
      iconRight,
      iconLeft,
      onClick,
      ...componentProps
    }: WrapperComponentProps<ComponentType, ButtonProps<ComponentType>>,
    ref: React.ForwardedRef<HTMLButtonElement | ComponentType | null>
  ) => {
    const handleClick: MouseEventHandler<ComponentType> = (e) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      onClick?.(e);
    };

    return (
      <Component
        className={clsx(s.Button, s[`Button_${variant}`], { [s.Button_disabled]: disabled }, className)}
        onClick={handleClick}
        {...(componentProps?.href && { target: '_blank', rel: 'noopener noreferrer' })}
        {...componentProps}
        ref={ref}>
        {iconLeft && <Icon icon={iconLeft} size={IconSize.Small} />}
        {loading ? (
          <Loader className={s.Button__loader} spinnerClassName={s.Button__loaderSpinner} />
        ) : (
          <span>{children}</span>
        )}
        {iconRight && <Icon icon={iconRight} size={IconSize.Small} />}
      </Component>
    );
  }
);
