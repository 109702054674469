import React from 'react';
import { ButtonLink } from 'components/Button';
import { Download } from 'components/Icon';
import { Spinner, SpinnerKind } from 'components/Spinner';
import { basename } from 'store/config';
import { FgiskiFileKind, IndicatorMethodologyType, useGetMethodologyUrlLazyQuery } from 'store/graphql';

export interface CardDownloadButtonProps {
  disabled?: boolean;
  filename?: string;
  path?: string;
  indicatorId?: number;
  methodologies?: IndicatorMethodologyType[];
}

export const CardDownloadButton = ({
  disabled,
  indicatorId,
  path,
  filename,
  methodologies
}: CardDownloadButtonProps) => {
  const [getDownloadUrl, { loading }] = useGetMethodologyUrlLazyQuery({
    fetchPolicy: 'network-only'
  });

  const handleDownload = async () => {
    if (methodologies?.length && indicatorId) {
      const linkNode = document.createElement('a');

      const { data } = await getDownloadUrl({
        variables: {
          input: {
            kind: FgiskiFileKind.Methodology,
            entityId: indicatorId,
            scope: methodologies[0].scope,
            fileIndex: 0
          }
        }
      });

      const downloadedURL = data?.fgiskiFilePresignedUrl;
      if (!downloadedURL) {
        console.error('Ошибка запроса URL на скачивание файла');
        return;
      }

      linkNode.href = downloadedURL;
      linkNode.click();
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      {loading && <Spinner kind={SpinnerKind.Inline} />}
      {methodologies?.length ? (
        <ButtonLink iconLeft={Download} onClick={handleDownload}>
          Скачать
        </ButtonLink>
      ) : (
        <ButtonLink
          iconLeft={Download}
          disabled={disabled}
          download
          href={encodeURI(`${basename}/indicators/${path ? path + '/' : ''}${filename}`)}>
          Скачать
        </ButtonLink>
      )}
    </div>
  );
};
