import React from 'react';
import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const Mcom = components[0]?.value;
  const R = components[1]?.value;
  const E = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={Mcom} description={'Значение показателя «Уровень удовлетворённости»'}>
        <Symbol sub={'com'}>M</Symbol>
      </SymbolDetails>
      <SymbolDetails value={R} description={'Значение параметра доли отказов'}>
        <Symbol>R</Symbol>
      </SymbolDetails>
      <SymbolDetails value={E} description={'Значение параметра количество просроченных заявлений'}>
        <Symbol>E</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <AdvancedTable
      rows={[
        { cells: ['Оценка, балл', ['Значение M', { text: 'com', align: 'sub' }]], isHeader: true },
        { cells: ['0', 'Уровень удовлетворенности составляет 0-3,99'] },
        { cells: ['0,5', 'Уровень удовлетворенности составляет 4-4,69'] },
        { cells: ['1,0', 'Уровень удовлетворенности составляет равной или более 4,70'] },
        { cells: ['Значение R'], isInTableCustomHeader: true },
        { cells: ['0', 'Доля отказов составляет более 80%'] },
        { cells: ['0,5', 'Доля отказов составляет 51-80%'] },
        { cells: ['1,0', 'Доля отказов составляет менее 50%'] },
        { cells: ['Значение E'], isInTableCustomHeader: true },
        { cells: ['0', 'Больше 0,20% просроченных заявлений'] },
        { cells: ['0,5', '0,11% - 0,20% просроченных заявлений'] },
        { cells: ['1,0', 'Меньше 0,10% просроченных заявлений'] }
      ]}
    />
  );
}

export function EpguFederalServicesQuality() {
  return (
    <IndicatorPage
      download={'Качество предоставления федеральных услуг на ЕПГУ.pdf'}
      updateDays={UpdateDays.AtLeast15}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      dynamicChartPreventGrow
    />
  );
}
