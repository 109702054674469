import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { ReactComponent as BadIcon } from './assets/bad.svg';
import { ReactComponent as GoodIcon } from './assets/good.svg';
import { ReactComponent as NeutralIcon } from './assets/neutral.svg';
import s from './PositionDynamic.module.scss';

export interface PositionDynamicProps {
  className?: string;
  ratingPosition: number;
  previousRatingPosition?: number | null;
  withIcon?: boolean;
}

export const PositionDynamic: FC<PositionDynamicProps> = (props) => {
  const { className, ratingPosition, previousRatingPosition, withIcon } = props;

  const positionDiff = (previousRatingPosition ?? 0) - ratingPosition;

  const { dynamicVariant, icon, prefix } = useMemo(() => {
    if (positionDiff > 0) {
      return {
        prefix: '+ ',
        dynamicVariant: 'good',
        icon: <GoodIcon />
      };
    }

    if (positionDiff < 0) {
      return {
        prefix: '- ',
        dynamicVariant: 'bad',
        icon: <BadIcon />
      };
    }

    return {
      prefix: null,
      dynamicVariant: 'neutral',
      icon: <NeutralIcon />
    };
  }, [positionDiff]);

  if (typeof previousRatingPosition !== 'number') {
    return null;
  }

  return (
    <div className={clsx(s.PositionDynamic, s[`PositionDynamic_${dynamicVariant}`], className)}>
      {withIcon && icon}
      <p>
        {prefix} {Math.abs(positionDiff)}
      </p>
    </div>
  );
};
