import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const digitalizationByMszu = components[0]?.value;

  return (
    <SymbolsGrid columns={4}>
      <SymbolDetails
        value={digitalizationByMszu}
        unit={'%'}
        description={
          <>
            Значение показателя цифровизации по МСЗУ, оказываемым на территории субъекта Российской Федерации в
            соответствии <br />с актуальной версией регионального перечня МСЗУ
          </>
        }>
        <Symbol sub={'общ'}>ПЦ</Symbol>
      </SymbolDetails>
      <SymbolDetails
        description={
          'Значение показателя цифровизации по первой услуге в соответствии с региональным перечнем, для которой был обеспечен перевод в электронный формат'
        }>
        <Symbol sub={'1'}>ПЦ</Symbol>
      </SymbolDetails>

      <SymbolDetails
        description={
          <>
            Значение показателя цифровизации
            <br /> по n-услуге в соответствии с региональным перечнем, для которой был обеспечен перевод в электронный
            формат
          </>
        }>
        <Symbol sub={'n'}>ПЦ</Symbol>
      </SymbolDetails>
      <SymbolDetails
        description={
          <>
            Количество МСЗУ, перевод которых должен быть обеспечен в электронный формат <br />в соответствии с
            региональным перечнем МСЗУ субъекта Российской Федерации
          </>
        }>
        <Symbol color="#ffffff">n</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'ПЦобщ < 25%']} />
      <TableRow values={['0,5', '25% ≤ ПЦобщ <50%']} />
      <TableRow values={['1,0', '50% ≤ ПЦобщ < 75%']} />
      <TableRow values={['1,5', '75% ≤ ПЦобщ < 100%']} />
      <TableRow values={['2,0', 'ПЦобщ = 100%']} />
    </Table>
  );
}

export function ServicesDigitalization() {
  return (
    <IndicatorPage
      regionScope
      download={'Цифровизация МСЗУ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
