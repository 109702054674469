import clsx from 'clsx';
import { DATE_FULL_DAY_SHORT, formatDate } from '@digital-gov/ui-utils';
import s from '../FeedbackItem.module.scss';

export interface FeedbackItemProps {
  className?: string;
  id: number;
  subject?: string;
  publisher: string;
  publishDate: string;
  indicatorName?: string;
  onClick: (id: number) => void;
}

export const FeedbackItem = ({
  className,
  id,
  subject,
  publisher,
  publishDate,
  indicatorName,
  onClick
}: FeedbackItemProps) => {
  return (
    <div className={clsx(s.FeedbackItem, className)} onClick={() => onClick(id)}>
      <div className={s.FeedbackItem__title}>{subject === void 0 ? `Показатель «${indicatorName}»` : subject}</div>
      <div className={s.FeedbackItem__footer}>
        <div className={s.FeedbackItem__publisher}>{publisher} |</div>
        <div className={s.FeedbackItem__publishDate}>{formatDate(publishDate, DATE_FULL_DAY_SHORT)}</div>
      </div>
    </div>
  );
};
