import { RatingGroupEnum } from 'store/graphql';

export const ratingGroups = [RatingGroupEnum.High, RatingGroupEnum.Average, RatingGroupEnum.Low];

export const ratingGroupsWithText = new Map<RatingGroupEnum, string>([
  [RatingGroupEnum.Low, 'Низкая'],
  [RatingGroupEnum.Average, 'Средняя'],
  [RatingGroupEnum.High, 'Высокая']
]);

export const budgetGroups: [number, number][] = [
  [0, 500_000_000],
  [500_000_000, 1_000_000_000],
  [1_000_000_000, 5_000_000_000],
  [5_000_000_000, Infinity]
];

// минимальная высота таблицы
export const departmentChartMinHeight = 180 * 3; //540;

// максимальная ширина таблицы
export const departmentChartMaxWidth = 1272;

// шрифт, используемый по-умолчанию в таблице ФРЦТ
export const departmentChartFontFamily = 'Roboto';
