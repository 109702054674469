import { Pipe } from './Pipe';
import { FileUploaderInterface } from './types';

export class FakeFileUploader extends Pipe<number> implements FileUploaderInterface<number> {
  private _stopUpload: null | (() => void) = null;
  private _isRun: boolean = false;
  private _isDone: boolean = false;
  private _promise: Promise<void> | null = null;
  private _id: number | null = null;

  private static _idGen = (function* () {
    let i = 0;
    while (true) {
      yield --i;
    }
  })();

  constructor(public readonly file: File) {
    super(0);
  }

  async run(): Promise<void> {
    if (this._promise) {
      await this._promise;
      return;
    }

    if (this._isDone) {
      return;
    }

    this._isRun = true;
    this._isDone = false;
    this._promise = this.upload();

    await this._promise;

    this._isRun = false;
    this._isDone = true;
    this._promise = null;
  }

  stop() {
    if (!this._isDone) {
      this._isRun = false;
      this._isDone = false;
    }

    if (this._stopUpload) {
      this._stopUpload();
      this._promise = null;
    }
  }

  get key() {
    return `${this.file.name}.${this.file.size}.${this.file.lastModified}`;
  }

  get error() {
    return null;
  }

  get id() {
    return this._id;
  }

  get progress() {
    return this.current;
  }

  get isUploading() {
    return this._isRun && !this._isDone;
  }

  get isDone() {
    return this._isDone;
  }

  get isFailed() {
    return false;
  }

  get promise() {
    return this._promise;
  }

  async waitEnd(): Promise<void> {
    await this._promise;
  }

  private async upload() {
    await new Promise<void>((resolve) => {
      const times = Math.ceil(Math.random() * 13);
      let i = 0;

      const t = setInterval(
        () => {
          const progress = (100 / times) * i;
          this.add(progress);

          if (i++ === times) {
            this._id = FakeFileUploader._idGen.next().value!;
            clearInterval(t);
            resolve();
          }
        },
        (Math.random() * 10000) / times
      );

      this._stopUpload = () => clearInterval(t);
    });
  }
}
