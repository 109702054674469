import { useCallback, useMemo, useState } from 'react';
import { DATE_FULL_DAY_SHORT, formatDate, useToggle } from '@digital-gov/ui-utils';
import { OptionItem } from 'componentsL/DropdownList/types';
import { RegionRatingType } from 'store/graphql';
import { POINTS_LAST_ID } from '../../RegionsFilters';
import { RegionsTableHeaderFields, SelectedIndicatorType } from '../types';

export const useRegionsTable = (regionalRating?: RegionRatingType | null) => {
  const [dropdownIsOpen, { toggle, unset }] = useToggle();

  const [selectedIndicator, setSelectedIndicator] = useState<SelectedIndicatorType | null>(null);

  const availableKeyIndicators = useMemo(
    () =>
      regionalRating?.availableKeyIndicators
        ? [
            {
              indicatorId: POINTS_LAST_ID,
              indicatorName: 'Общее количество баллов',
              maxValue: null
            },
            ...regionalRating.availableKeyIndicators
          ]
        : null,
    [regionalRating?.availableKeyIndicators]
  );

  const options: OptionItem[] | null = useMemo(() => {
    if (availableKeyIndicators?.length) {
      setSelectedIndicator(availableKeyIndicators[0]);
    }

    return (
      availableKeyIndicators?.map(({ indicatorId, indicatorName }) => ({
        label: indicatorName,
        value: String(indicatorId)
      })) ?? null
    );
  }, [availableKeyIndicators]);

  const headers = useMemo(() => {
    return [
      RegionsTableHeaderFields.ratingPosition,
      RegionsTableHeaderFields.representatives,
      RegionsTableHeaderFields.regionName,
      RegionsTableHeaderFields.rrct,
      RegionsTableHeaderFields.degree,
      RegionsTableHeaderFields.indicatorsValues
    ];
  }, []);

  const headersNameMapper: Record<
    RegionsTableHeaderFields,
    {
      title: string;
      subTitle?: string;
    }
  > = useMemo(() => {
    const lastUpdateDate = regionalRating?.lastUpdateDate;
    const currentDate = lastUpdateDate && formatDate(lastUpdateDate, DATE_FULL_DAY_SHORT);

    return {
      [RegionsTableHeaderFields.ratingPosition]: {
        title: '№'
      },
      [RegionsTableHeaderFields.representatives]: {
        title: 'Полномочный представитель'
      },
      [RegionsTableHeaderFields.regionName]: {
        title: 'Регион',
        subTitle: 'Федеральный округ'
      },
      [RegionsTableHeaderFields.rrct]: {
        title: 'Руководитель цифровой трансформации',
        subTitle: 'Год назначения'
      },
      [RegionsTableHeaderFields.degree]: {
        title: 'Текущее значение, %',
        subTitle: currentDate ?? undefined
      },
      [RegionsTableHeaderFields.indicatorsValues]: {
        title: 'Показатели'
      }
    };
  }, [regionalRating?.lastUpdateDate]);

  const onOptionClick = useCallback(
    (value: string) => () => {
      const newIndicator = availableKeyIndicators?.find(({ indicatorId }) => String(indicatorId) === value);
      if (newIndicator) {
        setSelectedIndicator(newIndicator);
      }
      unset();
    },
    [unset, availableKeyIndicators]
  );

  return {
    options,
    headers,
    headersNameMapper,
    handlers: { onOptionClick, unset, toggle },
    dropdownIsOpen,
    selectedIndicator
  };
};
