import React from 'react';
import { fullNameInitials } from 'utils/fullNameInitials';
import { noRctPatterns } from 'utils/noRctPatterns';
import s from './Person.module.scss';

export function Person({ person, formatToInitials }: { person: string | null; formatToInitials?: boolean }) {
  if (!person || person === '' || noRctPatterns.includes(person.toLowerCase())) {
    return <div className={s.Person__dimmed}>{'Не назначен'}</div>;
  }
  return <>{formatToInitials ? fullNameInitials(person) : person}</>;
}
