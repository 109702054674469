import React from 'react';
import { useAuth } from '@digital-gov/auth-apollo-store';
import { Button } from 'componentsL/Button';
import { ErrorMessage } from 'componentsL/ErrorMessage';
import s from './ProfileError.module.scss';

export function ProfileError() {
  const { logOut } = useAuth();
  return (
    <div className={s.ProfileError}>
      <ErrorMessage title={'Не удалось получить данные профиля'} />
      <Button className={s.AuthorityError__action} onClick={() => logOut()}>
        Выйти
      </Button>
    </div>
  );
}
