import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const ino = components[0]?.value;
  const k1 = components[1]?.value;
  const k2 = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={ino}
        description={
          'Значение показателя «Доля обращений в службу технической\n' +
          'поддержки пользователей ФГИС «ЕИСУКС», вызванных введением некорректных\n' +
          'данных со стороны лиц, уполномоченных на работу в ФГИС «ЕИСУКС»'
        }>
        <Symbol color={'#55D666'} square>
          ИНО
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k1}
        roundUp
        unit={'шт.'}
        description={
          'Количество обращений лиц, уполномоченных на работу в ФГИС\n' +
          '«ЕИСУКС», в СТП ФГИС «ЕИСУКС» по вопросам, предмет которых\n' +
          'непосредственно связан или является результатом ввода некорректных данных\n' +
          'со стороны лиц, уполномоченных в ФОИВ на работу в ФГИС «ЕИСУКС»'
        }>
        <Symbol sub={'1'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k2}
        roundUp
        unit={'шт.'}
        description={
          'Общее количество обращений в СТП ФГИС «ЕИСУКС» со стороны лиц,\n' +
          'уполномоченных в ФОИВ на работу в ФГИС «ЕИСУКС»'
        }>
        <Symbol sub={'2'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение ИНО, %']} isHeader />
      <TableRow
        values={[
          '0',
          'Доля обращений в СТП пользователей ФГИС «ЕИСУКС», вызванных введением некорректных данных со стороны лиц, уполномоченных на работу в ФГИС «ЕИСУКС», составляет свыше 4,99%'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          'Доля обращений в СТП пользователей ФГИС «ЕИСУКС», вызванных введением некорректных данных со стороны лиц, уполномоченных на работу в ФГИС «ЕИСУКС», составляет 4,10 – 4,99%'
        ]}
      />
      <TableRow
        values={[
          '2,0',
          'Доля обращений в СТП пользователей ФГИС «ЕИСУКС», вызванных введением некорректных данных со стороны лиц, уполномоченных на работу в ФГИС «ЕИСУКС», составляет 2,10 – 4,09%'
        ]}
      />
      <TableRow
        values={[
          '3,0',
          'Доля обращений в СТП пользователей ФГИС «ЕИСУКС», вызванных введением некорректных данных со стороны лиц, уполномоченных на работу в ФГИС «ЕИСУКС», составляет 1,10 – 2,09%'
        ]}
      />
      <TableRow
        values={[
          '4,0',
          'Доля обращений в СТП пользователей ФГИС «ЕИСУКС», вызванных введением некорректных данных со стороны лиц, уполномоченных на работу в ФГИС «ЕИСУКС», составляет 0 – 1,09%'
        ]}
      />
    </Table>
  );
}

export function EisuksIncorrectAppealsShare() {
  return (
    <IndicatorPage
      download={'Доля некорректных обращений в СТП ФГИС ЕИСУКС.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
