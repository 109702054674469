import React from 'react';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './SmevExploitation.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const againstSmev2Score = components[1]?.value;
  const shutdownSmev2Score = components[2]?.value;
  const connectionToSmev3Score = components[3]?.value;
  const dataMartsScore = components[4]?.value;

  return (
    <SymbolsGrid columns={4}>
      <SymbolDetails
        value={againstSmev2Score}
        description={
          <>
            Результаты работы по отказу <br />
            от использования федеральных сервисов СМЭВ2
          </>
        }>
        <Symbol sub={'1'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={shutdownSmev2Score}
        description={'Результаты работы по выводу из эксплуатации региональных электронных сервисов СМЭВ2'}>
        <Symbol sub={'2'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={connectionToSmev3Score}
        description={'Результаты работы по подключению к видам сведений СМЭВ3'}>
        <Symbol sub={'3'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={dataMartsScore}
        description={
          <>
            Результаты работы по внедрению региональных витрин данных и регламентированных запросов к ним <br />в
            продуктивной среде
          </>
        }>
        <Symbol sub={'4'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <div className={s.SmevExploitation__info}>
      <p>Итоговая оценка показателя определяется в баллах и равна значению показателя</p>
      <p>Максимальное количество баллов по показателю – 2,5</p>
    </div>
  );
}

export function SmevExploitation() {
  return (
    <IndicatorPage
      regionScope
      download={'Эксплуатация СМЭВ_v1.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
