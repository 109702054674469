import { memo } from 'react';
import clsx from 'clsx';
import { RegionRatingGroupEnum } from 'store/graphql';
import s from './ProgressBar.module.scss';

export interface ProgressBarProps {
  points: number;
  ratingGroup: RegionRatingGroupEnum;
}

export const ProgressBar = memo(({ points, ratingGroup }: ProgressBarProps) => {
  return (
    <div className={s.ProgressBar}>
      <div
        className={clsx(s.ProgressBar__fill, s[`ProgressBar__fill_${ratingGroup.toLowerCase()}`])}
        style={{ width: `${points}%` }}
      />
    </div>
  );
});

ProgressBar.displayName = 'ProgressBar';
