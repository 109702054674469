import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { useDebounceCallback } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'componentsL/Button';
import { IconDismiss } from 'componentsL/Icon';
import { SearchInput } from 'componentsL/Input/SearchInput/SearchInput';
import { Select, SelectOptionType } from 'componentsL/Select/Select';
import { RegionRatingType, RegionRatingGroupEnum } from 'store/graphql';
import { fullNameInitials } from 'utils/fullNameInitials';
import { ChangeModeBlock, ChangeModeBlockProps } from './ChangeModeBlock';
import { initialFilter, ratingGroups } from './constants';
import { mapRegionGroupToText } from './helpers';
import { IRegionsFilter } from './types';
import s from './RegionsFilters.module.scss';

export interface RegionsFiltersProps extends ChangeModeBlockProps {
  className?: string;
  regionalRating?: RegionRatingType | null | undefined;
  filter: IRegionsFilter;
  setFilter: React.Dispatch<React.SetStateAction<IRegionsFilter>>;
}

export const RegionsFilters: FC<RegionsFiltersProps> = (props) => {
  const { className, regionalRating, filter, setFilter, mode, onSetMode } = props;

  const { ratingGroup, representative } = filter;
  const [search, setSearch] = useState(filter.search);

  const ratingGroupOptions = useMemo(() => {
    const ratingGroupsResult = ratingGroups.map((group) => ({
      value: group,
      label: mapRegionGroupToText[group]
    }));
    return orderBy(ratingGroupsResult, ['label'], ['asc']);
  }, []);

  const representativeOptions = useMemo(() => {
    let options: SelectOptionType<string>[] = [];

    const representativeCache: Record<string, boolean> = {};

    regionalRating?.regions.forEach(({ representatives }) => {
      const firstRepresentative = representatives[0]?.fullName;
      if (!firstRepresentative || representativeCache[firstRepresentative]) {
        return;
      }

      representativeCache[firstRepresentative] = true;
      options.push({
        value: firstRepresentative,
        label: firstRepresentative
      });
      options = orderBy(options, ['value'], ['asc']);
      options = options.map((representative) => {
        const shortFio = fullNameInitials(representative.value);
        return {
          ...representative,
          label: shortFio
        };
      });
    });

    return options;
  }, [regionalRating?.regions]);

  useEffect(() => {
    setSearch(filter.search);
  }, [filter.search]);

  const onSearchChange = useDebounceCallback((value: string) => {
    setFilter((prev) => ({
      ...prev,
      search: value
    }));
  }, 500);

  const isFiltered = search || ratingGroup || representative;

  return (
    <div className={clsx(s.RegionsFilters, className)}>
      <SearchInput
        className={s.RegionsFilters__search}
        placeholder={'Поиск'}
        value={search}
        onChange={(value) => {
          setSearch(value);
          onSearchChange(value);
        }}
      />

      <Select<RegionRatingGroupEnum | null>
        className={s.RegionsFilters__ratingGroup}
        placeholder={'Все группы'}
        options={ratingGroupOptions}
        value={ratingGroup}
        onChange={(value) =>
          setFilter((prev) => ({
            ...prev,
            ratingGroup: value
          }))
        }
      />

      <Select<string | null>
        className={s.RegionsFilters__representative}
        placeholder={'Полномочный представитель'}
        options={representativeOptions}
        value={representative}
        onChange={(value) =>
          setFilter((prev) => ({
            ...prev,
            representative: value
          }))
        }
      />

      {isFiltered && (
        <Button iconLeft={IconDismiss} variant={ButtonVariant.LinkSecondary} onClick={() => setFilter(initialFilter)}>
          Сбросить
        </Button>
      )}

      <ChangeModeBlock className={s.RegionsFilters__navBar} mode={mode} onSetMode={onSetMode} />
    </div>
  );
};
