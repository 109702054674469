import { ApolloClient, useApolloClient } from '@apollo/client';
import { useFileUploadService } from 'hooks/useFileUploadService';
import {
  FileUploadDocument,
  FileUploadMutation,
  FileUploadMutationVariables,
  RatingFeedbackScope
} from 'store/graphql';
import {
  AbstractFileUploader,
  FileUploadClientError,
  FileUploaderInterface,
  FileUploaderManager
} from 'utils/FileUploader';

export class Uploader extends AbstractFileUploader {
  constructor(
    file: File,
    private scope: RatingFeedbackScope,
    private scopeId: number,
    private indicatorId: number | void | null,
    private apolloClient: ApolloClient<object>
  ) {
    super(file);
  }

  protected async request(fileLoadContent: Promise<any>) {
    const result = await this.apolloClient.mutate<FileUploadMutation, FileUploadMutationVariables>({
      mutation: FileUploadDocument,
      variables: {
        input: {
          fileName: this.file.name,
          scope: this.scope,
          scopeId: this.scopeId,
          content: await fileLoadContent,
          ...(this.indicatorId === void 0 ? {} : { indicatorId: this.indicatorId })
        }
      }
    });

    return result!.data!.result;
  }
}

// Фейковый загрузчик файлов для отображения некорректных файлов (тип/размер) на форме
export class FailedUploader extends AbstractFileUploader<null> {
  constructor(
    file: File,
    private readonly throwError: Error
  ) {
    super(file);
  }

  protected request(_: Promise<any>): Promise<null> {
    throw this.throwError;
  }
}

type ActionAddFiles = (files: File[]) => void;
type ActionRemoveFile = (fileKey: string) => void;

export function useFeedbackFileUploader(
  scope: RatingFeedbackScope,
  scopeId: number,
  indicatorId?: number | null,
  filesManager?: FileUploaderManager
): [FileUploaderInterface<number>[], FileUploaderManager, ActionAddFiles, ActionRemoveFile] {
  const apolloClient = useApolloClient();

  return useFileUploadService<number>((file: File) => {
    if (!/((\.pdf)|(\.xlsx)|(\.xls)|(\.docx)|(\.doc)|(\.pptx)|(\.ppt))$/.test(file.name)) {
      return new FailedUploader(file, new FileUploadClientError('Неверный формат файла'));
    }

    const size20mb = 20 * 1024 * 1024;
    if (file.size > size20mb) {
      return new FailedUploader(file, new FileUploadClientError('Превышен допустимый размер файла'));
    }

    return new Uploader(file, scope, scopeId, indicatorId, apolloClient);
  }, filesManager);
}
