import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Icon, IconWarning } from 'componentsL/Icon';
import { TextArea } from 'componentsL/Input';
import { ModalContentGroup, ModalFooter } from 'componentsL/Modal';
import { Select, SelectOptionType } from 'componentsL/Select/Select';
import { Indicator, useFeedbackAndIndicatorsList } from 'hooks/useFeedbackAndIndicatorsList';
import { useFeedbackFileUploader } from '../../../_hooks/useFeedbackFileUploader';
import { useFeedbackContext } from '../../../feedback-context';
import { FilesControl } from '../../FilesControl';
import s from '../FormContent.module.scss';

const FILES_LIMIT = 8;

export interface FeedbackFormProps {
  onSubmit: () => void | Promise<void>;
}

export const FeedbackForm = ({ onSubmit }: FeedbackFormProps) => {
  const context = useFeedbackContext();

  const [indicatorsLoading, indicatorsLoadError, feedbackList, indicatorsList] = useFeedbackAndIndicatorsList(
    context.scope,
    context.scopeId
  );

  const [sending, setSending] = useState(false);
  const [currentIndicatorId, setCurrentIndicatorId] = useState<number | void>(
    context.selectedIndicatorId ?? context.indicatorId
  );
  const [comment, setComment] = useState<string>(context.feedbackForm.comment);
  const [files, uploadManager, addFiles, removeFile] = useFeedbackFileUploader(
    context.scope,
    context.scopeId,
    currentIndicatorId ?? null,
    context.feedbackForm.uploadManager
  );

  const feedbackIds = useMemo(() => {
    return new Set(feedbackList.filter((item) => item.indicatorId !== null).map((item) => item.indicatorId!));
  }, [feedbackList]);

  const options = useMemo(() => {
    return orderBy<Indicator>(indicatorsList, ['name'], ['asc']).map<SelectOptionType<number>>((item) => ({
      label: item.name,
      labelElement: (
        <div
          className={clsx(s.IndicatorSelectItem, {
            [s.IndicatorSelectItem_disabled]: feedbackIds.has(item.id)
          })}>
          {item.name}
        </div>
      ),
      value: item.id
    }));
  }, [indicatorsList, feedbackIds]);

  const handleSelectIndicator = useCallback(
    (indicatorId: number) => {
      setCurrentIndicatorId(indicatorId);
    },
    [setCurrentIndicatorId]
  );

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.currentTarget.value);
    },
    [setComment]
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setSending(true);
      const result = onSubmit();
      if (result instanceof Promise) {
        await result;
      }
      setSending(false);
    },
    [onSubmit]
  );

  useEffect(() => {
    if (currentIndicatorId !== void 0) {
      context.selectedIndicatorId = currentIndicatorId;
    }

    context.feedbackForm.comment = comment;
  }, [context, comment, currentIndicatorId]);

  useEffect(() => {
    // При смене индикатора - очищаем список загруженных файлов
    context.feedbackForm.uploadManager.clean();
  }, [context, currentIndicatorId]);

  const [filesListDisabled, filesListDisabledReason] = useMemo<[boolean, string | void]>(() => {
    if (indicatorsLoading) {
      return [true, void 0];
    }

    if (currentIndicatorId === void 0) {
      return [true, 'Загрузка файлов возможна только после выбора показателя'];
    }

    if (feedbackIds.has(currentIndicatorId)) {
      return [true, void 0];
    }

    return [false, void 0];
  }, [indicatorsLoading, currentIndicatorId, feedbackIds]);

  const allIndicatorSent = !indicatorsLoading && !indicatorsLoadError && feedbackIds.size === indicatorsList.length;

  if (allIndicatorSent) {
    return (
      <ModalContentGroup className={s.FormInfoContent}>
        Поданы заявки по всем показателям текущей версии рейтинга.
        <br />
        Заявки по показателям станут доступны вновь после очередного обновления.
        <br />
        Пока возможно отправить только предложения по работе сервиса.
      </ModalContentGroup>
    );
  }

  return (
    <form className={s.FormContent} onSubmit={handleSubmit}>
      <ModalContentGroup title="Показатель">
        <div className={s.IndicatorSelect}>
          <Select
            placeholder="Выберите показатель из списка"
            classes={{
              input: clsx({
                [s.IndicatorSelect__input]: true,
                [s.IndicatorSelect__input_disabled]: currentIndicatorId && feedbackIds.has(currentIndicatorId)
              })
            }}
            waitData={indicatorsLoading}
            options={options}
            value={currentIndicatorId || -1}
            onChange={handleSelectIndicator}
          />
          {currentIndicatorId !== void 0 && feedbackIds.has(currentIndicatorId) && (
            <div className={s.IndicatorSelect__error}>
              <Icon icon={IconWarning} size={16} />
              По этому показателю заявка уже подана. Выберите другой показатель.
            </div>
          )}
          {indicatorsLoadError && (
            <div className={s.IndicatorSelect__error}>
              <Icon icon={IconWarning} size={16} />
              Не удалось загрузить индикаторы. Попробуйте позднее.
            </div>
          )}
        </div>
      </ModalContentGroup>

      <ModalContentGroup>
        <TextArea
          label="Комментарий"
          placeholder="Общий произвольный комментарий"
          value={comment}
          rows={5}
          classes={{ input: s.FormContent__input }}
          onChange={handleCommentChange}
        />

        <FilesControl
          disabled={filesListDisabled}
          disabledReason={filesListDisabledReason}
          limit={FILES_LIMIT}
          files={files}
          uploadManager={uploadManager}
          addFiles={addFiles}
          removeFile={removeFile}
        />
      </ModalContentGroup>

      <ModalFooter
        right={
          <Button
            variant={ButtonVariant.Primary}
            disabled={
              (currentIndicatorId && feedbackIds.has(currentIndicatorId)) ||
              indicatorsLoading ||
              indicatorsLoadError !== null ||
              sending ||
              context.feedbackForm.disabled
            }
            type="submit">
            Отправить
          </Button>
        }
      />
    </form>
  );
};
