import React from 'react';
import { formatDate } from '@digital-gov/ui-utils';

export const EfficiencyGroupText = () => {
  return (
    <>
      Группа
      <br />
      эффективности
    </>
  );
};

export const DegreeText = () => {
  return (
    <>
      Степень достижения
      <br /> показателей (%)
    </>
  );
};

export const PointsTotalText = () => {
  return (
    <>
      Общее количество
      <br /> баллов
    </>
  );
};

export const PointsMaxText = () => {
  return (
    <>
      Макс.возможное
      <br /> кол-во баллов
    </>
  );
};

export const DynamicFromPreviousText = ({ lastPeriodDate }: { lastPeriodDate?: string | null }) => {
  return (
    <>
      {lastPeriodDate != null ? (
        <>
          Динамика
          <br /> относительно {formatDate(lastPeriodDate, 'dd.MM')}
        </>
      ) : (
        <>
          Динамика относительно
          <br /> предыдущего периода
        </>
      )}
    </>
  );
};

export const PointsCountText = () => {
  return (
    <>
      Общее количество
      <br /> показателей
    </>
  );
};

export const RepresentativeText = () => {
  return (
    <>
      Полномочный
      <br />
      представитель
    </>
  );
};
