import { tryNumberLike } from '@digital-gov/ui-utils';

export enum InputErrorEnum {
  'NameLength',
  'NameContent',
  'SurnameLength',
  'SurnameContent',
  'PatronymicLength',
  'PatronymicContent',
  'YearValue',
  'AdditionalInfoLength'
}

const wrongCharacters = /[0-9|\\\/@&?!№#$%^*()"';:.,\[\]\+]/;

const fullOfSpaces = (value: string): boolean => value.trim().length === 0;

export const validate = (
  value: string,
  kinds: InputErrorEnum[],
  currentErrorsSet: Set<InputErrorEnum>
): Set<InputErrorEnum> => {
  const clonedErrorsSet = new Set(currentErrorsSet);
  for (const kind of kinds) {
    switch (kind) {
      case InputErrorEnum.NameLength:
        if (value.length > 50 || value.length < 1) clonedErrorsSet.add(InputErrorEnum.NameLength);
        else clonedErrorsSet.delete(InputErrorEnum.NameLength);
        break;
      case InputErrorEnum.NameContent:
        if (wrongCharacters.test(value) || fullOfSpaces(value)) clonedErrorsSet.add(InputErrorEnum.NameContent);
        else clonedErrorsSet.delete(InputErrorEnum.NameContent);
        break;
      case InputErrorEnum.SurnameLength:
        if (value.length > 50 || value.length < 1) clonedErrorsSet.add(InputErrorEnum.SurnameLength);
        else clonedErrorsSet.delete(InputErrorEnum.SurnameLength);
        break;
      case InputErrorEnum.SurnameContent:
        if (wrongCharacters.test(value) || fullOfSpaces(value)) clonedErrorsSet.add(InputErrorEnum.SurnameContent);
        else clonedErrorsSet.delete(InputErrorEnum.SurnameContent);
        break;
      case InputErrorEnum.PatronymicLength:
        if (value.length > 50 || value.length < 1) clonedErrorsSet.add(InputErrorEnum.PatronymicLength);
        else clonedErrorsSet.delete(InputErrorEnum.PatronymicLength);
        break;
      case InputErrorEnum.PatronymicContent:
        if (wrongCharacters.test(value) || fullOfSpaces(value)) clonedErrorsSet.add(InputErrorEnum.PatronymicContent);
        else clonedErrorsSet.delete(InputErrorEnum.PatronymicContent);
        break;
      case InputErrorEnum.YearValue:
        const year = tryNumberLike(value, null, true);
        if (!year || year > new Date().getFullYear()) clonedErrorsSet.add(InputErrorEnum.YearValue);
        else clonedErrorsSet.delete(InputErrorEnum.YearValue);
        break;
      case InputErrorEnum.AdditionalInfoLength:
        if (value.length > 50) clonedErrorsSet.add(InputErrorEnum.AdditionalInfoLength);
        else clonedErrorsSet.delete(InputErrorEnum.AdditionalInfoLength);
        break;
    }
  }
  return clonedErrorsSet;
};
