import { FileUploaderInterface, FileUploaderManager } from 'utils/FileUploader';

export class FilesController<FileIdType> {
  private manager = new FileUploaderManager<FileIdType>();
  private readonly uploaders: Map<string, FileUploaderInterface<FileIdType>> = new Map();

  get uploadManager() {
    return this.manager;
  }

  get files(): FileUploaderInterface<FileIdType>[] {
    return this.manager.files;
  }

  get isEmpty(): boolean {
    return this.uploaders.size === 0;
  }

  get inProgress(): boolean {
    return !this.manager.isAllDone;
  }

  clean() {
    this.manager.clean();
  }
}
