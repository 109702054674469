import React from 'react';
import clsx from 'clsx';
import s from './TableRow.module.scss';

export interface TableRowProps {
  values: [React.ReactNode, React.ReactNode];
  isHeader?: boolean;
}

export function TableRow({ values, isHeader }: TableRowProps) {
  return (
    <React.Fragment>
      <div className={clsx(s.TableItem, isHeader && s.TableItem_header)}>{values[0]}</div>
      <div className={clsx(s.TableItem, isHeader && s.TableItem_header)}>{values[1]}</div>
    </React.Fragment>
  );
}
