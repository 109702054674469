import React from 'react';
import clsx from 'clsx';
import { ReactComponent as WarningIcon } from './warning.svg';
import s from './Plug.module.scss';

export enum PlugVariant {
  Empty,
  Error
}

export function Plug({
  title,
  description = 'Нет данных, соответствующих заданным критериям',
  variant = PlugVariant.Empty,
  className
}: {
  title?: string;
  description?: React.ReactNode;
  variant?: PlugVariant;
  className?: string;
}) {
  return (
    <div className={clsx(s.Plug, className)}>
      <WarningIcon style={{ color: variant === PlugVariant.Error ? '#ffba27' : '#314278' }} />
      <div className={s.Plug__content}>
        {title && <h3 className={s.Plug__title}>{title}</h3>}
        <p className={s.Plug__description}>{description}</p>
      </div>
    </div>
  );
}
