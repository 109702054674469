import React, { useMemo, useState } from 'react';
import { Overlay } from 'components/Overlay';
import { RctControlProvider } from './_store/RctControlProvider';
import { errorTitleByScope, RctControlScopeEnum, RctType, ScopeObjectType, SecondPersonType } from './_utils';
import { ChangeRct, ChangeSecondPerson, RctChanged, RctControlError, SecondPersonChanged } from './modals';

export interface RctControlProps {
  scope: RctControlScopeEnum;
  scopeId: number;
  scopeName: string;
  currentRct?: RctType | null;
  currentSecondPersons?: SecondPersonType[];
  refetch?: () => void;
  handleClose: () => void;
}

/**
 * Данные представления 1 к 1 отражают модальные окна (директория Modals)
 */
enum RctControlCommonView {
  'changeRct',
  'changeSecondPerson',
  'rctChanged',
  'rctControlError',
  'secondPersonChanged'
}

const viewModeByScope = (scope: RctControlScopeEnum): RctControlCommonView =>
  scope === RctControlScopeEnum.DepartmentRct || scope === RctControlScopeEnum.RegionRct
    ? RctControlCommonView.changeRct
    : RctControlCommonView.changeSecondPerson;

export function RctControl({
  scope,
  scopeId,
  scopeName,
  currentRct,
  currentSecondPersons,
  refetch,
  handleClose
}: RctControlProps) {
  const [viewMode, setViewMode] = useState<RctControlCommonView>(viewModeByScope(scope));

  const onError = () => {
    setViewMode(RctControlCommonView.rctControlError);
  };

  const handleReset = () => {
    setViewMode(viewModeByScope(scope));
    handleClose();
  };

  const handleSuccessRct = () => {
    setViewMode(RctControlCommonView.rctChanged);
  };

  const handleSuccessSecondPerson = () => {
    setViewMode(RctControlCommonView.secondPersonChanged);
  };

  // Текущий ФОИВ / РОИВ
  const scopeObject: ScopeObjectType = useMemo(() => {
    return {
      scopeId,
      name: scopeName
    };
  }, [scopeName, scopeId]);

  return (
    <RctControlProvider
      scope={scope}
      currentScopeObject={scopeObject}
      currentRct={currentRct}
      currentSecondPersons={currentSecondPersons}
      refetchScopeData={refetch}>
      <Overlay onClick={handleClose}>
        {viewMode === RctControlCommonView.changeRct && (
          <ChangeRct scope={scope} onClose={handleClose} onSuccess={handleSuccessRct} onError={onError} />
        )}
        {viewMode === RctControlCommonView.changeSecondPerson && (
          <ChangeSecondPerson
            scope={scope}
            onClose={handleClose}
            onError={onError}
            onSuccess={handleSuccessSecondPerson}
          />
        )}
        {viewMode === RctControlCommonView.rctChanged && (
          <RctChanged handleForward={handleReset} handleClose={handleReset} />
        )}
        {viewMode === RctControlCommonView.rctControlError && (
          <RctControlError errorTitle={errorTitleByScope(scope)} handleBack={handleReset} handleClose={handleReset} />
        )}
        {viewMode === RctControlCommonView.secondPersonChanged && (
          <SecondPersonChanged
            kind={
              scope === RctControlScopeEnum.ViceChairman
                ? RctControlScopeEnum.ViceChairman
                : RctControlScopeEnum.Representative
            }
            handleForward={handleReset}
            handleClose={handleReset}
          />
        )}
      </Overlay>
    </RctControlProvider>
  );
}
