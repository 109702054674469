import React from 'react';
import { DATE_FULL_DAY_SHORT, formatDate } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'componentsL/Button';
import { ExcelLoader } from 'componentsL/ExcelLoader';
import { IconDownload } from 'componentsL/Icon';
import { PageHeader } from 'componentsL/PageHeader';
import { ScopeSwitch } from 'componentsL/ScopeSwitch';
import { ScopeEnum } from 'componentsL/ScopeSwitch/ScopeSwitch';
import { ExcelGenerator } from 'features/ExcelGenerator';
import { ExcelDataSourceEnum, RegionRatingType, useRegionsPageQuery } from 'store/graphql';
import { RegionsView } from './RegionsView';
import s from './RegionsPage.module.scss';

export function RegionsPage() {
  const { data, loading, error } = useRegionsPageQuery();

  const lastUpdateDate = data?.rating.regionRating.lastUpdateDate ?? undefined;

  return (
    <>
      <PageHeader
        className={s.RegionsPageHeader__root}
        title={'Оперативный рейтинг РРЦТ'}
        slotRight={
          <div className={s.RegionsPageHeader}>
            {lastUpdateDate && (
              <p className={s.RegionsPageHeader__date}>Обновление: {formatDate(lastUpdateDate, DATE_FULL_DAY_SHORT)}</p>
            )}
            <ExcelGenerator dataSource={ExcelDataSourceEnum.RegionsChart}>
              {({ download, loading }) =>
                loading ? (
                  <ExcelLoader />
                ) : (
                  <div className={s.RegionsPageHeader__excel}>
                    <Button
                      Component={'a'}
                      variant={ButtonVariant.LinkPrimary}
                      iconLeft={IconDownload}
                      onClick={download}
                      download>
                      Скачать
                    </Button>
                  </div>
                )
              }
            </ExcelGenerator>
            <ScopeSwitch scope={ScopeEnum.Rrct} />
          </div>
        }
      />
      <RegionsView loading={loading} error={error} regionalRating={data?.rating.regionRating as RegionRatingType} />
    </>
  );
}
