import React from 'react';
import { EMDASH, formatNumberIntl } from '@digital-gov/ui-utils';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { Table } from '../../../_components/Table';
import { TableRow } from '../../../_components/TableRow';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './GovDataControl.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const approvedIrIsDs = components[1]?.value;
  const totalIrIsDs = components[2]?.value;
  return (
    <div className={s.GovDataControl}>
      <h4 className={s.GovDataControl__title}>Компоненты</h4>
      <div className={s.GovDataControl__top}>
        <div className={s.GovDataControl__top__block}>
          <Symbol clearBackground clearPadding>
            МГД
          </Symbol>
          <p>
            <span className={s.GovDataControl__top__primaryText}>&nbsp;{'= 1'}</span>
            <span className={s.GovDataControl__top__secondaryText}>&nbsp;{'– 0,5 балла рейтинга'}</span>
          </p>
        </div>
        <div className={s.GovDataControl__top__block}>
          <Symbol clearBackground clearPadding>
            МГД
          </Symbol>
          <p>
            <span className={s.GovDataControl__top__primaryText}>&nbsp;{'≠ 1'}</span>
            <span className={s.GovDataControl__top__secondaryText}>&nbsp;{'– 0 баллов рейтинга'}</span>
          </p>
        </div>
      </div>

      <div className={s.GovDataControl__componentsGrid}>
        <div className={s.GovDataControl__component}>
          <div className={s.GovDataControl__descriptionGrid}>
            <div className={s.GovDataControl__descriptionGrid__symbol}>
              <Symbol square>МГД</Symbol>
            </div>
            <p className={s.GovDataControl__descriptionGrid__text}>
              Значение показателя «Оценка зрелости по управлению государственными данными»
            </p>
            <div className={s.GovDataControl__descriptionGrid__symbol}>
              <Symbol fixedCircle>ИР</Symbol>
            </div>
            <p className={s.GovDataControl__descriptionGrid__text}>Информационный ресурс</p>
            <div className={s.GovDataControl__descriptionGrid__symbol}>
              <Symbol fixedCircle>ИС</Symbol>
            </div>
            <p className={s.GovDataControl__descriptionGrid__text}>Информационная система</p>
            <div className={s.GovDataControl__descriptionGrid__symbol}>
              <Symbol fixedCircle>НД</Symbol>
            </div>
            <p className={s.GovDataControl__descriptionGrid__text}>Набор данных</p>
          </div>
        </div>

        <div className={s.GovDataControl__componentsGrid__divider}></div>

        <div className={s.GovDataControl__component}>
          <p className={s.GovDataControl__componentTitle}>Общее количество в статусе «Утверждено»</p>
          <div className={s.GovDataControl__componentSymbols}>
            <Symbol fixedCircle>ИР</Symbol>
            <Symbol fixedCircle>ИС</Symbol>
            <Symbol fixedCircle>НД</Symbol>
            <div className={s.GovDataControl__value}>
              {approvedIrIsDs != null ? formatNumberIntl(approvedIrIsDs) : EMDASH}&nbsp;шт.
            </div>
          </div>
        </div>

        <div className={s.GovDataControl__componentsGrid__divider}></div>

        <div className={s.GovDataControl__component}>
          <p className={s.GovDataControl__componentTitle}>Общее количество</p>
          <div className={s.GovDataControl__componentSymbols}>
            <Symbol fixedCircle>ИР</Symbol>
            <Symbol fixedCircle>ИС</Symbol>
            <Symbol fixedCircle>НД</Symbol>
            <div className={s.GovDataControl__value}>
              {totalIrIsDs != null ? formatNumberIntl(totalIrIsDs) : EMDASH}&nbsp;шт.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'ИР, ИС, НД в статусе «Не утверждено»']} />
      <TableRow
        values={[
          '0,5',
          'ФОИВ зарегистрирован в ФГИС ЕИП НСУД и описал свою Модель государственных данных и Модель витрины данных: ИР, ИС, НД в статусе «Утверждено»'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          <>
            ФОИВ развернул типовое ПО «Витрина данных» в своей инфраструктуре и запустило минимум 1 витрину <br />в
            эксплуатацию: ВД в статусе «ВД эксплуатируется в ПОДД».
          </>
        ]}
      />
      <TableRow
        values={[
          '1,5',
          'ФОИВ обеспечивает качество предоставляемых государственных данных с использованием ФГИС ЕИП НСУД путем оперативного реагирования на инциденты качества: сформированы и согласованы проверки качества, открытые инциденты качества в ФГИС ЕИП НСУД отсутствуют.'
        ]}
      />
    </Table>
  );
}

export function GovDataControl() {
  return (
    <IndicatorPage
      download={'НСУД_v1.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
