import { useCallback } from 'react';
import { IconButton } from 'components/Button';
import { DropdownList, DropdownListItem } from 'components/DropdownList';
import { DotsIcon, EditIcon, UnlinkIcon } from 'components/Icon';
import { useDropdown } from 'hooks/useDropdown';
import { useRctControlContext } from '../../../_store/useRctControlContext';
import s from './Rct.module.scss';

export interface RctProps {
  handleSwitchToEditMode: () => void;
}

export function Rct({ handleSwitchToEditMode }: RctProps) {
  const { showDropdown, setDropdownElement, handleDropdownToggle, handleDropdownClose } = useDropdown();
  const { currentRct, setCurrentRct } = useRctControlContext();

  const handleEditing = () => {
    handleDropdownClose();
    handleSwitchToEditMode();
  };

  const handleUnlink = useCallback(() => {
    setCurrentRct(null);
    handleDropdownClose();
  }, [handleDropdownClose, setCurrentRct]);

  return (
    <>
      {currentRct && (
        <div className={s.Rct__entry}>
          <div className={s.Rct__nameAndYear}>
            <div>{currentRct.fullName}</div>
            {currentRct.year && <div className={s.Rct__yearText}>Год назначения - {currentRct.year}</div>}
          </div>
          <div className={s.Rct__dotsIcon} ref={setDropdownElement}>
            <IconButton icon={DotsIcon} onClick={handleDropdownToggle} />
            {showDropdown && (
              <DropdownList className={s.Rct__actions}>
                <DropdownListItem>
                  <div className={s.Rct__action} onClick={handleEditing}>
                    <EditIcon />
                    <div>Редактировать</div>
                  </div>
                </DropdownListItem>
                <DropdownListItem>
                  <div className={s.Rct__action} onClick={handleUnlink}>
                    <UnlinkIcon />
                    <div>Отвязать</div>
                  </div>
                </DropdownListItem>
              </DropdownList>
            )}
          </div>
        </div>
      )}
    </>
  );
}
