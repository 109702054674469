import React, { useEffect } from 'react';
import clsx from 'clsx';
import s from './Overlay.module.scss';

export interface OverlayProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
  className?: string;
  backdropClassName?: string;
}

export function Overlay({ onClick, children, className, backdropClassName }: OverlayProps) {
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'hidden';
      return () => {
        body.style.overflow = '';
      };
    }
  }, []);

  return (
    <div className={clsx(s.Overlay, !!onClick && s.Overlay_interactive, className)}>
      <div className={clsx(s.Overlay__backdrop, backdropClassName)} onClick={onClick} />
      {children}
    </div>
  );
}
