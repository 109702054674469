import React from 'react';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './GovPublic.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const R = components[0]?.value; // 207 - R
  const F = components[1]?.value; // 208 - F
  const T = components[2]?.value; // 209 - T
  const E = components[3]?.value; // 210 - E
  const Mr = components[4]?.value; // 211 - Mr
  const I = components[5]?.value; // 212 - I
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={I} description={'Значение показателя «Госпаблики»'}>
        <Symbol color={'#55D666'} square>
          I
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={R}
        description={
          <>
            Количество баллов, которые получил субъект Российской Федерации в Рейтинге, актуальном на дату расчета
            показателя
          </>
        }>
        <Symbol>R</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={F}
        description={
          <>
            Количество баллов превышения целевого значения показателя по частотности публикаций субъекта Российской
            Федерации <br />
            (не более 100 баллов превышения) в соответствии с Рейтингом, актуальном на дату расчета показателя
          </>
        }>
        <Symbol>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={T}
        description={
          <>
            Количество баллов превышения целевого значения показателя по охвату интернет-аудитории субъекта Российской
            Федерации подписчиками официальных страниц (не более 100 баллов превышения) в соответствии с Рейтингом,
            актуальном на дату расчета показателя
          </>
        }>
        <Symbol>T</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={E}
        description={
          <>
            Количество баллов превышения целевого значения показателя по уровню вовлеченности публикаций официальных
            страниц субъекта Российской Федерации (не более 100 баллов превышения) в соответствии с Рейтингом,
            актуальном на дату расчета показателя
          </>
        }>
        <Symbol>E</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={Mr}
        description={
          <>Максимальное количество баллов, которые субъект Российской Федерации может получить в Рейтинге</>
        }>
        <Symbol sub={'R'}>M</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <>
      <p className={s.GovPublic__calc_p}>
        Итоговая оценка показателя определяется в баллах и равна значению показателя.
      </p>
      <p className={s.GovPublic__calc_p}>Максимальное количество баллов по показателю – 4,0</p>
    </>
  );
}

export function GovPublic() {
  return (
    <IndicatorPage
      regionScope
      download={'Госпаблики_v2.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
