import React from 'react';
import clsx from 'clsx';
import s from './Efficiency.module.scss';

export const EfficiencyGroup = ({ group, label }: { group: 'High' | 'Average' | 'Low'; label: string }) => {
  return <div className={clsx(s.EfficiencyGroup, s[`EfficiencyGroup_${group.toLowerCase()}`])}>{label}</div>;
};

export const EfficiencyGroupLine = ({ group }: { group: 'High' | 'Average' | 'Low' }) => {
  return <div className={clsx(s.EfficiencyGroupLine, s[`EfficiencyGroupLine_${group.toLowerCase()}`])} />;
};
