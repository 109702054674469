import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const k_ps = components[0]?.value; // 241 - Кпс1...i
  const k_spd = components[1]?.value; // 242 - Кспд1…i
  const s_sv = components[2]?.value; // 243 - Ссв
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={s_sv}
        description={
          <>
            Значение показателя «Информационное взаимодействие ФГИС ЕЦП НСПД с информационными системами субъекта
            Российской Федерации»
          </>
        }>
        <Symbol square color={'#55D666'} sub={'св'}>
          С
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k_ps}
        description={
          <>
            Количество переданных оператору ФГИС ЕЦП НСПД слоев (актуализации информации слоев) пространственных данных,
            содержащихся в региональных информационных системах субъекта Российской Федерации, осуществляющих
            информационное взаимодействие с ФГИС ЕЦП НСПД <br />в соответствии с соглашением об информационном
            взаимодействии, в отчетном периоде
          </>
        }>
        <Symbol sub={'пс1...i'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k_spd}
        description={
          <>
            Общее количество слоев пространственных данных, указанных <br />в протоколах информационного взаимодействия
            <br />с региональными информационными системами субъекта Российской Федерации, осуществляющими
            информационное взаимодействие с ФГИС ЕЦП НСПД, в отчетном периоде
          </>
        }>
        <Symbol sub={'спд1...i'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение Ссв, %']} isHeader />
      <TableRow values={['0', 'Ссв < 25%']} />
      <TableRow values={['0,5', '25% ≤ Ссв < 50%']} />
      <TableRow values={['1,0', '50% ≤ Ссв < 75%']} />
      <TableRow values={['1,5', '75% ≤ Ссв < 100%']} />
      <TableRow values={['2,0', 'Ссв =100%']} />
    </Table>
  );
}

export function NspdDigitalInteraction() {
  return (
    <IndicatorPage
      regionScope
      download={'ФГИС ЕЦП НСПД.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
