import clsx from 'clsx';
import { PieChart, Pie, Cell } from 'recharts';
import s from './Speedometer.module.scss';

export interface SpeedometerProps {
  classes?: {
    root?: string;
    cell?: string;
  };
  valuePercentage: number;
  hovered?: boolean;
  disabled?: boolean;
  startAnimation?: boolean;
}

const defaultNeedleColor = '#ffffff';
const disabledNeedleColor = '#545572';

const RADIAN = Math.PI / 180;
const data = [{ value: 1 }, { value: 1 }, { value: 1 }];
const boxSize = 145;
const innerRadius = 100;

const needle = (value: number, disabled?: boolean, startAnimation?: boolean) => {
  let total = 100;
  const ang = 180.0 * (1 - value / total);
  const length = (innerRadius + 2 * boxSize) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const sin1 = Math.sin(-RADIAN * (ang + 1.3));
  const cos1 = Math.cos(-RADIAN * (ang + 1.3));
  const r = 5;
  const x0 = boxSize + 5;
  const y0 = boxSize;
  const xba = x0 + 2 * sin;
  const yba = y0 - 2 * cos;
  const xbb = x0 - 2 * sin;
  const ybb = y0 + 2 * cos;
  const xpa = x0 + length * cos;
  const ypa = y0 + length * sin;
  const xpb = x0 + length * cos1;
  const ypb = y0 + length * sin1;

  return (
    <g
      className={clsx(startAnimation && s.Speedometer__needle)}
      style={{ transformOrigin: `${x0}px ${y0}px`, transform: `rotate(${ang - 180}deg)` }}>
      <circle
        cx={x0}
        cy={y0}
        r={r}
        fill={disabled ? disabledNeedleColor : defaultNeedleColor}
        stroke="none"
        key="circle"
        cursor="pointer"
      />
      ,
      <path
        key="path"
        d={`M${xba} ${yba} L${xbb} ${ybb} L${xpa} ${ypa} L${xpb} ${ypb} L${xba} ${yba}`}
        stroke="#none"
        fill={disabled ? disabledNeedleColor : defaultNeedleColor}
      />
    </g>
  );
};

export function Speedometer({ classes, valuePercentage, hovered, disabled, startAnimation }: SpeedometerProps) {
  return (
    <div className={clsx(s.Speedometer, disabled && s.Speedometer_disabled, classes?.root)}>
      <PieChart width={300} layout={'horizontal'} height={170}>
        <Pie
          isAnimationActive={false}
          cursor="pointer"
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={boxSize}
          cy={boxSize}
          innerRadius={innerRadius}
          outerRadius={boxSize}
          stroke="none">
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              className={clsx(
                s.Speedometer__cell,
                s[`Speedometer__cell${index}`],
                hovered ? s[`Speedometer__cellHover${index}`] : null,
                disabled ? s[`Speedometer__cellDisabled${index}`] : null,
                classes?.cell
              )}
            />
          ))}
        </Pie>
        {needle(valuePercentage, disabled, startAnimation)}
      </PieChart>
    </div>
  );
}
