import { memo } from 'react';
import clsx from 'clsx';
import { ISortedColumn } from '../types';
import { ReactComponent as SortIcon } from './TableCellHeaderSelect/Type=General-sort.svg';
import s from './TableCellHeader.module.scss';

export interface TableCellHeaderProps {
  title: string;
  subTitle?: string;
  index: number;
  sort: ISortedColumn['sort'];
  onColumnSortIcon: () => void;
}

export const TableCellHeader = memo((props: TableCellHeaderProps) => {
  const { title, subTitle, index, sort, onColumnSortIcon } = props;

  return (
    <div className={clsx(s.TableCellHeader, index === 0 && s.TableCellHeader_left)}>
      <div>
        <p className={s.TableCellHeader__title}>{title}</p>
        {subTitle && <p className={s.TableCellHeader__subTitle}>{subTitle}</p>}
      </div>
      <SortIcon
        className={clsx(s.TableCellHeader__icon, s[`TableCellHeader__icon_${sort}`])}
        onClick={onColumnSortIcon}
      />
    </div>
  );
});

TableCellHeader.displayName = 'TableCellHeader';
