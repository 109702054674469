import React from 'react';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { SmevExploitationComponent } from './SmevExploitationComponent';
import s from './SmevExploitationComponents.module.scss';

export function SmevExploitationComponents({ components }: IndicatorComponentsProps) {
  const k1SpeedSmev3 = components[0]?.value;
  const k2AvailabilitySmev3 = components[1]?.value;
  const k3SpeedSmev4 = components[2]?.value;
  const k4AvailabilitySmev4 = components[3]?.value;
  const k5DataQuality = components[4]?.value;
  const k6MaintenanceQuality = components[5]?.value;
  return (
    <>
      <h4 className={s.SmevExploitation__header}>Компоненты</h4>
      <div className={s.SmevExploitation_container}>
        <div className={s.SmevExploitation__qualityCriterion}>Критерии качества</div>
        <div className={s.SmevExploitation__pointsTotalCount}>Количество баллов</div>
        <div className={s.SmevExploitation__headerCell} style={{ gridColumnStart: 'c2' }}>
          1
        </div>
        <div className={s.SmevExploitation__headerCell} style={{ gridColumnStart: 'c3' }}>
          2
        </div>
        <div className={s.SmevExploitation__headerCell} style={{ gridColumnStart: 'c4' }}>
          3
        </div>
        <div className={s.SmevExploitation__headerCell} style={{ gridColumnStart: 'c5' }}>
          4
        </div>
        <div className={s.SmevExploitation__headerCell} style={{ gridColumnStart: 'c6' }}>
          5
        </div>

        {/*K1*/}
        <SmevExploitationComponent
          index={1}
          name={
            <>
              скорость выполнения информационного обмена <br />в СМЭВ3
            </>
          }
          scoreCriteria={[
            {
              from: (
                <>
                  свыше
                  <br /> 48 часов
                </>
              )
            },
            { from: 'от 12', to: 'до 48 часов' },
            { from: 'от 60 сек', to: 'до 12 часов' },
            { from: 'от 2', to: 'до 60 сек' },
            { from: 'менее 2 сек' }
          ]}
          componentValue={k1SpeedSmev3}
        />

        {/*K2*/}
        <SmevExploitationComponent
          index={2}
          name={'доступность информационного обмена в СМЭВ3'}
          scoreCriteria={[
            { from: 'менее 70%' },
            { from: 'от 70%', to: 'до 85%' },
            { from: 'от 85%', to: 'до 95%' },
            { from: 'от 95%', to: 'до 99%' },
            { from: 'более 99%' }
          ]}
          componentValue={k2AvailabilitySmev3}
        />

        {/*K3*/}
        <SmevExploitationComponent
          index={3}
          name={'скорость информационного обмена в СМЭВ4'}
          scoreCriteria={[
            {
              from: (
                <>
                  свыше
                  <br /> 48 часов
                </>
              )
            },
            { from: 'от 12', to: 'до 48 часов' },
            { from: 'от 60 сек', to: 'до 12 часов' },
            { from: 'от 2', to: 'до 60 сек' },
            { from: 'менее 2 сек' }
          ]}
          componentValue={k3SpeedSmev4}
        />

        {/*K4*/}
        <SmevExploitationComponent
          index={4}
          name={'доступность информационного обмена в СМЭВ4'}
          scoreCriteria={[
            { from: 'менее 70%' },
            { from: 'от 70%', to: 'до 85%' },
            { from: 'от 85%', to: 'до 95%' },
            { from: 'от 95%', to: 'до 99%' },
            { from: 'более 99%' }
          ]}
          componentValue={k4AvailabilitySmev4}
        />

        {/*K5*/}
        <SmevExploitationComponent
          index={5}
          name={'качество данных, являющихся предметом информационного обмена'}
          scoreCriteria={[
            { from: 'менее 50%' },
            { from: 'от 50%', to: 'до 75%' },
            { from: 'от 75%', to: 'до 90%' },
            { from: 'от 90%', to: 'до 97%' },
            { from: 'более 97%' }
          ]}
          componentValue={k5DataQuality}
        />

        {/*K6*/}
        <SmevExploitationComponent
          index={6}
          name={'качество сопровождения ИС'}
          scoreCriteria={[
            { from: 'менее 50%' },
            { from: 'от 50%', to: 'до 75%' },
            { from: 'от 75%', to: 'до 90%' },
            { from: 'от 90%', to: 'до 97%' },
            { from: 'более 97%' }
          ]}
          componentValue={k6MaintenanceQuality}
        />
      </div>
    </>
  );
}
