import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { saveAuthTarget, useAuth } from '@digital-gov/auth-apollo-store';
import { Button, ButtonVariant } from 'componentsL/Button';
import { IconWarning } from 'componentsL/Icon';
import { basename } from 'store/config';
import { ReactComponent as Logo } from './logo.svg';
import s from './LoginPage.module.scss';

export function LoginPage() {
  const { logIn, error } = useAuth();

  const [searchParams] = useSearchParams();
  const target = searchParams.get('target');

  useEffect(() => {
    saveAuthTarget(target);
  }, [target]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Вход</title>
      </Helmet>

      <div className={s.LoginPage}>
        <div className={s.LoginPage__content}>
          <Logo className={s.LoginPage__logo} />
          <Button onClick={() => logIn()}>Войти</Button>

          {error && (
            <div className={s.LoginPage__error}>
              <IconWarning className={s.LoginPage__errorIcon} />
              <span className={s.LoginPage__errorText}>{error}</span>
            </div>
          )}

          <div className={s.LoginPage__links}>
            <Button variant={ButtonVariant.LinkPrimary} Component={'a'} href={'https://www.gosuslugi.ru/crt'}>
              Как установить сертификаты Минцифры
            </Button>
            <Button
              variant={ButtonVariant.LinkPrimary}
              Component={'a'}
              href={encodeURI(`${basename}/uploads/Вход в Рейтинг РЦТ Инструкция.pdf`)}
              download>
              Скачать инструкцию
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
