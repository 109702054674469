import React, { useState } from 'react';
import { Icon, IconRecommendation } from 'componentsL/Icon';
import { Tooltip } from 'componentsL/Tooltip';
import { RatingIndicatorProgressGroupEnum } from 'store/graphql';
import s from './RecommendationIcon.module.scss';

export function RecommendationIcon({
  indicatorProgressGroup
}: {
  indicatorProgressGroup: RatingIndicatorProgressGroupEnum;
}) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);

  return (
    <>
      {active && (
        <Tooltip
          targetNode={ref}
          showArrow={true}
          tooltipOptions={{
            placement: 'top-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [10, 10, 0, 0]
                }
              }
            ]
          }}>
          <div className={s.RecommendationIcon__toolTipText}>Рекомендация по улучшению показателя</div>
        </Tooltip>
      )}
      <div ref={setRef} onMouseOver={() => setActive(true)} onMouseLeave={() => setActive(false)}>
        <Icon icon={IconRecommendation} className={s[`RecommendationIcon_${indicatorProgressGroup.toLowerCase()}`]} />
      </div>
    </>
  );
}
