import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const apv = components[0]?.value; // 247 - Апв
  const aesia = components[1]?.value; // 248 - Аесиа
  const apos = components[2]?.value; // 249 - Апос
  const a = components[3]?.value; // 250 -  А
  return (
    <SymbolsGrid columns={2} largeGap>
      <SymbolDetails
        value={a}
        description={
          <>
            Значение показателя «Доля выгруженных полных сведений об обучающихся начального общего, основного общего,
            среднего общего образования и среднего профессионального образования <br />
            на витрины данных сведений в сфере образования» за отчетный период
          </>
        }>
        <Symbol square color={'#55D666'}>
          A
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={apv}
        description={
          <>
            Значение подпоказателя «Полнота, актуальность, непротиворечивость и связанность загруженных сведений об
            обучающихся начального общего, основного общего, среднего общего образования и среднего профессионального
            образования на витрины данных сведений в сфере образования» <br />
            за отчетный период
          </>
        }>
        <Symbol sub={'ПВ'}>A</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={aesia}
        description={
          <>
            Значение подпоказателя «Авторизация и вход в РГИС только посредством SSO ЕСИА и доля детских учетных записей
            у обучающихся с 10 до 17 лет» за отчетный период
          </>
        }>
        <Symbol sub={'есиа'}>A</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={apos}
        description={
          <>
            Значение подпоказателя «Обращения в ПОС по подкатегориям: неполноты или отсутствия данных» <br />
            за отчетный период
          </>
        }>
        <Symbol sub={'пос'}>A</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение A, %']} isHeader />
      <TableRow values={['0', 'А < 0,31']} />
      <TableRow values={['0,5', '0,31 ≤ А < 0,4']} />
      <TableRow values={['1,0', '0,4 ≤ А < 0,73']} />
      <TableRow values={['2,0', '0,73 ≤ А < 0,8']} />
      <TableRow values={['3,0', '0,8 ≤ А ≤ 1']} />
    </Table>
  );
}

export function EducationQualityMonitoring() {
  return (
    <IndicatorPage
      regionScope
      download={'Мониторинг образование.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
