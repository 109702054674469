import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Icon, IconSize } from 'componentsL/Icon';
import { DigitalGovIcon, FgiskiIcon, NfapIcon, SamoobsIcon, ServicesIcon } from './icons';
import s from './ServicesMenu.module.scss';

interface ServicesMenuItemProps {
  icon: React.ElementType;
  title: string;
  link?: string;
}

function ServiceMenuItem({ icon, title, link }: ServicesMenuItemProps) {
  const content = (
    <React.Fragment>
      <div className={s.ServicesMenuItem__icon}>
        <Icon icon={icon} size={IconSize.XLarge} />
      </div>
      <label className={s.ServicesMenuItem__label}>{title}</label>
    </React.Fragment>
  );
  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        referrerPolicy="no-referrer"
        rel="nofollow noreferrer"
        className={s.ServicesMenuItem}>
        {content}
      </a>
    );
  } else {
    return <div className={clsx(s.ServicesMenuItem, s.ServicesMenuItem_disabled)}>{content}</div>;
  }
}

export const ServicesMenu = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const servicesMenuButton = useRef<HTMLButtonElement | null>(null);
  const serviceMenuPopup = useRef<HTMLDivElement | null>(null);
  const [isOpened, setIsOpened] = useState(false);

  const { styles, attributes } = usePopper(servicesMenuButton.current, serviceMenuPopup.current, {
    placement: 'top-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
  });

  const handleClose = useCallback(() => setIsOpened(false), [setIsOpened]);

  useClickOutside(ref.current, handleClose);

  return (
    <div ref={ref} className={s.ServicesMenu}>
      <Button
        ref={servicesMenuButton}
        className={clsx(s.ServicesMenu__button, {
          [s.ServicesMenu__button_active]: isOpened
        })}
        variant={ButtonVariant.LinkSecondary}
        onClick={() => setIsOpened(!isOpened)}>
        <Icon icon={ServicesIcon} size={IconSize.Medium} />
      </Button>

      <div
        ref={serviceMenuPopup}
        className={clsx(s.ServicesMenu__popup, {
          [s.ServicesMenu__popup_hidden]: !isOpened
        })}
        style={styles.popper}
        {...attributes.popper}>
        <ServiceMenuItem icon={DigitalGovIcon} title="Минцифры" link="https://digital.gov.ru/" />
        <ServiceMenuItem icon={FgiskiIcon} title="ФГИС КИ" link="https://392.eskigov.ru/" />
        <ServiceMenuItem icon={NfapIcon} title="НФАП" link="https://nfap.eskigov.ru/" />
        <ServiceMenuItem icon={SamoobsIcon} title="Портал самообслуживания" link={'https://portal.eskigov.ru/'} />
      </div>
    </div>
  );
};
