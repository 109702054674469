import React from 'react';
import { RatingGroupType } from '../types';

export type DepartmentChartType = {
  group: RatingGroupType | null;
  setGroup: (group: RatingGroupType | null) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  viceChairman: string;
  setViceChairman: (value: string) => void;
};

export const DepartmentChartContext = React.createContext<DepartmentChartType>({
  group: null,
  setGroup: () => {},
  searchValue: '',
  setSearchValue: () => {},
  viceChairman: '',
  setViceChairman: () => {}
});
