import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { RatingFeedbackScope } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { FeedbackHistory } from '../FeedbackHistory';
import { IndicatorFeedback } from '../IndicatorFeedback';
import { IndicatorFeedbackDetails } from '../IndicatorFeedbackDetails';
import { FeedbackIcon } from './FeedbackIcon';
import s from './CardFeedbackButton.module.scss';

export interface CardFeedbackButtonProps {
  hasFeedback?: boolean;
  scope: RatingFeedbackScope;
  scopeId: number;
  indicatorId?: number;
  // Аргумент indicatorId is void только, если было подано предложение; если было подано обращение в ОС - будет number
  onFeedbackSent?: (indicatorId: number | void) => void;
}

enum UsingModal {
  None,
  CreateFeedback,
  ShowHistory,
  ShowDetails
}

export const CardFeedbackButton = ({
  scope,
  scopeId,
  indicatorId,
  hasFeedback = false,
  onFeedbackSent
}: CardFeedbackButtonProps) => {
  const [badgeOn, setBadgeOn] = useState(hasFeedback);
  const [usingModal, setUsingModal] = useState<UsingModal>(UsingModal.None);
  const [detailsFor, setDetailsFor] = useState<Feedback | null>(null);

  const { isCurrentDepartmentPeriod, isCurrentRegionPeriod } = useProfile();
  const isCurrentPeriod =
    scope === RatingFeedbackScope.Department || scope === RatingFeedbackScope.DepartmentIndicator
      ? isCurrentDepartmentPeriod
      : isCurrentRegionPeriod;

  const showFeedbackModal = useCallback(() => setUsingModal(UsingModal.CreateFeedback), [setUsingModal]);
  const showHistoryModal = useCallback(() => setUsingModal(UsingModal.ShowHistory), [setUsingModal]);
  const showDetailsModal = useCallback(
    (feedback: Feedback) => {
      setUsingModal(UsingModal.ShowDetails);
      setDetailsFor(feedback);
    },
    [setUsingModal, setDetailsFor]
  );
  const hideModal = useCallback(() => setUsingModal(UsingModal.None), []);
  const closeDetails = useCallback(() => {
    setUsingModal(UsingModal.ShowHistory);
    setDetailsFor(null);
  }, [setUsingModal, setDetailsFor]);

  const handleSent = useCallback(
    (feedbackIndicatorId: number | void) => {
      if (indicatorId !== void 0 && indicatorId === feedbackIndicatorId) {
        // Если находимся на странице индикатора (показателя) - показываем бадж, чтобы показать,
        //  что тут обращение в ОС (не предложение) уже отправлено
        setBadgeOn(true);
      }

      onFeedbackSent?.(feedbackIndicatorId);
    },
    [onFeedbackSent, setBadgeOn, indicatorId]
  );

  const title = useMemo<string | void>(() => {
    if (indicatorId === void 0) {
      // Тултип для ФОИВ-а / РОИВ-а - всегда подсвечивать "История обращений"
      return 'История заявок';
    }

    // В случае если ФОС по показателю не передана - "История обращений"
    return badgeOn ? 'По этому показателю уже подана заявка' : 'История заявок';
  }, [indicatorId, badgeOn]);

  return (
    <>
      <div className={s.CardFeedbackButton}>
        {isCurrentPeriod && (
          <Button variant={ButtonVariant.LinkPrimary} onClick={showFeedbackModal}>
            Форма обратной связи
          </Button>
        )}

        <FeedbackIcon title={title} badgeOn={badgeOn} onClick={showHistoryModal} />
      </div>

      {usingModal === UsingModal.CreateFeedback && (
        <IndicatorFeedback
          scope={scope}
          scopeId={scopeId}
          indicatorId={indicatorId}
          onSent={handleSent}
          onClose={hideModal}
        />
      )}

      {usingModal === UsingModal.ShowHistory && (
        <FeedbackHistory
          scope={scope}
          scopeId={scopeId}
          indicatorId={indicatorId}
          onSelectFeedback={showDetailsModal}
          onClose={hideModal}
        />
      )}

      {usingModal === UsingModal.ShowDetails && (
        <IndicatorFeedbackDetails feedback={detailsFor!} onClose={hideModal} onBack={closeDetails} />
      )}
    </>
  );
};
