import React from 'react';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as FormulaPart1 } from './formula_p1.svg';
import { ReactComponent as FormulaPart2 } from './formula_p2.svg';

function Formula() {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: '60px' }}>
          <FormulaPart1 />
        </div>
        <div>
          <FormulaPart2 />
        </div>
      </div>
    </>
  );
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const userActivity = components[0]?.value;
  const activeUsersByTwoWeeks = components[1]?.value;
  const totalUsersCount = components[2]?.value;
  const workspaceTransfer = components[3]?.value;
  const employeesToTransferOnArmGs = components[4]?.value;
  return (
    <>
      <SymbolsGrid columns={3}>
        <SymbolDetails value={userActivity} unit={'%'} description={'Показатель активности пользователя на АРМ ГС'}>
          <Symbol sub={'а'}>П</Symbol>
        </SymbolDetails>
        <SymbolDetails
          value={activeUsersByTwoWeeks}
          description={'Количество активных пользователей за период 2 недели'}>
          <Symbol sub={'а'}>К</Symbol>
        </SymbolDetails>
        <SymbolDetails value={totalUsersCount} description={'Количество созданных учетных записей'}>
          <Symbol sub={'уз'}>К</Symbol>
        </SymbolDetails>
        <SymbolDetails value={workspaceTransfer} unit={'%'} description={'Показатель перевода рабочих мест на КС'}>
          <Symbol sub={'п'}>П</Symbol>
        </SymbolDetails>
        <SymbolDetails
          value={employeesToTransferOnArmGs}
          description={
            <>
              Количество сотрудников в ФОИВ, планируемых к переводу <br />
              на АРМ ГС
            </>
          }>
          <Symbol sub={'с'}>К</Symbol>
        </SymbolDetails>
      </SymbolsGrid>
    </>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Переведено менее 30% рабочих мест на КС ФОИВ и их территориальных органов']} />
      <TableRow values={['0,5', 'Переведено 30% и более рабочих мест на КС']} />
      <TableRow
        values={['1,0', 'Переведено 60% и более рабочих мест на КС, достигнут показатель активности не менее 40%']}
      />
      <TableRow
        values={['2,0', 'Переведено 75% и более рабочих мест на КС, достигнут показатель активности не менее 50%']}
      />
    </Table>
  );
}

export function UsingArmGs() {
  return (
    <IndicatorPage
      download={'Индекс использования АРМ ГС.pdf'}
      formula={Formula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
    />
  );
}
