import React, { useContext, useRef } from 'react';
import clsx from 'clsx';
import { FaqContext } from '../../faq-context';
import { QuestionItem } from '../../types';
import { QaQuestion } from '../QaQuestion';
import s from './QaSection.module.scss';

export interface QABlockProps {
  id: number;
  // Заголовок блока
  title?: string;
  // список вопросов (с ответами)
  questions: QuestionItem[];
}

export const QaSection = ({ id, title, questions }: QABlockProps) => {
  const titleRef = useRef<null | HTMLDivElement>(null);
  const faq = useContext(FaqContext);

  return (
    <div className={s.QaSection} id={String(id)}>
      {title && (
        <div ref={titleRef} className={s.QaSection__title} {...faq.props(faq.SearchArea.SectionTitle, id)}>
          {title}
        </div>
      )}
      <div className={s.QaSection__content}>
        {questions.map((item, index) => (
          <div
            key={item.id}
            className={clsx(s.QaSection__qaItem, { [s.QaSection__qaItem_hidden]: !faq.getQuestionVisibility(item.id) })}
            {...faq.props(faq.SearchArea.QaBlock, item.id)}>
            <QaQuestion key={item.id} id={item.id} question={item.question} answer={item.answer} />
          </div>
        ))}
      </div>
    </div>
  );
};
