import clsx from 'clsx';
import { RatingIndicatorProgressGroupEnum } from 'store/graphql';
import s from './DynamicsMark.module.scss';

type Props = {
  progressGroup: RatingIndicatorProgressGroupEnum;
};

export const DynamicsMark = ({ progressGroup }: Props) => {
  const style = clsx(s.DynamicsMark, s[`DynamicsMark__status${progressGroup}`]);
  return <div className={style} />;
};
