import { isNumberLike } from './tryNumberLike';
const defaultIntlLocale = 'RU-ru';
const fileSizeUnits = {
    size: ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'],
    speed: ['б', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб']
};
export function formatFileSize(bytes, units = fileSizeUnits.size, bytesPerKilobyte = 1000) {
    if (!isNumberLike(bytes))
        return '';
    let formattedSize = bytes;
    let unitIndex = 0;
    while (formattedSize >= bytesPerKilobyte && unitIndex < units.length - 1) {
        formattedSize /= bytesPerKilobyte;
        unitIndex++;
    }
    const maximumFractionDigits = bytes < Math.pow(bytesPerKilobyte, 2) ? 0 : 1;
    const formattedString = new Intl.NumberFormat(defaultIntlLocale, {
        maximumFractionDigits
    }).format(formattedSize);
    return `${formattedString}\u00A0${units[unitIndex]}`;
}
formatFileSize.units = fileSizeUnits;
