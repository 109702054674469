import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Options } from '@popperjs/core';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@digital-gov/ui-utils';
import { IconChevronDown } from 'componentsL/Icon';
import { IconProps } from 'componentsL/Icon/Icon';
import { TextInput, TextInputProps } from 'componentsL/Input/TextInput/TextInput';
import sTextInput from 'componentsL/Input/TextInput/TextInput.module.scss';
import { Loader } from 'componentsL/Loader';
import s from './Select.module.scss';

const popperOptionsDefaults: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom-end',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    }
  ]
};

export type SelectOptionLabelType = string | undefined;
export type SelectOptionValueType = string | number | null;

export type SelectOptionType<V = SelectOptionValueType> = {
  label?: SelectOptionLabelType;
  labelElement?: React.ReactNode;
  value: V;
  disabled?: boolean;
};

export interface SelectProps<V> extends Pick<TextInputProps, 'placeholder'> {
  waitData?: boolean;
  className?: string;
  classes?: {
    input?: string;
    dropdown?: string;
  };
  disabled?: boolean;
  options: SelectOptionType<V>[];
  value: SelectOptionType<V>['value'];
  iconLeft?: React.ElementType;
  iconLeftProps?: Pick<IconProps, 'className' | 'classes' | 'size' | 'iconSize'>;
  onChange: (value: SelectOptionType<V>['value']) => void;
}

export const Select = <V,>({
  waitData = false,
  className,
  classes,
  disabled,
  placeholder,
  options,
  value,
  iconLeft,
  iconLeftProps,
  onChange
}: SelectProps<V>) => {
  const [open, setOpen] = useState(false);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const selectControlRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      // Enter
      if (e.keyCode === 13) setOpen((open) => !open);
    },
    [setOpen]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      setOpen((open) => {
        if (!open) (e.target as HTMLInputElement).focus();
        return !open;
      });
    },
    [setOpen]
  );

  const handleChange = useCallback(
    (option: SelectOptionType<V>) => {
      if (option.disabled) return;
      onChange(option.value);
      handleClose();
    },
    [handleClose, onChange]
  );

  const selectedOption = isNil(value) ? undefined : options.find((o) => o.value === value);

  useClickOutside(dropdownMenuRef.current, handleClose);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(dropdownMenuRef.current, popperElement, {
    ...popperOptionsDefaults
  });

  const hasOptions = !!options.length;

  // Данный хук определяет поведение при навигации с клавиатуры:
  //  если поле input теряет фокус, но он переходит на эл-т меню - список остаётся развёрнут;
  //  если поле input или эл-т меню теряют фокус, который переходит на другой ком-т - меню закрывается.
  useLayoutEffect(() => {
    const handleBlur = (e: FocusEvent) => {
      if (!dropdownMenuRef.current || !e.relatedTarget) {
        return;
      }

      if (!dropdownMenuRef.current.contains(e.relatedTarget as HTMLElement)) {
        setOpen(false);
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    };

    window.addEventListener('blur', handleBlur, { capture: true });

    return () => window.removeEventListener('blur', handleBlur, { capture: true });
  }, []);

  // Данный хук добавляет функционал закрытия списка меню по клавише Escape
  useLayoutEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (!dropdownMenuRef.current || !e.target || !open) {
        return;
      }

      if (dropdownMenuRef.current.contains(e.target as HTMLElement) && e.key === 'Escape') {
        setOpen(false);
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    };

    window.addEventListener('keydown', handleEsc, { capture: true });

    return () => window.removeEventListener('keydown', handleEsc, { capture: true });
  }, [open]);

  return (
    <div ref={dropdownMenuRef} className={clsx(s.Select, disabled && s.Select_disabled, className)}>
      {waitData ? (
        <div className={clsx(sTextInput.TextInput__input, s.Select__input)}>
          <Loader className={s.Select__loader} spinnerClassName={s.Select__loaderSpinner} />
        </div>
      ) : (
        <TextInput
          ref={selectControlRef}
          classes={{
            input: clsx(s.Select__input, classes?.input)
          }}
          value={selectedOption?.label || ''}
          placeholder={placeholder}
          iconLeft={iconLeft}
          iconLeftProps={{
            ...iconLeftProps
          }}
          iconRight={IconChevronDown}
          iconRightProps={{
            classes: {
              root: s.Select__iconRight,
              icon: clsx(s.Select__arrow, {
                [s.Select__arrow_active]: open
              })
            }
          }}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          readOnly
        />
      )}

      {open && (
        <div
          ref={setPopperElement}
          className={clsx(s.Select__dropdown, classes?.dropdown)}
          style={{
            ...styles.popper,
            minWidth: dropdownMenuRef.current?.offsetWidth || ''
          }}
          {...attributes.popper}>
          <div
            className={clsx(s.Select__options, {
              [s.Select__options_noOptions]: !hasOptions
            })}>
            {!hasOptions
              ? 'Нет данных'
              : options.map((item, iItem) => (
                  <button
                    key={iItem}
                    disabled={item.disabled}
                    type={'button'}
                    className={clsx(s.Select__option, {
                      [s.Select__option_selected]: selectedOption?.value === item.value
                    })}
                    onClick={() => handleChange(item)}>
                    {item.labelElement || item.label}
                  </button>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};
