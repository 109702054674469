import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const completedActivitiesShare = components[0]?.value;
  const administrativeRegulationsOnApproval = components[1]?.value;
  const registeredAdministrativeRegulations = components[2]?.value;
  const administrativeRegulationsTotal = components[3]?.value;

  return (
    <SymbolsGrid columns={4}>
      <SymbolDetails
        value={completedActivitiesShare}
        unit={'%'}
        description={
          <>
            Доля выполненных мероприятий <br />
            по приведению административных регламентов в цифровой вид
          </>
        }>
        <Symbol sub={'ЦР'}>Д</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={administrativeRegulationsOnApproval}
        description={
          <>
            Количество разработанных административных регламентов в цифровом виде <br />с использованием
            программно-технических средств ФРГУ (конструктора цифровых регламентов), которые успешно прошли процедуры
            согласования, экспертизы <br />в Минэкономразвития России и Минцифры России, утверждены ведомством
            (подписаны электронной подписью) и направлены <br />
            на государственную регистрацию в Минюст России (в расчет не включаются цифровые регламенты, которые успешно
            прошли процедуру государственной регистрации <br />в Минюсте России)
          </>
        }>
        <Symbol sub={'УТВ'}>АР</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={registeredAdministrativeRegulations}
        description={
          <>
            Количество разработанных административных регламентов в цифровом виде <br />с использованием
            программно-технических средств ФРГУ (конструктора цифровых регламентов), которые успешно прошли процедуру
            государственной регистрации <br />в Минюсте России
          </>
        }>
        <Symbol sub={'РЕГ'}>АР</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={administrativeRegulationsTotal}
        description={
          <>
            Общее количество административных регламентов по предоставлению государственных услуг, имеющихся <br />у
            ведомства
          </>
        }>
        <Symbol sub={'ОБЩ'}>АР</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow
        values={['0', 'Доля выполненных мероприятий по приведению административных регламентов в цифровой вид 0-19,9%']}
      />
      <TableRow
        values={[
          '0,5',
          'Доля выполненных мероприятий по приведению административных регламентов в цифровой вид 20,0-39,9%'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          'Доля выполненных мероприятий по приведению административных регламентов в цифровой вид 40,0-74,9%'
        ]}
      />
      <TableRow
        values={[
          '1,5',
          'Доля выполненных мероприятий по приведению административных регламентов в цифровой вид 75,0-90,9%'
        ]}
      />
      <TableRow
        values={[
          '2,0',
          'Доля выполненных мероприятий по приведению административных регламентов в цифровой вид 91,0-100,0%'
        ]}
      />
    </Table>
  );
}

export function DigitalAdministrativeRegulations() {
  return (
    <IndicatorPage
      download={'Доля ЦАР.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
