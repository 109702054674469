import { useCallback } from 'react';
import { useRctControlContext } from '../_store/useRctControlContext';

export function useRemoveSecondPersonFromList({ userIdToRemove }: { userIdToRemove?: number }) {
  const { currentSecondPersons, setCurrentSecondPersons } = useRctControlContext();

  const removeSecondPersonFromList = useCallback(() => {
    if (!userIdToRemove) {
      return;
    }
    const secondPersonIds = currentSecondPersons.map((sp) => sp.userId);
    if (secondPersonIds.includes(userIdToRemove)) {
      const updatedSecondPersons = currentSecondPersons.filter((sp) => sp.userId !== userIdToRemove);
      setCurrentSecondPersons(updatedSecondPersons);
    }
  }, [currentSecondPersons, setCurrentSecondPersons, userIdToRemove]);

  return { removeSecondPersonFromList };
}
