import React from 'react';
import { IndicatorsTableHeaderFields, ISortedColumn } from '../types';
import { TableHeaderCell } from './TableHeaderCell';

type Props = {
  sortedColumn?: ISortedColumn;
  onColumnSortPress: (sort?: ISortedColumn) => void;
};

export const IndicatorsTableHeaderColumns = [
  { field: IndicatorsTableHeaderFields.Idx, name: '№ п/п' },
  { field: IndicatorsTableHeaderFields.IndicatorName, name: 'Показатель' },
  { field: IndicatorsTableHeaderFields.Value, name: 'Значение' },
  { field: IndicatorsTableHeaderFields.ValueDynamic, name: 'Динамика' },
  { field: IndicatorsTableHeaderFields.ValueMax, name: 'Максимальный балл' }
];

export const TableHeader = ({ sortedColumn, onColumnSortPress }: Props) => {
  return (
    <React.Fragment>
      {IndicatorsTableHeaderColumns.map((el) => {
        const props = el.field !== IndicatorsTableHeaderFields.Idx ? { sortedColumn, onColumnSortPress } : {};
        return (
          <TableHeaderCell key={`ith${el.field}`} field={el.field} {...props}>
            {el.name}
          </TableHeaderCell>
        );
      })}
    </React.Fragment>
  );
};
