import { createContext, useContext } from 'react';
import { RatingFeedbackScope } from 'store/graphql';
import { FeedbackFormController } from './controllers/feedbackFormController';
import { ProposalFormController } from './controllers/proposalFormController';
import { TabIndex } from './tab-index';

export interface FeedbackContext {
  activeTabIndex: TabIndex;
  scope: RatingFeedbackScope;
  scopeId: number;
  // ID показателя, на котором контекст был вызван
  indicatorId?: number;
  // ID показателя, выбранного на форме обратной связи
  selectedIndicatorId?: number;
  feedbackForm: FeedbackFormController<number>;
  proposalForm: ProposalFormController<number>;
}

export const FeedbackContext = createContext<FeedbackContext>({
  activeTabIndex: TabIndex.Indicator,
  scope: RatingFeedbackScope.Department,
  scopeId: -1,
  indicatorId: void 0,
  feedbackForm: new FeedbackFormController<number>(),
  proposalForm: new ProposalFormController<number>()
});

export function useFeedbackContext(): FeedbackContext {
  return useContext(FeedbackContext);
}
