import React, { useState } from 'react';
import { CommonMviType, MviType, ViewMode } from '../../types';
import { GaugeCard } from '../GaugeCard/GaugeCard';
import { GaugeCardsCarousel } from '../GaugeCardsCarousel/GaugeCardsCarousel';
import s from './GaugeCardsList.module.scss';

type Props = {
  listType: MviType;
  list: CommonMviType[];
  mode: ViewMode;
  onChangeMode: (mode: ViewMode) => void;
};

export function GaugeCardsList({ list, listType, mode, onChangeMode }: Props) {
  const [selected, setSelected] = useState<CommonMviType | undefined>(list[0]);

  if (mode === 'carousel' && selected) {
    return (
      <GaugeCardsCarousel
        list={list}
        selected={selected}
        onSelect={setSelected}
        listType={listType}
        onClose={() => onChangeMode('list')}
      />
    );
  }

  return (
    <div className={s.GaugeCardsList}>
      {list.map((item) => {
        return (
          <div className={s.GaugeCardsList__item} key={item.id}>
            <GaugeCard
              hoverAccepted
              cardType={listType}
              item={item}
              startAnimation={true}
              onSelect={(selected) => {
                onChangeMode('carousel');
                setSelected(selected);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
