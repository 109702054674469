import { memo } from 'react';
import clsx from 'clsx';
import { EMDASH, formatNumber } from '@digital-gov/ui-utils';
import { RegionRatingGroupEnum } from 'store/graphql';
import { ProgressBar } from './ProgressBar';
import s from './TableCellProgress.module.scss';

export interface TableCellProgressProps {
  className?: string;
  degree?: number | null;
  ratingGroup: RegionRatingGroupEnum;
}

export const TableCellProgress = memo((props: TableCellProgressProps) => {
  const { className, degree, ratingGroup } = props;

  return (
    <div className={clsx(s.TableCellProgress, className)}>
      <div className={s.TableCellProgress__content}>
        <p className={s.TableCellProgress__degree}>{degree === null ? EMDASH : `${formatNumber(degree)}%`}</p>
      </div>
      <ProgressBar points={degree ?? 0} ratingGroup={ratingGroup} />
    </div>
  );
});

TableCellProgress.displayName = 'TableCellProgress';
