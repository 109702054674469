import sanitizeHtml from 'sanitize-html';
import { QuestionsBlock } from '../_components/FaqInfo/types';
import { faqSearch, faqSearchReset } from './faq-search';
import { sanitizeFaq } from './sanitize-faq';

export enum SearchArea {
  Section = 'qa-section',
  SectionTitle = 'qa-section-title',
  QaBlock = 'qa-block',
  Question = 'qa-question',
  Answer = 'qa-answer'
}

export class Faq {
  readonly sections: Map<number, Section> = new Map();
  public readonly visibleIds: Visibilities = {
    sections: new Set(),
    questions: new Set()
  };

  public readonly SearchArea = SearchArea;

  private _domParser: DOMParser = new DOMParser();
  private _totalSize = 0;
  private _founds = 0;
  private _query: string | null = null;
  private _container: HTMLElement | null = null;

  constructor(blocks: QuestionsBlock[]) {
    blocks.forEach((block) => {
      this._totalSize += block.list.length;

      this.sections.set(block.id, {
        id: block.id,
        title: block.title,
        questions: new Map(
          block.list.map((item) => [
            item.id,
            {
              id: item.id,
              question: this.sanitizeTitle(item.question),
              answer: this.sanitizeBody(item.answer)
            }
          ])
        )
      });
    });
  }

  get query(): string {
    return this._query || '';
  }

  setDomContainer(container: HTMLElement): void {
    this.reset();
    this._container = container;
  }

  list(): Section[] {
    return Array.from(this.sections.values());
  }

  questions(sectionId: number): Question[] {
    return Array.from(this.sections.get(sectionId)?.questions.values() || []);
  }

  getSectionVisibility(sectionId: number): boolean {
    return this._query ? this.visibleIds.sections.has(sectionId) : true;
  }

  getQuestionVisibility(questionId: number): boolean {
    return this._query ? this.visibleIds.questions.has(questionId) : true;
  }

  get size(): number {
    return Boolean(this._query?.trim()) ? this._founds : this._totalSize;
  }

  search(query: string): void {
    if (!this._container) {
      return;
    }

    this.reset();

    this._query = query.trim();
    this._founds = 0;
    this.visibleIds.sections = new Set();
    this.visibleIds.questions = new Set();

    const groups = new Map<HTMLElement, Set<HTMLElement>>();

    const questionNodes = this.getNodes()!;

    faqSearch(questionNodes, query, (mark) => {
      const sectionTitle = mark.closest(`[${SearchArea.SectionTitle}]`);

      // Если заголовок секции найден - обрабатываем сценарий поиска по заголовкам и выходим
      if (sectionTitle !== null) {
        const section: HTMLElement = sectionTitle.closest(`[${SearchArea.Section}]`)!;
        groups.set(section, new Set());
        return;
      }

      // Заголовок не найден => выделение в блоке вопроса - обрабатываем сценарий поиска по вопросам

      const qaBlock: HTMLElement = mark.closest(`[${SearchArea.QaBlock}]`)!;
      const section: HTMLElement = qaBlock.closest(`[${SearchArea.Section}]`)!;

      if (!groups.has(section)) {
        groups.set(section, new Set());
      }

      groups.get(section)!.add(qaBlock);
    });

    groups.forEach((questions, title) => {
      const sectionId = Number(title.getAttribute(SearchArea.Section));
      this.visibleIds.sections.add(sectionId);

      if (questions.size === 0) {
        this._founds += 1;
        this.sections.get(sectionId)!.questions.forEach((question) => this.visibleIds.questions.add(question.id));
      } else {
        this._founds += questions.size;

        questions.forEach((question) => {
          const qaId = Number(question.getAttribute(SearchArea.QaBlock));
          this.visibleIds.questions.add(qaId);
        });
      }
    });
  }

  reset() {
    this._query = null;

    if (this._container) {
      faqSearchReset(this.getNodes()!);
    }
  }

  props(area: SearchArea, key: number | string) {
    return { [area]: key };
  }

  private getNodes(): NodeListOf<HTMLElement> | null {
    if (!this._container) {
      return null;
    }

    return this._container.querySelectorAll(
      [`[${SearchArea.SectionTitle}]`, `[${SearchArea.Question}]`, `[${SearchArea.Answer}]`].join(',')
    );
  }

  private sanitizeTitle(text: string): string {
    // Если есть намёки на HTML - извлекаем только текст
    if (/<\w/.test(text)) {
      text = this._domParser.parseFromString(text, 'text/html').body.innerText.trim();
    }

    return sanitizeHtml(text, {
      allowedTags: []
    });
  }

  private sanitizeBody(text: string): string {
    return sanitizeFaq(text.trim());
  }
}

export interface Question {
  id: number;
  question: string;
  answer: string;
}

export interface Section {
  id: number;
  title: string;
  questions: Map<number, Question>;
}

export interface Visibilities {
  sections: Set<number>;
  questions: Set<number>;
}
