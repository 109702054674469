import React from 'react';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { Table } from '../../../_components/Table';
import { TableRow } from '../../../_components/TableRow';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

export function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const integrationValue = components[0]?.value;
  const isInterfaces = components[1]?.value;
  const ukepFk = components[2]?.value;
  const unep = components[3]?.value;
  const ukep = components[4]?.value;
  const unepEpgu = components[5]?.value;
  const ukepEpgu = components[6]?.value;

  return (
    <SymbolsGrid columns={4} largeGap>
      <SymbolDetails
        value={integrationValue}
        description={'Значение показателя «Внедрение технологии мобильной электронной подписи «Госключ»'}>
        <Symbol sub={'Госключ'}>{'B'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={isInterfaces}
        description={
          <>
            Наличие интерфейсов в государственных и иных ИС для загрузки документов с открепленной подписью с указанием
            о возможности использовать для подписания мобильное приложение «Госключ», а также информации (например,
            баннер) на ведомственном сайте <br />о технологии мобильной электронной подписи «Госключ», в том числе с
            переходом на страницу «Госключа» на ЕПГУ (https://www.gosuslugi.ru/goskey) (да/нет)
          </>
        }>
        <Symbol sub={'Интерфейс'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={ukepFk}
        description={
          <>
            Наличие услуг/сервисов, реализованных <br />
            в государственных и иных ИС, в рамках которых обеспечена возможность подписания УКЭП ФК,
            <br />в мобильном приложении «Госключ» (да/нет)
          </>
        }>
        <Symbol sub={'УКЭПФКГосключИС'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={unep}
        description={
          <>
            Наличие услуг/сервисов, реализованных <br />
            в государственных и иных ИС, в рамках которых обеспечена возможность подписания УНЭП, <br />в мобильном
            приложении «Госключ» (да/нет)
          </>
        }>
        <Symbol sub={'УНЭПГосключИС'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={ukep}
        description={
          <>
            Наличие услуг/сервисов, реализованных <br />
            в государственных и иных ИС, в рамках которых обеспечена возможность подписания УКЭП, <br />в мобильном
            приложении «Госключ» (да/нет)
          </>
        }>
        <Symbol sub={'УКЭПГосключИС'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={unepEpgu}
        description={
          'Наличие ведомственных услуг/сервисов, реализованных на ЕПГУ и предусматривающих подписание УНЭП в мобильном приложении «Госключ» (да/нет)'
        }>
        <Symbol sub={'УНЭПГосключЕПГУ'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={ukepEpgu}
        description={
          'Наличие ведомственных услуг/сервисов, реализованных на ЕПГУ и предусматривающих подписание УКЭП в мобильном приложении «Госключ» (да/нет)'
        }>
        <Symbol sub={'УКЭПГосключЕПГУ'}>{'К'}</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}
export function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Критерий']} isHeader />
      <TableRow values={['0', 'Внедрение технологии мобильной электронной подписи «Госключ» не обеспечено']} />
      <TableRow
        values={[
          '0,5',
          <>
            Наличие интерфейсов в государственных и иных ИС для загрузки документов с открепленной подписью с указанием{' '}
            <br />о возможности использовать для подписания мобильное приложение «Госключ», а также информации
            (например, баннер) на ведомственном сайте о технологии мобильной электронной подписи «Госключ», в том числе
            с переходом <br />
            на страницу «Госключа» на ЕПГУ (https://www.gosuslugi.ru/goskey) (да)
          </>
        ]}
      />
      <TableRow
        values={[
          '0,5',
          'Наличие услуг/сервисов, реализованных в государственных и иных ИС, в рамках которых обеспечена возможность подписания УКЭП ФК в мобильном приложении «Госключ» (да)'
        ]}
      />
      <TableRow
        values={[
          '0,5',
          'Наличие услуг/сервисов, реализованных в государственных и иных ИС, в рамках которых обеспечена возможность подписания УНЭП в мобильном приложении «Госключ» (да)'
        ]}
      />
      <TableRow
        values={[
          '0,5',
          'Наличие услуг/сервисов, реализованных в государственных и иных ИС, в рамках которых обеспечена возможность подписания УКЭП в мобильном приложении «Госключ» (да)'
        ]}
      />
      <TableRow
        values={[
          '0,5',
          <>
            Наличие ведомственных услуг/сервисов, реализованных на ЕПГУ и предусматривающих подписание УНЭП <br />в
            мобильном приложении «Госключ» (да)
          </>
        ]}
      />
      <TableRow
        values={[
          '0,5',
          <>
            Наличие ведомственных услуг/сервисов, реализованных на ЕПГУ и предусматривающих подписание УКЭП <br />в
            мобильном приложении «Госключ» (да)
          </>
        ]}
      />
    </Table>
  );
}

export function GovSignatureIntegration() {
  return (
    <IndicatorPage
      download={'Внедрение Госключа_v1.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
