import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@digital-gov/ui-utils';
import { DropdownList, DropdownListItem } from 'components/DropdownList';
import { ArrowDown, ArrowUp, Dismiss } from 'components/Icon';
import { Input } from 'components/Input';
import s from './FilteredSelect.module.scss';

export interface FilteredSelectProps<V> {
  value?: V | null;
  onChange?: (value: V) => void;
  canReset?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  largeItems?: boolean;
  options: {
    value: V;
    label: string;
  }[];
}

export function FilteredSelect<V = string>({
  value,
  onChange,
  canReset,
  placeholder = 'Выберите значение',
  disabled,
  className,
  largeItems,
  options
}: FilteredSelectProps<V>) {
  const [dropdown, setDropdown] = useState(false);
  const [select, setSelect] = useState<HTMLDivElement | null>(null);
  const [filterInput, setFilterInput] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<
    {
      value: V;
      label: string;
    }[]
  >([]);

  useClickOutside(select, () => setDropdown(false));

  useEffect(() => {
    setSelectOptions(options.map((o) => ({ ...o })));
  }, [options]);

  const handleFilterInput = useCallback(
    (value: string) => {
      setFilterInput(value);
      setSelectOptions(
        options.filter((o) => (value !== '' ? o.label.toLowerCase().includes(value.toLowerCase()) : true))
      );
      if (value.length > 0) {
        setDropdown(true);
      }
      if (value === '') {
        setDropdown(false);
      }
    },
    [options]
  );

  const resetOptions = useCallback(() => {
    setSelectOptions(
      options.map((o) => {
        return { label: o.label, value: o.value };
      })
    );
  }, [options]);

  return (
    <div className={clsx(s.FilteredSelect, className)} ref={setSelect}>
      <Input
        disabled={disabled}
        value={filterInput}
        type={'text'}
        iconRight={dropdown ? ArrowUp : ArrowDown}
        placeholder={placeholder}
        dimmedPlaceHolder
        iconRightHandler={() => !disabled && setDropdown((dropdown) => !dropdown)}
        onChange={handleFilterInput}
      />

      {dropdown && (
        <DropdownList className={s.FilteredSelect__dropdown}>
          <div className={s.FilteredSelect__dropdownItems}>
            {selectOptions.map((o) => (
              <DropdownListItem
                key={`${o.value}`}
                selected={value === o.value}
                isLarge={largeItems}
                onClick={() => {
                  setFilterInput('');
                  setDropdown(false);
                  resetOptions();
                  onChange?.(o.value);
                }}>
                {o.label ?? `${o.value}`}
                {canReset && value === o.value && <Dismiss style={{ marginLeft: 'auto', color: '#787a92' }} />}
              </DropdownListItem>
            ))}
          </div>
        </DropdownList>
      )}
    </div>
  );
}
