import React from 'react';
import clsx from 'clsx';
import { IconButton } from 'components/Button';
import { Dismiss } from 'components/Icon';
import s from './Modal.module.scss';

export interface ModalProps {
  width?: number;
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  className?: string;
}

export function Modal({ width = 624, title, onClose, children, className }: ModalProps) {
  return (
    <div className={clsx(s.Modal, className)} style={{ width }}>
      <header className={s.Modal__header}>
        <h2 className={s.Modal__title}>{title ?? 'Модальное окно'}</h2>
        {!!onClose && <IconButton icon={Dismiss} onClick={onClose} style={{ width: 24, height: 24 }} />}
      </header>
      {children}
    </div>
  );
}
