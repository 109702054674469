import clsx from 'clsx';
import s from './TableCellHeader.module.scss';

export const TableCellHeader = () => {
  return (
    <>
      <div className={s.TableCellHeader__item}>№</div>
      <div className={s.TableCellHeader__item}>Регион</div>
      <div className={clsx(s.TableCellHeader__item, s.TableCellHeader__item_last)}>%</div>
    </>
  );
};
