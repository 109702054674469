import React, { useCallback } from 'react';
import { ModalContentGroup } from 'componentsL/Modal';
import { FeedbackItem } from '../FeedbackItem';
import s from './HistoryContent.module.scss';

export interface HistoryContentProps {
  list: Feedback[];
  onSelectFeedback: (feedback: Feedback) => void;
}

export const HistoryContent = ({ list, onSelectFeedback }: HistoryContentProps) => {
  const handleSelectFeedback = useCallback(
    (id: number) => {
      // Т.к. запись выбирается из списка, запись, соответствующая id, гарантировано существует
      onSelectFeedback(list.find((item) => item.id === id)!);
    },
    [list, onSelectFeedback]
  );

  return (
    <ModalContentGroup className={s.HistoryContent}>
      {list.map((item) => (
        <FeedbackItem
          key={item.id}
          className={s.HistoryContent__item}
          id={item.id}
          subject={item.subject ?? void 0}
          publisher={item.publisher}
          publishDate={item.publishDate}
          indicatorName={item.indicatorName ?? void 0}
          onClick={handleSelectFeedback}
        />
      ))}
    </ModalContentGroup>
  );
};
