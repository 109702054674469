import React from 'react';
import clsx from 'clsx';
import { IndicatorMethodologyType, IndicatorSpecificationType } from 'store/graphql';
import { CardDownloadsBlock } from './CardDownloadBlock';
import { CardDownloadButton } from './CardDownloadButton';
import s from './Card.module.scss';

export interface ICardProps {
  title: string;
  additionalInfo?: string | React.ReactNode;
  descriptor?: string;
  download?: string;
  downloadPath?: string;
  useDownloadBlock?: boolean;
  headerControls?: React.ReactNode;
  children: React.ReactNode;
  isChart?: boolean;
  isTable?: boolean;
  style?: React.CSSProperties;
  methodologies?: IndicatorMethodologyType[];
  specifications?: IndicatorSpecificationType[];
  scopeId?: number;
  indicatorId?: number;
}

export function Card({
  title,
  additionalInfo,
  descriptor,
  download,
  downloadPath,
  useDownloadBlock,
  headerControls,
  children,
  style,
  isChart,
  isTable,
  methodologies,
  specifications,
  scopeId,
  indicatorId
}: ICardProps) {
  return (
    <section className={clsx(s.Card, isChart && s.Card__isChart)} style={style}>
      <header className={s.Card__header}>
        <div className={s.Card__meta}>
          <h3 className={s.Card__heading}>{title}</h3>
          {additionalInfo && <p className={s.Card__descriptor}>{additionalInfo}</p>}
          {descriptor && <p className={s.Card__descriptor}>{descriptor}</p>}
        </div>
        <div className={s.Card__controls}>
          {!useDownloadBlock && (download || !!methodologies?.length) && (
            <CardDownloadButton
              filename={download}
              path={downloadPath}
              disabled={download === '#' && !methodologies?.length}
              methodologies={methodologies}
              indicatorId={indicatorId}
            />
          )}
          {useDownloadBlock && (
            <CardDownloadsBlock
              filename={download}
              path={downloadPath}
              disabled={download === '#' && !methodologies?.length}
              methodologies={methodologies}
              specifications={specifications}
              scopeId={scopeId}
              indicatorId={indicatorId}
            />
          )}
          {headerControls && <div className={s.Cart__controlsDivider} />}
          {headerControls && <>{headerControls}</>}
        </div>
      </header>
      {isChart || isTable ? <div className={clsx(isTable && s.Card__isTable)}>{children}</div> : children}
    </section>
  );
}
