import React, { MouseEventHandler, useMemo, useState } from 'react';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { DATE_FULL_DAY_SHORT, EMDASH, formatNumber, pluralize, useFormatDate } from '@digital-gov/ui-utils';
import { Icon, IconChevronDown, IconChevronLeftDouble, IconSize } from 'componentsL/Icon';
import { AvailableRegionIndicatorType, RegionType } from 'store/graphql';
import { PositionDynamic } from '../../PositionDynamic';
import { ProgressDynamic } from '../../ProgressDynamic';
import { ProgressBar } from '../../RegionsTableFull/TableCellProgress/ProgressBar';
import { InfoProperty } from '../InfoProperty/InfoProperty';
import { InfoSelect } from '../InfoSelect/InfoSelect';
import s from './Info.module.scss';

export interface InfoCurrentRegionProps {
  region: RegionType;
  availableKeyIndicators?: AvailableRegionIndicatorType[];
  updateDate?: string;
  onClose: () => void;
}

export const InfoCurrentRegion = ({ region, availableKeyIndicators, updateDate, onClose }: InfoCurrentRegionProps) => {
  const availableIndicators = useMemo(() => {
    if (!availableKeyIndicators) return [];
    return [
      {
        indicatorId: -1,
        indicatorName: 'Общее количество баллов',
        maxValue: null
      },
      ...availableKeyIndicators
    ];
  }, [availableKeyIndicators]);

  const [indicatorId, setIndicatorId] = useState<string | null>(availableIndicators[0]?.indicatorId.toString() || null);
  const [selectOpen, setSelectOpen] = useState(false);

  const dateStr = useFormatDate(updateDate, DATE_FULL_DAY_SHORT);

  const indicatorsSelectOptions = useMemo(() => {
    if (!availableIndicators) return [];
    return availableIndicators.map(({ indicatorId, indicatorName }) => ({
      label: indicatorName,
      value: String(indicatorId)
    }));
  }, [availableIndicators]);

  const selectedIndicator = useMemo(() => {
    if (indicatorId === null) return undefined;
    return availableIndicators.find((ind) => ind.indicatorId === +indicatorId);
  }, [indicatorId, availableIndicators]);

  const selectedIndicatorValue = useMemo(() => {
    if (!selectedIndicator) return null;
    const selectedId = selectedIndicator.indicatorId;
    const value =
      selectedId === -1
        ? region.pointsLast
        : (region.indicatorsValues.find(({ indicatorId }) => indicatorId === selectedId)?.scoreForLastPeriod ?? null);
    return formatNumber(value);
  }, [region, selectedIndicator]);

  const handleCloseClick: MouseEventHandler = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <>
      <InfoProperty
        className={clsx(s.InfoCurrentRegion__prop, s.InfoCurrentRegion__prop_name)}
        label={region.regionShortCode}
        vertical>
        <div className={s.InfoCurrentRegion__regionName}>
          {region.regionName}

          <Icon
            className={s.InfoCurrentRegion__regionCloseButton}
            icon={IconChevronLeftDouble}
            size={IconSize.Small}
            onClick={handleCloseClick}
          />
        </div>
      </InfoProperty>

      <InfoProperty className={s.InfoCurrentRegion__prop} label={'Место'} vertical>
        <div className={s.InfoCurrentRegion__position}>
          {region.ratingPosition}
          <PositionDynamic
            ratingPosition={region.ratingPosition}
            previousRatingPosition={region.previousRatingPosition}
            withIcon
          />
        </div>
      </InfoProperty>

      <InfoProperty className={s.InfoCurrentRegion__prop} label={'Полномочный представитель'} vertical>
        {region.representatives[0]?.fullName}
      </InfoProperty>

      <InfoProperty className={s.InfoCurrentRegion__prop} label={'РЦТ'} vertical>
        {region.rrct}
        <br />
        <br />
        {region.appointmentYear}
      </InfoProperty>

      <InfoProperty
        className={s.InfoCurrentRegion__prop}
        label={
          <div className={s.InfoCurrentRegion__progressLabel}>
            Текущий балл
            <span>{dateStr}</span>
          </div>
        }
        vertical>
        <div className={s.InfoCurrentRegion__progressContent}>
          <p className={s.InfoCurrentRegion__progressDegree}>
            {region.degree === null ? EMDASH : formatNumber(region.degree)}
          </p>
          <ProgressDynamic dynamic={region.dynamic} degree={region.degree} />
        </div>
        <ProgressBar points={region.degree ?? 0} ratingGroup={region.ratingGroup} />
      </InfoProperty>

      <InfoProperty
        className={clsx(s.InfoCurrentRegion__prop, s.InfoCurrentRegion__prop_indicators)}
        label={
          <div
            className={s.SelectHead}
            onClick={(e) => {
              e.preventDefault();
              setSelectOpen(!selectOpen);
            }}>
            <div className={s.SelectHead__trigger}>
              Показатели
              <Icon
                size={IconSize.Small}
                className={clsx(s.SelectHead__icon, {
                  [s.SelectHead__icon_active]: selectOpen
                })}
                icon={IconChevronDown}
              />
            </div>

            <div className={s.SelectHead__title}>
              {selectedIndicator && (
                <>
                  {selectedIndicator.indicatorName}
                  <br />
                  {!isNil(selectedIndicator.maxValue) &&
                    `(Max ${formatNumber(selectedIndicator.maxValue, 2, true)} ${pluralize(
                      selectedIndicator.maxValue,
                      'балл',
                      'балла',
                      'баллов'
                    )})`}
                </>
              )}
            </div>
          </div>
        }
        vertical>
        <InfoSelect
          options={indicatorsSelectOptions}
          value={indicatorId}
          isOpen={selectOpen}
          onClose={() => setSelectOpen(false)}
          onChange={setIndicatorId}
        />

        <div className={s.InfoCurrentRegion__indicatorValue}>{selectedIndicatorValue}</div>
      </InfoProperty>
    </>
  );
};
