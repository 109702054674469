import React from 'react';
import clsx from 'clsx';
import s from './Spinner.module.scss';

export enum SpinnerKind {
  Page = 'page',
  Inline = 'inline',
  InlineLarge = 'inlineLarge'
}

export function Spinner({ kind = SpinnerKind.Page }: { kind?: SpinnerKind }) {
  return (
    <div className={clsx(s.Spinner, s[`Spinner_${kind}`])}>
      <div className={clsx(s.Spinner__spinner, s[`Spinner__spinner_${kind}`])} />
    </div>
  );
}
