import { ApolloClient, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from './offsetLimitPagination';
export const apolloClient = new ApolloClient({
    assumeImmutableResults: true,
    cache: new InMemoryCache({
        typePolicies: {
            // ЛК РЦТ - Документы
            DocumentsType: {
                keyFields: [],
                fields: offsetLimitPagination('items', ['$archive', '$documentTypes', '$search', '$ids'])
            },
            // ЛК РЦТ - События
            EventsType: {
                keyFields: [],
                fields: offsetLimitPagination('entries', ['$tagIds', '$sort', '$search', '$ids'])
            },
            // главная сущность Оперативного рейтинга РЦТ
            RatingType: { keyFields: [] }
        }
    })
});
