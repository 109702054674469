import { ActionModal } from 'components/Modal';

export interface ResponsibleAddedProps {
  handleForward: () => void;
  handleClose: () => void;
}

export function ResponsibleChanged({ handleClose, handleForward }: ResponsibleAddedProps) {
  return (
    <ActionModal
      title={'Изменение ответственного по показателю'}
      message={'Ответственный по показателю был успешно изменен'}
      onClose={handleClose}
      width={624}
      actions={[
        {
          name: 'Продолжить',
          onAction: handleForward
        }
      ]}
    />
  );
}
