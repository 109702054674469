import { memo, ReactNode } from 'react';
import clsx from 'clsx';
import { RegionRatingGroupEnum } from 'store/graphql';
import s from './TableCellText.module.scss';

export interface TableCellTextProps {
  className?: string;
  title?: string | null;
  subTitle?: string | null;
  current?: boolean;
  ratingGroup?: RegionRatingGroupEnum;
  titleVariant?: 's' | 'xs' | 'm';
  rightComponent?: ReactNode;
}

export const TableCellText = memo((props: TableCellTextProps) => {
  const { className, title, subTitle, current, ratingGroup, titleVariant = 'text-body-s', rightComponent } = props;

  return (
    <div className={clsx(s.TableCellText, className)}>
      <div className={s.TableCellText__content}>
        {title && (
          <p
            className={clsx(
              s.TableCellText__title,
              current && s.TableCellText__title_current,
              current && ratingGroup === RegionRatingGroupEnum.High && s.TableCellText__title_groupHigh,
              s[`TableCellText__title_${titleVariant}`]
            )}>
            {title}
          </p>
        )}
        {subTitle && <p className={s.TableCellText__subTitle}>{subTitle}</p>}
      </div>
      {rightComponent && <div className={s.TableCellText__right}>{rightComponent}</div>}
    </div>
  );
});

TableCellText.displayName = 'TableCellText';
