import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import s from './DropdownListItem.module.scss';

export interface DropdownListItemProps {
  disabled?: boolean;
  selected?: boolean;
  navTo?: string;
  to?: string;
  replace?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  className?: string;
  isLarge?: boolean;
}

export function DropdownListItem({
  disabled,
  selected,
  navTo,
  to,
  replace,
  href,
  onClick,
  children,
  className,
  isLarge
}: DropdownListItemProps) {
  const dropdownListItemClassName = clsx(
    s.DropdownListItem,
    isLarge && s.DropdownListItem_isLarge,
    disabled && s.DropdownListItem_disabled,
    !disabled && selected && s.DropdownListItem_selected,
    className
  );

  if (navTo && !disabled) {
    return (
      <NavLink
        to={navTo}
        replace={replace}
        className={({ isActive }) => clsx(dropdownListItemClassName, isActive && s.DropdownListItem_selected)}>
        {children}
      </NavLink>
    );
  } else if (to && !disabled) {
    return (
      <Link className={dropdownListItemClassName} to={to} replace={replace}>
        {children}
      </Link>
    );
  } else if (href && !href.startsWith('http') && !disabled) {
    return (
      <a href={href} className={dropdownListItemClassName}>
        {children}
      </a>
    );
  } else if (href && !disabled) {
    return (
      <a href={href} target={'_blank'} rel={'noreferrer'} className={dropdownListItemClassName}>
        {children}
      </a>
    );
  } else {
    const handleClick = !disabled ? onClick : undefined;
    return (
      <button disabled={disabled} onClick={handleClick} className={dropdownListItemClassName}>
        {children}
      </button>
    );
  }
}
