import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Icon, IconDismiss, IconSize } from 'componentsL/Icon';
import s from './Alert.module.scss';

export interface AlertProps extends PropsWithChildren {
  className?: string;
  onClose?: () => void;
}

export const Alert = ({ className, onClose, children }: AlertProps) => {
  return (
    <div className={clsx(s.Alert, className)}>
      <div className={s.Alert__box}>
        <div className={s.Alert__content}>{children}</div>

        {!!onClose && <Icon icon={IconDismiss} size={IconSize.Small} onClick={onClose} />}
      </div>
    </div>
  );
};
