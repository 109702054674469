import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import s from './IconButton.module.scss';

export enum IconButtonKind {
  Primary,
  Secondary,
  Round
}

export interface IconButtonProps {
  className?: string;
  children: React.ReactNode;
  kind?: IconButtonKind;
  disabled?: boolean;
  isActive?: boolean;
  to?: string;
  href?: string;
  download?: string | boolean;
  target?: string;
  onClick?: () => void;
}

export function IconButton({
  className: customClassName,
  children,
  kind = IconButtonKind.Primary,
  disabled,
  isActive,
  to,
  href,
  download,
  target,
  onClick
}: IconButtonProps) {
  const className = clsx(customClassName, s.IconButton, isActive && s.IconButton_active, {
    [s.IconButton_primary]: kind === IconButtonKind.Primary,
    [s.IconButton_secondary]: kind === IconButtonKind.Secondary,
    [s.IconButton_round]: kind === IconButtonKind.Round,
    [s.IconButton_disabled]: disabled
  });

  if (to) {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  } else if (href) {
    return (
      <a className={className} href={href} target={target} download={download}>
        {children}
      </a>
    );
  } else {
    return (
      <button className={className} onClick={onClick}>
        {children}
      </button>
    );
  }
}
