import React from 'react';
import { Route } from 'react-router-dom';
import {
  AiMaturity,
  AppealsQuality,
  CashExecution,
  ConnectedToEpguSystems,
  DigitalAdministrativeRegulations,
  DigitalServicesOnEpgu,
  EisuksEldShare,
  EisuksIncorrectAppealsShare,
  EpguFederalServicesQuality,
  FeedbackPlatform,
  GovCloudUtilization,
  GovCompaniesStrategy,
  GovDataControlV1,
  GovDataControlV2,
  GovEdo,
  GovPublic,
  GovSignatureIntegrationV1,
  GovSignatureIntegrationV2,
  GovTechV1,
  GovTechV2,
  InformationSecurity,
  OnlineServices,
  RelevantAccruals,
  SatisfactionLevel,
  SmevExploitationV1,
  SmevExploitationV2,
  SmevExploitationV3,
  SoftwareImportIndependence,
  TransferToArmGs,
  UsingArmGs,
  UsingArmGsIndex,
  ViolationElimination,
  GiisEbAppealsQuality,
  RobotMaxServices
} from './departmentIndicators';

export const existedDepartmentIndicatorIds = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  33, 34
];

export const departmentIndicatorRoutes = (
  <Route path={'/department/:departmentId/indicator'}>
    <Route path={'1'} Component={GovDataControlV1} />
    <Route path={'2'} Component={ViolationElimination} />
    <Route path={'3'} Component={FeedbackPlatform} />
    <Route path={'4'} Component={DigitalServicesOnEpgu} />
    <Route path={'5'} Component={SatisfactionLevel} />
    <Route path={'6'} Component={RelevantAccruals} />
    <Route path={'7'} Component={OnlineServices} />
    <Route path={'8'} Component={DigitalAdministrativeRegulations} />
    <Route path={'9'} Component={SmevExploitationV1} />
    <Route path={'10'} Component={GovCompaniesStrategy} />
    <Route path={'11'} Component={AiMaturity} />
    <Route path={'12'} Component={SoftwareImportIndependence} />
    <Route path={'13'} Component={GovPublic} />
    <Route path={'14'} Component={CashExecution} />
    <Route path={'15'} Component={TransferToArmGs} />
    <Route path={'16'} Component={UsingArmGs} />
    <Route path={'17'} Component={GovSignatureIntegrationV1} />
    <Route path={'18'} Component={InformationSecurity} />
    <Route path={'19'} Component={GovTechV1} />
    <Route path={'20'} Component={AppealsQuality} />
    <Route path={'21'} Component={ConnectedToEpguSystems} />
    <Route path={'22'} Component={GovCloudUtilization} />
    <Route path={'23'} Component={GovEdo} />
    <Route path={'24'} Component={SmevExploitationV2} />
    <Route path={'25'} Component={RobotMaxServices} />
    <Route path={'26'} Component={GovDataControlV2} />
    <Route path={'27'} Component={GovSignatureIntegrationV2} />
    <Route path={'28'} Component={GovTechV2} />
    <Route path={'29'} Component={EisuksEldShare} />
    <Route path={'30'} Component={EisuksIncorrectAppealsShare} />
    <Route path={'31'} Component={GiisEbAppealsQuality} />
    <Route path={'32'} Component={UsingArmGsIndex} />
    <Route path={'33'} Component={SmevExploitationV3} />
    <Route path={'34'} Component={EpguFederalServicesQuality} />
  </Route>
);
