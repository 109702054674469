import React from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal, ModalFooter } from 'componentsL/Modal';

export interface CloseConfirmModalProps {
  onBack: () => void;
  onConfirm: () => void;
}

export const CloseConfirmModal = ({ onBack, onConfirm }: CloseConfirmModalProps) => {
  const backButton = (
    <Button variant={ButtonVariant.Secondary} onClick={onConfirm}>
      Закрыть
    </Button>
  );

  const continueButton = (
    <Button variant={ButtonVariant.Primary} onClick={onBack}>
      Продолжить
    </Button>
  );

  return (
    <Modal title="Внимание!" width={456} onClose={onConfirm} onCloseViaBackdrop={false}>
      Вы уверены, что хотите закрыть форму обратной связи?
      <br />
      Данные не будут сохранены
      <ModalFooter left={backButton} right={continueButton} />
    </Modal>
  );
};
