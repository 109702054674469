import React from 'react';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { Table } from '../../../_components/Table';
import { TableRow } from '../../../_components/TableRow';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const k1 = components[0]?.value;
  const k2 = components[1]?.value;
  const k3 = components[2]?.value;
  const k4 = components[3]?.value;
  const k5 = components[4]?.value;
  const k6 = components[5]?.value;
  const sum = (k1 ?? 0) + (k2 ?? 0) + (k3 ?? 0) + (k4 ?? 0) + (k5 ?? 0) + (k6 ?? 0);

  return (
    <SymbolsGrid columns={3} largeGap>
      <SymbolDetails value={sum} description={'Значение показателя «ГосТех»'}>
        <Symbol sub={'ГосТех'}>{'П'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k1}
        description={
          <>
            Обеспечено выполнение мероприятий Плана мероприятий <br />
            со стороны ФОИВ на момент расчета показателя в полном объеме
          </>
        }>
        <Symbol sub={'1'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k2}
        description={
          <>
            Обеспечено выполнение мероприятий Ведомственного плана <br />
            со стороны ФОИВ на момент расчета показателя в полном объеме (при наличии)
          </>
        }>
        <Symbol sub={'2'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k3}
        description={'Обеспечен уровень эффективной утилизации заказанных вычислительных ресурсов'}>
        <Symbol sub={'3'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails value={k4} description={'Обеспечено использование базовых сервисов платформы «ГосТех»'}>
        <Symbol sub={'4'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails value={k5} description={'Обеспечено использование производственного конвейера платформы «ГосТех»'}>
        <Symbol sub={'5'}>{'К'}</Symbol>
      </SymbolDetails>
      <SymbolDetails value={k6} description={'Согласована архитектура сервиса'}>
        <Symbol sub={'6'}>{'К'}</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Критерий']} isHeader />
      <TableRow
        values={[
          '1',
          <>
            Обеспечено выполнение мероприятий Плана мероприятий со стороны ФОИВ на момент расчета показателя
            <br />в полном объеме
          </>
        ]}
      />
      <TableRow
        values={[
          '1',
          <>
            Обеспечено выполнение мероприятий Ведомственного плана со стороны ФОИВ на момент расчета показателя
            <br />в полном объеме (при наличии)
          </>
        ]}
      />
      <TableRow values={['1', 'Обеспечен уровень эффективной утилизации заказанных вычислительных ресурсов']} />
      <TableRow values={['1', 'Обеспечено использование базовых сервисов платформы «ГосТех»']} />
      <TableRow values={['1', 'Обеспечено использование производственного конвейера платформы «ГосТех»']} />
      <TableRow values={['1', 'Согласована архитектура сервиса']} />
    </Table>
  );
}

export function GovTech() {
  return (
    <IndicatorPage
      download={'ГосТех_v2.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
