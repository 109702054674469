import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './GovCloudUtilization.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const govCloudUtilization = components[0]?.value;
  const costInTermsOfContract = components[1]?.value;
  const costActual = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={govCloudUtilization}
        unit={'%'}
        description={'Показатель «Утилизация ресурсов и услуг Государственной единой облачной платформы»'}>
        <Symbol sub={'ур'}>П</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={costActual}
        unit={'руб.'}
        description={'Стоимость потребленных услуг в рублях, с НДС, с учетом фактической утилизации'}>
        <Symbol>СМ</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={costInTermsOfContract}
        unit={'руб.'}
        description={'Стоимость потребленных услуг в рублях, с НДС, согласно условиям контракта'}>
        <Symbol>СК</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <>
      <p className={s.GovCloudUtilization__calc_p}>Максимальное количество баллов по показателю - 6</p>
      <Table>
        <TableRow values={['Оценка, балл', 'Критерий']} isHeader />
        <TableRow values={['0', 'Пур < 15%']} />
        <TableRow values={['1', '15% ≤ Пур < 30%']} />
        <TableRow values={['2', '30% ≤ Пур < 45%']} />
        <TableRow values={['3', '45% ≤ Пур < 60%']} />
        <TableRow values={['4', '60% ≤ Пур < 75%']} />
        <TableRow values={['5', '75% ≤ Пур < 90%']} />
        <TableRow values={['6', '90% ≤ Пур ≤ 100%']} />
      </Table>
    </>
  );
}

export function GovCloudUtilization() {
  return (
    <IndicatorPage
      download={'Утилизация ресурсов и услуг ГосОблака.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
