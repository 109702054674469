export class AutoInc {
  private *IdGen() {
    let i = 0;
    while (true) {
      yield i++;
    }
  }

  private _gen = this.IdGen();

  next(): number {
    return this._gen.next().value!;
  }
}
