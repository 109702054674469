import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import s from './GaugeCardGridItem.module.scss';

type Props = {
  classNameValue?: string;
  text: ReactNode;
  disabled?: boolean;
};

export function GaugeCardGridItem({ classNameValue, text, disabled, children }: PropsWithChildren<Props>) {
  return (
    <div className={clsx(s.GaugeCardGridItem, disabled ? s.GaugeCardGridItem__disabled : null)}>
      <div
        className={clsx(s.GaugeCardGridItem__value, disabled ? s.GaugeCardGridItem__disabled : null, classNameValue)}>
        {children}
      </div>
      <div className={clsx(s.GaugeCardGridItem__text, disabled ? s.GaugeCardGridItem__disabled : null)}>{text}</div>
    </div>
  );
}
