import { useEffect } from 'react';
/**
 * Вызывает callback при клике за пределы элемента обозначенного рефом ref.
 *
 * @param element - элемент, определяющий наружность клика
 * @param callback - функция, которая вызовется при клике снаружи элемента.
 *  Можно передать null в качестве callback, тогда обработчик события не будет создан.
 *  Запоминайте callback через useCallback, чтобы избежать лишних вычислений.
 *
 * @example
 *  function MyComponent() {
 *    const [open, setOpen] = useState(false);
 *    const handleClickOutside = useCallback(() => setOpen(false)}), [];
 *    const modalRef = useRef();
 *    useClickOutside(modalRef, open ? handleClickOutside : null);
 *    return <div>
 *      <button onClick={() => setOpen(true)}>Open</button>
 *      {open && <div ref={modalRef}>Modal</div>}
 *    </div>;
 *  }
 */
export function useClickOutside(element, callback) {
    useEffect(() => {
        if (!callback)
            return;
        const handleClick = (e) => {
            const target = e.target;
            if (element &&
                element !== target &&
                // Используем composedPath при наличии, так как элемент target
                // может уже не быть в дом-дереве, если успел произойти перерендер
                (e.composedPath ? !e.composedPath().includes(element) : !element.contains(target))) {
                callback(e);
            }
        };
        let active = true;
        // Используем setTimeout, т.к. иначе может сработать на клик, активирующий эффект
        setTimeout(() => {
            if (active) {
                window.addEventListener('click', handleClick);
            }
        }, 0);
        return () => {
            active = false;
            window.removeEventListener('click', handleClick);
        };
    }, [callback, element]);
}
