import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Link, To } from 'react-router-dom';
import s from './IconButton.module.scss';

export interface IconButtonProps {
  disabled?: boolean;
  active?: boolean;
  to?: To;
  replace?: boolean;
  preventScrollReset?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
}

export function IconButton({
  disabled,
  active,
  to,
  replace,
  preventScrollReset,
  href,
  onClick,
  icon: Icon,
  className,
  style
}: IconButtonProps) {
  const iconButtonClassName = clsx(
    s.IconButton,
    disabled && s.IconButton_disabled,
    !disabled && active && s.IconButton_active,
    className
  );

  if (to && !disabled && !active) {
    return (
      <Link
        className={iconButtonClassName}
        to={to}
        replace={replace}
        preventScrollReset={preventScrollReset}
        style={style}>
        <Icon />
      </Link>
    );
  } else if (href && !href.startsWith('http') && !disabled && !active) {
    return (
      <a href={href} className={iconButtonClassName} style={style}>
        <Icon />
      </a>
    );
  } else if (href && !disabled && !active) {
    return (
      <a href={href} target={'_blank'} rel={'noreferrer'} className={iconButtonClassName} style={style}>
        <Icon />
      </a>
    );
  } else {
    const handleClick = !disabled ? onClick : undefined;
    return (
      <button disabled={disabled} onClick={handleClick} className={iconButtonClassName} style={style}>
        <Icon />
      </button>
    );
  }
}
