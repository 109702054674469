import React from 'react';
import { Spinner } from 'components/Spinner';
import s from './PageLoader.module.scss';

export function PageLoader() {
  return (
    <div className={s.PageLoader}>
      <Spinner />
    </div>
  );
}
