import { getFileKey } from 'utils/getFileKey';
import { FileUploaderInterface } from './types';

export class FileUploaderManager<FileTypeId = number> {
  private uploaders: Map<string, FileUploaderInterface<FileTypeId>> = new Map();

  get isAllDone(): boolean {
    for (const uploader of Array.from(this.uploaders.values())) {
      if (!uploader.isDone) {
        return false;
      }
    }

    return true;
  }

  add(uploader: FileUploaderInterface<FileTypeId>): boolean {
    if (this.uploaders.has(uploader.key)) {
      return false;
    }

    uploader.run();
    this.uploaders.set(uploader.key, uploader);
    return true;
  }

  breakAll() {
    Array.from(this.uploaders.values()).forEach((uploader) => {
      uploader.stop();
    });
  }

  remove(key: string): boolean {
    if (!this.uploaders.has(key)) {
      return false;
    }

    this.uploaders.get(key)!.stop();
    this.uploaders.delete(key);
    return true;
  }

  clean() {
    this.uploaders.clear();
  }

  exists(file: File) {
    return this.uploaders.has(getFileKey(file));
  }

  get files(): FileUploaderInterface<FileTypeId>[] {
    return Array.from(this.uploaders.values());
  }

  async wait(): Promise<void> {
    const list = Array.from(this.uploaders.values()).map((uploader) => uploader.waitEnd());
    await Promise.all(list);
  }
}
