import React from 'react';
import { Route } from 'react-router-dom';
import {
  DigitalMaturity,
  ServicesDigitalization,
  AppealsInMszu,
  SatisfactionLevel,
  SoftwareImportIndependence,
  SmevExploitationV1,
  SmevExploitationV2,
  EmsrShare,
  FeedbackPlatform,
  ServicesDigitalizationByVC,
  SocialSignificantDocuments,
  ExecutiveDocumentsDirections,
  GovPublicV1,
  GovPublicV2,
  InformationSecurity,
  GarEnrichment,
  ElkOfflineNotifications,
  AppealsQuality,
  MortgageForItV2,
  GovSignatureIntegration,
  GisJkh,
  GisJkhMajorRenovation,
  Gisogd,
  EisuksEldShare,
  EducationQualityMonitoring,
  SwitchToGovTech,
  NspdDigitalInteraction,
  DigitalServicesOnEpgu
} from './regionIndicators';

export const existedRegionIndicatorIds = [
  1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];

export const regionIndicatorRoutes = (
  <Route path={'/region/:regionId/indicator'}>
    <Route path={'1'} Component={DigitalMaturity} />
    <Route path={'2'} Component={ServicesDigitalization} />
    <Route path={'3'} Component={AppealsInMszu} />
    <Route path={'4'} Component={SatisfactionLevel} />
    <Route path={'5'} Component={SoftwareImportIndependence} />
    <Route path={'6'} Component={SmevExploitationV1} />
    <Route path={'8'} Component={EmsrShare} />
    <Route path={'9'} Component={FeedbackPlatform} />
    <Route path={'10'} Component={ServicesDigitalizationByVC} />
    <Route path={'11'} Component={SocialSignificantDocuments} />
    <Route path={'12'} Component={ExecutiveDocumentsDirections} />
    <Route path={'13'} Component={GovPublicV1} />
    <Route path={'14'} Component={InformationSecurity} />
    <Route path={'15'} Component={GarEnrichment} />
    <Route path={'16'} Component={ElkOfflineNotifications} />
    <Route path={'17'} Component={AppealsQuality} />
    <Route path={'19'} Component={MortgageForItV2} />
    <Route path={'20'} Component={GovSignatureIntegration} />
    <Route path={'21'} Component={SmevExploitationV2} />
    <Route path={'22'} Component={GovPublicV2} />
    <Route path={'23'} Component={MortgageForItV2} />
    <Route path={'24'} Component={GisJkh} />
    <Route path={'25'} Component={GisJkhMajorRenovation} />
    <Route path={'26'} Component={Gisogd} />
    <Route path={'27'} Component={SwitchToGovTech} />
    <Route path={'28'} Component={EisuksEldShare} />
    <Route path={'29'} Component={NspdDigitalInteraction} />
    <Route path={'30'} Component={DigitalServicesOnEpgu} />
    <Route path={'31'} Component={EducationQualityMonitoring} />
  </Route>
);
