import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const softwareImportIndependence = components[0]?.value;
  const informationSystemsIndependence = components[1]?.value;
  const typicalSoftwareIndependence = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={softwareImportIndependence}
        unit={'%'}
        description={'Значение показателя «Уровень импортонезависимости ПО»'}>
        <Symbol sub={'ин'}>У</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={informationSystemsIndependence}
        description={'Значение показателя «Доля импортонезависимости ИС»'}>
        <Symbol sub={'ис'}>А</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={typicalSoftwareIndependence}
        description={'Значение показателя «Доля импортонезависимости ПО типовой деятельности»'}>
        <Symbol sub={'тд'}>А</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'Уин ≤ 20%']} />
      <TableRow values={['0,5', '20% < Уин ≤ 40%']} />
      <TableRow values={['1,0', '40% < Уин ≤ 60%']} />
      <TableRow values={['2,0', '60% < Уин ≤ 80%']} />
      <TableRow values={['3,0', '80% < Уин ≤ 90%']} />
      <TableRow values={['4,0', '90% < Уин ≤ 100%']} />
    </Table>
  );
}

export function SoftwareImportIndependence() {
  return (
    <IndicatorPage
      regionScope
      download={'Уровень импортонезависимости ПО.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
