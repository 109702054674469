import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { Button, ButtonLink } from 'components/Button';
import { Plug, PlugVariant } from 'components/Plug';
import s from './PageError.module.scss';

interface PageErrorProps {
  className?: string;
  title?: string;
  description?: React.ReactNode;
}

export function PageError({
  className,
  title = 'Не удалось загрузить данные',
  description = 'Попробуйте обновить страницу или зайдите через некоторое время'
}: PageErrorProps) {
  const { pathname } = useLocation();
  return (
    <div className={clsx(s.PageError, className)}>
      <Plug title={title} description={description} variant={PlugVariant.Error} />
      <div className={s.PageError__actions}>
        {pathname !== '/' && (
          <ButtonLink to={'/'} className={s.PageError__action}>
            На главную
          </ButtonLink>
        )}

        <Button className={s.PageError__action} onClick={() => window.location.reload()}>
          Обновить
        </Button>
      </div>
    </div>
  );
}
