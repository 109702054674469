import { useCallback, useState } from 'react';
import { ViewState } from '../view-state';

export { ViewState };

export function useViewState(): [ViewState, (newState: ViewState) => () => void] {
  const [state, setState] = useState<ViewState>(ViewState.Input);

  const toState = useCallback(
    (newState: ViewState) => {
      return () => setState(newState);
    },
    [setState]
  );

  return [state, toState];
}
