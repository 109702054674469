import { RegionRatingGroupEnum, RegionType } from 'store/graphql';
import { IRegionsFilter } from '../RegionsFilters';
import { RegionShortNames } from './regions-map.types';

export type RegionMapItemColorObjectType = { default: string; filtered: string };

export class RegionMapUtils {
  static regionsRows = [
    [38],
    [21, 46, 39, 32, 33, 72],
    [52, 22, 64, 15, 8, 9, 63],
    [12, 23, null /* Москва */, 37, 13, 18, 27, 73, 84, 35, 24],
    [31, 43, 56, 48, 17, 26, 45, 59, 67, 7, 6, 50],
    [88, 11, 34, 54, 75, 83, 74, 80, 58, 3, 62, 85, 4],
    [86, 89, 53, 16, 14, 44, 60, 78, 51, 57, 41, 55, 81],
    [87, 65, 2, 47, 71, 49, 29, 42, 66, 61, 30, 40, 25, 19],
    [28, 77, 5, 10, 1, 70, 79],
    [69, 20],
    [76, 82],
    [68]
  ];

  static mapRegionDefaultColors(regionShortName?: string | null): RegionMapItemColorObjectType {
    switch (regionShortName) {
      case RegionShortNames.CFO:
        return {
          default: '#F0C330',
          filtered: '#8B7536'
        };
      case RegionShortNames.DFO:
        return {
          default: '#48D381',
          filtered: '#377D5F'
        };
      case RegionShortNames.PFO:
        return {
          default: '#E47E30',
          filtered: '#855336'
        };
      case RegionShortNames.SFO:
        return {
          default: '#9A5CB4',
          filtered: '#604278'
        };
      case RegionShortNames.SKFO:
        return {
          default: '#8473EE',
          filtered: '#564E95'
        };
      case RegionShortNames.SZFO:
        return {
          default: '#3A99D8',
          filtered: '#30608A'
        };
      case RegionShortNames.UFO:
        return {
          default: '#D15419',
          filtered: '#7C3E2B'
        };
      case RegionShortNames.UrFO:
        return {
          default: '#239F85',
          filtered: '#256361'
        };
      case RegionShortNames.DNR:
      case RegionShortNames.ZPR:
      case RegionShortNames.LNR:
      case RegionShortNames.HersObl:
        return {
          default: '#FFFFFF',
          filtered: '#93939E'
        };
      case null:
      default:
        return {
          default: '',
          filtered: ''
        };
    }
  }

  static mapRegionNames(regionShortName?: string | null): string {
    switch (regionShortName) {
      case RegionShortNames.CFO:
        return 'Центральный';
      case RegionShortNames.DFO:
        return 'Дальневосточный';
      case RegionShortNames.PFO:
        return 'Приволжский';
      case RegionShortNames.SFO:
        return 'Сибирский';
      case RegionShortNames.SKFO:
        return 'Северо-Кавказский';
      case RegionShortNames.SZFO:
        return 'Северо-Западный';
      case RegionShortNames.UFO:
        return 'Южный';
      case RegionShortNames.UrFO:
        return 'Уральский';
      case RegionShortNames.DNR:
      case RegionShortNames.ZPR:
      case RegionShortNames.LNR:
      case RegionShortNames.HersObl:
        return 'Новые территории';
      case null:
      default:
        return '';
    }
  }

  static mapEffectGroupColors(regionRating?: RegionRatingGroupEnum | null): RegionMapItemColorObjectType {
    switch (regionRating) {
      case RegionRatingGroupEnum.High:
        return {
          default: '#55D666',
          filtered: '#3F8D4A'
        };
      case RegionRatingGroupEnum.Average:
        return {
          default: '#FFC74F',
          filtered: '#A5843C'
        };
      case null:
      default:
        return {
          default: '',
          filtered: ''
        };
    }
  }

  static mapEffectGroupNames(regionRating: RegionRatingGroupEnum): string {
    switch (regionRating) {
      case RegionRatingGroupEnum.High:
        return 'Лидирующая группа эффективности';
      case RegionRatingGroupEnum.Average:
        return 'Перспективная группа эффективности';
      default:
        return '';
    }
  }

  static checkMapItemIsFiltered(item: RegionType, filter: IRegionsFilter): boolean {
    const { search, ratingGroup, representative } = filter;

    return (
      (!!search && !item.regionName.toLowerCase().includes(search.toLowerCase())) ||
      (!!ratingGroup && item.ratingGroup !== ratingGroup) ||
      (!!representative && item.representatives[0]?.fullName !== representative)
    );
  }
}
