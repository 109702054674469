import { FilteredSelect } from 'components/FilteredSelect';
import { Select } from 'components/Select';
import { useRctControlContext } from '../../../_store/useRctControlContext';

export interface SecondPersonListProps {
  disabled?: boolean;
}

export function SecondPersonList({ disabled }: SecondPersonListProps) {
  const { secondPersonsList, currentSecondPersons, setCurrentSecondPersons } = useRctControlContext();

  const secondPersonOptions = secondPersonsList.map((sp) => {
    return {
      label: sp.fullName,
      value: sp.userId
    };
  });

  const handleSecondPersonPick = (userId: number) => {
    const sp = secondPersonsList.find((entry) => entry.userId === userId);
    const _currentSecondPersonIds = currentSecondPersons.map((s) => s.userId);
    if (sp && !_currentSecondPersonIds.includes(sp.userId)) {
      currentSecondPersons.push(sp);
      const persons = [...currentSecondPersons];
      setCurrentSecondPersons(persons);
    }
  };

  return (
    <FilteredSelect
      disabled={disabled ?? false}
      options={secondPersonOptions}
      placeholder={'Выбрать'}
      onChange={handleSecondPersonPick}
    />
  );
}
