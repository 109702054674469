import { useMemo, useState } from 'react';
import { RctControlScopeEnum, RctType, ScopeObjectType, SecondPersonType } from '../_utils';
import { IRctControlContext, RctControlContext } from './RctControlContext';

export interface RctControlProviderProps {
  children: React.ReactNode;
  scope?: RctControlScopeEnum;
  currentScopeObject: ScopeObjectType;
  currentRct?: RctType | null;
  currentSecondPersons?: SecondPersonType[];
  refetchScopeData?: () => void;
}

export const RctControlProvider = ({
  children,
  scope,
  currentScopeObject,
  currentRct,
  currentSecondPersons,
  refetchScopeData
}: RctControlProviderProps) => {
  const [_scope, setScope] = useState<RctControlScopeEnum>(scope ?? RctControlScopeEnum.DepartmentRct);
  const [_currentRct, setCurrentRct] = useState<RctType | null | undefined>(currentRct ? { ...currentRct } : null);
  const [_currentSecondPersons, setCurrentSecondPersons] = useState<SecondPersonType[]>(
    currentSecondPersons ? currentSecondPersons.map((p) => ({ ...p })) : []
  );
  const [_rctList, setRctList] = useState<RctType[]>([]);
  const [_secondPersonsList, setSecondPersonsList] = useState<SecondPersonType[]>([]);

  const contextValue = useMemo<IRctControlContext>(() => {
    return {
      scope: _scope,
      setScope,
      currentScopeObject: currentScopeObject,
      currentRct: _currentRct,
      setCurrentRct,
      currentSecondPersons: _currentSecondPersons,
      setCurrentSecondPersons,
      rctList: _rctList,
      setRctList,
      secondPersonsList: _secondPersonsList,
      setSecondPersonsList,
      refetchScopeData: refetchScopeData
    };
  }, [_scope, _currentRct, _currentSecondPersons, _rctList, _secondPersonsList, refetchScopeData, currentScopeObject]);

  return <RctControlContext.Provider value={contextValue}>{children}</RctControlContext.Provider>;
};
