import { useCallback, useLayoutEffect, useState } from 'react';
import { POINTS_LAST_ID } from '../../RegionsFilters';
import { RegionalRatingFilteredType, RegionRatingFilteredReportType } from '../../types';
import { ISortedColumn, RegionsTableHeaderFields, SelectedIndicatorType } from '../types';

const sortTable = ({
  data,
  sortedColumn,
  selectedIndicator
}: {
  data: RegionRatingFilteredReportType[];
  sortedColumn: ISortedColumn;
  selectedIndicator: SelectedIndicatorType | null;
}) => {
  if (!sortedColumn.field || !sortedColumn.sort) {
    return data;
  }

  return [...data].sort((a, b) => {
    if (!sortedColumn.field) {
      return 0;
    }

    const { field: key, sort } = sortedColumn;

    if (sortedColumn.field === RegionsTableHeaderFields.indicatorsValues) {
      const aScore =
        selectedIndicator?.indicatorId === POINTS_LAST_ID
          ? (a.pointsLast ?? 0)
          : (a.indicatorsValues.find(({ indicatorId }) => indicatorId === selectedIndicator?.indicatorId)
              ?.scoreForLastPeriod ?? 0);
      const bScore =
        selectedIndicator?.indicatorId === POINTS_LAST_ID
          ? (b.pointsLast ?? 0)
          : (b.indicatorsValues.find(({ indicatorId }) => indicatorId === selectedIndicator?.indicatorId)
              ?.scoreForLastPeriod ?? 0);

      if (aScore < bScore) {
        return sort === 'asc' ? -1 : 1;
      }
      if (aScore > bScore) {
        return sort === 'asc' ? 1 : -1;
      }
      return 0;
    }

    if (a[key]! < b[key]!) {
      return sort === 'asc' ? -1 : 1;
    }
    if (a[key]! > b[key]!) {
      return sort === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const useRegionsTableSort = (
  regionalRating: RegionalRatingFilteredType | null,
  selectedIndicator: SelectedIndicatorType | null
) => {
  const [sortedColumn, setSortedColumn] = useState<ISortedColumn>({
    field: RegionsTableHeaderFields.ratingPosition,
    sort: 'asc'
  });

  const [sortedData, setSortedData] = useState<RegionRatingFilteredReportType[]>();

  useLayoutEffect(() => {
    if (regionalRating?.regions) {
      setSortedData(
        sortTable({
          data: regionalRating?.regions,
          sortedColumn,
          selectedIndicator
        })
      );
    }
  }, [regionalRating?.regions, sortedColumn, selectedIndicator]);

  const onColumnSortIcon = useCallback(
    (header: RegionsTableHeaderFields) => () => {
      let nextSort: ISortedColumn['sort'] = null;
      if (sortedColumn.field !== header) {
        nextSort = 'asc';
      } else if (sortedColumn.sort === 'asc') {
        nextSort = 'desc';
      }

      const nextSortedColumn = {
        field: nextSort !== null ? header : null,
        sort: nextSort
      };

      setSortedColumn(nextSortedColumn);

      setSortedData((prev) => {
        if (!prev) {
          return;
        }

        return sortTable({
          data: prev,
          sortedColumn: nextSortedColumn,
          selectedIndicator
        });
      });
    },
    [selectedIndicator, sortedColumn]
  );

  return {
    sortedData,
    sortedColumn,
    onColumnSortIcon
  };
};
