import { RatingGroupEnum, RegionRatingGroupEnum } from 'store/graphql';

export const departmentEfficiencyGroups = {
  High: {
    value: RatingGroupEnum.High,
    text: 'Высокая'
  },
  Average: {
    value: RatingGroupEnum.Average,
    text: 'Средняя'
  },
  Low: {
    value: RatingGroupEnum.Low,
    text: 'Низкая'
  }
};

export const regionEfficiencyGroups = {
  High: {
    value: RegionRatingGroupEnum.High,
    text: 'Лидирующая'
  },
  Average: {
    value: RegionRatingGroupEnum.Average,
    text: 'Перспективная'
  }
};
