import React from 'react';
import s from './Loading.module.scss';

export function Loading() {
  return (
    <div className={s.Loading}>
      <div className={s.Loading__indicator}>
        {/* ··· */}
        <div className={s.Loading__ellipseContainer}>
          <div className={s.Loading__ellipse} />
        </div>
        <div className={s.Loading__ellipseContainer}>
          <div className={s.Loading__ellipse} />
        </div>
        <div className={s.Loading__ellipseContainer}>
          <div className={s.Loading__ellipse} />
        </div>
      </div>

      <p className={s.Loading__text}>Загрузка...</p>
    </div>
  );
}
