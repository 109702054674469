import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const emsrScore = components[0]?.value;
  const digitalBirthCertificates = components[1]?.value;
  const birthCertificates = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={emsrScore}
        unit={'%'}
        description={
          <>
            Значение показателя &laquo;Доля оформления медицинских свидетельств о&nbsp;рождении в&nbsp;форме
            электронного документа в&nbsp;медицинских организациях государственной и&nbsp;муниципальной систем
            здравоохранения&raquo; в&nbsp;субъекте Российской Федерации за&nbsp;отчетный период
          </>
        }>
        <Symbol sub={'ЭМД'}>А</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={digitalBirthCertificates}
        unit={'шт.'}
        roundUp
        description={
          <>
            Количество оформленных медицинских свидетельств о&nbsp;рождении в&nbsp;форме электронного документа
            в&nbsp;субъекте Российской Федерации за&nbsp;отчетный период
          </>
        }>
        <Symbol sub={'ЭМД'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={birthCertificates}
        roundUp
        unit={'шт.'}
        description={
          <>
            Общее количество медицинских свидетельств о&nbsp;рождении в&nbsp;субъекте Российской Федерации
            за&nbsp;отчетный период
          </>
        }>
        <Symbol sub={'дет'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'Аэмд < 5%']} />
      <TableRow values={['0,5', '5% ≤ Аэмд < 10%']} />
      <TableRow values={['1,0', '10% ≤ Аэмд < 25%']} />
      <TableRow values={['1,5', '25% ≤ Аэмд < 50%']} />
      <TableRow values={['2,0', '50% ≤ Аэмд ≤ 100%']} />
    </Table>
  );
}

export function EmsrShare() {
  return (
    <IndicatorPage
      regionScope
      download={'Доля ЭМСР.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
