import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'componentsL/Modal';
import { Tabs, TabType } from 'componentsL/Tabs/Tabs';
import { useFeedbackContext } from '../../feedback-context';
import { TabIndex } from '../../tab-index';
import { FeedbackForm, ProposalForm } from '../FormContent';

const TabsList: TabType<number>[] = [
  {
    label: 'Показатели',
    value: TabIndex.Indicator
  },
  {
    label: 'Предложения',
    value: TabIndex.Proposal
  }
];

export interface FeedbackModalProps {
  feedbackAvailable?: boolean;
  proposalAvailable?: boolean;
  onSendFeedback: () => void | Promise<void>;
  onSendProposal: () => void | Promise<void>;
  onClose: (isFormEmpty: boolean) => void;
}

export const FeedbackModal = ({
  feedbackAvailable = true,
  proposalAvailable = true,
  onSendFeedback,
  onSendProposal,
  onClose
}: FeedbackModalProps) => {
  const context = useFeedbackContext();

  const [activeTab, setActiveTab] = useState<TabIndex>(() => {
    if (feedbackAvailable && proposalAvailable) {
      return context.activeTabIndex;
    }

    if (feedbackAvailable) {
      return TabIndex.Indicator;
    }

    return TabIndex.Proposal;
  });

  const handleClose = useCallback(() => {
    switch (activeTab) {
      case TabIndex.Indicator: {
        onClose(context.feedbackForm.isEmpty);
        break;
      }
      case TabIndex.Proposal: {
        onClose(context.proposalForm.isEmpty);
        break;
      }
      default: {
        onClose(true);
      }
    }
  }, [activeTab, context, onClose]);

  useEffect(() => {
    context.activeTabIndex = activeTab;
    if (activeTab === TabIndex.Proposal) {
      // В "предложении" ID индикатора не нужен (это значение используется для отправки)
      context.selectedIndicatorId = void 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Modal title="Форма обратной связи" width={624} onClose={handleClose}>
      {feedbackAvailable && proposalAvailable && <Tabs tabs={TabsList} value={activeTab} onChange={setActiveTab} />}
      {activeTab === TabIndex.Indicator ? (
        <FeedbackForm onSubmit={onSendFeedback} />
      ) : (
        <ProposalForm onSubmit={onSendProposal} />
      )}
    </Modal>
  );
};
