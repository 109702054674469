import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from 'componentsL/ErrorMessage';
import { Loader } from 'componentsL/Loader';
import { useFrctFaqRequestQuery } from 'store/graphql';
import { AutoInc } from 'utils/autoInc';
import { FaqInfo } from '../../_components/FaqInfo';
import * as Types from '../../_components/FaqInfo/types';
import { Faq } from '../../_utils/Faq';

const blockIdGen = new AutoInc();
const qaIdGen = new AutoInc();

export const FrctFaqPage = () => {
  const { loading, data, error } = useFrctFaqRequestQuery();
  const [faqSections, setFaqSections] = useState<Types.QuestionsBlock[]>([]);

  useEffect(() => {
    if (!data) {
      setFaqSections([]);
      return;
    }

    const sections: Types.QuestionsBlock[] = data.rating.departmentsFaq.sections.map((section) => ({
      id: blockIdGen.next(),
      title: section.name,
      list: section.questions.map((question) => ({
        id: qaIdGen.next(),
        question: question.title,
        answer: question.answer
      }))
    }));

    setFaqSections(sections);
  }, [data]);

  const faq = useMemo<Faq | null>(() => {
    return faqSections.length ? new Faq(faqSections) : null;
  }, [faqSections]);

  return (
    <>
      {loading && <Loader />}
      {error && <ErrorMessage title="Не удалось получить список вопросов и ответов" />}
      {/* faq есть, только если данные уже получены */}
      {faq && <FaqInfo faq={faq} />}
    </>
  );
};
