import React from 'react';
import { clsx } from 'clsx';
import s from './ModalContentGroup.module.scss';

export interface ModalContentGroupProps extends React.PropsWithChildren {
  className?: string;
  title?: string;
}

export const ModalContentGroup = ({ title, className, children }: ModalContentGroupProps) => {
  return (
    <div className={clsx(s.ModalContentGroup, className)}>
      {title && <div className={s.ModalContentGroup__title}>{title}</div>}

      <div className={s.ModalContentGroup__content}>{children}</div>
    </div>
  );
};
