import React, { PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import { Icon, IconSize, IconSort } from 'componentsL/Icon';
import { IndicatorsTableHeaderFields, ISortedColumn } from '../types';
import s from './TableHeaderCell.module.scss';

type Props = {
  field: IndicatorsTableHeaderFields;
  onColumnSortPress?: (sortedColumn?: ISortedColumn) => void;
  sortedColumn?: ISortedColumn;
} & PropsWithChildren;

export const TableHeaderCell = ({ children, onColumnSortPress, field, sortedColumn }: Props) => {
  const onPress = () => {
    if (!onColumnSortPress) return;
    if (field === sortedColumn?.field) {
      onColumnSortPress(sortedColumn.sort !== 'desc' ? { field, sort: 'desc' } : undefined);
    } else {
      onColumnSortPress({ field, sort: 'asc' });
    }
  };
  const iconStyle = useMemo(() => {
    if (sortedColumn?.field !== field) return s.TableHeaderCell__icon;
    return clsx(s.TableHeaderCell__icon, s[`TableHeaderCell__icon_${sortedColumn.sort}`]);
  }, [sortedColumn, field]);

  const renderSortIcon = () => {
    if (!onColumnSortPress) return null;
    return <Icon icon={IconSort} size={IconSize.Small} className={iconStyle} onClick={onPress} />;
  };

  return (
    <div className={s.TableHeaderCell}>
      {children}
      {renderSortIcon()}
    </div>
  );
};
