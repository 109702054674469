import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const connectedToEpguSystemsShare = components[0]?.value;
  const smevSystemsCount = components[1]?.value;
  const systemsWithoutEpguRequirement = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={connectedToEpguSystemsShare}
        unit={'%'}
        description={'Значение показателя «Доля информационных систем, передающих результаты услуг на ЕПГУ»'}>
        <Symbol>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={smevSystemsCount}
        roundUp
        description={
          <>
            Количество ИС, интегрированных с видом сведений СМЭВ «Передача статусов и результатов предоставления
            государственных и муниципальных услуг», применяемых <br />
            для оказания услуг из Перечня
          </>
        }>
        <Symbol sub={'1'}>Q</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={systemsWithoutEpguRequirement}
        roundUp
        description={
          <>
            Количество ИС, используемых для оказания услуг из Перечня, <br />
            по которым предусмотрена возможность подачи заявления без использования ЕПГУ
          </>
        }>
        <Symbol sub={'2'}>Q</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Доля ИС, передающих результаты услуг на ЕПГУ, составляет 0-20,9%']} />
      <TableRow values={['0,5', 'Доля ИС, передающих результаты услуг на ЕПГУ, составляет 21,0-40,9%']} />
      <TableRow values={['1,0', 'Доля ИС, передающих результаты услуг на ЕПГУ, составляет 41,0-60,9%']} />
      <TableRow values={['1,5', 'Доля ИС, передающих результаты услуг на ЕПГУ, составляет 61,0-80,9%']} />
      <TableRow values={['2,0', 'Доля ИС, передающих результаты услуг на ЕПГУ, составляет 81,0-100,0%']} />
    </Table>
  );
}

export function ConnectedToEpguSystems() {
  return (
    <IndicatorPage
      download={'Доля информационных систем.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
