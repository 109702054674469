import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const verifiedPages = components[1]?.value;
  const registeredPages = components[2]?.value;
  const appealsShare = components[3]?.value;
  const surveyShare = components[4]?.value;
  const published3InWeekPages = components[5]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={verifiedPages} description={'Создание и подтверждение официальных страниц'}>
        <Symbol sub={'со'}>Y</Symbol>
      </SymbolDetails>
      <SymbolDetails value={registeredPages} description={'Оформление официальных страниц'}>
        <Symbol sub={'оформ'}>U</Symbol>
      </SymbolDetails>
      <SymbolDetails value={appealsShare} description={'Размещение электронной формы сообщений и обращений ПОС'}>
        <Symbol sub={'сообщ'}>F</Symbol>
      </SymbolDetails>
      <SymbolDetails value={surveyShare} description={'Размещение электронной формы опросов и голосований ПОС'}>
        <Symbol sub={'опрос'}>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={published3InWeekPages}
        description={'Доля официальных страниц, размещающих не менее трех публикаций в неделю'}>
        <Symbol sub={'актив'}>R</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Критерий']} isHeader />
      <TableRow values={['до 0,5', 'Создание и подтверждение официальных страниц']} />
      <TableRow values={['до 0,5', 'Оформление официальных страниц']} />
      <TableRow
        values={['до 0,5', 'Размещение на официальных страницах электронной формы подачи сообщений и обращений ПОС']}
      />
      <TableRow
        values={['до 0,5', 'Размещение на официальных страницах электронной формы опросов и голосований ПОС']}
      />
      <TableRow values={['до 0,5', 'Размещение публикаций на официальных страницах']} />
      <TableRow values={['до 2,5', 'Госпаблики. Создание и ведение официальных страниц']} />
    </Table>
  );
}

export function GovPublic() {
  return (
    <IndicatorPage
      download={'Госпаблики.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
