import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useProfile } from 'store/profile/useProfile';
import { RegionalRatingFilteredType } from '../types';
import { accessNavigateToRegion } from '../utils/accessNavigateToRegion';
import { TableCell } from './TableCell';
import { TableCellHeader } from './TableCellHeader';
import s from './RegionsTableCompact.module.scss';

export interface RegionsTableCompactProps {
  className?: string;
  filteredData: RegionalRatingFilteredType | null;
  columns?: number;
}

export const RegionsTableCompact: FC<RegionsTableCompactProps> = (props) => {
  const { className, filteredData, columns = 4 } = props;

  const { isObserver, isRRCT } = useProfile();

  const navigate = useNavigate();

  const renderedData = filteredData?.regions;

  const dataColumns = useMemo(() => {
    if (!renderedData?.length || columns < 1) {
      return [];
    }

    const itemsInColumns: number[] = [];
    let length = renderedData.length;
    let i = columns;

    while (i > 0) {
      const itemsCount = Math.ceil(length / i);
      itemsInColumns.push(itemsCount);
      length -= itemsCount;
      i--;
    }

    let cursor = 0;

    return itemsInColumns.map((count) => {
      const result = renderedData.slice(cursor, cursor + count);
      cursor = cursor + count;
      return result;
    });
  }, [columns, renderedData]);

  if (columns < 1) {
    return null;
  }

  return (
    <div className={clsx(s.RegionsTableCompact, className)} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
      {dataColumns.map((column, index) => (
        <div key={index} className={s.RegionsTableCompact__column}>
          <TableCellHeader />
          {column.map(
            ({
              regionId,
              ratingPosition,
              ratingGroup,
              previousRatingPosition,
              isCurrentRegion,
              regionName,
              degree,
              pointsLast,
              filtered
            }) => {
              const isLink = accessNavigateToRegion({
                pointsLast,
                isRRCT,
                isCurrentRegion,
                isObserver
              });

              return (
                <TableCell
                  key={regionId}
                  filtered={filtered}
                  ratingPosition={ratingPosition}
                  previousRatingPosition={previousRatingPosition}
                  regionName={regionName}
                  current={isCurrentRegion}
                  ratingGroup={ratingGroup}
                  degree={degree}
                  onClick={isLink ? () => navigate(`/region/${regionId}`) : undefined}
                />
              );
            }
          )}
        </div>
      ))}
    </div>
  );
};
