import React from 'react';
import clsx from 'clsx';
import s from './SymbolsGrid.module.scss';

export interface SymbolsGridProps {
  columns?: number;
  children: React.ReactNode[];
  largeGap?: boolean;
}

export function SymbolsGrid({ columns = 3, largeGap, children }: SymbolsGridProps) {
  // TODO: подумать над упрощением (может, сделать divider'ы фоном, как в InfoTable)
  const templateColumns: string[] = [];
  for (let i = 0; i < columns + columns - 1; i++) {
    templateColumns.push((i + 1) % 2 === 0 ? '1px' : 'minmax(0, 1fr)');
  }

  return (
    <div className={s.SymbolsGrid__wrapper}>
      <h4 className={s.SymbolsGrid__heading}>Компоненты</h4>
      <div
        className={clsx(s.SymbolsGrid, largeGap && s.SymbolsGrid__largeGap)}
        style={{ gridTemplateColumns: templateColumns.join(' ') }}>
        {children.map((child, index) => (
          <React.Fragment key={`symbol-${index}`}>
            {child}
            {(index + 1) % columns !== 0 && index + 1 !== children.length && <div className={s.SymbolsGrid__divider} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
