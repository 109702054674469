import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { pluralize } from '@digital-gov/ui-utils';
import { IconDismiss, IconSearch } from 'componentsL/Icon';
import { TextInput } from 'componentsL/Input';
import { Faq, SearchArea } from '../../_utils/Faq';
import { QaSection } from './_components/QaSection';
import { FaqContext } from './faq-context';
import s from './FaqInfo.module.scss';

export interface FaqInfoProps {
  faq: Faq;
}

export const FaqInfo = ({ faq }: FaqInfoProps) => {
  const { hash } = useLocation();
  const [sectionId, setSectionId] = useState(hash.substring(1));
  const preventScrollAffectRef = useRef(false);
  const [query, setQuery] = useState('');
  const containerRef = useRef<null | HTMLDivElement>(null);
  const sectionsRefs = useRef<Element[]>([]);

  const handleClean = useCallback(() => {
    faq.reset();
    setQuery('');
  }, [faq]);

  const handleQueryInput: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newQuery = event.target.value;
      if (newQuery.trim().length > 0) {
        faq.search(newQuery);
      } else {
        faq.reset();
      }

      setQuery(newQuery);
    },
    [faq]
  );

  const handleAnchorClick = () => {
    preventScrollAffectRef.current = true;
  };

  const handleScroll = useCallback(() => {
    /**
     * При клике на ссылку-якорь триггерится авто-скролл к секции с соотв. ID,
     * поэтому приходится отменять вызов setSectionId(firstVisibleElId) вручную,
     * иначе при клике на последнюю ссылку-якорь, она не будет активироваться.
     */
    if (preventScrollAffectRef.current) {
      preventScrollAffectRef.current = false;
      return;
    }

    const nodes = sectionsRefs.current;
    if (nodes.length) {
      const firstVisibleElId = nodes
        .find((n) => {
          const bounds = n.getBoundingClientRect();
          return bounds.bottom > 0 && bounds.bottom < window.innerHeight;
        })
        ?.getAttribute('id');

      if (firstVisibleElId) {
        setSectionId(firstVisibleElId);
      }
    }
  }, []);

  useEffect(() => {
    sectionsRefs.current = Array.from(document.querySelectorAll('[id]')).filter((el) => {
      return faq.sections.has(Number(el.id));
    });

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [faq, handleScroll]);

  useEffect(() => {
    setSectionId(hash.substring(1));

    const nodes = sectionsRefs.current;
    if (nodes.length) {
      nodes.find((n) => n.getAttribute('id') === hash.substring(1))?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  useEffect(() => {
    if (containerRef.current) {
      faq.setDomContainer(containerRef.current);
    }
  }, [faq]);

  return (
    <FaqContext.Provider value={faq}>
      <div className={s.FaqInfo} ref={containerRef}>
        <div className={s.FaqInfo__searchSection}>
          <TextInput
            value={query}
            iconLeft={IconSearch}
            iconRight={query.trim().length === 0 ? void 0 : IconDismiss}
            onIconRightClick={handleClean}
            onChange={handleQueryInput}
          />

          {query.length > 0 && (
            <div className={s.FaqInfo__hint}>
              {faq.size === 0
                ? 'Не найдено совпадений'
                : `Найдено ${faq.size} ${pluralize(faq.size, 'совпадение', 'совпадения', 'совпадений')}`}
            </div>
          )}
        </div>

        <div className={s.FaqInfo__qaSectionsBlock}>
          {faq.list().map((section) => {
            const hidden = !faq.getSectionVisibility(section.id);

            return (
              <div
                key={section.id}
                {...faq.props(SearchArea.Section, section.id)}
                style={{ display: hidden ? 'none' : 'block' }}>
                <QaSection id={section.id} title={section.title} questions={faq.questions(section.id)} />
              </div>
            );
          })}
        </div>

        <div className={s.FaqInfo__shortcutsSection}>
          <div className={s.FaqInfo__shortcutsMenu}>
            {faq.list().map(
              (section) =>
                section.title &&
                faq.getSectionVisibility(section.id) && (
                  <a
                    key={section.id}
                    href={`#${section.id}`}
                    className={clsx(s.FaqInfo__shortcut, {
                      [s.FaqInfo__shortcut_active]: sectionId === section.id.toString()
                    })}
                    onClick={handleAnchorClick}>
                    {section.title}
                  </a>
                )
            )}
          </div>
        </div>
      </div>
    </FaqContext.Provider>
  );
};
