import { useCallback, useState } from 'react';
import { tryNumberLike } from '@digital-gov/ui-utils';
import { Button, IconButton } from 'components/Button';
import { Dismiss } from 'components/Icon';
import { TrashBoxIcon, UnlinkIcon } from 'components/Icon';
import { Input } from 'components/Input';
import { RctScopeEnum, useUpsertRctMutation } from 'store/graphql';
import { useRctControlContext } from '../../../_store/useRctControlContext';
import { InputErrorEnum, RctControlScopeEnum, RctType, validate } from '../../../_utils';
import s from './RctEditor.module.scss';

export interface RctEditorProps {
  mode: 'add' | 'edit';
  currentRct?: RctType;
  onError: () => void;
  onSuccess: () => void;
  onBack: () => void;
  handleUpdate: (rct: RctType, mode: 'add' | 'edit') => void;
}

type RctTemplate = {
  userId: number | null;
  name: string;
  surname: string;
  patronymic: string;
  fullName: string;
  year?: number | string | null;
};

const templateHaveEmptyFields = (t: RctTemplate): boolean => {
  return (
    t.name === '' ||
    t.surname === '' ||
    !t.patronymic ||
    t.patronymic === '' ||
    t.fullName === '' ||
    !t.year ||
    t.year === ''
  );
};

export function RctEditor({ mode, currentRct, onError, onSuccess, onBack, handleUpdate }: RctEditorProps) {
  const { setCurrentRct } = useRctControlContext();
  const context = useRctControlContext();
  const [upsertRctMutation] = useUpsertRctMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [inputErrors, setInputErrors] = useState<Set<InputErrorEnum>>(new Set());
  const [rctTemplate, setRctTemplate] = useState<RctTemplate>(
    currentRct
      ? {
          ...currentRct,
          patronymic: currentRct.patronymic ?? ''
        }
      : {
          userId: null,
          name: '',
          surname: '',
          patronymic: '',
          fullName: '',
          year: null
        }
  );

  // Сохранение изменений в РЦТ
  const saveRct = useCallback(async () => {
    try {
      const isFrct = context.scope === RctControlScopeEnum.DepartmentRct;
      setLoading(true);
      const result = await upsertRctMutation({
        variables: {
          input: {
            userId: rctTemplate.userId,
            scope: isFrct ? RctScopeEnum.Frct : RctScopeEnum.Rrct,
            name: rctTemplate.name.trim(),
            surname: rctTemplate.surname.trim(),
            patronymic: rctTemplate.patronymic.trim(),
            year: rctTemplate.year ? +rctTemplate.year : 2024
          }
        }
      });
      if (!result.data) {
        onError();
        return;
      }
      if (context.refetchRctList) {
        await context.refetchRctList();
      }
      if (context.refetchScopeData) {
        await context.refetchScopeData();
      }
      handleUpdate(
        {
          ...rctTemplate,
          year: rctTemplate.year ? +rctTemplate.year : 2024,
          userId: result.data.upsertRct.userId
        },
        mode
      );
      setLoading(false);
      onSuccess();
    } catch (e) {
      onError();
    }
  }, [rctTemplate, upsertRctMutation, setLoading, context, onError, onSuccess, handleUpdate, mode]);

  // отвязка РЦТ
  const unlinkRct = useCallback(async () => {
    setCurrentRct(null);
    onSuccess();
  }, [setCurrentRct, onSuccess]);

  // Хендлеры работы с текстовыми полями
  const clearHandler = (field: 'name' | 'surname' | 'patronymic' | 'year') => {
    if (field === 'name') setRctTemplate((rct) => ({ ...rct, name: '', fullName: `${rct.surname} ${rct.patronymic}` }));
    if (field === 'surname')
      setRctTemplate((rct) => ({ ...rct, surname: '', fullName: `${rct.name} ${rct.patronymic}` }));
    if (field === 'patronymic')
      setRctTemplate((rct) => ({ ...rct, patronymic: '', fullName: `${rct.surname} ${rct.name}` }));
    if (field === 'year') setRctTemplate((rct) => ({ ...rct, year: null }));
  };

  const nameHandler = (value: string) => {
    setInputErrors((errors) => validate(value, [InputErrorEnum.NameLength, InputErrorEnum.NameContent], errors));
    setRctTemplate((rct) => ({ ...rct, name: value, fullName: `${rct.surname} ${value} ${rct.patronymic}` }));
  };

  const surnameHandler = (value: string) => {
    setInputErrors((errors) => validate(value, [InputErrorEnum.SurnameLength, InputErrorEnum.SurnameContent], errors));
    setRctTemplate((rct) => ({ ...rct, surname: value, fullName: `${value} ${rct.name} ${rct.patronymic}` }));
  };

  const patronymicHandler = (value: string) => {
    setInputErrors((errors) =>
      validate(value, [InputErrorEnum.PatronymicLength, InputErrorEnum.PatronymicContent], errors)
    );
    setRctTemplate((rct) => ({
      ...rct,
      patronymic: value,
      fullName: `${rct.surname} ${rct.name} ${value}`
    }));
  };

  const yearHandler = (value: string) => {
    setInputErrors((errors) => validate(value, [InputErrorEnum.YearValue], errors));
    const year = tryNumberLike(value, null, true);
    if (year) setRctTemplate((rct) => ({ ...rct, year }));
    if (!year) setRctTemplate((rct) => ({ ...rct, year: value }));
  };

  return (
    <>
      {mode === 'edit' && (
        <div className={s.RctEditor__box}>
          <div>Редактирование РЦТ</div>
          <div className={s.RctEditor__nameAndYear}>
            <div>{rctTemplate.fullName}</div>
            {rctTemplate.year && <div className={s.RctEditor__yearText}>Год назначения - {rctTemplate.year}</div>}
          </div>
          <IconButton icon={UnlinkIcon} className={s.RctEditor__unlink} onClick={unlinkRct}></IconButton>
          <Input
            disabled={loading}
            value={rctTemplate.surname}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('surname')}
            onChange={surnameHandler}
          />
          <Input
            disabled={loading}
            value={rctTemplate.name}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('name')}
            onChange={nameHandler}
          />
          <Input
            disabled={loading}
            value={rctTemplate.patronymic ?? ''}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('patronymic')}
            onChange={patronymicHandler}
          />
          <Input
            disabled={loading}
            value={rctTemplate.year?.toString() ?? ''}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('year')}
            onChange={yearHandler}
          />
          <div className={s.RctEditor__disclaimer}>Необходимо полностью указать Фамилию, Имя и Отчество.</div>
          {inputErrors.size > 0 && <div className={s.RctEditor__inputErrors}>Некорректные данные</div>}
          <Button
            loading={loading}
            disabled={loading || inputErrors.size > 0 || templateHaveEmptyFields(rctTemplate)}
            onClick={saveRct}>
            Сохранить
          </Button>
          <Button className={s.RctEditor__backButton} disabled={loading} onClick={onBack}>
            Отмена
          </Button>
        </div>
      )}

      {mode === 'add' && (
        <div className={s.RctEditor__box}>
          <div>Создание нового РЦТ</div>
          {onBack && <IconButton icon={TrashBoxIcon} className={s.RctEditor__unlink} onClick={onBack}></IconButton>}
          <Input
            disabled={loading}
            type={'text'}
            placeholder={'Фамилия*'}
            dimmedPlaceHolder
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('surname')}
            onChange={surnameHandler}
          />
          <Input
            disabled={loading}
            type={'text'}
            placeholder={'Имя*'}
            dimmedPlaceHolder
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('name')}
            onChange={nameHandler}
          />
          <Input
            disabled={loading}
            type={'text'}
            placeholder={'Отчество*'}
            dimmedPlaceHolder
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('patronymic')}
            onChange={patronymicHandler}
          />
          <Input
            disabled={loading}
            type={'text'}
            iconRight={Dismiss}
            placeholder={'Год назначения*'}
            dimmedPlaceHolder
            iconRightHandler={() => clearHandler('year')}
            onChange={yearHandler}
          />
          <div className={s.RctEditor__disclaimer}>Необходимо полностью указать Фамилию, Имя и Отчество.</div>
          {inputErrors.size > 0 && <div className={s.RctEditor__inputErrors}>Некорректные данные</div>}
          <Button
            loading={loading}
            disabled={loading || inputErrors.size > 0 || templateHaveEmptyFields(rctTemplate)}
            onClick={saveRct}>
            Сохранить
          </Button>
          <Button className={s.RctEditor__backButton} disabled={loading} onClick={onBack}>
            Отмена
          </Button>
        </div>
      )}
    </>
  );
}
