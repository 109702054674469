import React, { useCallback, useState } from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Loader } from 'componentsL/Loader';
import { Modal } from 'componentsL/Modal';
import { usePublishCommentMutation } from 'store/graphql';
import { isDepartmentScope, isIndicatorScope, isRegionScope } from '../_utils/helpers';
import { useCommentContext } from '../context/useCommentContext';
import s from './ConfirmPublishing.module.scss';

export interface ConfirmPublishingProps {
  onBack: () => void;
  onError: () => void;
  onSuccess: () => void;
}

export function ConfirmPublishing({ onBack, onError, onSuccess }: ConfirmPublishingProps) {
  const context = useCommentContext();

  const [publishCommentMutation, { loading }] = usePublishCommentMutation();
  const [refetchLoading, setRefetchLoading] = useState(false);

  const handlePublishComment = async () => {
    await publishComment();
  };

  const publishComment = useCallback(async () => {
    if (context.commentText) {
      try {
        await publishCommentMutation({
          variables: {
            scope: context.scope,
            scopeId: context.scopeId,
            scopeIndicatorId: context.scopeIndicatorId,
            commentId: context.commentId ?? null,
            commentText: context.commentText
          }
        });
        // перезапрос данных для обновления счётчика и черновика
        setRefetchLoading(true);
        if (isDepartmentScope(context.scope) && context.departmentRefetch) {
          await context.departmentRefetch();
        }
        if (isRegionScope(context.scope) && context.regionRefetch) {
          await context.regionRefetch();
        }
        setRefetchLoading(false);
        onSuccess();
      } catch (e) {
        setRefetchLoading(false);
        onError();
      }
    } else {
      onError();
    }
  }, [publishCommentMutation, context, onSuccess, onError]);

  return (
    <Modal
      title={isIndicatorScope(context.scope) ? 'Публикация рекомендации' : 'Публикация комментария'}
      width={456}
      onClose={onBack}>
      <div className={s.ConfirmPublishing__text}>
        {isIndicatorScope(context.scope) ? (
          <>
            Вы уверены, что хотите опубликовать рекомендацию?
            <br />
            После публикации она станет видна для пользователей.
          </>
        ) : (
          <>
            Вы уверены, что хотите опубликовать комментарий?
            <br />
            После публикации он станет виден для пользователей.
          </>
        )}
      </div>
      <div className={s.ConfirmPublishing__buttons}>
        <Button variant={ButtonVariant.Secondary} onClick={onBack}>
          Назад
        </Button>
        <Button variant={ButtonVariant.Primary} disabled={loading || refetchLoading} onClick={handlePublishComment}>
          {loading || refetchLoading ? (
            <Loader spinnerWidth={20} spinnerHeight={20} transparentBgColor={true} />
          ) : (
            <div>Опубликовать</div>
          )}
        </Button>
      </div>
    </Modal>
  );
}
