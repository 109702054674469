import React from 'react';
import { formatDate, DATE_FULL_DAY_SHORT } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal, ModalContentGroup, ModalFooter } from 'componentsL/Modal';
import { AttachmentItem } from './AttachmentItem';
import { AttachmentLoader } from './AttachmentLoader';
import { AttachmentLoadingItem } from './AttachmentLoadingItem';
import s from './IndicatorFeedbackDetails.module.scss';

export interface IndicatorFeedbackDetailsProps {
  feedback: Feedback;
  onClose: () => void;
  onBack: () => void;
}

export const IndicatorFeedbackDetails = ({ feedback, onClose, onBack }: IndicatorFeedbackDetailsProps) => {
  return (
    <Modal title="Карточка заявки" width={624} className={s.IndicatorFeedbackDetails} onClose={onClose}>
      {typeof feedback.subject !== 'string' && (
        <ModalContentGroup title="Показатель">{feedback.indicatorName}</ModalContentGroup>
      )}

      <ModalContentGroup title="Дата подачи заявки">
        {formatDate(feedback.publishDate, DATE_FULL_DAY_SHORT)}
      </ModalContentGroup>

      <ModalContentGroup title="Инициатор заявки">{feedback.publisher}</ModalContentGroup>

      {typeof feedback.subject === 'string' && (
        <ModalContentGroup title="Тема заявки">{feedback.subject}</ModalContentGroup>
      )}

      <ModalContentGroup title="Комментарий">
        <div className={s.IndicatorFeedbackDetails__comment}>{feedback.commentary}</div>
      </ModalContentGroup>

      {feedback.attachments.length > 0 && (
        <ModalContentGroup title="Приложенные файлы">
          <div className={s.IndicatorFeedbackDetails__attachments}>
            {feedback.attachments.map((file) => (
              <AttachmentLoader key={file.id} attachmentId={file.id}>
                {({ download, loading }) =>
                  loading ? (
                    <AttachmentLoadingItem filename={file.fullName} size={file.size} />
                  ) : (
                    <div onClick={download}>
                      <AttachmentItem key={file.id} filename={file.fullName} size={file.size} />
                    </div>
                  )
                }
              </AttachmentLoader>
            ))}
          </div>
        </ModalContentGroup>
      )}

      <ModalFooter
        right={
          <Button variant={ButtonVariant.Primary} onClick={onBack}>
            Назад
          </Button>
        }
      />
    </Modal>
  );
};
