import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Icon, IconDismiss, IconSize } from 'componentsL/Icon';
import s from './Modal.module.scss';

type CloseHandler = () => void;

function useCloseHandler(
  sourceHandler: void | false | CloseHandler,
  fallbackHandler: void | false | CloseHandler
): [false, void] | [true, CloseHandler] {
  return useMemo(() => {
    if (sourceHandler === false) {
      return [false, void 0];
    }

    if (sourceHandler instanceof Function || fallbackHandler instanceof Function) {
      // По условию, как минимум одно из значений - функция, но TS не понимает, поэтому указываем явно через as
      return [true, (sourceHandler || fallbackHandler) as CloseHandler];
    }

    return [false, void 0];
  }, [sourceHandler, fallbackHandler]);
}

export interface ModalProps {
  title?: string | React.ReactNode;
  className?: string;
  children: React.ReactNode;
  // Желаемая внешняя (максимальная) ширина окна (по умолчанию - авто)
  //  Приоритетнее значения style.maxWidth
  width?: number;
  // Желаемая внешняя (максимальная) высота окна (по умолчанию - авто)
  //  Приоритетнее значения style.maxHeight
  height?: number;
  style?: React.CSSProperties;
  /**
   * Общий обработчик закрытия (отображение/закрытие формы осуществляется извне, поэтому нужен,
   * если форму можно закрыть), используется, если не заданы специфичные *1
   *
   * Если указан как false или не передан (undefined):
   *  - Если не указаны специфичные обработчики *1 - запрет на закрытие (крестик не показывается)
   *  - Если указаны специфичные *1 - обрабатываются только они.
   *
   *  Т.е. крестик показывается только если для него есть обработчик.
   *
   * *1 Специфичные обработчики закрытия:
   *  - {@link onCloseViaCross}
   *  - {@link onCloseViaBackdrop}
   *  - {@link onCloseViaEscape}
   */
  onClose?: false | CloseHandler;
  /**
   * Обработчик закрытия через крестик, если false - не обрабатывается (даже из {@link onClose}),
   *  крестик не показывается.
   * Если не передан (undefined) и:
   *  - передан {@link onClose} - обрабатывается им, крестик показывается;
   *  - {@link onClose} не передан или false - не обрабатывается, крестик не показывается.
   *
   *  Т.е. крестик показывается только если для него есть обработчик.
   */
  onCloseViaCross?: false | CloseHandler;
  /**
   * Обработчик закрытия по клику на подложку, если false - не обрабатывается (даже из {@link onClose})
   */
  onCloseViaBackdrop?: false | CloseHandler;
  /**
   * Обработчик закрытия по клавише Esc, если false - не обрабатывается (даже из {@link onClose})
   */
  onCloseViaEscape?: false | CloseHandler;
}

export const Modal = ({
  title,
  className,
  width,
  height,
  style,
  onClose,
  onCloseViaCross,
  onCloseViaBackdrop,
  onCloseViaEscape,
  children
}: ModalProps) => {
  const windowRef = useRef<HTMLDivElement | null>(null);
  const [cssStyle, setCssStyle] = useState<React.CSSProperties>({
    ...(style || {}),
    maxWidth: width ?? style?.maxWidth,
    maxHeight: height ?? style?.maxHeight
  });

  const [isClosableViaCross, handleClickCross] = useCloseHandler(onCloseViaCross, onClose);
  const [isClosableViaBackdrop, handleClickBackdrop] = useCloseHandler(onCloseViaBackdrop, onClose);
  const [isClosableViaEsc, handleEsc] = useCloseHandler(onCloseViaEscape, onClose);

  const onEscKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (handleEsc && event.key === 'Escape') {
        handleEsc();
      }
    },
    [handleEsc]
  );

  // При создании окна блокируем скролл окна, при закрытии - возвращаем исходное значение
  useEffect(() => {
    const original = document.body.style.overflow;

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = original;
    };
  }, []);

  // При открытии окна добавляем слушателя клавиатуры, чтобы по "Esc" закрывать окно
  useEffect(() => {
    if (isClosableViaEsc) {
      window.addEventListener('keyup', onEscKeyUp);
    }

    return isClosableViaEsc ? () => window.removeEventListener('keyup', onEscKeyUp) : void 0;
  }, [isClosableViaEsc, onEscKeyUp]);

  useEffect(() => {
    setCssStyle({
      ...(style || {}),
      maxWidth: width ?? style?.maxWidth,
      maxHeight: height ?? style?.maxHeight
    });
  }, [width, height, style]);

  return (
    <div className={s.Modal}>
      <div
        className={clsx(s.Modal__backdrop, { [s.Modal__backdrop_inactive]: !isClosableViaBackdrop })}
        onClick={isClosableViaBackdrop ? handleClickBackdrop : void 0}
      />
      <div ref={windowRef} style={cssStyle} className={clsx(s.Modal__window, className)}>
        {isClosableViaCross && (
          <Icon className={s.Modal__closeButton} icon={IconDismiss} size={IconSize.Medium} onClick={handleClickCross} />
        )}

        {title && <div className={s.Modal__windowTitle}>{title}</div>}

        <div className={s.Modal__windowBody}>{children}</div>
      </div>
    </div>
  );
};
