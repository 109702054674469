import React from 'react';
import { FileInput } from 'componentsL/FileInput';
import { FileUploadItem } from 'componentsL/FileUploadItem';
import s from './FilesList.module.scss';

export interface FileProps {
  key: any;
  name: string;
  // Размер файла в КБ
  size: number;
  // Загружается (true) или загружен (false)
  loading: boolean;
  // Наличие ошибки загрузки
  error: boolean;
  // Текст ошибки загрузки
  errorMessage?: string;
}

export interface FilesListProps {
  files: FileProps[];
  limit: number;
  // Максимальный размер файла в МБ
  fileSizeLimit?: number;
  disabled?: boolean;
  /**
   * Можно ли через окно выбора файла выбрать несколько (true) или только один (false)
   * @see {@link FileInputProps.multiple}
   */
  availableMultiple?: boolean;
  /**
   * Перечень допустимых форматов
   * {@link FileInputProps.accept}
   */
  accept: string | string[];
  // Перечень поддерживаемых типов (это именно формат вывода, т.к. в accept можно указывать MIME-types,
  //  что совсем не user-friendly
  acceptFileTypes: string;
  onAddFiles: (files: File[]) => void;
  onRemoveFile: (fileId: any) => void;
}

export const FilesList = ({
  files,
  limit,
  disabled,
  availableMultiple,
  accept,
  acceptFileTypes,
  onAddFiles,
  onRemoveFile
}: FilesListProps) => {
  return (
    <div className={s.FilesList}>
      {files.length > 0 && (
        <div className={s.FilesList__list}>
          {files.map((file) => (
            <FileUploadItem
              key={file.key}
              fileID={file.key}
              loading={file.loading}
              filename={file.name}
              fileSize={file.size}
              disabled={disabled}
              error={file.error}
              errorMessage={file.errorMessage}
              onRemove={onRemoveFile}
            />
          ))}
        </div>
      )}

      <div className={s.FilesList__footer}>
        <FileInput disabled={disabled} multiple={availableMultiple} accept={accept} onSelect={onAddFiles} />
        <div className={s.FilesList__filesLimit}>
          Загрузите до {limit} файлов объёмом не более 20 мб в формате {acceptFileTypes}.
        </div>
      </div>
    </div>
  );
};
