import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DATE_DAY_MONTH_SHORT, DATE_FULL, formatDate, formatNumber } from '@digital-gov/ui-utils';
import s from './IndicatorDynamicsChart.module.scss';

interface IndicatorDynamicsChartProps {
  maxInChart: number;
  chart: { date: string; value: number }[];
  minHeightChart?: number;
}

const ChartColors: Record<string, string> = {
  axisGrid: '#504a69',
  axisLine: '#504a69',
  xTickText: '#d2d3dd',
  yTickText: '#787a92',
  chartLine: '#55d666',
  chartDot: '#4c77fe',
  chartDotPast: '#3a54a5',
  tooltipBackground: '#27283d',
  tooltipText: '#d2d3dd',
  tooltipLabel: '#787a92'
};

export function IndicatorDynamicsChart({ maxInChart, minHeightChart = 168, chart }: IndicatorDynamicsChartProps) {
  // // в дизайне именно три последние отображаются, а иначе график разрушается визуально
  // const chart = props.chart.slice(-3).map((payload, index) => ({ ...payload, index }));
  if (!chart.length) return null;

  const renderTick = ({ x, y, payload }: any) => {
    const isMaxVal = payload.value === maxInChart;
    return (
      <g transform={`translate(${x},${y})`}>
        <text fill={ChartColors.yTickText} style={{ fontWeight: 400, fontSize: 12 }} dy={16} dx={-14}>
          {formatNumber(Math.round(payload.value * 100) / 100)} {isMaxVal ? 'макс' : null}
        </text>
      </g>
    );
  };

  return (
    <div className={s.DynamicsChart} style={{ '--min-height-chart': `${minHeightChart}px` } as React.CSSProperties}>
      <ResponsiveContainer className={s.DynamicsChart__container}>
        <LineChart data={chart} margin={{ top: 10 }}>
          <CartesianGrid strokeDasharray={'5 5'} style={{ stroke: ChartColors.axisGrid }} vertical={false} />

          <XAxis
            dataKey={'date'}
            interval={'preserveStartEnd'}
            padding={{ left: 80, right: 50 }}
            axisLine={{ stroke: ChartColors.axisLine }}
            tickFormatter={(val) => `  ${formatDate(val, DATE_DAY_MONTH_SHORT).slice(0, 6)}  `}
            tickLine={false}
            tick={{ fontSize: 12, fontWeight: 400, fill: ChartColors.xTickText }}
            tickMargin={8}
          />

          <YAxis
            // TODO: подумать, можно ли как-то избежать не-кратных 0,5 значений
            domain={[0, maxInChart || 'auto']}
            type={'number'}
            axisLine={false}
            style={{ fontWeight: 400, fontSize: 12 }}
            tick={renderTick}
            tickLine={false}
            interval={0}
            tickCount={4}
            width={1}
            tickMargin={-20}
          />

          <Line
            legendType={'none'}
            isAnimationActive={false}
            dataKey={'value'}
            stroke={ChartColors.chartLine}
            activeDot={{ fill: ChartColors.chartDot, strokeWidth: 0 }}
            dot={({ cx, cy, payload }) => (
              <circle
                key={payload.date}
                r={chart.length > 16 ? 3 : chart.length > 4 ? 4 : 6}
                fill={ChartColors.chartDot}
                cx={cx}
                cy={cy}
              />
            )}
          />

          <Tooltip
            cursor={{ stroke: ChartColors.chartDot }}
            labelFormatter={(label) => formatDate(label, DATE_FULL)}
            labelStyle={{ color: ChartColors.tooltipLabel, fontSize: 12, lineHeight: '16px' }}
            wrapperStyle={{ outline: 0, boxShadow: '0px 2px 16px rgba(10, 9, 17, 0.45)', borderRadius: 8 }}
            formatter={(value) => [formatNumber(value as number), '']}
            itemStyle={{ color: ChartColors.tooltipColor, fontSize: 12, lineHeight: '16px' }}
            contentStyle={{
              display: 'flex',
              flexFlow: 'column-reverse',
              backgroundColor: ChartColors.tooltipBackground,
              border: 0,
              borderRadius: 8
            }}
            separator={''}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
