/**
 * Функция предварительной обработки строки...
 * "Левченко  Андрей   Вадимович " -> "Левченко Андрей Вадимович"
 */
export function repairFioString(sourceFio: string): string {
  let parts = sourceFio.trim().split(' ');
  // удаляем " " - то есть лишние части
  parts = parts.filter((p) => p.trim() !== '');
  let result = '';
  parts.forEach((part) => (result += `${part} `));
  return result.trim();
}
