import React from 'react';
import clsx from 'clsx';
import { TextAreaPropsWithoutRef } from '@digital-gov/ui-utils';
import { Hint, HintVariant } from 'componentsL/Hint/Hint';
import s from './TextArea.module.scss';

export interface TextAreaClasses {
  root?: string;
  input?: string;
  inputWrapper?: string;
}

export interface TextAreaProps
  extends Pick<
    TextAreaPropsWithoutRef,
    | 'className'
    | 'placeholder'
    | 'value'
    | 'disabled'
    | 'readOnly'
    | 'onChange'
    | 'onFocus'
    | 'onBlur'
    | 'onFocusCapture'
    | 'onBlurCapture'
    | 'maxLength'
    | 'rows'
  > {
  label?: string;
  classes?: TextAreaClasses;
  hint?: React.ReactNode;
  hintVariant?: HintVariant;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, classes, label, value, disabled, hint, hintVariant, ...inputProps }, inputRef) => {
    return (
      <label
        className={clsx(s.TextArea, className, classes?.root, {
          [s.TextArea_disabled]: disabled
        })}>
        {label && <span className={s.TextArea__label}>{label}</span>}
        <div className={clsx(s.TextArea__inputWrapper, classes?.inputWrapper)}>
          <textarea
            ref={inputRef}
            className={clsx(s.TextArea__input, classes?.input)}
            value={value || ''}
            disabled={disabled}
            {...inputProps}
          />
        </div>
        <Hint className={s.TextArea__hint} variant={hintVariant} disabled={disabled}>
          {hint}
        </Hint>
      </label>
    );
  }
);
