import React from 'react';
import { EMDASH, formatNumber } from '@digital-gov/ui-utils';
import s from './SmevExploitationComponents.module.scss';

type ScoreCriteria = {
  from: string | React.ReactNode;
  to?: string | React.ReactNode;
};

export interface SmevExploitationComponentProps {
  name?: string | React.ReactNode;
  index?: number;
  componentValue?: number | null;
  scoreCriteria: ScoreCriteria[];
}

export function SmevExploitationComponent({
  name,
  index,
  componentValue,
  scoreCriteria
}: SmevExploitationComponentProps) {
  const [scoreCriteria1, scoreCriteria2, scoreCriteria3, scoreCriteria4, scoreCriteria5] = scoreCriteria;

  return (
    <>
      <div className={s.SmevExploitation__cell} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span className={s.SmevExploitation__mainLetter}>K</span>
          {index}{' '}
          {name && typeof name === 'string' ? (
            `${EMDASH} ${name}`
          ) : name && typeof name === 'object' ? (
            <>
              {`${EMDASH}`} {name}
            </>
          ) : (
            ''
          )}
        </div>
        <div>
          <span className={s.SmevExploitation__value}>
            {componentValue !== undefined ? formatNumber(componentValue) : ''}
          </span>
        </div>
      </div>
      <div className={s.SmevExploitation__cell}>
        {scoreCriteria1 ? (
          <>
            {scoreCriteria1.from}
            {scoreCriteria1.to && <br />}
            {scoreCriteria1.to}
          </>
        ) : (
          EMDASH
        )}
      </div>
      <div className={s.SmevExploitation__cell}>
        {scoreCriteria2 ? (
          <>
            {scoreCriteria2.from}
            {scoreCriteria2.to && <br />}
            {scoreCriteria2.to}
          </>
        ) : (
          EMDASH
        )}
      </div>
      <div className={s.SmevExploitation__cell}>
        {scoreCriteria3 ? (
          <>
            {scoreCriteria3.from}
            {scoreCriteria3.to && <br />}
            {scoreCriteria3.to}
          </>
        ) : (
          EMDASH
        )}
      </div>
      <div className={s.SmevExploitation__cell}>
        {scoreCriteria4 ? (
          <>
            {scoreCriteria4.from}
            {scoreCriteria4.to && <br />}
            {scoreCriteria4.to}
          </>
        ) : (
          EMDASH
        )}
      </div>
      <div className={s.SmevExploitation__cell}>
        {scoreCriteria5 ? (
          <>
            {scoreCriteria5.from}
            {scoreCriteria5.to && <br />}
            {scoreCriteria5.to}
          </>
        ) : (
          EMDASH
        )}
      </div>
    </>
  );
}
