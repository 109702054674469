import React, { useCallback, useMemo } from 'react';
import { ErrorMessage } from 'componentsL/ErrorMessage';
import { Modal } from 'componentsL/Modal';
import { RatingFeedbackScope } from 'store/graphql';
import { CloseConfirmModal } from './_components/CloseConfirmModal';
import { ConfirmSendModal } from './_components/ConfirmSendModal';
import { FailModal } from './_components/FailModal';
import { FeedbackModal } from './_components/FeedbackModal';
import { SuccessModal } from './_components/SuccessModal';
import { useSender } from './_hooks/useSender';
import { useViewState, ViewState } from './_hooks/useViewState';
import { FeedbackFormController } from './controllers/feedbackFormController';
import { ProposalFormController } from './controllers/proposalFormController';
import { FeedbackContext } from './feedback-context';
import { TabIndex } from './tab-index';

export interface IndicatorFeedbackProps {
  feedbackAvailable?: boolean;
  proposalAvailable?: boolean;
  scope: RatingFeedbackScope;
  scopeId: number;
  indicatorId?: number;
  // Обращение успешно отправлено
  onSent?: (indicatorId: number | void) => void;
  onClose: () => void;
}

export const IndicatorFeedback = ({
  feedbackAvailable = true,
  proposalAvailable = true,
  scope,
  scopeId,
  indicatorId,
  onSent,
  onClose
}: IndicatorFeedbackProps) => {
  const [state, toState] = useViewState();

  const context = useMemo<FeedbackContext>(
    () => ({
      activeTabIndex: TabIndex.Indicator,
      scope,
      scopeId,
      indicatorId,
      feedbackForm: new FeedbackFormController<number>(),
      proposalForm: new ProposalFormController<number>()
    }),
    [scope, scopeId, indicatorId]
  );

  //<editor-fold desc="[Методы переключения состоянием]">
  const toInputState = toState(ViewState.Input);
  const toConfirmClose = toState(ViewState.CloseConfirm);
  const toConfirmFeedbackSend = toState(ViewState.FeedbackSendingConfirm);
  const toFeedbackSentSuccess = toState(ViewState.FeedbackSentSuccess);
  const toFeedbackSentFailed = toState(ViewState.FeedbackSentFailed);
  const toProposalSentSuccess = toState(ViewState.ProposalSentSuccess);
  const toProposalSentFailed = toState(ViewState.ProposalSentFailed);
  //</editor-fold>

  // Подтверждение закрытия формы ОС
  const handleConfirmClose = useCallback(() => {
    context.feedbackForm.uploadManager.breakAll();
    context.proposalForm.uploadManager.breakAll();
    if (onClose) {
      onClose();
    }
  }, [context, onClose]);

  // Закрытия формы ОС
  const handleFormClose = useCallback(
    (isEmpty: boolean) => {
      // Если форма не пуста - запрашиваем подтверждение
      if (isEmpty) {
        onClose();
      } else {
        toConfirmClose();
      }
    },
    [toConfirmClose, onClose]
  );

  // Отправка
  const handleSendFeedback = useSender({
    context,
    form: context.feedbackForm,
    scope:
      context.scope === RatingFeedbackScope.DepartmentIndicator || context.scope === RatingFeedbackScope.Department
        ? RatingFeedbackScope.DepartmentIndicator
        : RatingFeedbackScope.RegionIndicator,
    onSuccess: (feedbackIndicatorId) => {
      // Сбрасываем выбранный индикатор
      context.selectedIndicatorId = void 0;
      onSent?.(feedbackIndicatorId);
      toFeedbackSentSuccess();
    },
    onFailed: toFeedbackSentFailed
  });

  const handleSendProposal = useSender({
    context,
    form: context.proposalForm,
    scope:
      context.scope === RatingFeedbackScope.Department ? RatingFeedbackScope.Department : RatingFeedbackScope.Region,
    onSuccess: (feedbackIndicatorId) => {
      // Сбрасываем выбранный индикатор
      context.selectedIndicatorId = void 0;
      onSent?.(feedbackIndicatorId);
      toProposalSentSuccess();
    },
    onFailed: toProposalSentFailed
  });

  if (!proposalAvailable && !feedbackAvailable) {
    return <ErrorMessage title="Ошибка" description="Недопустимое состояние" />;
  }

  // Подтверждение закрытия формы ОС
  if (state === ViewState.CloseConfirm) {
    return <CloseConfirmModal onConfirm={handleConfirmClose} onBack={toInputState} />;
  }

  // Уведомление об ошибке отправки ОС или Предложения
  if (state === ViewState.FeedbackSentFailed || state === ViewState.ProposalSentFailed) {
    return <FailModal onClose={toInputState} onBack={toInputState} />;
  }

  // Уведомление об успешной отправке ОС или Предложения
  if (state === ViewState.FeedbackSentSuccess || state === ViewState.ProposalSentSuccess) {
    return <SuccessModal onClose={onClose} onContinue={onClose} />;
  }

  // Подтверждение отправки ОС
  if (state === ViewState.FeedbackSendingConfirm) {
    return <ConfirmSendModal onConfirm={handleSendFeedback} onBack={toInputState} />;
  }

  if (state === ViewState.Input) {
    return (
      <FeedbackContext.Provider value={context}>
        <FeedbackModal
          feedbackAvailable={feedbackAvailable}
          proposalAvailable={proposalAvailable}
          onSendFeedback={toConfirmFeedbackSend}
          onSendProposal={handleSendProposal}
          onClose={handleFormClose}
        />
      </FeedbackContext.Provider>
    );
  }

  return <Modal onClose={onClose}>Неизвестная ошибка</Modal>;
};
