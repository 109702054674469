import React from 'react';
import s from './Symbol.module.scss';

export interface SymbolProps {
  sub?: string;
  up?: string;
  clearBackground?: boolean;
  clearPadding?: boolean;
  square?: boolean;
  fixedCircle?: boolean;
  children: string;
  color?: string;
  styleUp?: React.CSSProperties;
  styleSub?: React.CSSProperties;
}

export function Symbol({
  sub,
  up,
  square,
  clearBackground,
  clearPadding,
  fixedCircle,
  children,
  color,
  styleUp,
  styleSub
}: SymbolProps) {
  return (
    <div
      className={s.Symbol}
      style={{
        background: clearBackground ? 'inherit' : undefined,
        padding: fixedCircle ? 10 : clearPadding ? 0 : undefined,
        borderRadius: square ? 'unset' : undefined
      }}>
      <span className={s.Symbol__text} style={{ color: `${color}` }}>
        {children}
      </span>
      {sub && (
        <div className={s.Symbol__sub} style={styleSub}>
          {sub}
        </div>
      )}
      {up && (
        <div className={s.Symbol__up} style={styleUp}>
          {up}
        </div>
      )}
    </div>
  );
}
