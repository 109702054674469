import React from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal } from 'componentsL/Modal';
import s from './CommentError.module.scss';

export interface CommentErrorProps {
  onBack: () => void;
}

export function CommentError({ onBack }: CommentErrorProps) {
  return (
    <Modal title={'Ошибка'} width={456} onClose={onBack}>
      <div className={s.CommentError__text}>
        При отправке данных произошла ошибка.<br></br>
        Попробуйте повторить операцию через какое-то время.
      </div>
      <div className={s.CommentError__button}>
        <Button variant={ButtonVariant.Secondary} onClick={onBack}>
          Назад
        </Button>
      </div>
    </Modal>
  );
}
