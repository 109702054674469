import React, { useCallback } from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { IconAttachment } from 'componentsL/Icon';

export interface FileInputProps {
  /**
   *  Перечисление допустимых типов файлов:
   *  Строка вида ".jpg,.png"
   *  Массив строк вида ['.jpg', '.png'] - точки обязательны
   * Также допустимо указывать MIME-типы: "text/*,application/msword" или ["text/*", "application/msword"]
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types         Список MIMETypes
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers Док по использованию поля `accept`
   */
  accept?: string | string[];
  // Можно ли одновременно загружать несколько файлов
  multiple?: boolean;
  disabled?: boolean;
  onSelect: (files: File[]) => void;
}

export const FileInput = ({ accept, multiple, disabled, onSelect }: FileInputProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        return;
      }

      onSelect(Array.from(event.target.files));

      // Сбрасываем выбранное значение, чтобы не мешало
      event.target.value = '';
    },
    [onSelect]
  );

  return (
    <Button Component="label" iconLeft={IconAttachment} variant={ButtonVariant.LinkSecondary} disabled={disabled}>
      <input
        type="file"
        style={{ display: 'none' }}
        accept={accept instanceof Array ? accept.join(',') : accept}
        multiple={multiple}
        onChange={handleChange}
      />
      Прикрепить файл
    </Button>
  );
};
