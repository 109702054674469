import { DepartmentFeedbackType, FileType, RegionFeedbackType } from 'store/graphql';

function convertFeedbackAttachmentType(attachment: FileType): Attachment {
  return {
    id: attachment.id,
    fileName: attachment.fileName,
    fileType: attachment.fileType,
    fullName: attachment.fullName,
    size: attachment.size
  };
}

function convertFeedbackType(feedback: DepartmentFeedbackType | RegionFeedbackType): Feedback {
  return {
    id: feedback.feedbackId,
    publisher: feedback.publisher,
    publisherId: feedback.publisherId!,
    publishDate: feedback.publishDate,
    indicatorId: feedback.indicatorId ?? null,
    indicatorName: feedback.indicatorName ?? null,
    subject: feedback.subject,
    commentary: feedback.commentary,
    attachments: feedback.attachments.map(convertFeedbackAttachmentType)
  };
}

export function normalizeFeedbackList(list?: DepartmentFeedbackType[] | RegionFeedbackType[] | null): Feedback[] {
  return list?.map((feedback) => convertFeedbackType(feedback)) ?? [];
}
