import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { formatNumberIntl } from '@digital-gov/ui-utils';
import { Tooltip } from 'componentsL/Tooltip';
import { TooltipProps } from 'componentsL/Tooltip/Tooltip';
import { RatingGroupEnum } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { fullNameInitials } from 'utils/fullNameInitials';
import { RatingDataType } from '../../types';
import s from './RatingItem.module.scss';

interface TooltipPropertyProps {
  label: string;
  value: string | number;
}

const TooltipProperty = ({ label, value }: TooltipPropertyProps) => {
  return (
    <div className={s.TooltipProperty}>
      <span className={s.TooltipProperty__label}>{label}</span>{' '}
      <span className={s.TooltipProperty__value}>– {value}</span>
    </div>
  );
};

interface RatingTooltipProps
  extends Pick<TooltipProps, 'targetNode'>,
    Pick<RatingDataType, 'vpctSum' | 'degree' | 'rct'> {
  label?: React.ReactNode;
}

const RatingTooltip = ({ targetNode, label, vpctSum, degree, rct }: RatingTooltipProps) => {
  return (
    <Tooltip
      className={s.RatingItem__tooltip}
      targetNode={targetNode}
      tooltipOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          }
        ]
      }}>
      <div className={s.Tooltip}>
        <div className={s.Tooltip__name}>{label}</div>
        <div className={s.Tooltip__content}>
          {rct && <TooltipProperty label={'РЦТ'} value={fullNameInitials(rct)} />}
          <TooltipProperty
            label={'Бюджет'}
            value={formatNumberIntl(vpctSum, {
              notation: 'compact'
            })}
          />
          <TooltipProperty
            label={'Степень достижения'}
            value={formatNumberIntl(degree, {
              minimumFractionDigits: 2
            })}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export enum RatingLabelPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right'
}

export type RatingItemContentNodesType = {
  innerNode: HTMLDivElement;
  dotNode: HTMLDivElement;
  labelNode: HTMLDivElement;
};

export interface RatingItemProps extends RatingDataType {
  className?: string;
  label?: React.ReactNode;
  labelPosition?: RatingLabelPosition;
  absolute?: boolean;
  completeness?: RatingGroupEnum;
  selected?: boolean;
  filtered?: boolean;
  onMount?: (nodes: RatingItemContentNodesType) => void;
}

export const RatingItem = ({
  className,
  label,
  labelPosition = RatingLabelPosition.Right,
  departmentId,
  degree,
  ratingGroup,
  vpctSum,
  isCurrentDepartment,
  rct,
  absolute,
  selected,
  filtered,
  onMount
}: RatingItemProps) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef<Partial<RatingItemContentNodesType>>({
    innerNode: undefined,
    dotNode: undefined,
    labelNode: undefined
  });

  const { isFRCT, isObserver } = useProfile();
  const isLink = (isFRCT && isCurrentDepartment) || isObserver;

  useEffect(() => {
    if (Object.values(contentRef.current).every(Boolean)) {
      onMount?.(contentRef.current as RatingItemContentNodesType);
    }
  }, [onMount]);

  const props = {
    className: clsx(s.RatingItem__button, {
      [s.RatingItem__button_link]: isLink,
      [s.RatingItem__button_current]: isCurrentDepartment,
      [s.RatingItem__button_selected]: selected,
      [s.RatingItem__button_filtered]: filtered
    }),
    onMouseMove: () => setActive(true),
    onMouseLeave: () => setActive(false)
  };

  const linkProps = { ...props, to: `/department/${departmentId}` };
  const content = (
    <div
      ref={(r) => (contentRef.current.innerNode = r || undefined)}
      className={clsx(s.RatingItem__inner, s[`RatingItem__inner_${labelPosition}`])}>
      <div ref={(r) => (contentRef.current.dotNode = r || undefined)} className={s.RatingItem__dot} />
      <div ref={(r) => (contentRef.current.labelNode = r || undefined)} className={s.RatingItem__label}>
        {label}
      </div>
    </div>
  );

  return (
    <div
      className={clsx(s.RatingItem, s[`RatingItem_${ratingGroup.toLowerCase()}`], className, {
        [s.RatingItem_absolute]: absolute
      })}>
      {isLink ? <Link {...linkProps}>{content}</Link> : <div {...props}>{content}</div>}
      {active && (
        <RatingTooltip
          label={label}
          targetNode={contentRef.current.dotNode || null}
          vpctSum={vpctSum}
          degree={degree}
          rct={rct}
        />
      )}
    </div>
  );
};

export interface RatingListItemProps extends RatingDataType {
  className?: string;
  label?: React.ReactNode;
  selected?: boolean;
  filtered?: boolean;
}

export const RatingListItem = ({
  className,
  label,
  vpctSum,
  degree,
  departmentId,
  isCurrentDepartment,
  rct,
  ratingGroup,
  selected,
  filtered
}: RatingListItemProps) => {
  const [active, setActive] = useState(false);
  const itemRef = useRef<HTMLAnchorElement | HTMLDivElement | null>(null);

  const { isFRCT, isObserver } = useProfile();
  const isLink = (isFRCT && isCurrentDepartment) || isObserver;

  const props = {
    ref: itemRef as React.MutableRefObject<HTMLDivElement>,
    className: clsx(s.RatingListItem, s[`RatingListItem_${ratingGroup.toLowerCase()}`], className, {
      [s.RatingListItem_current]: isCurrentDepartment,
      [s.RatingListItem_selected]: selected,
      [s.RatingListItem_filtered]: filtered
    }),
    onMouseMove: () => setActive(true),
    onMouseLeave: () => setActive(false)
  };

  const linkProps = {
    ...props,
    ref: itemRef as React.MutableRefObject<HTMLAnchorElement>,
    to: `/department/${departmentId}`
  };

  const content = (
    <React.Fragment>
      <div className={s.RatingListItem__label}>{label}</div>
      {active && (
        <RatingTooltip label={label} targetNode={itemRef.current} vpctSum={vpctSum} degree={degree} rct={rct} />
      )}
    </React.Fragment>
  );

  return isLink ? <Link {...linkProps}>{content}</Link> : <div {...props}>{content}</div>;
};
