import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PageHeader } from 'componentsL/PageHeader';
import { ScopeSwitch } from 'componentsL/ScopeSwitch';
import { ScopeEnum, ScopeTabType } from 'componentsL/ScopeSwitch/ScopeSwitch';

const FaqTabs: ScopeTabType<ScopeEnum>[] = [
  {
    label: 'ФРЦТ',
    value: ScopeEnum.Frct,
    path: '/faq/frct'
  },
  {
    label: 'РРЦТ',
    value: ScopeEnum.Rrct,
    path: '/faq/rrct'
  }
];

export const FaqPage = () => {
  const { pathname } = useLocation();
  const currentScope = pathname === '/faq/frct' ? ScopeEnum.Frct : ScopeEnum.Rrct;
  return (
    <>
      <PageHeader
        backTo={'prevPage'}
        title={`Вопросы и ответы`}
        pageTitle={'Вопросы и ответы'}
        slotRight={<ScopeSwitch scope={currentScope} scopeTabs={FaqTabs} />}
      />

      <Outlet />
    </>
  );
};
