import React from 'react';
import clsx from 'clsx';
import { Icon, IconGraphView, IconMapView, IconTableView } from 'componentsL/Icon';
import { ViewMode } from '../types';
import s from './ChangeModeBlock.module.scss';

export type ChangeModeBlockProps = {
  className?: string;
  mode: ViewMode;
  onSetMode: (mode: ViewMode) => void;
};

export const ChangeModeBlock = ({ className, mode, onSetMode }: ChangeModeBlockProps) => {
  return (
    <div className={clsx(s.ChangeModeBlock, className)}>
      <Icon
        className={clsx(s.ChangeModeBlock__icon, {
          [s.ChangeModeBlock__iconActive]: mode === 'table-full'
        })}
        size={22}
        icon={IconGraphView}
        onClick={() => onSetMode('table-full')}
      />

      {/* TODO: ОБНОВИТЬ ИКОНКУ НА ПРАВИЛЬНЫЕ РАЗМЕРЫ (из ДС) */}
      <Icon
        className={clsx(s.ChangeModeBlock__icon, {
          [s.ChangeModeBlock__iconActive]: mode === 'map'
        })}
        size={22}
        icon={IconMapView}
        onClick={() => onSetMode('map')}
      />

      <Icon
        className={clsx(s.ChangeModeBlock__icon, {
          [s.ChangeModeBlock__iconActive]: mode === 'table-compact'
        })}
        size={22}
        icon={IconTableView}
        onClick={() => onSetMode('table-compact')}
      />
    </div>
  );
};
