import React from 'react';
import clsx from 'clsx';
import { ErrorMessage } from './ErrorMessage';
import s from './PageError.module.scss';

interface PageErrorProps {
  className?: string;
  title?: string;
  description?: React.ReactNode;
}

export function PageError({ className, description, title }: PageErrorProps) {
  return (
    <div className={clsx(s.PageError, className)}>
      <ErrorMessage title={title || 'Не удалось получить данные'} description={description} />
    </div>
  );
}
