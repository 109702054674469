import { FilesController } from './filesController';

export class FeedbackFormController<FileIdType> {
  public comment: string = '';
  private readonly filesController: FilesController<FileIdType>;

  constructor() {
    this.filesController = new FilesController<FileIdType>();
  }

  get uploadManager() {
    return this.filesController.uploadManager;
  }

  // Возвращает true - если форму нельзя отправить
  get disabled(): boolean {
    return this.comment.trim().length === 0 || this.filesController.inProgress;
  }

  get isEmpty(): boolean {
    return this.comment.trim().length === 0 && this.filesController.isEmpty;
  }

  get files() {
    return this.filesController.files;
  }

  clean() {
    this.filesController.clean();
  }
}
