import Mark from 'mark.js';

export const MarkerTag = 'mark';

export type SearchMatchingProcess = (hasMatch: boolean) => void;

const MarkOptions: Mark.MarkOptions = { element: MarkerTag, acrossElements: true, exclude: [MarkerTag] };
const TokenOptions: Mark.MarkOptions = { ...MarkOptions, separateWordSearch: false };
const WordsOptions: Mark.MarkOptions = { ...MarkOptions, separateWordSearch: true };

type ElementSelector = string;

export function faqSearch(
  dom: HTMLElement | HTMLElement[] | ElementSelector | NodeList,
  query: string,
  onEach?: (mark: HTMLElement) => void
): number {
  const marker = new Mark(dom);
  let foundNumber: number = 0;

  const matchDone = (marksTotal: number, ...args: any[]) => {
    foundNumber += marksTotal;
  };

  const fnEach = (mark: HTMLElement) => onEach && onEach(mark);

  const tokenOptions = { ...TokenOptions, done: matchDone, each: fnEach };
  const wordsOptions = { ...WordsOptions, done: matchDone, each: fnEach };

  marker.markRegExp(new RegExp(query.replaceAll(/\s+/g, '\\s+'), 'gi'), tokenOptions);

  // Если запрос найден полностью, части не ищем
  if (foundNumber > 0) {
    return foundNumber;
  }

  const parts = query.split(/\s+/);

  // Если в запросе нет и 2-х слов, то оно уже обработано выше и, если сюда дошло, ничего не найдено.
  if (parts.length < 2) {
    return 0;
  }

  // Входную строку будем разбивать на токены из последовательных слов
  const tokens = new Set<string>();

  let words = parts.length - 1;

  while (words > 1) {
    // Максимальное смещение от 0-го индекса, для объединения слов (в кол-ве words) в токен
    const maxShift = parts.length - words;

    for (let i = 0; i <= maxShift; ++i) {
      tokens.add(parts.slice(i, i + words).join(' '));
    }

    words--;
  }

  tokens.forEach((token) => {
    const regex = new RegExp(`\\b${token.replaceAll(' ', '\\s+')}\\b`, 'gi');
    marker.markRegExp(regex, tokenOptions);
  });

  marker.mark(query, wordsOptions);

  return foundNumber;
}

export function faqSearchReset(dom: HTMLElement | HTMLElement[] | ElementSelector | NodeList) {
  new Mark(dom).unmark();
}
