import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const irrelevantAccrualsShare = components[0]?.value;
  const uniqIdsFor30Days = components[1]?.value;
  const uniqIdsTotal = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={irrelevantAccrualsShare}
        unit={'%'}
        description={'Значение показателя процент актуальных начислений на ЕПГУ'}>
        <Symbol sub={'f'}>P</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={uniqIdsFor30Days}
        description={
          <>
            Количество уникальных идентификаторов начислений ведомства, оплаченных посредством ЕПГУ и квитированных{' '}
            <br />в течение 30 календарных дней
          </>
        }>
        <Symbol sub={'p'}>P</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={uniqIdsTotal}
        description={'Общее количество уникальных идентификаторов начислений ведомства, оплаченных посредством ЕПГУ'}>
        <Symbol sub={'n'}>P</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Процент актуальных начислений на ЕПГУ составляет 0-50%']} />
      <TableRow values={['0,5', 'Процент актуальных начислений на ЕПГУ составляет 51-70%']} />
      <TableRow values={['1,0', 'Процент актуальных начислений на ЕПГУ составляет 71-85%']} />
      <TableRow values={['1,5', 'Процент актуальных начислений на ЕПГУ составляет 86-98%']} />
      <TableRow values={['2,0', 'Процент актуальных начислений на ЕПГУ составляет более 98%']} />
    </Table>
  );
}

export function RelevantAccruals() {
  return (
    <IndicatorPage
      download={'Процент актуальных начислений на ЕПГУ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
