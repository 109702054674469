import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const executiveDocumentsScore = components[0]?.value;
  const documentsDirectedToFssp = components[1]?.value;
  const courtSitesCount = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={executiveDocumentsScore}
        description={
          <>
            Значение показателя «Взвешенное количество исполнительных документов, направляемых мировыми судьями субъекта
            Российской Федерации в ФССП России в электронном виде» <br />
            для i-го субъекта Российской Федерации
          </>
        }>
        <Symbol sub={'i'}>I</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={documentsDirectedToFssp}
        roundUp
        unit={'шт.'}
        description={
          'Количество документов, направленных судебными участками мировых судей i-го субъекта Российской Федерации и полученных ФССП России в электронном виде'
        }>
        <Symbol sub={'i'}>E</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={courtSitesCount}
        roundUp
        unit={'шт.'}
        description={
          'Количество судебных участков мировых судей i-го субъекта Российской Федерации (согласно статье 1 Федерального закона от 29 декабря 1999г. № 218-ФЗ «Об общем числе мировых судей и количестве судебных участков в субъектах Российской Федерации»)'
        }>
        <Symbol sub={'i'}>J</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,5', '0 < Pi ≤ N/4']} />
      <TableRow values={['1,0', 'N/4 < Pi ≤ N/2']} />
      <TableRow values={['1,5', 'N/2 < Pi ≤ 3 N/4']} />
      <TableRow values={['2,0', '3 N/4 ≤ N']} />
    </Table>
  );
}

export function ExecutiveDocumentsDirections() {
  return (
    <IndicatorPage
      regionScope
      download={'Направление исп. документов в электронном виде.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
