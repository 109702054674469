import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { formatFileSize } from '@digital-gov/ui-utils';
import { Tooltip } from 'componentsL/Tooltip';
import s from '../AttachmentItem.module.scss';
import s2 from './AttachmentLoadingItem.module.scss';

const units = formatFileSize.units.size.slice(1);

export interface AttachmentItemProps {
  filename: string;
  size: number;
}

export const AttachmentLoadingItem = ({ filename, size }: AttachmentItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const mouseEnter = useCallback(() => setIsHovered(true), [setIsHovered]);
  const mouseLeave = useCallback(() => setIsHovered(false), [setIsHovered]);

  const kbSize = size * 1024;

  return (
    <>
      {isHovered && (
        <Tooltip
          targetNode={ref.current}
          tooltipOptions={{
            placement: 'top',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 12]
                }
              }
            ]
          }}>
          {filename}
        </Tooltip>
      )}
      <div
        className={clsx(s.AttachmentItem, isHovered && s.AttachmentItem_hovered)}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}>
        <div className={s.AttachmentItem__icon}>
          <div className={s2.AttachmentLoadingItem}>
            <div className={s2.AttachmentLoadingItem__spinner} />
          </div>
        </div>

        <div className={s.AttachmentItem__filename}>
          <span ref={ref}>{filename}</span>
        </div>

        <div className={s.AttachmentItem__size}>
          {kbSize > 0 ? formatFileSize(kbSize, units, 1024) : '< ' + formatFileSize(1, units)}
        </div>
      </div>
    </>
  );
};
