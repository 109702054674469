import { orderBy, sum, uniq } from 'lodash-es';
import { RatingGroupEnum } from 'store/graphql';
import {
  budgetGroups,
  departmentChartMinHeight,
  departmentChartFontFamily as FontFamily,
  ratingGroups,
  ratingGroupsWithText
} from '../constants';
import { DepartmentChartUtils } from '../DepartmentChartUtils';
import { RatingDataGroupsType, RatingDataType } from '../types';
import { DataCellSize, DataCellSizesByGroups } from './tableLimitations.types';

/**
 * Функция определения размеров ячеек таблицы ФРЦТ
 *
 * Для каждой ячейки данных (т.е. набора ФОИВ-ов распределённых по {эффективности:бюджету}) определяется:
 * - ширина
 * - высота,
 * - кол-во элементов для разделения по колонкам внутри ячейки
 */
export function getDepartmentsTableLimitations(data: RatingDataGroupsType, rowHeightInPx = 20): DataCellSizesByGroups {
  // Маппинг данных в формат списка - на каждую комбинацию {эффективность:бюджет} свой список ФОИВ-ов
  // всего будет 12 элементов
  const dataGroupsAsList = new Map<string, RatingDataType[]>();
  for (const ratingGroup of ratingGroups) {
    budgetGroups.forEach((_, index) => {
      dataGroupsAsList.set(`${ratingGroup}_${index}`, data[ratingGroup][index] ?? []);
    });
  }

  // определение площади занимаемой ячейкой
  // вычисляется как сумма площадей занимаемых названиями ФОИВ-ов (ширина и высота в пикселях) внутри ячейки
  let dataGroupsSquares: { key: string; square: number }[] = [];
  dataGroupsAsList.forEach((ratingData, key) => {
    const squares = ratingData.map((department) => {
      const sizes = measureTextSquare(department.departmentName, 14);
      return sizes ? sizes.height * sizes.width : 0;
    });
    const square = sum(squares);
    dataGroupsSquares.push({
      key,
      square
    });
  });

  // Сортировка полученных площадей по убыванию - заполнение ограничений происходит от меньшей площади к большей
  // (т.е. сначала заполняются минимально необходимые пространства, а затем большие подстраиваются)
  dataGroupsSquares = orderBy(dataGroupsSquares, ['square'], ['asc']);

  // определение размеров ячеек (результат данной функции)
  // обход идёт от меньшей по площади ячейки к большей
  const dataCellSizes = new Map<string, DataCellSize>();
  for (const squareByGroup of dataGroupsSquares) {
    const currentGroupKey = squareByGroup.key;

    // получение доступной высоты
    const availableHeight = getAvailableHeight(currentGroupKey, dataCellSizes);

    // делитель по колонкам:
    // доступную для ячейки высоту делит по высоте одной строки (если не передана - то считает за 1)
    const divider = Math.floor(availableHeight / (rowHeightInPx === 0 ? 1 : rowHeightInPx));

    // построение ограничений (результат для ячейки данных)
    const dataByGroup = dataGroupsAsList.get(currentGroupKey)!;
    let groupWidth = 0;
    let groupHeight = 0;
    let elementsPerColumn = 0;
    // в случае если ячейка пустая (нет ФОИВ-ов) - ограничениями выступает название группы по высоте и "бюджет" по ширине
    if (dataByGroup.length === 0) {
      const [ratingGroup, budgetGroupIndex] = currentGroupKey.split('_');
      groupWidth =
        (measureTextSquare(DepartmentChartUtils.getBudgetGroupLabel(budgetGroups[+budgetGroupIndex]), 12)?.width ?? 0) +
        20;
      groupHeight =
        measureTextSquare(ratingGroupsWithText.get(ratingGroup as RatingGroupEnum)!, 12, true)?.height ?? 50;
      elementsPerColumn = 1;
    }
    // в случае, если в ячейке есть ФОИВ-ы, то происходит заполнение ФОИВ-ами доступного пространства
    else {
      const maxWidthInGroup = getMaxWidthInPx(
        dataByGroup.map((d) => d.departmentName),
        20
      );
      // одна колонка
      if (dataByGroup.length <= divider) {
        groupWidth = maxWidthInGroup;
        groupHeight = dataByGroup.length * rowHeightInPx;
        elementsPerColumn = dataByGroup.length;
      }
      // множество колонок
      else {
        // получение уже заполненных ячеек в рамках той-же группы - они могут повлиять на высоту колонки
        const columnCount = Math.ceil(dataByGroup.length / divider);
        groupHeight = Math.ceil((dataByGroup.length * rowHeightInPx) / columnCount);
        groupWidth = maxWidthInGroup * columnCount;
        elementsPerColumn = divider;
      }
    }

    // запись в Map (сохранение результата по ячейке)
    dataCellSizes.set(currentGroupKey, {
      rowHeight: groupHeight,
      columnWidth: groupWidth,
      elementsPerColumn
    });
  }
  return {
    sizes: dataCellSizes
  };
}

/**
 * Функция получения доступной высоты для определения размеров ячейки
 * - изначально доступна вся высота таблицы (departmentChartMinHeight)
 * - учитывает уже существующие ограничения, заданные другими ячейками (dataCellSizes)
 *
 * Логика следующая:
 * - из уже определённых ограничений (dataCellSizes) забираются ограничения по высоте
 * (не касается текущей группы рейтинга)
 * - из полученного списка ограничений забирается максимальное на группу, т.е.
 *
 * Пример:
 * - у "высокой" группы соответствующие высоты ячеек - 120,240,300,360 - максимальной будет 360
 * - у "средней" группы - 100, 50, 100, 150 - максимальной будет 150
 *
 * итого общее ограничение по высоте будет разницей: общая_высота_таблицы - (360+150)
 */
export function getAvailableHeight(currentGroupKey: string, dataCellSizes: Map<string, DataCellSize>): number {
  let availableHeight = departmentChartMinHeight;
  const heightLimitPerRatingGroup: { ratingGroup: string; limit: number }[] = [];
  dataCellSizes.forEach((value, key) => {
    const [_ratingGroup, _] = key.split('_');
    const [currentRatingGroup, __] = currentGroupKey.split('_');
    if (currentRatingGroup !== _ratingGroup) {
      heightLimitPerRatingGroup.push({
        ratingGroup: _ratingGroup,
        limit: value.rowHeight
      });
    }
  });
  let totalHeightLimit = 0;
  const limitGroups = uniq(heightLimitPerRatingGroup.map((hl) => hl.ratingGroup));
  limitGroups.forEach((group) => {
    const limitsByGroup = heightLimitPerRatingGroup.filter((hl) => hl.ratingGroup === group);
    totalHeightLimit += limitsByGroup.length ? Math.max(...limitsByGroup.map((hl) => hl.limit)) : 0;
  });
  availableHeight -= totalHeightLimit;
  return availableHeight;
}

/**
 * Определение площади для текста (
 * - text - сам текст
 * - fontSize - размер шрифта в пикселях
 * - inverse - флаг, означающий что текст будет повёрнут на 90 градусов, что означает инверсию полученных
 * "ширины" и "высоты"
 */
export function measureTextSquare(
  text: string,
  fontSize: number,
  inverse?: boolean
): { width: number; height: number } | undefined {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  function measure(_text: string, _fontSize: number) {
    if (!ctx) {
      return undefined;
    }
    ctx.font = `${_fontSize}px ${FontFamily}`;
    return {
      width: !inverse ? ctx.measureText(_text).width : _fontSize,
      height: !inverse ? _fontSize : ctx.measureText(_text).width
    };
  }

  return measure(text, fontSize);
}

/**
 * Определение максимальной ширины в пикселях из массива строк (названий ФОИВ-ов)
 * - используется для определения ширины колонки
 */
export function getMaxWidthInPx(values: string[], additionalColumnWith = 20): number {
  const results = values
    .map((v) => measureTextSquare(v, 14)?.width ?? 0)
    .map((v) => (v !== 0 ? v + additionalColumnWith : 0));
  return Math.max(...results);
}
