import { ActionModal } from 'components/Modal';

export interface RctAddedProps {
  handleForward: () => void;
  handleClose: () => void;
}

export function RctChanged({ handleForward, handleClose }: RctAddedProps) {
  return (
    <ActionModal
      title={'Изменение РЦТ'}
      message={'РЦТ был успешно изменен'}
      width={624}
      onClose={handleClose}
      actions={[
        {
          name: 'Продолжить',
          onAction: handleForward
        }
      ]}
    />
  );
}
