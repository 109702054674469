import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './InformationSecurity.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const informationSecurityScore = components[0]?.value;
  const c1DepartmentInformationSecurty = components[1]?.value;
  const c2ResponsibleUnit = components[2]?.value;
  const c3AdditionalEvents = components[3]?.value;
  const c4IndependentAnalytics = components[4]?.value;
  const c5ImportIndependence = components[5]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={informationSecurityScore} description={'Значение показателя «Информационная безопасность»'}>
        <Symbol>ИБ</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={c1DepartmentInformationSecurty}
        description={'Возложение полномочий по обеспечению ИБ на заместителя руководителя органа'}>
        <Symbol sub={'1'}>ПП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={c2ResponsibleUnit}
        description={
          'В органе создано структурное подразделение, осуществляющее функции по обеспечению ИБ, либо данные функции возложены на существующее структурное подразделение'
        }>
        <Symbol sub={'2'}>ПП</Symbol>
      </SymbolDetails>
      <SymbolDetails value={c3AdditionalEvents} description={'Дополнительные мероприятия по обеспечению ИБ'}>
        <Symbol sub={'3'}>ПП</Symbol>
      </SymbolDetails>
      <SymbolDetails value={c4IndependentAnalytics} description={'Независимый анализ'}>
        <Symbol sub={'4'}>ПП</Symbol>
      </SymbolDetails>
      <SymbolDetails value={c5ImportIndependence} description={'Обеспечение импортозамещения'}>
        <Symbol sub={'5'}>ПП</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <>
      <p className={s.InformationSecurity__calc_p}>
        <span style={{ paddingBottom: '6px' }}>
          Оценка работы субъекта Российской Федерации проводится путем сложения значений подпоказателей
        </span>
        Максимальное количество баллов по показателю - 6
      </p>
      <Table>
        <TableRow values={['Оценка, балл', 'Критерий']} isHeader />
        <TableRow values={['0,7', 'Возложение полномочий по обеспечению ИБ на заместителя руководителя органа']} />
        <TableRow
          values={[
            '2,0',
            'В органе создано структурное подразделение, осуществляющее функции по обеспечению ИБ, либо данные функции возложены на существующее структурное подразделение'
          ]}
        />
        <TableRow values={['1,5', 'Дополнительные мероприятия по обеспечению ИБ']} />
        <TableRow values={['1,4', 'Независимый анализ']} />
        <TableRow values={['0,4', 'Обеспечение импортозамещения']} />
      </Table>
    </>
  );
}

export function InformationSecurity() {
  return (
    <IndicatorPage
      regionScope
      download={'Информационная безопасность.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
