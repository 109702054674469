var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ApolloProvider, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthContext } from '../auth/AuthContext';
import { apolloClient } from './apolloClient';
export class ApolloClientProvider extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.apolloClient = apolloClient;
        // preserveHeaderCase – ради X-Authority
        const httpLink = new HttpLink({ uri: props.apiUrl, preserveHeaderCase: true });
        const authLink = setContext((_1, _a) => __awaiter(this, [_1, _a], void 0, function* (_, { headers }) {
            const accessToken = yield context.getToken();
            if (!accessToken)
                throw Error('no accessToken provided');
            return { headers: Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${accessToken}` }) };
        }));
        apolloClient.setLink(from([authLink, httpLink]));
    }
    render() {
        return React.createElement(ApolloProvider, { client: this.apolloClient }, this.props.children);
    }
}
// TODO: сбрасывать apolloClient (clearStore?) в случае выхода
ApolloClientProvider.contextType = AuthContext;
