import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg';
import { Nav } from './Nav';
import { PeriodMenu } from './PeriodMenu';
import { UserMenu } from './UserMenu';
import s from './TopBar.module.scss';

export interface TopBarProps {
  accessToPeriodMenu?: boolean;
  scope: 'rrct' | 'frct';
}

export function TopBar({ accessToPeriodMenu, scope }: TopBarProps) {
  const { pathname } = useLocation();

  return (
    <div className={s.TopBar}>
      <div className={s.TopBar__box}>
        <Link to={pathname.startsWith('/region/') ? '/regions' : '/'} className={s.TopBar__logo}>
          <Logo />
        </Link>
        <UserMenu />
        {accessToPeriodMenu && <PeriodMenu scope={scope} />}
        <Nav />
      </div>
    </div>
  );
}
