import React from 'react';
import { Outlet } from 'react-router-dom';
import { AlertRatingFeedback } from 'componentsL/alert';
import { ServiceWork } from 'componentsL/alert/ServiceWork';
import { useProfile } from 'store/profile/useProfile';
import { Footer } from './Footer/Footer';
import { Loading } from './Loading';
import { ProfileError } from './ProfileError';
import { TopBar } from './TopBar';
import s from './UserLayout.module.scss';

interface UserLayoutProps {
  showServiceWork?: boolean;
  showTopBar?: boolean;
  showPeriodSelectControl?: boolean;
  isLoggedIn?: boolean;
  accessToPeriodMenu?: boolean;
  isCurrentPeriod?: boolean;
  scope?: 'rrct' | 'frct';
}

function UserLayoutComponent({
  showServiceWork = false,
  showTopBar = true,
  isLoggedIn = false,
  showPeriodSelectControl = false,
  accessToPeriodMenu = true,
  isCurrentPeriod = true,
  scope = 'frct'
}: UserLayoutProps) {
  return (
    <div className={s.UserLayout}>
      {/* Отключён по требованию МЦ: {profile.feedbackSurveyAvailable && <AlertFeedbackSurvey />}*/}
      {/* Отключён по требованию МЦ: {isLoggedIn && isCurrentPeriod && <AlertRatingFeedback />}*/}
      {showServiceWork && <ServiceWork />}
      {showTopBar && <TopBar scope={scope} accessToPeriodMenu={showPeriodSelectControl && accessToPeriodMenu} />}

      <div className={s.UserLayout__box}>
        <main className={s.UserLayout__content}>
          <Outlet />
        </main>
      </div>

      <Footer />
    </div>
  );
}

function UserLayoutWithAuth(props: UserLayoutProps) {
  const {
    profileLoading,
    profileError,
    profile,
    allowedToChangePeriod,
    departmentId,
    isCurrentDepartmentPeriod,
    isCurrentRegionPeriod
  } = useProfile();

  if (!!profileError) return <ProfileError />;
  if (!profile || profileLoading) return <Loading />;

  return (
    <UserLayoutComponent
      {...props}
      isCurrentPeriod={!!departmentId ? isCurrentDepartmentPeriod : isCurrentRegionPeriod}
      accessToPeriodMenu={allowedToChangePeriod}
    />
  );
}

export function UserLayout(props: UserLayoutProps) {
  return props.isLoggedIn ? <UserLayoutWithAuth {...props} /> : <UserLayoutComponent {...props} />;
}
