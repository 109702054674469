import { useCallback, useState } from 'react';
import { Button, IconButton } from 'components/Button';
import { Dismiss, TrashBoxIcon, UnlinkIcon } from 'components/Icon';
import { Input } from 'components/Input';
import { useUpsertSecondPersonMutation } from 'store/graphql';
import { useRemoveSecondPersonFromList } from '../../../_hooks';
import { useRctControlContext } from '../../../_store/useRctControlContext';
import { InputErrorEnum, RctControlScopeEnum, SecondPersonType, validate } from '../../../_utils';
import s from './SecondPersonEditor.module.scss';

export interface SecondPersonEditorProps {
  mode: 'add' | 'edit';
  kind: RctControlScopeEnum.ViceChairman | RctControlScopeEnum.Representative;
  secondPerson?: SecondPersonType;
  onError: () => void;
  onSuccess: () => void;
  onBack: () => void;
  handleUpdate: (person: SecondPersonType, mode: 'add' | 'edit') => void;
}

type SecondPersonTemplate = {
  userId: number | null;
  name: string;
  surname: string;
  patronymic: string;
  fullName: string;
  additionalInfo?: string | null;
};

const templateHaveEmptyFields = (t: SecondPersonTemplate): boolean => {
  return t.name === '' || t.surname === '' || !t.patronymic || t.patronymic === '' || t.fullName === '';
};

export function SecondPersonEditor({
  mode,
  kind,
  secondPerson,
  onBack,
  onError,
  onSuccess,
  handleUpdate
}: SecondPersonEditorProps) {
  const context = useRctControlContext();
  const [upsertSecondPerson] = useUpsertSecondPersonMutation();
  const [inputErrors, setInputErrors] = useState<Set<InputErrorEnum>>(new Set());
  const [loading, setLoading] = useState<boolean>(false);
  const [secondPersonTemplate, setSecondPersonTemplate] = useState<SecondPersonTemplate>(
    secondPerson
      ? { ...secondPerson, patronymic: secondPerson.patronymic ?? '' }
      : {
          userId: null,
          name: '',
          surname: '',
          patronymic: '',
          fullName: '',
          additionalInfo: null
        }
  );

  // Сохранение изменений в Вице-премьере \ Полномочном представителе
  const saveSecondPerson = useCallback(async () => {
    try {
      setLoading(true);
      const result = await upsertSecondPerson({
        variables: {
          input: {
            userId: secondPersonTemplate.userId,
            name: secondPersonTemplate.name.trim(),
            surname: secondPersonTemplate.surname.trim(),
            patronymic: secondPersonTemplate.patronymic.trim()
          }
        }
      });
      if (!result.data) {
        onError();
        return;
      }
      if (context.refetchSecondPersonsList) {
        await context.refetchSecondPersonsList();
      }
      if (context.refetchScopeData) {
        await context.refetchScopeData();
      }
      handleUpdate(
        {
          ...secondPersonTemplate,
          userId: result.data.upsertViceChairmanOrRepresentative.userId
        },
        mode
      );
      setLoading(false);
      onSuccess();
    } catch (e) {
      onError();
    }
  }, [secondPersonTemplate, upsertSecondPerson, setLoading, context, onSuccess, onError, handleUpdate, mode]);

  // Удаление из списка Вице-премьера \ Полномочного представителя
  const { removeSecondPersonFromList } = useRemoveSecondPersonFromList({
    userIdToRemove: secondPerson?.userId
  });
  const removeSecondPersonCurrentList = useCallback(() => {
    removeSecondPersonFromList();
    onSuccess();
  }, [removeSecondPersonFromList, onSuccess]);

  const clearHandler = (field: 'name' | 'surname' | 'patronymic' | 'additionalInfo') => {
    if (field === 'name')
      setSecondPersonTemplate((sp) => ({ ...sp, name: '', fullName: `${sp.surname} ${sp.patronymic}` }));
    if (field === 'surname')
      setSecondPersonTemplate((sp) => ({ ...sp, surname: '', fullName: `${sp.name} ${sp.patronymic}` }));
    if (field === 'patronymic')
      setSecondPersonTemplate((sp) => ({ ...sp, patronymic: '', fullName: `${sp.surname} ${sp.name}` }));
    if (field === 'additionalInfo') setSecondPersonTemplate((sp) => ({ ...sp, additionalInfo: '' }));
  };

  const nameHandler = (value: string) => {
    setInputErrors((errors) => validate(value, [InputErrorEnum.NameLength, InputErrorEnum.NameContent], errors));
    setSecondPersonTemplate((sp) => ({ ...sp, name: value, fullName: `${sp.surname} ${value} ${sp.patronymic}` }));
  };

  const surnameHandler = (value: string) => {
    setInputErrors((errors) => validate(value, [InputErrorEnum.SurnameLength, InputErrorEnum.SurnameContent], errors));
    setSecondPersonTemplate((sp) => ({ ...sp, surname: value, fullName: `${value} ${sp.name} ${sp.patronymic}` }));
  };

  const patronymicHandler = (value: string) => {
    setInputErrors((errors) =>
      validate(value, [InputErrorEnum.PatronymicLength, InputErrorEnum.PatronymicContent], errors)
    );
    setSecondPersonTemplate((sp) => ({
      ...sp,
      patronymic: value,
      fullName: `${sp.surname} ${sp.name} ${value}`
    }));
  };

  const additionalInfoHandler = (value: string) => {
    setInputErrors((errors) => validate(value, [InputErrorEnum.AdditionalInfoLength], errors));
    setSecondPersonTemplate((sp) => ({ ...sp, additionalInfo: value }));
  };

  const title = `${mode === 'edit' ? 'Редактирование' : 'Создание нового'} ${
    kind === RctControlScopeEnum.ViceChairman ? 'Вице-премьера' : 'полномочного представителя'
  }`;

  return (
    <>
      {mode === 'edit' && (
        <div className={s.SpEditor__box}>
          <div>{title}</div>
          <div className={s.SpEditor__name}>{secondPersonTemplate.fullName}</div>
          <IconButton
            icon={UnlinkIcon}
            className={s.SpEditor__removeFromList}
            onClick={removeSecondPersonCurrentList}
          />
          <Input
            disabled={loading}
            value={secondPersonTemplate.surname}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('surname')}
            onChange={surnameHandler}
          />
          <Input
            disabled={loading}
            value={secondPersonTemplate.name}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('name')}
            onChange={nameHandler}
          />
          <Input
            disabled={loading}
            value={secondPersonTemplate.patronymic ?? ''}
            type={'text'}
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('patronymic')}
            onChange={patronymicHandler}
          />
          <Input
            disabled={loading}
            value={secondPersonTemplate.additionalInfo ?? ''}
            type={'text'}
            placeholder={'Дополнительная информация'}
            dimmedPlaceHolder
            iconRight={Dismiss}
            iconRightHandler={() => clearHandler('additionalInfo')}
            onChange={additionalInfoHandler}
          />
          <div className={s.SpEditor__disclaimer}>Необходимо полностью указать Фамилию, Имя и Отчество.</div>
          {inputErrors.size > 0 && <div className={s.SpEditor__inputErrors}>Некорректные данные</div>}
          <Button
            loading={loading}
            disabled={loading || inputErrors.size > 0 || templateHaveEmptyFields(secondPersonTemplate)}
            onClick={saveSecondPerson}>
            Сохранить
          </Button>
          <Button className={s.SpEditor__backButton} disabled={loading} onClick={onBack}>
            Отмена
          </Button>
        </div>
      )}
      {mode === 'add' && (
        <div className={s.SpEditor__box}>
          <div>{title}</div>
          {onBack && (
            <IconButton icon={TrashBoxIcon} className={s.SpEditor__removeFromList} onClick={onBack}></IconButton>
          )}
          <Input
            disabled={loading}
            type={'text'}
            iconRight={Dismiss}
            placeholder={'Фамилия'}
            dimmedPlaceHolder
            iconRightHandler={() => clearHandler('surname')}
            onChange={surnameHandler}
          />
          <Input
            disabled={loading}
            type={'text'}
            iconRight={Dismiss}
            placeholder={'Имя'}
            dimmedPlaceHolder
            iconRightHandler={() => clearHandler('name')}
            onChange={nameHandler}
          />
          <Input
            disabled={loading}
            type={'text'}
            iconRight={Dismiss}
            placeholder={'Отчество'}
            dimmedPlaceHolder
            iconRightHandler={() => clearHandler('patronymic')}
            onChange={patronymicHandler}
          />
          <Input
            disabled={loading}
            type={'text'}
            iconRight={Dismiss}
            placeholder={'Дополнительная информация'}
            dimmedPlaceHolder
            iconRightHandler={() => clearHandler('additionalInfo')}
            onChange={additionalInfoHandler}
          />
          <div className={s.SpEditor__disclaimer}>Необходимо полностью указать Фамилию, Имя и Отчество.</div>
          {inputErrors.size > 0 && <div className={s.SpEditor__inputErrors}>Некорректные данные</div>}
          <Button
            loading={loading}
            disabled={loading || inputErrors.size > 0 || templateHaveEmptyFields(secondPersonTemplate)}
            onClick={saveSecondPerson}>
            Сохранить
          </Button>
          <Button className={s.SpEditor__backButton} disabled={loading} onClick={onBack}>
            Отмена
          </Button>
        </div>
      )}
    </>
  );
}
