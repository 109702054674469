import React, { ChangeEventHandler, useRef } from 'react';
import { InputPropsWithoutRef } from '@digital-gov/ui-utils';
import { IconDismiss, IconSearch } from 'componentsL/Icon';
import { TextInput, TextInputProps } from '../TextInput/TextInput';

export interface SearchInputProps
  extends Pick<TextInputProps, 'value' | 'placeholder'>,
    Pick<InputPropsWithoutRef, 'onKeyDown'> {
  className?: string;
  onChange: (value: string) => void;
  onSubmit?: (value: string) => void;
}

export const SearchInput = ({ className, value, onChange, onSubmit, ...inputProps }: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
  };

  const handleClear = () => {
    onChange('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit?.(inputRef.current?.value || '');
    }
  };

  return (
    <TextInput
      ref={inputRef}
      className={className}
      value={value}
      iconLeft={IconSearch}
      iconRight={value ? IconDismiss : undefined}
      onIconRightClick={handleClear}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      {...inputProps}
    />
  );
};
