import React, { useCallback } from 'react';
import { useFeedbackAttachmentPresignedUrlLazyQuery } from 'store/graphql';

export type AttachmentLoaderHelpersType = {
  loading: boolean;
  download: () => void;
};

export interface AttachmentLoaderProps {
  attachmentId: number;
  children?: (helpers: AttachmentLoaderHelpersType) => React.ReactNode;
}

export function AttachmentLoader({ attachmentId, children }: AttachmentLoaderProps) {
  const [getDownloadUrl, { loading }] = useFeedbackAttachmentPresignedUrlLazyQuery({ fetchPolicy: 'network-only' });

  const downloadAttachment = useCallback(async () => {
    try {
      if (loading) return;

      const { data } = await getDownloadUrl({ variables: { id: attachmentId } });
      const downloadUrl = data?.url;

      if (!downloadUrl) {
        console.error('Ошибка запроса URL на скачивание файла');
        return;
      }
      const linkNode = document.createElement('a');
      linkNode.href = downloadUrl;
      linkNode.click();
    } catch (e) {
      console.error(e);
    }
  }, [getDownloadUrl, attachmentId, loading]);

  /**
   * Хелперу download, которая может быть установлена в onClick
   * присваивается downloadExcel
   */
  const content = children?.({
    loading: loading,
    download: downloadAttachment
  });

  return <>{content}</>;
}
