import React from 'react';
import { EMDASH, formatNumber } from '@digital-gov/ui-utils';
import {
  Indicator,
  IndicatorColorScheme,
  primaryColors as primary,
  softColors as soft,
  listColors as list
} from 'components/Indicator';
import { getDynamicSymbol } from './getDynamicSymbol';
import s from './DynamicValue.module.scss';

export interface DynamicValueProps {
  dynamic?: number | null;
  withSymbol?: boolean;
  postfix?: string;
  formatted?: boolean;
  withArrow?: boolean;
  softColors?: boolean;
  listColors?: boolean;
  showDashOnNull?: boolean;
}

export function DynamicValue({
  dynamic,
  withArrow,
  withSymbol,
  postfix,
  formatted,
  softColors,
  listColors,
  showDashOnNull
}: DynamicValueProps) {
  const dynamicSymbol = withSymbol ? getDynamicSymbol(dynamic) : '';
  const dynamicValue = dynamic != null ? (formatted ? formatNumber(dynamic) : dynamic) : showDashOnNull ? EMDASH : '';

  const colorScheme = softColors ? IndicatorColorScheme.Soft : listColors ? IndicatorColorScheme.List : undefined;
  const colors = softColors ? soft : listColors ? list : primary;
  const dynamicValueColor = dynamic == null || dynamic === 0 ? colors[2] : dynamic > 0 ? colors[0] : colors[1];

  return (
    <div>
      {dynamic !== undefined && (
        <div className={s.DynamicValue}>
          {withArrow && <Indicator dynamic={dynamic} colorScheme={colorScheme} />}
          <div style={{ color: dynamicValueColor }}>
            {`${dynamicSymbol}${dynamicValue}`}
            {`${dynamic && postfix ? postfix : ''}`}
          </div>
        </div>
      )}
    </div>
  );
}
