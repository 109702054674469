import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './InfoProperty.module.scss';

export interface InfoPropertyClasses {
  root?: string;
  content?: string;
  label?: string;
}

export interface InfoPropertyProps extends PropsWithChildren {
  className?: string;
  classes?: InfoPropertyClasses;
  label?: React.ReactNode;
  vertical?: boolean;
}

export const InfoProperty = ({ className, classes, label, vertical, children }: InfoPropertyProps) => {
  return (
    <div
      className={clsx(s.InfoProperty, className, classes?.root, {
        [s.InfoProperty_vertical]: vertical
      })}>
      <div className={clsx(s.InfoProperty__label, classes?.label)}>{label}</div>
      {children && <div className={clsx(s.InfoProperty__content, classes?.content)}>{children}</div>}
    </div>
  );
};

export interface InfoPropertyIndicatorProps {
  className?: string;
  color: string;
}

export const InfoPropertyIndicator = ({ className, color }: InfoPropertyIndicatorProps) => {
  return <div className={clsx(s.InfoPropertyIndicator, className)} style={{ backgroundColor: color }} />;
};
