import React from 'react';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

export function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const _violationElimination = components[0]?.value;
  const informationSecurity = components[1]?.value;
  const registeredInformationSystems = components[2]?.value;
  const fgiskiInforamtionSystems = components[3]?.value;
  return (
    <SymbolsGrid columns={4}>
      <SymbolDetails
        value={_violationElimination}
        unit={'%'}
        description={
          'Значение показателя «Устранение выявленных в рамках инвентаризации ИТ-ресурсов органов государственной власти нарушений»'
        }>
        <Symbol sub={'наруш'}>У</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={informationSecurity}
        description={'Значение показателя «Доля ИС, соответствующих требованиям информационной безопасности»'}>
        <Symbol sub={'ис'}>А</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={registeredInformationSystems}
        description={'Значение показателя «Доля зарегистрированных ИС»'}>
        <Symbol sub={'ис'}>P</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={fgiskiInforamtionSystems}
        description={
          <>
            Значение показателя «Доля ИС, имеющих <br />в ФГИС КИ сведения о правовых основаниях создания, ввода в
            эксплуатацию и вывода <br />
            из эксплуатации»
          </>
        }>
        <Symbol sub={'ис'}>Д</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}
function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Уин ≤ 20%']} />
      <TableRow values={['0,5', '20% < Унаруш ≤ 40%']} />
      <TableRow values={['1', '40% < Унаруш ≤ 60%']} />
      <TableRow values={['2', '60% < Унаруш ≤ 80%']} />
      <TableRow values={['3', '80% < Унаруш ≤ 90%']} />
      <TableRow values={['4', '90% < Унаруш ≤ 100%']} />
    </Table>
  );
}

export function ViolationElimination() {
  return (
    <IndicatorPage
      download={'Устранение выявленных нарушений.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
    />
  );
}
