import { EMDASH } from '@digital-gov/ui-utils';

export enum RegionShortNames {
  CFO = 'ЦФО',
  DFO = 'ДФО',
  PFO = 'ПФО',
  SFO = 'СФО',
  SKFO = 'СКФО',
  SZFO = 'СЗФО',
  UFO = 'ЮФО',
  UrFO = 'УрФО',
  DNR = 'Донецкая Народная Республика (ДНР)',
  ZPR = 'Запорожская область',
  LNR = 'Луганская Народная Республика (ЛНР)',
  HersObl = 'Херсонская область'
}

export enum MapMode {
  Regions = 'regions',
  EffectGroup = 'effectGroup'
}

export const MSK_REGION = {
  regionId: 'MSK',
  regionName: 'г. Москва',
  regionShortCode: 'МСК'
};

export const UNKNOWN_REGION = {
  regionId: 'UNKNOWN',
  regionName: 'Регион не определен',
  regionShortCode: EMDASH
};
