import { RctControlScopeEnum } from './rctControlScope';

export const errorTitleByScope = (scope: RctControlScopeEnum): string => {
  switch (scope) {
    case RctControlScopeEnum.DepartmentRct:
      return 'Изменение РЦТ';
    case RctControlScopeEnum.RegionRct:
      return 'Изменение РЦТ';
    case RctControlScopeEnum.ViceChairman:
      return 'Изменение Вице-премьера';
    case RctControlScopeEnum.Representative:
      return 'Изменение полномочного представителя';
  }
};
