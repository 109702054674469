import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { TextArea, TextInput } from 'componentsL/Input';
import { ModalContentGroup, ModalFooter } from 'componentsL/Modal';
import { useFeedbackFileUploader } from '../../../_hooks/useFeedbackFileUploader';
import { useFeedbackContext } from '../../../feedback-context';
import { FilesControl } from '../../FilesControl';
import s from '../FormContent.module.scss';

const FILES_LIMIT = 8;

export interface ProposalFormProps {
  onSubmit: () => void | Promise<void>;
}

export const ProposalForm = ({ onSubmit }: ProposalFormProps) => {
  const context = useFeedbackContext();
  const [sending, setSending] = useState(false);
  const [subject, setSubject] = useState<string>(context.proposalForm.subject);
  const [comment, setComment] = useState<string>(context.proposalForm.comment);
  const [files, uploadManager, addFiles, removeFile] = useFeedbackFileUploader(
    context.scope,
    context.scopeId,
    context.indicatorId,
    context.proposalForm.uploadManager
  );

  const handleSubjectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(event.currentTarget.value);
    },
    [setSubject]
  );

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.currentTarget.value);
    },
    [setComment]
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setSending(true);
      const result = onSubmit();

      if (result instanceof Promise) {
        await result;
      }

      setSending(false);
    },
    [onSubmit]
  );

  useEffect(() => {
    context.proposalForm.subject = subject;
    context.proposalForm.comment = comment;
  }, [context, subject, comment]);

  return (
    <form className={s.FormContent} onSubmit={handleSubmit}>
      <ModalContentGroup>
        <TextInput
          label="Тема"
          placeholder="Тема предложения или заявки"
          className={s.FormContent__input}
          value={subject}
          onChange={handleSubjectChange}
        />
      </ModalContentGroup>

      <ModalContentGroup>
        <TextArea
          label="Комментарий"
          placeholder="Общий произвольный комментарий"
          value={comment}
          rows={5}
          classes={{ input: s.FormContent__input }}
          onChange={handleCommentChange}
        />

        <FilesControl
          limit={FILES_LIMIT}
          files={files}
          uploadManager={uploadManager}
          addFiles={addFiles}
          removeFile={removeFile}
        />
      </ModalContentGroup>

      <ModalFooter
        right={
          <Button variant={ButtonVariant.Primary} disabled={sending || context.proposalForm.disabled} type="submit">
            Отправить
          </Button>
        }
      />
    </form>
  );
};
