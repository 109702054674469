import React from 'react';
import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const iKr = components[0]?.value;
  const iRp = components[1]?.value;
  const vRp = components[2]?.value;
  const iBalances = components[3]?.value;
  const vBalances = components[4]?.value;
  const iFund = components[5]?.value;
  const vFund = components[6]?.value;
  return (
    <SymbolsGrid columns={4} largeGap>
      <SymbolDetails
        value={iKr}
        unit={'%'}
        description={
          'Значение показателя «Размещение в ГИС ЖКХ информации по капитальному ремонту общего имущества МКД»'
        }>
        <Symbol sub={'КР'}>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={iRp}
        unit={'%'}
        description={
          'Значение подпоказателя «Размещение в ГИС ЖКХ информации по региональной программе капитального ремонта общего имущества МКД»'
        }>
        <Symbol sub={'РП'}>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={vRp}
        unit={'%'}
        description={
          'Вес подпоказателя «Размещение в ГИС ЖКХ информации по региональной программе капитального ремонта общего имущества МКД»'
        }>
        <Symbol sub={'РП'}>В</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={iBalances}
        unit={'%'}
        description={
          'Значение подпоказателя «Доля внесённой в ГИС ЖКХ информации об остатках на банковских счетах для формирования фонда капитального ремонта общего имущества МКД»'
        }>
        <Symbol sub={'остатки'}>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={vBalances}
        unit={'%'}
        description={
          'Вес подпоказателя «Доля внесённой в ГИС ЖКХ информации об остатках на банковских счетах для формирования фонда капитального ремонта общего имущества МКД»'
        }>
        <Symbol sub={'остатки'}>В</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={iFund}
        unit={'%'}
        description={
          'Значение подпоказателя «Размещение в ГИС ЖКХ информации по формированию фонда капитального ремонта общего имущества МКД»'
        }>
        <Symbol sub={'фонд'}>И</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={vFund}
        unit={'%'}
        description={
          'Вес подпоказателя «Размещение в ГИС ЖКХ информации по формированию фонда капитального ремонта общего имущества МКД»'
        }>
        <Symbol sub={'фонд'}>В</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <AdvancedTable
      rows={[
        { cells: ['Оценка, балл', 'Значение'], isHeader: true },
        { cells: ['0,0', ['И', { text: 'КР', align: 'sub' }, ' < 50%']] },
        { cells: ['0,5', ['50% ≤ И', { text: 'КР', align: 'sub' }, ' < 70%']] },
        { cells: ['1,0', ['70% ≤ И', { text: 'КР', align: 'sub' }, ' < 80%']] },
        { cells: ['1,5', ['80% ≤ И', { text: 'КР', align: 'sub' }, ' < 90%']] },
        { cells: ['2,0', ['90% ≤ И', { text: 'КР', align: 'sub' }, ' < 100%']] }
      ]}
    />
  );
}

export function GisJkhMajorRenovation() {
  return (
    <IndicatorPage
      regionScope
      download={'ГИС ЖКХ кап. ремонт.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
