import React, { useEffect, useMemo, useState } from 'react';
import { Overlay } from 'components/Overlay';
import { IndicatorObjectType, ResponsibleType } from './_utils';
import { ChangeResponsible, ResponsibleChanged, ResponsibleControlError } from './modals';

export interface ResponsibleControlProps {
  scope: 'frct' | 'rrct';
  scopeId: number;
  indicatorId: number;
  indicatorName: string;
  currentResponsibleRaw?: { fullName?: string | null; email?: string | null } | null;
  refetch?: () => void;
  handleClose: () => void;
}

enum ResponsibleControlView {
  'changeResponsible',
  'responsibleChanged',
  'responsibleControlError'
}

export function ResponsibleControl({
  scope,
  scopeId,
  currentResponsibleRaw,
  indicatorId,
  indicatorName,
  refetch,
  handleClose
}: ResponsibleControlProps) {
  const [viewMode, setViewMode] = useState<ResponsibleControlView>(ResponsibleControlView.changeResponsible);
  const [currentResponsibles, setCurrentResponsibles] = useState<ResponsibleType[]>([]);

  const onError = () => {
    setViewMode(ResponsibleControlView.responsibleControlError);
  };

  const handleReset = () => {
    setViewMode(ResponsibleControlView.changeResponsible);
    handleClose();
  };

  const handleSuccess = () => {
    setViewMode(ResponsibleControlView.responsibleChanged);
  };

  const indicatorObject: IndicatorObjectType = useMemo(() => {
    return {
      indicatorId,
      name: indicatorName
    };
  }, [indicatorName, indicatorId]);

  useEffect(() => {
    // массив если приходит, то в строке:
    // "email": "test_2@fakeadmin.ru, test_2@fakeadmin.ru",
    // "fullName": "Орлов Иван Иванович, Орлов Иван Иванович",
    const responsibleNames =
      !currentResponsibleRaw || !currentResponsibleRaw.fullName ? [] : currentResponsibleRaw.fullName.split(',');
    const responsilbeEmails =
      !currentResponsibleRaw || !currentResponsibleRaw.email ? [] : currentResponsibleRaw.email.split(',');
    const _currentResponsibles: ResponsibleType[] = [];
    for (let i = 0; i < responsibleNames.length; i++) {
      const _email = responsilbeEmails[i];
      _currentResponsibles.push({
        fullName: responsibleNames[i],
        email: _email.includes('@') ? _email : null
      });
    }
    setCurrentResponsibles(_currentResponsibles);
  }, [currentResponsibleRaw]);

  return (
    <>
      <Overlay onClick={handleClose}>
        {viewMode === ResponsibleControlView.changeResponsible && (
          <ChangeResponsible
            scope={scope}
            scopeId={scopeId}
            indicatorObject={indicatorObject}
            currentResponsibles={currentResponsibles}
            refetchScopeData={refetch}
            onSuccess={handleSuccess}
            onError={onError}
            onClose={handleClose}
          />
        )}
        {viewMode === ResponsibleControlView.responsibleChanged && (
          <ResponsibleChanged handleForward={handleReset} handleClose={handleReset} />
        )}
        {viewMode === ResponsibleControlView.responsibleControlError && (
          <ResponsibleControlError handleBack={handleReset} handleClose={handleReset} />
        )}
      </Overlay>
    </>
  );
}
