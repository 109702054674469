import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const n1 = components[0]?.value; // 252 - N1
  const n2 = components[1]?.value; // 253 - N2
  const fi = components[2]?.value; // 254 - Fi
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={fi}
        description={
          <>
            Значение показателя доля услуг на ЕПГУ из Перечня, по которым субъектом i осуществлен перевод с РПГУ либо
            иных сайтов для оказания услуг
          </>
        }>
        <Symbol sub={'i'} square color={'#55D666'}>
          F
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={n1}
        roundUp
        description={
          <>
            Количество услуг на ЕПГУ из Перечня, по которым субъектом i осуществлен перевод с РПГУ либо иных сайтов для
            оказания услуг
          </>
        }>
        <Symbol sub={'1'}>N</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={n2}
        roundUp
        description={<>Количество услуг на РПГУ и иных сайтах оказания услуг, приведенных в перечне услуг</>}>
        <Symbol sub={'2'}>N</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение F𝒊, %']} isHeader />
      <TableRow values={['0,5', '25% ≥ F𝑖 < 50%']} />
      <TableRow values={['1,0', '50% ≥ F𝑖 < 100%']} />
      <TableRow values={['2,0', 'F𝑖 = 100%']} />
    </Table>
  );
}

export function DigitalServicesOnEpgu() {
  return (
    <IndicatorPage
      regionScope
      download={'РПГУ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
