export enum ViewState {
  /** Состояние наполнения формы */
  Input,
  /** Состояние подтверждения закрытия формы */
  CloseConfirm,
  /* region [Состояния формы ОС] */
  /** Состояние подтверждения отправки ОС */
  FeedbackSendingConfirm,
  /** Состояние уведомления об успешной отправке ОС */
  FeedbackSentSuccess,
  /** Состояние уведомления об ошибке отправки ОС */
  FeedbackSentFailed,
  /* endregion */
  /* region [Состояние формы предложений] */
  /** Состояние уведомления об успешном отправке Предложения */
  ProposalSentSuccess,
  /** Состояние уведомления об ошибке отправки Предложения */
  ProposalSentFailed
  /* endregion */
}
