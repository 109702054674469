import { Indicator } from 'hooks/useFeedbackAndIndicatorsList';
import { DepartmentIndicatorValueType } from 'store/graphql';

export function normalizeIndicatorValues(indicatorValues?: DepartmentIndicatorValueType[] | null): Indicator[] {
  if (indicatorValues === void 0 || indicatorValues === null) {
    return [];
  }

  return indicatorValues
    .filter(
      (value) => value.indicatorName && value.scoreForLastPeriod !== undefined && value.scoreForLastPeriod !== null
    )
    .map((value) => ({
      id: value.indicatorId,
      name: value.indicatorName
    }));
}
