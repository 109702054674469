import { FC } from 'react';
import clsx from 'clsx';
import { formatNumber } from '@digital-gov/ui-utils';
import { RegionRatingGroupEnum } from 'store/graphql';
import { PositionDynamic } from '../../PositionDynamic';
import s from './TableCell.module.scss';

export interface TableCellProps {
  filtered?: boolean;
  ratingPosition: number;
  previousRatingPosition?: number | null;
  regionName: string;
  current?: boolean;
  ratingGroup: RegionRatingGroupEnum;
  degree?: number | null;
  onClick?: () => void;
}

export const TableCell: FC<TableCellProps> = (props: TableCellProps) => {
  const { ratingPosition, previousRatingPosition, regionName, current, ratingGroup, degree, filtered, onClick } = props;

  const commonClasses = clsx(
    s.TableCell__item,
    filtered && s.TableCell__item_filtered,
    onClick && s.TableCell__item_pointer
  );

  return (
    <>
      <div className={clsx(commonClasses, s.TableCell__item_flexStart)} onClick={onClick}>
        <p className={s.TableCell__ratingPosition}>{ratingPosition}</p>
        <PositionDynamic
          className={s.TableCell__dynamic}
          ratingPosition={ratingPosition}
          previousRatingPosition={previousRatingPosition}
        />
      </div>
      <div
        onClick={onClick}
        className={clsx(
          commonClasses,
          s.TableCell__regionName,
          current && s.TableCell__regionName_current,
          current && ratingGroup === RegionRatingGroupEnum.High && s.TableCell__regionName_groupHigh
        )}>
        <span>{regionName}</span>
      </div>
      <div className={clsx(commonClasses, s.TableCell__item_last)} onClick={onClick}>
        {formatNumber(degree ?? 0)}
      </div>
    </>
  );
};
