import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { BaseLink } from 'componentsL/BaseLink/BaseLink';
import s from './Icon.module.scss';

export enum IconSize {
  XLarge = 48,
  Large = 32,
  Medium = 24,
  XMedium = 20,
  Small = 16,
  XSmall = 12
}

export interface IconClasses {
  root?: string;
  icon?: string;
}

export interface IconProps {
  className?: string;
  classes?: IconClasses;
  size?: IconSize | number;
  iconSize?: number;
  icon: React.ElementType;
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
}

export const Icon = ({ className, classes, size = IconSize.Small, iconSize, icon, onClick, children }: IconProps) => {
  const boxStyle = size
    ? {
        width: `${size}px`,
        height: `${size}px`
      }
    : {};

  const iconStyle = iconSize
    ? {
        width: `${iconSize}px`,
        height: `${iconSize}px`
      }
    : {};

  const IconComponent = icon;
  const clickable = !!onClick;

  return (
    <BaseLink
      Component={clickable ? 'button' : 'div'}
      className={clsx(s.Icon, size && s[`Icon_${size}`], className, classes?.root, {
        [s.Icon_clickable]: clickable
      })}
      style={boxStyle}
      onClick={onClick}>
      {icon && (
        <div className={clsx(s.Icon__icon, classes?.icon)} style={iconStyle}>
          <IconComponent />
        </div>
      )}
      {children}
    </BaseLink>
  );
};
