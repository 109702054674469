import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { DATE_FULL, formatDate } from '@digital-gov/ui-utils';
import { Select } from 'componentsL/Select/Select';
import { useProfile } from 'store/profile/useProfile';
import { UnpublishedIcon, PublishedIcon } from './icons';
import { PeriodItem } from './PeriodItem';
import s from './PeriodMenu.module.scss';

export function PeriodMenu({ scope }: { scope: 'rrct' | 'frct' }) {
  const { departmentPeriod, setDepartmentPeriod, regionPeriod, setRegionPeriod, profile } = useProfile();

  const periods = useMemo(() => {
    return (scope === 'frct' ? profile?.departmentPeriods : profile?.regionPeriods) ?? [];
  }, [scope, profile?.departmentPeriods, profile?.regionPeriods]);

  const selectedPeriod = useMemo(() => {
    const periodId = scope === 'frct' ? departmentPeriod : regionPeriod;
    return periods.find((data) => data.periodId === periodId) ?? null;
  }, [scope, periods, departmentPeriod, regionPeriod]);

  const options = useMemo(() => {
    if (periods.length === 0) {
      return [];
    }

    return periods.map((dp) => ({
      labelElement: <PeriodItem dateSubmit={dp.dateSubmit} published={dp.published} available={dp.available} />,
      label: formatDate(dp.dateSubmit, DATE_FULL),
      value: dp.periodId,
      disabled: !dp.available
    }));
  }, [periods]);

  const handleChange = useCallback(
    (id: number | null) => {
      if (scope === 'frct') {
        setDepartmentPeriod(id || 0);
      } else {
        setRegionPeriod(id || 0);
      }
    },
    [scope, setDepartmentPeriod, setRegionPeriod]
  );

  return (
    <div className={s.PeriodMenu}>
      <Select
        classes={{ dropdown: s.PeriodMenu_selectDropdown }}
        iconLeft={selectedPeriod?.published ? PublishedIcon : UnpublishedIcon}
        iconLeftProps={{
          classes: {
            root: clsx(s.ViewPeriodSelectItem__icon, {
              [s.PeriodItem__date_inactive]: !selectedPeriod?.published || !selectedPeriod?.available
            })
          }
        }}
        placeholder={scope === 'frct' ? 'Период ФРЦТ' : 'Период РРЦТ'}
        options={options}
        value={selectedPeriod?.periodId ?? null}
        onChange={handleChange}
      />
    </div>
  );
}
