import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const informationSystemIndependence = components[0]?.value;
  const softwareIndependence = components[1]?.value;
  const totalSoftwareIndependence = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={totalSoftwareIndependence}
        unit={'%'}
        description={'Значение показателя «Уровень импортонезависимости ПО»'}>
        <Symbol sub={'ин'}>У</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={informationSystemIndependence}
        description={'Значение показателя «Доля импортонезависимости ИС»'}>
        <Symbol sub={'ис'}>А</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={softwareIndependence}
        description={'Значение показателя «Доля импортонезависимости ПО типовой деятельности»'}>
        <Symbol sub={'тд'}>А</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Уин ≤ 15%']} />
      <TableRow values={['1', '15% < Уин ≤ 30%']} />
      <TableRow values={['2', '30% < Уин ≤ 45%']} />
      <TableRow values={['3', '45% < Уин ≤ 60%']} />
      <TableRow values={['4', '60% < Уин ≤ 75%']} />
      <TableRow values={['5', '75% < Уин ≤ 90%']} />
      <TableRow values={['6', '90% < Уин ≤ 100%']} />
    </Table>
  );
}

export function SoftwareImportIndependence() {
  return (
    <IndicatorPage
      download={'Уровень импортонезависимости ПО.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
