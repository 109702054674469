import { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { Optional } from '@digital-gov/ui-utils';
import { RatingProfileType } from 'store/graphql';

export interface IProfileContext {
  // query
  profileLoading: boolean;
  profileError: Optional<ApolloError>;
  profile: Optional<Omit<RatingProfileType, 'feedbackSurvey'>>;
  // roles
  isObserver: Optional<boolean>;
  isRRCT: boolean;
  regionId: Optional<number>;
  regionName: Optional<string>;
  isFRCT: boolean;
  departmentId: Optional<number>;
  departmentName: Optional<string>;
  departmentPublished: boolean;
  // period control
  allowedToChangePeriod: boolean;
  departmentPeriod: Optional<number>;
  regionPeriod: Optional<number>;
  setDepartmentPeriod: (periodId: number) => void;
  setRegionPeriod: (periodId: number) => void;
  isCurrentDepartmentPeriod: boolean;
  isCurrentRegionPeriod: boolean;
  // comments
  allowedToComment: boolean;
}

export const ProfileContext = createContext<IProfileContext>({
  // query
  profileLoading: false,
  profileError: null,
  profile: null,
  // roles
  isObserver: false,
  isRRCT: false,
  regionId: null,
  regionName: null,
  isFRCT: false,
  departmentId: null,
  departmentName: null,
  departmentPublished: false,
  // period control
  allowedToChangePeriod: false,
  departmentPeriod: null,
  regionPeriod: null,
  setDepartmentPeriod: () => {},
  setRegionPeriod: () => {},
  isCurrentDepartmentPeriod: true,
  isCurrentRegionPeriod: true,
  // comments
  allowedToComment: false
});
