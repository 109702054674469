import clsx from 'clsx';
import { Check } from 'components/Icon';
import { DetalizationCellType, DetalizationTableViewForm, IndicatorDetalizationTableType } from 'store/graphql';
import { isFirstCell, isLastCell, isLinkCell, isPreLastCell } from './_utils/cellFunctions';
import { DetalizationCell } from './DetalizationCell';
import s from './Detalization.module.scss';

export interface DetalizationProps {
  indicatorId: number;
  detalizationTable: IndicatorDetalizationTableType;
}

export function Detalization({ detalizationTable, indicatorId }: DetalizationProps) {
  const { viewForm, columns, rows } = detalizationTable;
  // заполнение справедливо для формы 1
  return viewForm === DetalizationTableViewForm.Form1 ? (
    <table className={s.DetalizationTable}>
      <tbody>
        <tr>
          {columns.map((column, i) => {
            return (
              <td
                key={i + 'h'}
                className={clsx(
                  s.DetalizationTable__td,
                  s.DetalizationTable__td_head,
                  isFirstCell(i) && s.DetalizationTable__td_first,
                  isLastCell(i, columns.length) && s.DetalizationTable__td_rightEdge,
                  (isLastCell(i, columns.length) || isPreLastCell(i, columns.length)) && s.DetalizationTable__td_last,
                  column === 'Показатель' && s.DetalizationTable__td_name,
                  s.DetalizationTable__td_topEdge
                )}>
                {column}
              </td>
            );
          })}
        </tr>
        {rows.map((row, i) => {
          return (
            <tr key={i + 'r'}>
              {row.cells.map((cell, j) => {
                const rowLength = row.cells.length;
                return (
                  <td
                    key={`${i}r${j}c`}
                    className={clsx(
                      s.DetalizationTable__td,
                      isFirstCell(j) && s.DetalizationTable__td_first,
                      isLastCell(j, rowLength) && s.DetalizationTable__td_rightEdge,
                      (isLastCell(j, rowLength) || isPreLastCell(j, rowLength)) && s.DetalizationTable__td_last
                    )}>
                    {cell.type === DetalizationCellType.Text &&
                      (isLinkCell(j, columns.length, cell) ? (
                        <DetalizationCell cell={cell} indicatorId={indicatorId} />
                      ) : (
                        <>{cell.value}</>
                      ))}
                    {cell.type === DetalizationCellType.Boolean && cell.value !== '' && (
                      <Check style={{ color: '#55D666' }} />
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <></>
  );
}
