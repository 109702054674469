import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const offlineNotificationsScore = components[0]?.value;
  const elkServicesCount = components[1]?.value;
  const epguServicesCount = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={offlineNotificationsScore}
        unit={'%'}
        description={
          <>
            Значение показателя доля услуг из Перечня, по которым субъектом i осуществляется передача статусов <br />и
            результатов в ЕЛК, по которым предусмотрена возможность подачи заявления без использования ЕПГУ
          </>
        }>
        <Symbol sub={'i'}>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={elkServicesCount}
        unit={'шт.'}
        roundUp
        description={
          <>
            Количество услуг из Перечня, по которым субъектом i передано
            <br /> в ЕЛК не менее 2 заявлений с 1 января 2023 г.
          </>
        }>
        <Symbol sub={'1'}>N</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={epguServicesCount}
        unit={'шт.'}
        roundUp
        description={
          <>
            Количество услуг из Перечня в субъекте i, по которым
            <br />
            предусмотрена возможность подачи заявления <br />
            без использования ЕПГУ
          </>
        }>
        <Symbol sub={'2'}>N</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', '0% < Fi ≤ 20%']} />
      <TableRow values={['0,5', '20% < Fi ≤ 40%']} />
      <TableRow values={['1,0', '40% < Fi ≤ 60%']} />
      <TableRow values={['1,5', '60% < Fi ≤ 80%']} />
      <TableRow values={['2,0', '80% < Fi ≤ 100%']} />
    </Table>
  );
}

export function ElkOfflineNotifications() {
  return (
    <IndicatorPage
      regionScope
      download={'Оффлайн уведомления в ЕЛК.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
