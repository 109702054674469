import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const govEdoReadiness = components[0]?.value;
  const k1 = components[1]?.value;
  const k2 = components[2]?.value;
  const k3 = components[3]?.value;
  const k4 = components[4]?.value;
  const k5 = components[5]?.value;
  const k6 = components[6]?.value;
  const k7 = components[7]?.value;
  const k8 = components[8]?.value;
  return (
    <SymbolsGrid columns={5}>
      <SymbolDetails
        value={govEdoReadiness}
        description={
          <div>
            Готовность перехода органов государственной власти <br />
            на электронный документооборот
          </div>
        }>
        <Symbol>Р</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k1}
        description={
          <div>
            Готовность органов государственной власти к обмену электронными документами <br />с ограничительной отметкой
            ДСП
          </div>
        }>
        <Symbol sub={'1'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k2}
        description={
          <div>
            Объемы исходящих документов <br />
            на бумажном носителе органа государственной власти
          </div>
        }>
        <Symbol sub={'2'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k3}
        description={'Объемы исходящих документов ДСП на бумажном носителе органа государственной власти'}>
        <Symbol sub={'3'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k4}
        description={
          <div>
            Количество подведомственных организаций, подключенных <br />к МЭДО
          </div>
        }>
        <Symbol sub={'4'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k5}
        description={'Доля отказов в регистрации документов от общего количества документов, переданных по МЭДО'}>
        <Symbol sub={'5'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k6}
        description={
          <div>
            Количество ошибок формата <br />
            МЭДО 2.7.1
          </div>
        }>
        <Symbol sub={'6'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k7}
        description={
          <div>
            Готовность органов государственной власти к работе <br />с цифровыми форматами поручений
          </div>
        }>
        <Symbol sub={'7'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k8}
        description={
          'Способ обмена электронными документами с ограничительной отметкой ДСП, используемый органом государственной власти'
        }>
        <Symbol sub={'8'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Критерий']} isHeader />
      <TableRow values={['2', 'Полная готовность']} />
      <TableRow values={['от 1,5 до 2', 'Высокий уровень готовности к переходу на электронный документооборот']} />
      <TableRow values={['от 0,5 до 1,5', 'Умеренный уровень готовности к переходу']} />
      <TableRow values={['от 0 до 0,5', 'Низкий уровень готовности к переходу']} />
      <TableRow values={['0', 'Полная неготовность']} />
    </Table>
  );
}

export function GovEdo() {
  return (
    <IndicatorPage
      download={'ГосЭДО.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
