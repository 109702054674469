import React from 'react';
import s from './Table.module.scss';

export interface TableProps {
  children: React.ReactNode;
}

export function Table({ children }: TableProps) {
  return <div className={s.Table}>{children}</div>;
}
