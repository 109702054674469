import React from 'react';
import clsx from 'clsx';
import { Icon, IconProps, IconSize } from 'componentsL/Icon/Icon';
import { ReactComponent as IconInfo } from './assets/i-info.svg';
import s from './Hint.module.scss';

export enum HintVariant {
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}

export interface HintProps {
  className?: string;
  variant?: HintVariant;
  icon?: IconProps['icon'];
  iconProps?: Omit<IconProps, 'icon' | 'type' | 'onClick'>;
  showInfoIcon?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Hint: React.FC<HintProps> = ({
  className,
  variant = HintVariant.Info,
  icon,
  iconProps,
  showInfoIcon,
  disabled,
  children
}) => {
  if (!children) return null;

  const hintIcon = icon ? icon : showInfoIcon ? IconInfo : null;

  return (
    <div
      className={clsx(s.Hint, s[`Hint_${variant}`], className, {
        [s.Hint_disabled]: disabled
      })}>
      {hintIcon && (
        <Icon
          className={clsx(s.Hint__icon, iconProps?.className)}
          icon={hintIcon}
          size={IconSize.Small}
          {...iconProps}
        />
      )}
      <div className={s.Hint__content}>{children}</div>
    </div>
  );
};
