import React from 'react';
import { formatNumber } from '@digital-gov/ui-utils';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import s from './TransferToArmGs.module.scss';

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const valueByCriterion = components[0]?.value;
  return (
    <>
      <h4 className={s.TransferToArmGs__heading}>Компоненты</h4>
      <div className={s.TransferToArmGs__content}>
        <span className={s.TransferToArmGs__symbol}>X</span>
        <span className={s.TransferToArmGs__equality}>=</span>
        <div className={s.TransferToArmGs__value}>{formatNumber(valueByCriterion)}</div>
        <span className={s.TransferToArmGs__text}>
          Значение показателя рассчитывается по итогам перехода на АРМ ГС, исходя из критериев оценки.
        </span>
      </div>
    </>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'Не является участником эксперимента']} />
      <TableRow values={['0,5', 'Является участником эксперимента']} />
      <TableRow
        values={[
          '1,0',
          'Заключено соглашение ФОИВ с Минцифры России о взаимодействии по переходу на использование АРМ ГС'
        ]}
      />
      <TableRow values={['2', 'Обеспечен переход ФОИВ на АРМ ГС по треку 1']} />
      <TableRow values={['3', 'Обеспечен переход ФОИВ на АРМ ГС по треку 2']} />
    </Table>
  );
}

export function TransferToArmGs() {
  return (
    <IndicatorPage
      download={'Индекс перехода на КС АРМ ГС.pdf'}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
    />
  );
}
