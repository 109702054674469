import React, { ElementType, FocusEventHandler, MouseEventHandler } from 'react';
import { WrapperComponentProps } from '@digital-gov/ui-utils';

export interface BaseLinkProps<ComponentType extends ElementType = 'a'> {
  onClick?: MouseEventHandler<ComponentType>;
  onFocusCapture?: FocusEventHandler<ComponentType>;
  onBlurCapture?: FocusEventHandler<ComponentType>;
}

export const BaseLink = <ComponentType extends ElementType = 'a'>({
  Component = 'a',
  ...props
}: WrapperComponentProps<ComponentType, BaseLinkProps<ComponentType>>) => {
  return <Component {...(props?.href && { target: '_blank', rel: 'noopener noreferrer' })} {...props} />;
};
