import { createContext } from 'react';
import { RctType, RctControlScopeEnum, SecondPersonType, ScopeObjectType } from '../_utils';

export interface IRctControlContext {
  // ФРЦТ или РРЦТ + РЦТ или ВП\ПП
  scope: RctControlScopeEnum;
  setScope: (scope: RctControlScopeEnum) => void;
  // ФОИВ или РОИВ (зависит от scope)
  currentScopeObject: ScopeObjectType;
  // РЦТ текущего ФОИВ-а или РОИВ-а
  currentRct: RctType | null | undefined;
  setCurrentRct: (rct: RctType | null) => void;
  // Вице-премьеры (ФОИВ) либо полномочные представители (РОИВ)
  currentSecondPersons: SecondPersonType[];
  setCurrentSecondPersons: (persons: SecondPersonType[]) => void;
  // Список доступных для выбора РЦТ (ФРЦТ-шники либо РРЦТ-шники)
  rctList: RctType[];
  setRctList: (entries: RctType[]) => void;
  // Список доступных Вице-премьеров и полномочных представителей (общий для всех scope)
  secondPersonsList: SecondPersonType[];
  setSecondPersonsList: (entries: SecondPersonType[]) => void;
  // обновление данных ФОИВ-а или РОИВ-а, списка РЦТ и списка ВП\ПП
  refetchScopeData?: () => void;
  refetchRctList?: () => void;
  refetchSecondPersonsList?: () => void;
}

export const RctControlContext = createContext<IRctControlContext>({
  scope: RctControlScopeEnum.DepartmentRct,
  setScope: () => {},
  currentScopeObject: {
    scopeId: -1,
    name: '-'
  },
  currentRct: null,
  setCurrentRct: () => {},
  currentSecondPersons: [],
  setCurrentSecondPersons: () => {},
  rctList: [],
  setRctList: () => {},
  secondPersonsList: [],
  setSecondPersonsList: () => {},
  refetchScopeData: () => {},
  refetchRctList: () => {},
  refetchSecondPersonsList: () => {}
});
