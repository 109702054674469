import React from 'react';
import clsx from 'clsx';
import { CardVariant } from './types';
import s from './BaseCard.module.scss';

export interface BaseCardProps {
  className?: string;
  variant?: CardVariant;
  children?: React.ReactNode;
}

export const BaseCard = ({ className, variant = CardVariant.default, children }: BaseCardProps) => {
  return <div className={clsx(s.BaseCard, s[`BaseCard_${variant}`], className)}>{children}</div>;
};
