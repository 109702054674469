import React from 'react';
import clsx from 'clsx';
import { IconWarning } from 'componentsL/Icon';
import s from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
  title?: string;
  description?: React.ReactNode;
  className?: string;
  errorType?: 'warning' | 'info';
}

export function ErrorMessage({
  title,
  description = 'Попробуйте обновить страницу или зайдите через некоторое время',
  className,
  errorType = 'warning'
}: ErrorMessageProps) {
  return (
    <div className={clsx(s.ErrorMessage, className)}>
      <IconWarning className={s[`ErrorMessage__${errorType}Icon`]} />
      {title && <h4 className={s.ErrorMessage__title}>{title}</h4>}
      <p className={s.ErrorMessage__description}>{description}</p>
    </div>
  );
}
