import { memo, MouseEventHandler } from 'react';
import clsx from 'clsx';
import s from './DropdownItem.module.scss';

export interface DropdownItemProps {
  className?: string;
  value: string;
  isActive?: boolean;
  onClick?: MouseEventHandler;
}

export const DropdownItem = memo((props: DropdownItemProps) => {
  const { value, className, isActive, onClick } = props;

  return (
    <div onClick={onClick} className={clsx(s.DropdownItem, className)}>
      <p className={clsx(s.DropdownItem__text, { [s.DropdownItem__active]: isActive })}>{value}</p>
    </div>
  );
});

DropdownItem.displayName = 'DropdownItem';
