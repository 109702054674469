import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useGoBack } from '@digital-gov/ui-utils';
import { IconChevronLeft } from 'componentsL/Icon';
import { IconButton, IconButtonKind } from 'componentsL/IconButton';
import s from './PageHeader.module.scss';

export interface PageHeaderProps {
  backTo?: 'prevPage' | string | undefined;
  title: string;
  titleSecondRow?: string;
  pageTitle?: string;
  descriptor?: string;
  slotRight?: React.ReactNode;
  className?: string;
}

export function PageHeader({
  backTo,
  title,
  titleSecondRow,
  pageTitle,
  descriptor,
  slotRight,
  className
}: PageHeaderProps) {
  const goBack = useGoBack(backTo === 'prevPage' ? undefined : (backTo ?? '/'));

  return (
    <header className={clsx(s.PageHeader, className)}>
      <Helmet>
        <title>{pageTitle || title}</title>
      </Helmet>

      {backTo && (
        <IconButton onClick={() => goBack()} kind={IconButtonKind.Round}>
          <IconChevronLeft />
        </IconButton>
      )}

      <div className={s.PageHeader__meta}>
        <h2 className={s.PageHeader__title}>
          {!titleSecondRow ? (
            title
          ) : (
            <>
              {title}
              <br />
              {titleSecondRow}
            </>
          )}
        </h2>
        {descriptor && <p className={s.PageHeader__descriptor}>{descriptor}</p>}
      </div>

      {slotRight}
    </header>
  );
}
