export interface AccessNavigateToRegionProps {
  pointsLast?: number | null;
  isRRCT: boolean;
  isCurrentRegion: boolean;
  isObserver?: boolean | null;
}

export const accessNavigateToRegion = ({
  pointsLast,
  isRRCT,
  isCurrentRegion,
  isObserver
}: AccessNavigateToRegionProps) =>
  pointsLast !== undefined && pointsLast !== null && ((isRRCT && isCurrentRegion) || isObserver);
