export type CommentType = {
  id: number;
  publishDate: string;
  commentText: string;
};

export type CommentDraftType = {
  id: number;
  commentText: string;
};

/**
 * Модальные окна при отправке комментария/сохранении черновика
 */
export enum ControlViewState {
  // Ни одно из модальных окон не открыто
  None = 'None',
  // Окно ошибки
  CommentError = 'CommentError',
  // Главное окно комментария - с инпутом и историей
  CommentSection = 'CommentSection',
  // Окно отражающее успешный публикации комментария
  CommentSuccess = 'CommentSuccess',
  // Окно с подтверждением намерения отправки
  ConfirmPublishing = 'ConfirmPublishing',
  // Модальное окно - Предупреждение о требовании сохранении черновика (?)
  DraftAlert = 'DraftAlert'
}
