import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const epguServices = components[0]?.value;
  const csi = components[1]?.value;
  const departmentServicesCount = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={epguServices}
        description={
          'Значение показателя «Уровень удовлетворенности клиентов по услугам ведомства, опубликованным на Едином портале государственных и муниципальных услуг (функций)»'
        }>
        <Symbol sub={'com'}>M</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={csi}
        description={'Значение показателя CSI для услуги (функции) ведомства, удовлетворяющего описанным условиям'}>
        <Symbol sub={'s'}>M</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={departmentServicesCount}
        description={'Общее количество услуг (функций) ведомства, удовлетворяющих описанным условиям'}>
        <Symbol>n</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow
        values={[
          '0',
          'Уровень удовлетворенности клиентов по услугам ведомства, опубликованным на ЕПГУ составляет 0–2,9999'
        ]}
      />
      <TableRow
        values={[
          '0,5',
          'Уровень удовлетворенности клиентов по услугам ведомства, опубликованным на ЕПГУ составляет 3–3,9999'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          'Уровень удовлетворенности клиентов по услугам ведомства, опубликованным на ЕПГУ составляет 4–4,3999'
        ]}
      />
      <TableRow
        values={[
          '1,5',
          'Уровень удовлетворенности клиентов по услугам ведомства, опубликованным на ЕПГУ составляет 4,4–4,7999'
        ]}
      />
      <TableRow
        values={[
          '2,0',
          'Уровень удовлетворенности клиентов по услугам ведомства, опубликованным на ЕПГУ составляет более 4,8'
        ]}
      />
    </Table>
  );
}

export function SatisfactionLevel() {
  return (
    <IndicatorPage
      download={'Уровень удовлетворенности клиентов по услугам ведомства.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
