import { isFinite, isNil } from 'lodash-es';
/**
 * isNumberLike - позволяет понять, является ли переданный аргумент подобным числовому значению (Int, Float)
 * и возвращает boolean
 *
 * i.e.
 * isNumberLike(123): true
 * isNumberLike("123.2"): true
 *
 * isNumberLike("abc"): false
 * isNumberLike("123abc"): false
 * isNumberLike(""): false
 * isNumberLike(null): false
 * isNumberLike({ a: 1 }): false
 *
 * @param n: any
 * @return boolean
 */
export function isNumberLike(n) {
    return !isNil(n) && !isNaN(parseFloat(n)) && isFinite(+n);
}
export function tryNumberLike(value, defaultValue, convertToNumber = false) {
    if (isNumberLike(value)) {
        const val = value;
        return convertToNumber ? +val : val;
    }
    return defaultValue;
}
