import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const eld = components[0]?.value;
  const k1 = components[1]?.value;
  const k2 = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={eld}
        description={
          'Значение показателя «Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью»'
        }>
        <Symbol color={'#55D666'} square>
          ИЛД
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k1}
        roundUp
        unit={'шт.'}
        description={
          <>
            Количество электронных личных дел сотрудников ФОИВ <br />с привязкой к учетным записям пользователей ФГИС
            «ЕИСУКС»
          </>
        }>
        <Symbol sub={'1'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k2}
        roundUp
        unit={'шт.'}
        description={
          'Общее количество электронных личных дел сотрудников ФОИВ, назначенных на соответствующую должность в ФОИВ'
        }>
        <Symbol sub={'2'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение ИЛД, %']} isHeader />
      <TableRow
        values={[
          '0',
          'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 0,00 – 20,09 %'
        ]}
      />
      <TableRow
        values={[
          '1,0',
          'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 20,10 – 40,09 %'
        ]}
      />
      <TableRow
        values={[
          '2,0',
          'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 40,10 – 60,09 %'
        ]}
      />
      <TableRow
        values={[
          '3,0',
          'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 60,10 – 80,09 %'
        ]}
      />
      <TableRow
        values={[
          '4,0',
          'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 80,10 – 100,00 %'
        ]}
      />
    </Table>
  );
}

export function EisuksEldShare() {
  return (
    <IndicatorPage
      download={'Доля ЭЛД, связанных с УЗ ФГИС ЕИСУКС.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
