import { Autolinker } from 'autolinker';
import sanitizeHtml from 'sanitize-html';

const autoLinker = new Autolinker({
  urls: {
    schemeMatches: true,
    tldMatches: true,
    ipV4Matches: true
  },
  email: true,
  phone: true,
  mention: false,
  hashtag: false,

  stripPrefix: false,
  stripTrailingSlash: true,
  newWindow: true,

  truncate: {
    length: 0,
    location: 'end'
  }
});

export function sanitizeFaq(html: string): string {
  let cleanHtml = sanitizeHtml(html, {
    allowedTags: ['a', 's', 'b', 'br', 'p'],
    allowedAttributes: {
      a: ['href', 'hreflang', 'title', 'type', 'target', 'rel']
    },
    transformTags: {
      h1: 'p',
      h2: 'p',
      h3: 'p',
      h4: 'p',
      h5: 'p',
      h6: 'p',
      div: 'p',
      a: (tagName, attributes) => {
        return {
          tagName,
          attribs: {
            ...attributes,
            target: '_blank',
            rel: 'noopener noreferrer'
          }
        };
      }
    }
  });

  return autoLinker.link(
    cleanHtml
      // Упрощаем BR: sanitizeHTML генерирует <br />, но в HTML достаточно <br>
      .replaceAll(/<br(\s*\/)?>/gi, '<br>')
      // Множественные переносы заменяем одним
      .replaceAll(/(<br>|\n){2,}/g, '<br>')
      .replaceAll(/(\S)<p(\W)/g, '$1 <p$2')
      // Удаляем все переносы строк, обрамляющие контент тега, т.к. они добавляются в sanitizeHTML, при этом увеличивают
      //  объём HTML и могут влиять на вёрстку (например, при наличии CSS-правила `white-space: pre`)
      // $2$4 используется для вставки края тега, по такому выражению всегда присутствует только один из них:
      //  $2 = < или </
      //  $4 = >
      .replaceAll(/(\n(<\/?))|((>)\n)/g, '$2$4')
      .replaceAll(/(%[0-9A-F]{2})+/gi, (match) => decodeURI(match))
  );
}
