import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export function useGoBack(path) {
    const navigate = useNavigate();
    const location = useLocation();
    return useCallback((toPath) => {
        if (toPath || path) {
            navigate(toPath || path || '');
        }
        else if (location.key !== 'default') {
            navigate(-1);
        }
        else {
            navigate('/');
        }
    }, [location.key, navigate, path]);
}
