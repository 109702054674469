export function tryParseJSON(json, defaultValue = null, log) {
    try {
        if (!json)
            return defaultValue;
        return JSON.parse(json);
    }
    catch (e) {
        log === null || log === void 0 ? void 0 : log('Error parsing json', e, json);
        return defaultValue;
    }
}
