import React, { useMemo, useState } from 'react';
import { TableHeader } from './_components/TableHeader';
import { TableRow } from './_components/TableRow';
import { IndicatorsTableHeaderFields, IndicatorsTableRow, ISortedColumn } from './types';
import s from './IndicatorsTable.module.scss';

type Props = {
  rows: IndicatorsTableRow[];
};

export const IndicatorsTable = (props: Props) => {
  const [sortedColumn, setSortedColumn] = useState<ISortedColumn | undefined>(undefined);

  const data = useMemo(() => {
    const isIndexField = sortedColumn?.field === IndicatorsTableHeaderFields.Idx;
    const isAcsSort = sortedColumn?.sort === 'asc';
    if (!sortedColumn || (isIndexField && isAcsSort)) return props.rows;
    const sorted = [...props.rows];
    if (isIndexField && !isAcsSort) return sorted.reverse();
    return sorted.sort((a, b) => {
      if (sortedColumn.field === IndicatorsTableHeaderFields.Idx) return 0;
      const sortA = a[sortedColumn.field] || 0;
      const sortB = b[sortedColumn.field] || 0;
      if (isAcsSort) {
        return sortA > sortB ? -1 : 1;
      } else {
        return sortA < sortB ? -1 : 1;
      }
    });
  }, [props.rows, sortedColumn]);

  return (
    <div className={s.IndicatorsTable}>
      <TableHeader sortedColumn={sortedColumn} onColumnSortPress={setSortedColumn} />
      {data.map((el, idx) => (
        <TableRow key={`ort-${idx}`} {...el} idx={idx + 1} />
      ))}
    </div>
  );
};
