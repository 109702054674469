import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { AuthApolloProvider, AuthScope } from '@digital-gov/auth-apollo-store';
import { apiUrl, basename } from 'store/config';
import { ProfileProvider } from 'store/profile/ProfileProvider';
import App from './App';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import 'styles/fonts.scss';
import 'styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <HelmetProvider>
        <AuthApolloProvider authScope={AuthScope.Rating} apiUrl={apiUrl} redirectPathname={`${basename}/auth`}>
          <ProfileProvider>
            <App />
          </ProfileProvider>
        </AuthApolloProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
