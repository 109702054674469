import React, { useState } from 'react';
import { RegionRatingType } from 'store/graphql';
import { IRegionsFilter } from '../RegionsFilters';
import { Info } from './Info/Info';
import { Map } from './Map/Map';
import { MapMode } from './regions-map.types';
import s from './RegionsMap.module.scss';

export interface RegionsMapProps {
  regionalRating?: RegionRatingType;
  filter: IRegionsFilter;
}

export const RegionsMap = ({ regionalRating, filter }: RegionsMapProps) => {
  const [mode, setMode] = useState<MapMode>(MapMode.Regions);
  const [regionId, setRegionId] = useState<number | string>();

  const handleRegionSelect = (id: number | string) => {
    setRegionId((prevId) => (prevId === id ? undefined : id));
  };

  const handleRegionReset = () => {
    setRegionId(undefined);
  };

  return (
    <div className={s.RegionsMap}>
      <div className={s.RegionsMap__map}>
        <Map
          regionalRating={regionalRating}
          selectedRegionId={regionId}
          filter={filter}
          mode={mode}
          onRegionSelect={handleRegionSelect}
        />
      </div>
      <div className={s.RegionsMap__info}>
        <Info
          regionalRating={regionalRating}
          selectedRegionId={regionId}
          onRegionReset={handleRegionReset}
          mode={mode}
          onModeChange={setMode}
        />
      </div>
    </div>
  );
};
