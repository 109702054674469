import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { RegionRatingType, RegionType } from 'store/graphql';
import { IRegionsFilter } from '../../RegionsFilters';
import { RegionMapUtils } from '../RegionMapUtils';
import { MapMode, MSK_REGION, UNKNOWN_REGION } from '../regions-map.types';
import vodichkaSrc from './assets/vodichka.svg';
import { MapItem } from './MapItem';
import s from './Map.module.scss';

const vodichkaArr = new Array(8).fill(vodichkaSrc);

export interface MapProps {
  regionalRating?: RegionRatingType;
  selectedRegionId?: number | string;
  filter: IRegionsFilter;
  mode: MapMode;
  onRegionSelect: (id: number | string) => void;
}

export const Map = ({ regionalRating, selectedRegionId, filter, mode, onRegionSelect }: MapProps) => {
  const regions = regionalRating?.regions;

  const groupedRegions = useMemo(() => {
    if (!regions?.length) return [];
    return RegionMapUtils.regionsRows.map((row) => {
      return row.map((id) => {
        return regions.find((r) => r.regionId === id);
      });
    });
  }, [regions]);

  const handleItemClick = useCallback(
    (id?: number | string) => {
      if (isNil(id)) return;
      onRegionSelect(id);
    },
    [onRegionSelect]
  );

  // тут проставляются цвета регионов по нужным условиям
  const getItemColor = useCallback(
    (region?: RegionType, filtered?: boolean) => {
      const colorObject = (() => {
        switch (mode) {
          case MapMode.Regions:
            return RegionMapUtils.mapRegionDefaultColors(region?.regionShortName || null);
          case MapMode.EffectGroup:
            return RegionMapUtils.mapEffectGroupColors(region?.ratingGroup || null);
        }
      })();
      return filtered ? colorObject.filtered : colorObject.default;
    },
    [mode]
  );

  return (
    <div className={s.Map}>
      <div className={s.Map__content}>
        {groupedRegions.map((row, iRow) => (
          <div key={iRow} className={s.Map__row}>
            {row.map((r, i) => {
              const filtered = !!r && RegionMapUtils.checkMapItemIsFiltered(r, filter);
              const region = r ? r : iRow === 3 && i === 2 ? MSK_REGION : UNKNOWN_REGION;
              return (
                <MapItem
                  key={i}
                  className={clsx(s[`Map__item_${iRow + 1}${i + 1}`])}
                  regionId={region.regionId}
                  code={region.regionShortCode}
                  color={getItemColor(r, filtered)}
                  selected={region.regionId === selectedRegionId}
                  filtered={filtered}
                  onClick={() => handleItemClick(region.regionId)}
                />
              );
            })}
          </div>
        ))}
        {vodichkaArr.map((v, i) => (
          <img key={i} className={clsx(s.Map__water, s[`Map__water_${i + 1}`])} src={v} alt="" />
        ))}
      </div>
    </div>
  );
};
