import { RegionRatingGroupEnum } from 'store/graphql';
import { IRegionsFilter } from './types';

export const ratingGroups: RegionRatingGroupEnum[] = [];

for (let item in RegionRatingGroupEnum) {
  ratingGroups.push(item as RegionRatingGroupEnum);
}

export const POINTS_LAST_ID = 'pointsLast';

export const initialFilter: IRegionsFilter = {
  search: '',
  ratingGroup: null,
  representative: null
};
