import { formatNumber } from '@digital-gov/ui-utils';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './RobotMaxServices.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const q1 = components[0]?.value;
  return (
    <>
      <h4 className={s.RobotMaxServices__heading}>Компоненты</h4>
      <div className={s.RobotMaxServices__content}>
        <Symbol sub={'1'}>Q</Symbol>
        <span className={s.RobotMaxServices__equality}>=</span>
        <div className={s.RobotMaxServices__value}>{formatNumber(q1, 0)}</div>
        <span className={s.RobotMaxServices__text}>
          Фактическое количество федеральных услуг, выведенных ФОИВ в диалоговом окне на ЕПГУ на дату предоставления
          отчетности
        </span>
      </div>
    </>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение Z']} isHeader />
      <TableRow values={['0', '0 услуг']} />
      <TableRow values={['0,5', '1 услуга']} />
      <TableRow values={['1,0', '2 услуги и больше']} />
    </Table>
  );
}

export function RobotMaxServices() {
  return (
    <IndicatorPage
      download={'Перевод услуг в диалоговый режим Робота Макса.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.AtLeast15EveryMonth}
    />
  );
}
