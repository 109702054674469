import { useMemo, useState } from 'react';
import { cloneDeep } from 'lodash-es';
import { RegionRatingType } from 'store/graphql';
import { IRegionsFilter, initialFilter, ViewMode } from '../RegionsFilters';

export const useRegionsFilter = (mode: ViewMode, regionalRating?: RegionRatingType) => {
  const [filter, setFilter] = useState<IRegionsFilter>(initialFilter);

  const filteredData = useMemo(() => {
    if (!regionalRating) {
      return null;
    }

    const clonedData = cloneDeep(regionalRating);

    let regions = clonedData.regions;

    if (filter.ratingGroup) {
      regions = regions.map((region) =>
        region.ratingGroup === filter.ratingGroup
          ? region
          : {
              ...region,
              filtered: true
            }
      );
    }

    if (filter.representative) {
      regions = regions.map((region) =>
        region.representatives[0]?.fullName === filter.representative
          ? region
          : {
              ...region,
              filtered: true
            }
      );
    }

    if (filter.search) {
      const search = filter.search.trim().toLowerCase();

      // в режиме карты результат фильтра проходит постобработку, но подразумевается полный как в таблице РРЦТ
      if (mode === 'table-full' || mode === 'map') {
        regions = regions.map((region) =>
          region.regionName.toLowerCase().includes(search) ||
          region.regionShortName.toLowerCase().includes(search) ||
          region.rrct?.toLowerCase().includes(search) ||
          region.representatives[0]?.fullName.toLowerCase().includes(search)
            ? region
            : {
                ...region,
                filtered: true
              }
        );
      } else {
        regions = regions.map((region) =>
          region.regionName.toLowerCase().includes(search)
            ? region
            : {
                ...region,
                filtered: true
              }
        );
      }
    }

    clonedData.regions = regions;

    return clonedData;
  }, [filter, mode, regionalRating]);

  return {
    filteredData,
    filter,
    setFilter
  };
};
