import React from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { Alert } from '../Alert';

export const ServiceWork = () => {
  const showAlert =
    null !==
    matchRoutes(
      [
        {
          path: '/'
        },
        {
          path: '/regions'
        }
      ],
      useLocation()
    );

  if (!showAlert) {
    return null;
  }

  return (
    <Alert>
      Сегодня, 20 сентября, с 14:00 до 20:00 мск на сайте будут проводится технические работы: сервис может быть
      временно недоступен.
      <br />
      Приносим извинения за неудобства.
    </Alert>
  );
};
