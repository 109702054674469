import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { MSK_REGION } from '../regions-map.types';
import s from './Map.module.scss';

export interface MapItemProps {
  className?: string;
  regionId: string | number;
  code: string;
  color: string;
  selected: boolean;
  filtered: boolean;
  onClick?: MouseEventHandler;
}

export const MapItem = ({ className, regionId, code, color, selected, filtered, onClick }: MapItemProps) => {
  const handleClick: MouseEventHandler = (e) => {
    if (filtered) return;
    onClick?.(e);
  };

  const clickable = !!onClick && !filtered;

  return (
    <button
      className={clsx(s.MapItem, className, {
        [s.MapItem_clickable]: clickable,
        [s.MapItem_selected]: selected,
        [s.MapItem_filtered]: filtered,
        [s.MapItem_msk]: regionId === MSK_REGION.regionId
      })}
      style={{
        backgroundColor: color
      }}
      disabled={!clickable}
      onClick={handleClick}>
      {code}
    </button>
  );
};
