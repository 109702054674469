import { getFileKey } from 'utils/getFileKey';
import { Pipe } from './Pipe';
import { FileUploaderInterface } from './types';

export abstract class AbstractFileUploader<FileIdType = number>
  extends Pipe<number>
  implements FileUploaderInterface<FileIdType>
{
  private _stopUpload: null | (() => void) = null;
  private _isRun: boolean = false;
  private _isDone: boolean = false;
  private _promise: Promise<void> | null = null;
  private _id: FileIdType | null = null;
  private _error: Error | null = null;

  protected constructor(public readonly file: File) {
    super(0);
  }

  async run(): Promise<void> {
    if (this._promise) {
      await this._promise;
      return;
    }

    if (this._isDone) {
      return;
    }

    this._isRun = true;
    this._isDone = false;
    this._promise = this.upload();

    await this._promise;

    this._isRun = false;
    this._isDone = true;
    this._promise = null;
  }

  stop() {
    if (!this._isDone) {
      this._isRun = false;
      this._isDone = false;
    }

    if (this._stopUpload) {
      this._stopUpload();
      this._promise = null;
    }
  }

  get key() {
    return getFileKey(this.file);
  }

  get id(): FileIdType | null {
    return this._id;
  }

  get progress() {
    return this.current;
  }

  get error() {
    return this._error;
  }

  get isUploading() {
    return this._isRun && !this.isDone;
  }

  get isDone() {
    return this._isDone;
  }

  get isFailed() {
    return this.isDone && this.error !== null;
  }

  async waitEnd(): Promise<void> {
    await this._promise;
  }

  private async upload(): Promise<void> {
    try {
      let setFileContent: (content: string) => void;
      let fileLoadContent = new Promise<string>((resolve) => (setFileContent = resolve));

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        setFileContent(String(event.target!.result));
      });
      reader.addEventListener('progress', (event) => {
        if (event.loaded && event.total) {
          // Прогресс загрузки не ясен, пока используется подход: чтение файла - первые 50%, остальные - отправка файла
          this.add((event.loaded / event.total) * 50);
        }
      });
      reader.readAsDataURL(this.file);

      this._id = await this.request(fileLoadContent);

      this._isDone = true;
      this.add(100);
    } catch (err) {
      this._error = err as Error;
      this.end(0);
    }
  }

  protected abstract request(fileLoadContent: Promise<any>): Promise<FileIdType>;
}
