import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './FeedbackPlatform.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const feedbackPlatformIntegration = components[0]?.value;
  const ratingTotalScore = components[1]?.value;
  const indicatorMaxScore = components[2]?.value;
  const ratingMaxScore = components[3]?.value;

  return (
    <SymbolsGrid columns={4}>
      <SymbolDetails
        value={feedbackPlatformIntegration}
        description={'Значение показателя «Внедрение Платформы обратной связи»'}>
        <Symbol>I</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={ratingTotalScore}
        description={
          'Количество баллов, которые получил субъект Российской Федерации в Рейтинге, актуальным на дату расчета показателя'
        }>
        <Symbol>R</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={indicatorMaxScore}
        description={
          <>
            Максимальное количество баллов <br />
            по показателю (константа), значение равно 6
          </>
        }>
        <Symbol>m</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={ratingMaxScore}
        description={
          'Максимальное количество баллов, которые субъект Российской Федерации может получить в Рейтинге (670 баллов)'
        }>
        <Symbol styleUp={{ padding: '0 4px', backgroundColor: '#314278' }} up={'R'}>
          M
        </Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <>
      <p className={s.FeedbackPlatform__calc_p}>
        Итоговая оценка показателя определяется в баллах и равна значению показателя.
      </p>
      <p className={s.FeedbackPlatform__calc_p}>Максимальное количество баллов по показателю – 6,0.</p>
    </>
  );
}

export function FeedbackPlatform() {
  return (
    <IndicatorPage
      regionScope
      download={'Внедрение ПОС.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
