import { isNil } from 'lodash-es';
export function isPositive(value) {
    if (isNil(value))
        return false;
    return value > 0;
}
export function isNegative(value) {
    if (isNil(value))
        return false;
    return value < 0;
}
export function getNumberSign(value) {
    if (isNil(value))
        return '';
    return isPositive(value) ? '+' : isNegative(value) ? '-' : '';
}
export function addNumberSign(value) {
    if (isNil(value))
        return value;
    return isPositive(value) ? `+ ${value}` : isNegative(value) ? `- ${value * -1}` : value;
}
