import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const garEnrichmentScore = components[0]?.value;
  const cadastralNumbersCount = components[1]?.value;
  const garAddressesObjectsCount = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={garEnrichmentScore}
        unit={'%'}
        description={
          'Доля кадастровых номеров, размещенных в привязке к адресам объектов адресации в ГАР, к общему количеству адресов объектов адресации в субъекте Российской Федерации'
        }>
        <Symbol sub={'i'}>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={cadastralNumbersCount}
        roundUp
        unit={'шт.'}
        description={
          <>
            Количество кадастровых номеров, размещенных в привязке <br />к адресам объектов адресации в ГАР
          </>
        }>
        <Symbol sub={'1'}>N</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={garAddressesObjectsCount}
        roundUp
        unit={'шт.'}
        description={
          <>
            Общее количество адресов объектов адресации, размещенных <br />в ГАР
          </>
        }>
        <Symbol sub={'2'}>N</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', '40 < Fi']} />
      <TableRow values={['0,5', '40 ≤ Fi ≤ 64']} />
      <TableRow values={['1,0', '65 ≤ Fi ≤ 79']} />
      <TableRow values={['1,5', '80 ≤ Fi ≤ 94']} />
      <TableRow values={['2,0', 'Fi > 95']} />
    </Table>
  );
}

export function GarEnrichment() {
  return (
    <IndicatorPage
      regionScope
      download={'Обогащение ГАР кадастровыми номерами.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
