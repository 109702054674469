import React, { useState } from 'react';
import clsx from 'clsx';
import { EMDASH } from '@digital-gov/ui-utils';
import { Icon, IconChangeDown, IconChangeNone, IconChangeUp, IconDismiss, IconSize } from 'componentsL/Icon';
import { CommonMviType, MviType } from '../../types';
import { GaugeCardGridItem } from './GaugeCardGridItem';
import { Speedometer, SpeedometerProps } from './Speedometer';
import s from './GaugeCard.module.scss';

export enum GaugeCardSize {
  Medium = 'medium',
  Large = 'large'
}

type Props = {
  cardType: MviType;
  size?: GaugeCardSize;
  item: CommonMviType;
  onSelect?: (item: CommonMviType) => void;
  hoverAccepted?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  startAnimation?: boolean;
  classes?: {
    root?: string;
    name?: string;
    footer?: string;
    speedometer?: SpeedometerProps['classes'];
    gridItemValue?: string;
  };
};

const MviTypeName: Record<MviType, string> = {
  [MviType.Rrct]: 'РОИВ',
  [MviType.Frct]: 'ФОИВ'
};

export function GaugeCard({
  classes,
  size = GaugeCardSize.Medium,
  cardType,
  item,
  onSelect,
  hoverAccepted,
  selected,
  onClose,
  disabled,
  startAnimation
}: Props) {
  const { averageDegree, maxShare, minShare, quantity, dynamic, name, pointsAverage, pointsMax } = item;

  const [hovered, setHovered] = useState(false);

  const renderDynamicsIcon = () => {
    if (dynamic === undefined || dynamic === null) {
      return <></>;
    }
    switch (true) {
      case dynamic > 0:
        return <Icon className={s.GaugeCard__dynamicUp} icon={IconChangeUp} size={IconSize.Medium} />;
      case dynamic < 0:
        return <Icon className={s.GaugeCard__dynamicDown} icon={IconChangeDown} size={IconSize.Medium} />;
      default:
        return <Icon className={s.GaugeCard__dynamicNone} icon={IconChangeNone} size={IconSize.Medium} />;
    }
  };

  const onPress = () => {
    if (onSelect) onSelect(item);
  };

  const style = clsx(
    s.GaugeCard,
    s[`GaugeCard_${size}`],
    onSelect && s.GaugeCard__pointer,
    hoverAccepted && s.GaugeCard__hover,
    selected && s.GaugeCard__selected,
    disabled && s.GaugeCard__disabled,
    classes?.root
  );

  return (
    <div
      className={style}
      onClick={onPress}
      onMouseOver={hoverAccepted ? () => setHovered(true) : undefined}
      onMouseOut={hoverAccepted ? () => setHovered(false) : undefined}>
      <div className={s.GaugeCard__title}>
        <div className={clsx(s.GaugeCard__name, classes?.name)}>{name}</div>
        {onClose && (
          <Icon
            className={s.GaugeCard__close}
            icon={IconDismiss}
            size={IconSize.Medium}
            onClick={(e) => {
              e.stopPropagation();
              onClose?.();
            }}
          />
        )}
      </div>
      <div>
        <Speedometer
          classes={{
            root: clsx(
              s.GaugeCard__speedometer,
              onSelect && s.GaugeCard__speedometer_pointer,
              classes?.speedometer?.root
            ),
            cell: classes?.speedometer?.cell
          }}
          startAnimation={startAnimation}
          valuePercentage={averageDegree}
          hovered={hovered || selected}
          disabled={disabled}
        />
        <div className={clsx(s.GaugeCard__footer, classes?.footer)}>
          <div className={s.GaugeCard__averageValueWrapper}>
            <div
              className={s.GaugeCard__averageValue}
              style={{
                transform: startAnimation ? `translateX(-${averageDegree * 100}px)` : undefined,
                animationTimingFunction: startAnimation ? `steps(${averageDegree}, start)` : undefined,
                animationName: startAnimation ? s['percent-increase'] : undefined
              }}>
              {new Array(averageDegree + 1).fill(null).map((_, index) => (
                <span key={index}>{index}%</span>
              ))}
            </div>
          </div>
          <div className={s.GaugeCard__averageText}>Средняя степень достижения</div>
        </div>
      </div>
      <div className={s.GaugeCard__divider} />
      <div className={s.GaugeCard__dataGrid}>
        <GaugeCardGridItem
          classNameValue={clsx(s.GaugeCard__gridItemValue, classes?.gridItemValue)}
          text="Максимальный балл"
          disabled={disabled}>
          {pointsMax}
        </GaugeCardGridItem>
        <GaugeCardGridItem
          classNameValue={clsx(s.GaugeCard__gridItemValue, classes?.gridItemValue)}
          text="Средний балл"
          disabled={disabled}>
          {pointsAverage}
        </GaugeCardGridItem>
        <GaugeCardGridItem classNameValue={clsx(s.GaugeCard__gridItemValue, classes?.gridItemValue)} text="Динамика">
          <div className={s.GaugeCard__dynamicRow}>
            {dynamic !== undefined && dynamic !== null && renderDynamicsIcon()}
            {dynamic ?? EMDASH}
          </div>
        </GaugeCardGridItem>
        <GaugeCardGridItem
          classNameValue={clsx(s.GaugeCard__gridItemValue, classes?.gridItemValue)}
          text={
            <>
              Кол-во {MviTypeName[cardType]},<br />
              участвующих в&nbsp;показателе
            </>
          }
          disabled={disabled}>
          {quantity}
        </GaugeCardGridItem>
        <GaugeCardGridItem
          classNameValue={clsx(s.GaugeCard__gridItemValue, classes?.gridItemValue)}
          text={
            <>
              Доля {MviTypeName[cardType]},<br />
              имеющих MAX балл(%)
            </>
          }
          disabled={disabled}>
          {maxShare}%
        </GaugeCardGridItem>
        <GaugeCardGridItem
          classNameValue={clsx(s.GaugeCard__gridItemValue, classes?.gridItemValue)}
          text={
            <>
              Доля {MviTypeName[cardType]},<br />
              имеющих MIN балл(%)
            </>
          }
          disabled={disabled}>
          {minShare}%
        </GaugeCardGridItem>
      </div>
      {disabled ? <div className={s.GaugeCard__overlay} /> : null}
    </div>
  );
}
