export function offsetLimitPagination(fieldName, keyArgs) {
    return {
        [fieldName]: {
            keyArgs,
            merge(existing, incoming, { variables }) {
                var _a;
                const offset = (_a = variables === null || variables === void 0 ? void 0 : variables.offset) !== null && _a !== void 0 ? _a : 0;
                const merged = existing ? existing.slice(0) : [];
                for (let i = 0; i < incoming.length; ++i) {
                    merged[offset + i] = incoming[i];
                }
                return merged;
            }
        },
        pagination: {
            keyArgs
        }
    };
}
