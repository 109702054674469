import React from 'react';
import { Search } from 'components/Icon';
import { Input } from './Input';

export function SearchInput({
  value,
  onChange,
  disabled,
  placeholder,
  dimmedPlaceholder
}: {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  dimmedPlaceholder?: boolean;
}) {
  return (
    <Input
      disabled={disabled}
      iconLeft={Search}
      type={'search'}
      placeholder={placeholder ?? 'Поиск'}
      dimmedPlaceHolder={dimmedPlaceholder ?? false}
      value={value}
      onChange={onChange}
    />
  );
}
