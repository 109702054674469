import React, { useCallback } from 'react';
import { Hint, HintVariant } from 'componentsL/Hint/Hint';
import { FileUploadClientError, FileUploaderInterface, FileUploaderManager } from 'utils/FileUploader';
import { FilesList } from './FilesList';

export interface FilesControlProps {
  limit: number;
  files: FileUploaderInterface<number>[];
  uploadManager: FileUploaderManager<number>;
  disabled?: boolean;
  disabledReason?: string | void;
  addFiles: (files: File[]) => void;
  removeFile: (key: string) => void;
}

export const FilesControl = ({
  limit,
  files,
  uploadManager,
  disabled,
  disabledReason,
  addFiles,
  removeFile
}: FilesControlProps) => {
  const handleAddFiles = useCallback(
    (newFiles: File[]) => {
      // Откидываем файлы, которые уже добавлены
      newFiles = newFiles.filter((file) => !uploadManager.exists(file));
      // Откидываем файлы, которые выходят за лимит
      addFiles(newFiles.slice(0, limit - uploadManager.files.length));
    },
    [limit, uploadManager, addFiles]
  );

  const handleRemoveFile = useCallback(
    (key: string) => {
      removeFile(key);
    },
    [removeFile]
  );

  return (
    <>
      <FilesList
        files={files.map((fileUploader) => ({
          name: fileUploader.file.name,
          size: fileUploader.file.size,
          key: fileUploader.key,
          loading: !fileUploader.isDone,
          error: fileUploader.isFailed,
          errorMessage: fileUploader.error instanceof FileUploadClientError ? fileUploader.error.message : void 0
        }))}
        disabled={disabled}
        limit={8}
        fileSizeLimit={20}
        availableMultiple
        accept=".pdf, .xlsx, .xls, .docx, .doc, .pptx, .ppt"
        acceptFileTypes=".pdf, .xlsx, .xls, .docx, .doc, .pptx, .ppt"
        onAddFiles={handleAddFiles}
        onRemoveFile={handleRemoveFile}
      />
      {disabled && disabledReason && <Hint variant={HintVariant.Error}>{disabledReason}</Hint>}
    </>
  );
};
