import { useCallback, useState } from 'react';
import { IconButton } from 'components/Button';
import { DropdownList, DropdownListItem } from 'components/DropdownList';
import { DotsIcon, UnlinkIcon } from 'components/Icon';
import { Spinner, SpinnerKind } from 'components/Spinner';
import { useDropdown } from 'hooks/useDropdown';
import s from './Responsible.module.scss';

export interface ResponsibleProps {
  data?: { fullName: string; email: string | null } | null;
  handleUnlink?: () => void;
}

export function Responsible({ data, handleUnlink }: ResponsibleProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { showDropdown, setDropdownElement, handleDropdownToggle, handleDropdownClose } = useDropdown();

  const unlinkResponsible = useCallback(() => {
    setLoading(true);
    if (handleUnlink) {
      handleUnlink();
    }
    setLoading(false);
    handleDropdownClose();
  }, [handleUnlink, setLoading, handleDropdownClose]);

  return (
    <>
      {data && (
        <div className={s.Responsible__entry}>
          <div className={s.Responsible__nameAndEmail}>
            <div>{data.fullName}</div>
            {data.email && <div className={s.Responsible__emailText}>{data.email}</div>}
          </div>
          {handleUnlink && (
            <div className={s.Responsible__dotsIcon} ref={setDropdownElement}>
              <IconButton icon={DotsIcon} onClick={handleDropdownToggle} />
              {showDropdown && (
                <DropdownList className={s.Responsible__actions}>
                  <DropdownListItem>
                    <div className={s.Responsible__action} onClick={unlinkResponsible}>
                      {loading ? <Spinner kind={SpinnerKind.Inline} /> : <UnlinkIcon />}
                      <div>Отвязать</div>
                    </div>
                  </DropdownListItem>
                </DropdownList>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
