export function saveAuthTarget(target) {
    // устанавливает целевую страницу для возвращения после успешной авторизации
    sessionStorage.setItem('target', target || '/');
}
export function getAuthTarget(basename = '') {
    const target = sessionStorage.getItem('target');
    if (!target || target === basename || target.includes('/auth'))
        return '/';
    return target.replace(basename, '');
}
