import React from 'react';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import s from './TabBar.module.scss';

export interface TabBarProps {
  className?: string;
  items: {
    icon: React.ElementType;
    to?: string;
    navTo?: string;
    end?: boolean;
    active?: boolean;
  }[];
}

export function TabBar({ items, className }: TabBarProps) {
  return (
    <nav className={clsx(s.TabBar, className)}>
      {items.map(({ icon: Icon, to, navTo, end, active }) =>
        to ? (
          <Link key={to} to={to} className={clsx(s.TabBar__tab, active && s.TabBar__tab_active)}>
            <Icon />
          </Link>
        ) : navTo ? (
          <NavLink
            key={navTo}
            to={navTo}
            end={end}
            className={({ isActive }) => clsx(s.TabBar__tab, isActive && s.TabBar__tab_active)}>
            <Icon />
          </NavLink>
        ) : null
      )}
    </nav>
  );
}
