import { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from 'componentsL/ErrorMessage';
import { Loader } from 'componentsL/Loader';
import { Modal, ModalContentGroup } from 'componentsL/Modal';
import { Tabs, TabType } from 'componentsL/Tabs/Tabs';
import { useFeedbackAndIndicatorsList } from 'hooks/useFeedbackAndIndicatorsList';
import { RatingFeedbackScope } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { HistoryContent } from './_components/HistoryContent';

enum TabIndex {
  All,
  Mine
}

const TabsList: TabType<number>[] = [
  {
    label: 'Мои',
    value: TabIndex.Mine
  },
  {
    label: 'Все',
    value: TabIndex.All
  }
];

interface FeedbackHistoryProps {
  scope: RatingFeedbackScope;
  scopeId: number;
  indicatorId?: number;
  onSelectFeedback: (feedback: Feedback) => void;
  onClose: () => void;
}

export const FeedbackHistory = ({ scope, scopeId, indicatorId, onSelectFeedback, onClose }: FeedbackHistoryProps) => {
  const { profile } = useProfile();
  const [loading, error, list] = useFeedbackAndIndicatorsList(scope, scopeId);
  const [activeTab, setActiveTab] = useState(TabIndex.All);
  const [filteredList, setFilteredList] = useState<Feedback[]>([]);

  useEffect(() => {
    if (activeTab === TabIndex.All) {
      setFilteredList(list);
    } else if (profile !== void 0 && profile !== null) {
      setFilteredList(list.filter((item) => item.publisherId === profile.id));
    }
  }, [profile, list, activeTab, indicatorId]);

  const loadContent = useMemo(() => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <ErrorMessage title="Ошибка" description="Не удалось получить список заявок. Попробуйте позднее." />;
    }

    return null;
  }, [loading, error]);

  return (
    <Modal title="История заявок" width={624} onClose={onClose}>
      {loading && loadContent}
      {!loading && list.length > 0 && (
        <>
          <Tabs tabs={TabsList} value={activeTab} onChange={setActiveTab} />
          <HistoryContent list={filteredList} onSelectFeedback={onSelectFeedback} />
        </>
      )}
      {!loading && list.length === 0 && (
        <ModalContentGroup>На текущий момент не было подано ни одной заявки.</ModalContentGroup>
      )}
    </Modal>
  );
};
