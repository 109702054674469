import { memo, ReactNode, Ref, useRef } from 'react';
import clsx, { ClassValue } from 'clsx';
import s from './DropdownList.module.scss';

export interface DropdownListClasses {
  root?: ClassValue;
  items?: ClassValue;
}

export interface DropdownListProps {
  children?: ReactNode; // Слот для списка значений
  itemsRef?: Ref<HTMLDivElement>; // Реф на элемент списка значений
  className?: string;
  classes?: DropdownListClasses;
}

export const DropdownList = memo((props: DropdownListProps) => {
  const { classes, className, children, itemsRef } = props;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className={clsx(s.DropdownList, className, classes?.root)} {...props}>
      {children && (
        <div ref={itemsRef} className={clsx(s.DropdownList__items, classes?.items)}>
          {children}
        </div>
      )}
    </div>
  );
});

DropdownList.displayName = 'DropdownList';
