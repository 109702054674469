import React from 'react';
import { EMDASH } from '@digital-gov/ui-utils';
import { IndicatorPage, UpdateDays } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import { SmevExploitationComponents } from './SmevExploitationComponents';
import s from './SmevExploitation.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const A = components[0]?.value;
  const B = components[3]?.value;
  const k5 = components[6]?.value;
  const k6 = components[7]?.value;
  return (
    <>
      <SymbolsGrid columns={4}>
        <SymbolDetails
          value={A}
          description={
            'Характеристика, описывающая среднее значение баллов по всем ИС по скорости выполнения и доступности информационного обмена в СМЭВ3'
          }>
          <Symbol>A</Symbol>
        </SymbolDetails>
        <SymbolDetails
          value={B}
          description={
            'Характеристика, описывающая среднее значение баллов по всем ИС по скорости выполнения и доступности информационного обмена в СМЭВ4'
          }>
          <Symbol>B</Symbol>
        </SymbolDetails>
        <SymbolDetails
          value={k5}
          description={'Критерий, описывающий качество данных, являющихся предметом информационного обмена'}>
          <Symbol sub={'5'}>K</Symbol>
        </SymbolDetails>
        <SymbolDetails value={k6} description={'Критерий, описывающий качество сопровождения функционирования ИС'}>
          <Symbol sub={'6'}>K</Symbol>
        </SymbolDetails>
      </SymbolsGrid>
      <SmevExploitationComponents components={components} />
    </>
  );
}

function IndicatorTable() {
  return (
    <div className={s.SmevExploitation__calculation}>
      <p className={s.SmevExploitation__mainParagraph}>
        Итоговая оценка показателя определяется в баллах и равна значению показателя
      </p>
      <p className={s.SmevExploitation__paragraph}>Максимальное количество баллов по показателю {EMDASH} 5,0</p>
    </div>
  );
}

export function SmevExploitation() {
  return (
    <IndicatorPage
      download={'Эксплуатация СМЭВ_v2.pdf'}
      regionScope
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.AtLeast15}
    />
  );
}
