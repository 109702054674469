import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const satisfactionLevel = components[0]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={satisfactionLevel} description={'Значение показателя «Уровень удовлетворенности услуг»'}>
        <Symbol>L</Symbol>
      </SymbolDetails>
      <SymbolDetails
        description={'Балл удовлетворенности граждан в отношении услуги из перечня МСЗУ субъекта Российской Федерации'}>
        <div style={{ display: 'flex', gap: 10 }}>
          <Symbol sub={'1'}>Y</Symbol>
          <p>{','}</p>
          <Symbol sub={'n'}>Y</Symbol>
        </div>
      </SymbolDetails>
      <SymbolDetails
        description={
          'Количество МСЗУ, предусмотренных к переводу в электронный формат, в соответствии с перечнем МСЗУ субъекта Российской Федерации'
        }>
        <Symbol color="#fff">n</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

export function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'L ≤ 3,8 балла']} />
      <TableRow values={['1,0', '3,81 балла < L < 4,39 балла']} />
      <TableRow values={['2,0', '4,4 балла ≤ L']} />
    </Table>
  );
}

export function SatisfactionLevel() {
  return (
    <IndicatorPage
      regionScope
      download={'Уровень удовлетворенности МСЗУ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
