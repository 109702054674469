import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Icon, IconPlus, IconMinus } from 'componentsL/Icon';
import s from './Spoiler.module.scss';

export interface SpoilerProps {
  title: React.ReactNode;
  className?: string;
  classTitle?: string;
  classContent?: string;
  collapsed: boolean;
  onTitleClick: () => void;
  children: React.ReactNode;
}

export const Spoiler = ({
  title,
  className,
  classTitle,
  classContent,
  collapsed,
  onTitleClick,
  children
}: SpoilerProps) => {
  const textRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = useCallback(() => {
    onTitleClick();
  }, [onTitleClick]);

  return (
    <div className={clsx(s.Spoiler, className)}>
      <div className={clsx(s.Spoiler__title, classTitle)} onClick={handleToggle}>
        {title}
        {collapsed ? <Icon icon={IconPlus} /> : <Icon icon={IconMinus} />}
      </div>
      <div
        className={s.Spoiler__content}
        style={{ height: collapsed || textRef.current === null ? 0 : textRef.current!.clientHeight }}>
        <div ref={textRef} className={clsx(s.Spoiler__text, classContent)}>
          {children}
        </div>
      </div>
    </div>
  );
};
