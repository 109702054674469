import React from 'react';
import clsx from 'clsx';
import { Icon, IconCarouselView, IconListView } from 'componentsL/Icon';
import { ViewMode } from '../../types';
import s from './ChangeModeBlock.module.scss';

export type ChangeModeBlockProps = {
  className?: string;
  mode: ViewMode;
  onSetMode: (mode: ViewMode) => void;
};

export const ChangeModeBlock = ({ className, mode, onSetMode }: ChangeModeBlockProps) => {
  return (
    <div className={clsx(s.ChangeModeBlock, className)}>
      <Icon
        className={clsx(s.ChangeModeBlock__icon, {
          [s.ChangeModeBlock__iconActive]: mode === 'carousel'
        })}
        icon={IconCarouselView}
        onClick={() => onSetMode('carousel')}
      />

      <Icon
        className={clsx(s.ChangeModeBlock__icon, {
          [s.ChangeModeBlock__iconActive]: mode === 'list'
        })}
        icon={IconListView}
        onClick={() => onSetMode('list')}
      />
    </div>
  );
};
