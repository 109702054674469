import { useCallback, useMemo, useReducer } from 'react';
import { FileUploaderInterface } from 'utils/FileUploader';
import { FileUploaderManager } from 'utils/FileUploader/FileUploadManager';

type FileUploaderFactory<FileIdType> = (file: File) => FileUploaderInterface<FileIdType>;

export function useFileUploadService<FileIdType>(
  uploaderFactory: FileUploaderFactory<FileIdType>,
  fileUploadManager?: FileUploaderManager<FileIdType>
): [
  FileUploaderInterface<FileIdType>[],
  FileUploaderManager<FileIdType>,
  (file: File[]) => void,
  (fileKey: string) => void
] {
  const manager = useMemo(() => {
    return fileUploadManager || new FileUploaderManager<FileIdType>();
  }, [fileUploadManager]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const addFiles = useCallback(
    (files: File[]) => {
      files.forEach(async (file) => {
        const uploader = uploaderFactory(file);
        manager.add(uploader);

        await uploader.waitEnd();
        // Обновляем вью после загрузки файла
        forceUpdate();
      });

      // Обновляем вью для отображения обновлённого списка файлов
      forceUpdate();
    },
    [manager, uploaderFactory]
  );

  const removeFile = useCallback(
    (fileKey: string) => {
      manager.remove(fileKey);
      // Обновляем вью после обновления списка файлов
      forceUpdate();
    },
    [manager]
  );

  return [manager.files, manager, addFiles, removeFile];
}
