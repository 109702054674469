import React from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal, ModalFooter } from 'componentsL/Modal';

export interface FailModalProps {
  onClose: () => void;
  onBack: () => void;
}

export const FailModal = ({ onBack, onClose }: FailModalProps) => {
  const continueButton = (
    <Button variant={ButtonVariant.Primary} onClick={onBack}>
      Назад
    </Button>
  );

  return (
    <Modal title="Ошибка" width={456} onClose={onClose}>
      Не удалось сохранить данные. Вернитесь на форму обратной связи и подайте заявку еще раз.
      <ModalFooter right={continueButton} />
    </Modal>
  );
};
