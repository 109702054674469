import React, { useMemo } from 'react';
import clsx from 'clsx';
import { groupBy, isNil } from 'lodash-es';
import { Link } from 'react-router-dom';
import { EMDASH } from '@digital-gov/ui-utils';
import { Icon, IconChevronLeftDouble, IconSize } from 'componentsL/Icon';
import { Tabs } from 'componentsL/Tabs/Tabs';
import { RegionRatingType, RegionRatingGroupEnum, RegionType } from 'store/graphql';
import { InfoProperty } from '../InfoProperty/InfoProperty';
import { RegionMapUtils } from '../RegionMapUtils';
import { MapMode, MSK_REGION, UNKNOWN_REGION } from '../regions-map.types';
import { InfoCurrentRegion } from './InfoCurrentRegion';
import { InfoEffectGroup } from './InfoEffectGroup';
import { InfoRegions } from './InfoRegions';
import s from './Info.module.scss';

const infoTabs = [
  {
    label: 'Фед. округа',
    value: MapMode.Regions
  },
  {
    label: 'Группа эффективности',
    value: MapMode.EffectGroup
  }
];

export interface InfoProps {
  regionalRating?: RegionRatingType;
  selectedRegionId?: number | string;
  onRegionReset: () => void;
  mode: MapMode;
  onModeChange: (mode: MapMode) => void;
}

export const Info = ({ regionalRating, selectedRegionId, onRegionReset, mode, onModeChange }: InfoProps) => {
  const regions = regionalRating?.regions;
  const availableKeyIndicators = regionalRating?.availableKeyIndicators;

  const selectedRegion = useMemo(() => {
    if (isNil(selectedRegionId)) return null;
    if (selectedRegionId === MSK_REGION.regionId) return MSK_REGION;
    if (selectedRegionId === UNKNOWN_REGION.regionId) return UNKNOWN_REGION;
    return regions?.find((r) => r.regionId === selectedRegionId);
  }, [regions, selectedRegionId]);

  const modeRegionsProperties = useMemo(() => {
    if (!regions?.length) return [];

    return Object.entries(
      groupBy(
        regions.map((r) => ({
          name: RegionMapUtils.mapRegionNames(r.regionShortName),
          color: RegionMapUtils.mapRegionDefaultColors(r.regionShortName).default
        })),
        'name'
      )
    ).map(([key, value]) => ({
      name: key,
      amount: value.length,
      color: value[0]?.color || ''
    }));
  }, [regions]);

  const modeEffectGroupProperties = useMemo(() => {
    if (!regions?.length) return [];

    return Object.entries(groupBy(regions, 'ratingGroup')).map(([key, value]) => ({
      name: RegionMapUtils.mapEffectGroupNames(key as RegionRatingGroupEnum),
      amount: value.length,
      color: RegionMapUtils.mapEffectGroupColors(key as RegionRatingGroupEnum).default
    }));
  }, [regions]);

  if (selectedRegion) {
    if (selectedRegion.regionId === MSK_REGION.regionId) {
      return (
        <div>
          <InfoProperty
            className={clsx(s.InfoCurrentRegion__prop, s.InfoCurrentRegion__prop_name)}
            label={'Не принимает участие в рейтинге'}
            vertical>
            <div className={s.InfoCurrentRegion__regionName}>
              г. Москва
              <Icon
                className={s.InfoCurrentRegion__regionCloseButton}
                icon={IconChevronLeftDouble}
                size={IconSize.Small}
                onClick={(e) => {
                  e.preventDefault();
                  onRegionReset();
                }}
              />
            </div>
          </InfoProperty>
        </div>
      );
    } else if (selectedRegion.regionId === UNKNOWN_REGION.regionId) {
      return (
        <div>
          <InfoProperty
            className={clsx(s.InfoCurrentRegion__prop, s.InfoCurrentRegion__prop_name)}
            label={'Данные по региону не определены'}
            vertical>
            <div className={s.InfoCurrentRegion__regionName}>
              {EMDASH}
              <Icon
                className={s.InfoCurrentRegion__regionCloseButton}
                icon={IconChevronLeftDouble}
                size={IconSize.Small}
                onClick={(e) => {
                  e.preventDefault();
                  onRegionReset();
                }}
              />
            </div>
          </InfoProperty>
        </div>
      );
    } else {
      return (
        <Link to={`/region/${selectedRegion.regionId}`} className={s.Info_currentRegion}>
          <InfoCurrentRegion
            region={selectedRegion as RegionType}
            availableKeyIndicators={availableKeyIndicators}
            updateDate={regionalRating?.lastUpdateDate ?? undefined}
            onClose={onRegionReset}
          />
        </Link>
      );
    }
  }

  return (
    <div className={s.Info_general}>
      <Tabs className={s.Info__modeTabs} value={mode} tabs={infoTabs} onChange={onModeChange} />

      <div className={s.Info__content}>
        {mode === MapMode.Regions && <InfoRegions properties={modeRegionsProperties} />}
        {mode === MapMode.EffectGroup && <InfoEffectGroup properties={modeEffectGroupProperties} />}

        <InfoProperty
          label={
            <>
              * Рейтинг формируется на основании степени достижения показателей (% от максимально возможного количества
              баллов).
              <br />
              г. Москва не принимает участие в рейтинге.
              <br />
              ДНР, ЛНР, Херсонская и Запорожская области включаются в расчет с 3 кв. 2023 г.
            </>
          }
        />
      </div>
    </div>
  );
};
