import React from 'react';
import { Icon, IconSize } from '../Icon';
import { ReactComponent as IconFileBmp } from './assets/icon-bmp.svg';
import { ReactComponent as IconFileDoc } from './assets/icon-doc.svg';
import { ReactComponent as IconFileDocx } from './assets/icon-docx.svg';
import { ReactComponent as IconFileGif } from './assets/icon-gif.svg';
import { ReactComponent as IconFileJpeg } from './assets/icon-jpeg.svg';
import { ReactComponent as IconFileJpg } from './assets/icon-jpg.svg';
import { ReactComponent as IconFilePdf } from './assets/icon-pdf.svg';
import { ReactComponent as IconFilePng } from './assets/icon-png.svg';
import { ReactComponent as IconFilePpt } from './assets/icon-ppt.svg';
import { ReactComponent as IconFilePptx } from './assets/icon-pptx.svg';
import { ReactComponent as IconFileRar } from './assets/icon-rar.svg';
import { ReactComponent as IconFileTxt } from './assets/icon-txt.svg';
import { ReactComponent as IconFileUnknown } from './assets/icon-unknown.svg';
import { ReactComponent as IconFileWebp } from './assets/icon-webp.svg';
import { ReactComponent as IconFileXls } from './assets/icon-xls.svg';
import { ReactComponent as IconFileXlsx } from './assets/icon-xlsx.svg';
import { ReactComponent as IconFileZip } from './assets/icon-zip.svg';
import s from './FileIcon.module.scss';

export interface FileIconProps {
  // Полное имя файла с расширением: «filename.txt»
  // Если нужно вывести иконку, не имея имени файла, можно передать только расширение: «.txt» (с точкой в начале)
  filename: string;
  //Размер иконки. По умолчанию - {@link IconSize.Large}
  size?: IconSize | number;
  // "Обтягивание" иконки: если true (по умолчанию) - иконка прямоугольная -> ширина изменяется пропорционально высоте;
  //                       если false - иконка квадратная -> ширина равна высоте
  fit?: boolean;
}

export const FileIcon = ({ filename, size, fit }: FileIconProps) => {
  const type = (/\.([^.]+)$/.exec(filename) || [])[1];
  const icon = (() => {
    switch (type.toLowerCase()) {
      case 'bmp':
        return IconFileBmp;
      case 'doc':
        return IconFileDoc;
      case 'docx':
        return IconFileDocx;
      case 'gif':
        return IconFileGif;
      case 'jpeg':
        return IconFileJpeg;
      case 'jpg':
        return IconFileJpg;
      case 'pdf':
        return IconFilePdf;
      case 'png':
        return IconFilePng;
      case 'ppt':
        return IconFilePpt;
      case 'pptx':
        return IconFilePptx;
      case 'rar':
        return IconFileRar;
      case 'txt':
        return IconFileTxt;
      case 'webp':
        return IconFileWebp;
      case 'xls':
        return IconFileXls;
      case 'xlsx':
        return IconFileXlsx;
      case 'zip':
        return IconFileZip;
      default:
        return IconFileUnknown;
    }
  })();
  const boxSize: React.CSSProperties = size
    ? {
        height: `${size}px`,
        width: fit ? 'auto' : `${size}px`
      }
    : {};

  return (
    <div className={s.FileIcon} style={boxSize}>
      <Icon icon={icon} className={s.FileIcon__icon} />
    </div>
  );
};
