import React, { useState } from 'react';
import { EMDASH } from '@digital-gov/ui-utils';
import { IconButton } from 'components/Button';
import { Mail, RctControlIcon } from 'components/Icon';
import { ResponsibleControl, ResponsibleControlProps } from 'features/ResponsibleControl';
import { IndicatorResponsibleType } from 'store/graphql';
import s from './PersonResponsible.module.scss';

export function PersonResponsible({
  responsible: data,
  responsibleControl
}: {
  responsible: IndicatorResponsibleType;
  responsibleControl?: Omit<ResponsibleControlProps, 'handleClose'>;
}) {
  const renderMailIcon = (email: string) => <IconButton href={`mailto:${email}`} icon={Mail} />;
  const [controlOpened, setControlOpened] = useState(false);
  const handleControlOpen = () => setControlOpened(true);
  const handleControlClose = () => setControlOpened(false);

  // массив если приходит, то в строке:
  // "email": "test_2@fakeadmin.ru, test_2@fakeadmin.ru",
  // "fullName": "Орлов Иван Иванович, Орлов Иван Иванович",
  const responsible: IndicatorResponsibleType[] = [];

  if (!!data.email)
    data.email.split(', ').forEach((email, index) => {
      responsible[index] = { ...responsible[index], email };
    });

  if (!!data.fullName)
    data.fullName.split(`, `).forEach((fullName, index) => {
      responsible[index] = { ...responsible[index], fullName };
    });

  // а это на случай, если приходит "null" – да, тоже строкой
  const emails = responsible.filter(({ email }) => !!email && email.includes('@')).map(({ email }) => email);

  return (
    <section className={s.PersonResponsible}>
      <div className={s.PersonResponsible__control}>
        {responsibleControl && (
          <div className={s.PersonResponsible__controlIcon}>
            <IconButton icon={RctControlIcon} onClick={handleControlOpen} />
            {controlOpened && <ResponsibleControl {...responsibleControl} handleClose={handleControlClose} />}
          </div>
        )}
        <div className={s.PersonResponsible__content}>
          <h4 className={s.PersonResponsible__heading}>Ответственное лицо</h4>

          {!responsible.length && <div className={s.PersonResponsible__person}>{EMDASH}</div>}
          {responsible.map(({ fullName, email }, index) => (
            <div key={`${index}-${email}`} className={s.PersonResponsible__person}>
              <span>
                {fullName || EMDASH}
                {fullName && responsible.length !== index + 1 && ','}
              </span>
              {emails.length > 0 && !!email && renderMailIcon(email)}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
