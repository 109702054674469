import React from 'react';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './MortgageForIt.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

export function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const i = components[0]?.value;
  const n = components[1]?.value;
  const p = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={i}
        unit={'%'}
        description={
          <>
            Доля поддержанных ИТ-специалистов в субъекте Российской
            <br /> Федерации на 1000 человек от численности населения субъекта
            <br /> Российской Федерации
          </>
        }>
        <Symbol>I</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={n}
        unit={'шт.'}
        roundUp
        description={
          <>
            Количество поддержанных ИТ-специалистов в субъекте
            <br />
            Российской Федерации (накопленный итог)
          </>
        }>
        <Symbol>N</Symbol>
      </SymbolDetails>
      <SymbolDetails value={p} unit={'тыс. чел.'} description={'Численность населения в субъекте Российской Федерации'}>
        <Symbol>P</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

export function IndicatorTable() {
  return (
    <div className={s.Table}>
      <div className={s.Table__headerCell} style={{ gridColumn: 'c1 / c3' }}>
        Региональная программа реализована по первой модели
      </div>
      <div className={s.Table__headerCell} style={{ gridColumn: 'c3 / end' }}>
        Региональная программа реализована по второй модели
      </div>
      {/*--------*/}
      <div className={s.Table__headerCell} style={{ gridColumn: 'c1', gridRow: 'r2' }}>
        Оценка, балл
      </div>
      <div className={s.Table__headerCell} style={{ gridColumn: 'c2', gridRow: 'r2' }}>
        Значение
      </div>
      <div className={s.Table__headerCell} style={{ gridColumn: 'c3', gridRow: 'r2' }}>
        Оценка, балл
      </div>
      <div className={s.Table__headerCell} style={{ gridColumn: 'c4', gridRow: 'r2' }}>
        Значение
      </div>
      {/*--------*/}
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        0
      </div>
      <div className={s.Table__cell}>
        Отсутствует нормативный правовой акт субъекта Российской Федерации, регулирующий региональную программу
      </div>
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        0
      </div>
      <div className={s.Table__cell}>
        Отсутствует нормативный правовой акт субъекта Российской Федерации, регулирующий региональную программу
      </div>
      {/*--------*/}
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        1,0
      </div>
      <div className={s.Table__cell}>
        Принят нормативный правовой акт субъекта
        <br /> Российской Федерации, регулирующий
        <br /> региональную программу
      </div>
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        0,5
      </div>
      <div className={s.Table__cell}>
        Принят нормативный правовой акт субъекта
        <br /> Российской Федерации, регулирующий
        <br /> региональную программу
      </div>
      {/*--------*/}
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        1,5
      </div>
      <div className={s.Table__cell}>{'1% ≤ I < 3%'}</div>
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        1,0
      </div>
      <div className={s.Table__cell}>{'1% ≤ I < 3%'}</div>
      {/*--------*/}
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        2,0
      </div>
      <div className={s.Table__cell}>{'I ≥ 3%'}</div>
      <div className={s.Table__cell} style={{ textAlign: 'center' }}>
        1,5
      </div>
      <div className={s.Table__cell}>{'I ≥ 3%'}</div>
    </div>
  );
}

export function MortgageForIt() {
  return (
    <IndicatorPage
      regionScope
      download={'Льготная ИТ Ипотека_v2.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
