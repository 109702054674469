import React, { useState } from 'react';
import { EMDASH, formatNumber, formatNumberIntl } from '@digital-gov/ui-utils';
import { IconButton } from 'components/Button';
import { DynamicValue } from 'components/DynamicValue';
import { RctControlIcon } from 'components/Icon';
import { Comments } from 'features/Comments';
import { CommentDraftType, CommentType } from 'features/Comments/_utils/types';
import { RctControl } from 'features/RctControl';
import { RctControlScopeEnum, RctType, SecondPersonType } from 'features/RctControl/_utils';
import { RatingCommentScopeEnum } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { EfficiencyGroup, EfficiencyGroupLine } from './Efficiency';
import { Person } from './Person';
import { StatisticsBlockItem } from './StatisticsBlockItem';
import {
  DegreeText,
  DynamicFromPreviousText,
  EfficiencyGroupText,
  PointsCountText,
  PointsMaxText,
  PointsTotalText,
  RepresentativeText
} from './StatisticsBlockTexts';
import s from './StatisticsBlock.module.scss';

/**
 * Пропсы используемые в фиче "Комментарии"
 */
export interface StatisticsBlockCommentsProps {
  scope: RatingCommentScopeEnum;
  scopeId: number;
  scopeName?: string | null;
  scopeIndicatorId?: number | null;
  draft?: CommentDraftType | null;
  comments?: CommentType[] | null;
  refetch?: () => void;
}

/**
 * Пропсы, используемые в фиче "Управление РЦТ"
 */
export interface StatisticsBlockRctControlProps {
  scopeId: number; // id ФОИВ-а или РОИВ-а
  scopeName: string; // название ФОИВ-а или РОИВ-а
  refetchScopeData?: () => void; // обновление данных ФОИВ-а или РОИВ-а
  rct?: RctType | null;
  secondPersons: SecondPersonType[];
}

export interface StatisticsBlockProps {
  // общие пропсы для ФОИВ-а и РОИВ-а
  efficiencyGroup: 'High' | 'Average' | 'Low';
  efficiencyGroupText: string;
  degree?: number | null;
  pointsTotal?: number | null;
  pointsMax?: number | null;
  pointsDynamic?: number | null;
  lastPeriodDate?: string | null;
  indicatorsCount?: number | null;
  // только для ФОИВ-а
  frct?: string | null;
  viceChairman?: string | null;
  // только для РОИВ-а
  rrct?: string | null;
  representative?: string | null;
  // блок комментариев
  commentsControl?: StatisticsBlockCommentsProps;
  // блок "Управления РЦТ и ВП\ПП"
  rctControl?: StatisticsBlockRctControlProps;
}

export function StatisticsBlock({
  efficiencyGroup,
  efficiencyGroupText,
  degree,
  pointsTotal,
  pointsMax,
  pointsDynamic,
  lastPeriodDate,
  indicatorsCount,
  frct,
  rrct,
  viceChairman,
  representative,
  commentsControl,
  rctControl
}: StatisticsBlockProps) {
  const { isObserver } = useProfile();
  const [changeRct, setChangeRct] = useState<boolean>(false);
  const [changeSecondPersons, setChangeSecondPersons] = useState<boolean>(false);

  const handleChangeRctOpen = () => setChangeRct(true);
  const handleChangeRctClose = () => setChangeRct(false);

  const handleChangeSecondPersonsOpen = () => setChangeSecondPersons(true);
  const handleChangeSecondPersonsClose = () => setChangeSecondPersons(false);

  return (
    <div className={s.StatisticBlock}>
      <div className={s.StatisticBlock__items}>
        <EfficiencyGroupLine group={efficiencyGroup} />
        <StatisticsBlockItem
          label={<EfficiencyGroupText />}
          content={<EfficiencyGroup group={efficiencyGroup} label={efficiencyGroupText} />}
        />
        <StatisticsBlockItem label={<DegreeText />} content={`${formatNumber(degree)}`} />
        <StatisticsBlockItem label={<PointsTotalText />} content={formatNumber(pointsTotal)} />
        <StatisticsBlockItem label={<PointsMaxText />} content={`${formatNumber(pointsMax)}`} />
        <StatisticsBlockItem
          label={<DynamicFromPreviousText lastPeriodDate={lastPeriodDate} />}
          content={
            pointsDynamic ? <DynamicValue dynamic={pointsDynamic} withArrow withSymbol formatted softColors /> : EMDASH
          }
        />
        {indicatorsCount != null && (
          <StatisticsBlockItem label={<PointsCountText />} content={`${formatNumberIntl(indicatorsCount)}`} />
        )}
        {/*
            _________________________
            2 колонки только для ФРЦТ - для Админа и для Пользователя
        */}
        {/*ФРЦТ - Для админа*/}
        {isObserver && frct !== undefined && (
          <StatisticsBlockItem
            label={'ФРЦТ'}
            content={
              <div className={s.StatisticBlock__rctControlWrapper}>
                {rctControl && <IconButton icon={RctControlIcon} onClick={handleChangeRctOpen} />}
                <Person person={frct} formatToInitials={true} />
                {rctControl && changeRct && (
                  <RctControl
                    scope={RctControlScopeEnum.DepartmentRct}
                    scopeId={rctControl.scopeId}
                    scopeName={rctControl.scopeName}
                    refetch={rctControl.refetchScopeData}
                    currentRct={rctControl.rct}
                    handleClose={handleChangeRctClose}
                  />
                )}
              </div>
            }
          />
        )}
        {/* ФРЦТ - для пользователя*/}
        {!isObserver && frct && (
          <StatisticsBlockItem label={'ФРЦТ'} content={<Person person={frct} formatToInitials={true} />} />
        )}
        {/* Вице-премьер - для админа */}
        {isObserver && viceChairman !== undefined && (
          <StatisticsBlockItem
            label={'Вице-премьер'}
            content={
              <div className={s.StatisticBlock__rctControlWrapper}>
                {rctControl && <IconButton icon={RctControlIcon} onClick={handleChangeSecondPersonsOpen} />}
                <Person person={viceChairman} formatToInitials={true} />
                {rctControl && changeSecondPersons && (
                  <RctControl
                    scope={RctControlScopeEnum.ViceChairman}
                    scopeId={rctControl.scopeId}
                    scopeName={rctControl.scopeName}
                    refetch={rctControl.refetchScopeData}
                    currentRct={rctControl.rct}
                    currentSecondPersons={rctControl.secondPersons}
                    handleClose={handleChangeSecondPersonsClose}
                  />
                )}
              </div>
            }
          />
        )}
        {/* Вице-премьер для пользователя*/}
        {!isObserver && viceChairman && (
          <StatisticsBlockItem
            label={'Вице-премьер'}
            content={<Person person={viceChairman} formatToInitials={true} />}
          />
        )}
        {/*
            _________________________
            2 колонки только для РРЦТ - для Админа и для Пользователя
        */}
        {/* РРЦТ для Админа*/}
        {isObserver && rrct !== undefined && (
          <StatisticsBlockItem
            label={'РРЦТ'}
            content={
              <div className={s.StatisticBlock__rctControlWrapper}>
                {rctControl && <IconButton icon={RctControlIcon} onClick={handleChangeRctOpen} />}
                <Person person={rrct} formatToInitials={true} />
                {rctControl && changeRct && (
                  <RctControl
                    scope={RctControlScopeEnum.RegionRct}
                    scopeId={rctControl.scopeId}
                    scopeName={rctControl.scopeName}
                    refetch={rctControl.refetchScopeData}
                    currentRct={rctControl.rct}
                    handleClose={handleChangeRctClose}
                  />
                )}
              </div>
            }
          />
        )}
        {/* РРЦТ для пользователя*/}
        {!isObserver && rrct && (
          <StatisticsBlockItem label={'РРЦТ'} content={<Person person={rrct} formatToInitials={true} />} />
        )}
        {/* Полномочный представитель для Админа*/}
        {isObserver && representative !== undefined && (
          <StatisticsBlockItem
            label={<RepresentativeText />}
            content={
              <div className={s.StatisticBlock__rctControlWrapper}>
                {rctControl && <IconButton icon={RctControlIcon} onClick={handleChangeSecondPersonsOpen} />}
                <Person person={representative} formatToInitials={true} />
                {rctControl && changeSecondPersons && (
                  <RctControl
                    scope={RctControlScopeEnum.Representative}
                    scopeId={rctControl.scopeId}
                    scopeName={rctControl.scopeName}
                    refetch={rctControl.refetchScopeData}
                    currentRct={rctControl.rct}
                    currentSecondPersons={rctControl.secondPersons}
                    handleClose={handleChangeSecondPersonsClose}
                  />
                )}
              </div>
            }
          />
        )}
        {!isObserver && representative && (
          <StatisticsBlockItem
            label={<RepresentativeText />}
            content={<Person person={representative} formatToInitials={true} />}
          />
        )}
        {/*  Полномочный представитель для пользователя */}
      </div>
      {commentsControl && (
        <Comments
          scope={commentsControl.scope}
          scopeId={commentsControl.scopeId}
          scopeName={commentsControl.scopeName}
          comments={commentsControl.comments}
          draft={commentsControl.draft}
          departmentRefetch={
            commentsControl.scope === RatingCommentScopeEnum.Department ? commentsControl.refetch : undefined
          }
          regionRefetch={commentsControl.scope === RatingCommentScopeEnum.Region ? commentsControl.refetch : undefined}
        />
      )}
    </div>
  );
}
