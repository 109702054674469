import { useCallback, useState } from 'react';
export const useToggle = (defaultValue = false) => {
    const [value, change] = useState(defaultValue);
    const set = useCallback(() => {
        change(true);
    }, [change]);
    const unset = useCallback(() => {
        change(false);
    }, [change]);
    const toggle = useCallback(() => {
        change((v) => !v);
    }, [change]);
    return [
        value,
        {
            change,
            set,
            unset,
            toggle
        }
    ];
};
