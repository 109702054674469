import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './AiMaturity.module.scss';
function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const sumAiMaturityScore = components[0]?.value;
  const commonIndicatorsScore = components[1]?.value;
  const additionalIndicatorsScore = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={sumAiMaturityScore}
        description={
          'Суммарный показатель зрелости ИИ ФОИВ, отражающий изменение значений индикаторов, характеризующих уровень внедрения и проникновения ИИ и готовность ФОИВ, участвующих в оценке, к внедрению ИИ'
        }>
        <Symbol>ИИ</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={commonIndicatorsScore}
        description={'Значение в баллах, соответствующее уровню зрелости блока «Основные индикаторы»'}>
        <Symbol sub={'осн'}>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={additionalIndicatorsScore}
        description={'Значение в баллах, соответствующее уровню зрелости блока «Вспомогательные индикаторы»'}>
        <Symbol sub={'всп'}>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <div className={s.AiMaturity_container}>
      <div className={s.AiMaturity__headerCell} style={{ gridColumn: 'c1' }}>
        Оценка, балл
      </div>
      <div className={s.AiMaturity__headerCell} style={{ gridColumn: 'c2 / end' }}>
        Значение
      </div>

      <div className={s.AiMaturity__headerCell} style={{ gridColumn: 'c1', gridRow: 'r2' }}></div>
      <div className={s.AiMaturity__headerCell} style={{ gridColumn: 'c2', gridRow: 'r2' }}>
        Основные индикаторы
      </div>
      <div className={s.AiMaturity__headerCell} style={{ gridColumn: 'c3', gridRow: 'r2' }}></div>
      <div className={s.AiMaturity__headerCell} style={{ gridColumn: 'c4', gridRow: 'r2' }}>
        Вспомогательные индикаторы
      </div>

      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        0
      </div>
      <div className={s.AiMaturity__cell}>Начальный</div>
      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        0
      </div>
      <div className={s.AiMaturity__cell}>Начальный</div>

      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        1
      </div>
      <div className={s.AiMaturity__cell}>Базовый</div>
      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        0,25
      </div>
      <div className={s.AiMaturity__cell}>Базовый</div>

      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        1,5
      </div>
      <div className={s.AiMaturity__cell}>Прогрессивный</div>
      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        0,5
      </div>
      <div className={s.AiMaturity__cell}>Прогрессивный</div>

      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        2
      </div>
      <div className={s.AiMaturity__cell}>Лидерский</div>
      <div className={s.AiMaturity__cell} style={{ textAlign: 'center' }}>
        1
      </div>
      <div className={s.AiMaturity__cell}>Лидерский</div>
    </div>
  );
}

export function AiMaturity() {
  return (
    <IndicatorPage
      download={'ИИ-зрелость.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
