import { FC, Fragment, useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { RegionRatingType } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { RegionalRatingFilteredType } from '../types';
import { accessNavigateToRegion } from '../utils/accessNavigateToRegion';
import { useRegionsTable } from './hooks/useRegionsTable';
import { useRegionsTableSort } from './hooks/useRegionsTableSort';
import { TableCellFactory } from './TableCellFactory';
import { TableCellHeader, TableCellHeaderSelect } from './TableCellHeader';
import { RegionsTableHeaderFields } from './types';
import s from './RegionsTableFull.module.scss';

export interface RegionsTableFullProps {
  className?: string;
  regionalRating?: RegionRatingType;
  filteredData: RegionalRatingFilteredType | null;
}

export const RegionsTableFull: FC<RegionsTableFullProps> = (props) => {
  const { className, regionalRating, filteredData } = props;

  const { isObserver, isRRCT } = useProfile();

  const { options, headers, headersNameMapper, selectedIndicator, dropdownIsOpen, handlers } =
    useRegionsTable(regionalRating);

  const { sortedData, sortedColumn, onColumnSortIcon } = useRegionsTableSort(filteredData, selectedIndicator);

  const renderedData = useMemo(() => sortedData?.filter(({ filtered }) => !filtered), [sortedData]);

  const { unset, onOptionClick, toggle } = handlers;

  return (
    <div className={clsx(s.RegionsTableFull, className)}>
      {headers.map((header, index) =>
        header !== RegionsTableHeaderFields.indicatorsValues ? (
          <TableCellHeader
            key={header + index}
            index={index}
            sort={sortedColumn.field === header ? sortedColumn.sort : null}
            title={headersNameMapper[header].title}
            subTitle={headersNameMapper[header].subTitle}
            onColumnSortIcon={onColumnSortIcon(header)}
          />
        ) : (
          <TableCellHeaderSelect
            key={header + index}
            title={headersNameMapper[header].title}
            subTitle={selectedIndicator?.indicatorName}
            maxValue={selectedIndicator?.maxValue}
            sort={sortedColumn.field === header ? sortedColumn.sort : null}
            options={options}
            onLeftIconClick={toggle}
            dropdownClose={unset}
            isOpen={dropdownIsOpen}
            selectedIndicator={selectedIndicator}
            onOptionClick={onOptionClick}
            onColumnSortIcon={onColumnSortIcon(header)}
          />
        )
      )}

      {renderedData?.map((row, rowIndex) => (
        <Fragment key={row.regionId}>
          {headers.map((header, headerIndex) => {
            const isLink = accessNavigateToRegion({
              pointsLast: row.pointsLast,
              isRRCT,
              isCurrentRegion: row.isCurrentRegion,
              isObserver
            });

            const props = {
              className: clsx(s.RegionsTableFull__cell, headerIndex === 0 && s.RegionsTableFull__cell_left),
              style: { gridRow: rowIndex + 2 },
              key: `${rowIndex}-${headerIndex}`
            };

            const linkProps = { ...props, to: `/region/${row.regionId}` };
            const content = <TableCellFactory cellData={row} header={header} selectedIndicator={selectedIndicator} />;

            if (isLink) {
              return <Link {...linkProps}>{content}</Link>;
            } else {
              return <div {...props}>{content}</div>;
            }
          })}
        </Fragment>
      ))}
    </div>
  );
};
