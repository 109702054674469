import React from 'react';
import { IndicatorPage, UpdateDays } from '../../../_components/IndicatorPage';
import { Table } from '../../../_components/Table';
import { TableRow } from '../../../_components/TableRow';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import { SmevExploitationComponents } from './SmevExploitationComponents';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  return <SmevExploitationComponents components={components} />;
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', 'В промышленной эксплуатации ФОИВ используются только электронные сервисы СМЭВ2']} />
      <TableRow
        values={[
          '0,5',
          <>
            В промышленной эксплуатации ФОИВ используются средства межведомственного взаимодействия СМЭВ3, СМЭВ4,
            <br />
            при этом электронные сервисы СМЭВ2 используются ФОИВ в роли владельца и потребителя (наличие доступа)
          </>
        ]}
      />
      <TableRow
        values={[
          '1,0',
          <>
            В промышленной эксплуатации ФОИВ используются средства межведомственного взаимодействия СМЭВ3, СМЭВ4,
            <br />
            при этом электронные сервисы СМЭВ2 используются ФОИВ в роли владельца или потребителя (наличие доступа)
          </>
        ]}
      />
      <TableRow
        values={[
          '3,0',
          <>
            В промышленной эксплуатации ФОИВ используются средства межведомственного взаимодействия СМЭВ3, СМЭВ4,
            <br />
            при этом электронные сервисы СМЭВ2 не используются
          </>
        ]}
      />
    </Table>
  );
}

export function SmevExploitation() {
  return (
    <IndicatorPage
      download={`Эксплуатация СМЭВ_v1.pdf`}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
    />
  );
}
