import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './DigitalMaturity.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const totalScore = components[0]?.value;
  const healthcareScore = components[1]?.value;
  const educationScore = components[2]?.value;
  const urbanScore = components[3]?.value;
  const publicTransportScore = components[4]?.value;
  const governmentScore = components[5]?.value;

  return (
    <SymbolsGrid columns={4}>
      <SymbolDetails
        value={totalScore}
        unit={'%'}
        description={'Значение показателя оценки цифровой зрелости отрасли в регионе'}>
        <Symbol sub={'ЦЗО'}>{'Y'}</Symbol>
      </SymbolDetails>
      <SymbolDetails value={healthcareScore} unit={'%'} description={'Значение показателя отрасли «Здравоохранение»'}>
        <Symbol sub={'ЦЗО1'}>{'И'}</Symbol>
      </SymbolDetails>
      <SymbolDetails value={educationScore} unit={'%'} description={'Значение показателя отрасли «Образование»'}>
        <Symbol sub={'ЦЗО2'}>{'И'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={urbanScore}
        unit={'%'}
        description={'Значение показателя отрасли «Городское хозяйство и строительство»'}>
        <Symbol sub={'ЦЗО3'}>{'И'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={publicTransportScore}
        unit={'%'}
        description={'Значение показателя отрасли «Общественный транспорт»'}>
        <Symbol sub={'ЦЗО4'}>{'И'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={governmentScore}
        unit={'%'}
        description={
          'Значение показателя государственного управления органов государственной власти субъектов Российской Федерации, органов местного самоуправления и организаций субъектов Российской Федерации'
        }>
        <Symbol sub={'ГО'}>{'И'}</Symbol>
      </SymbolDetails>
      <SymbolDetails
        description={
          'Количество отраслей экономики и социальной сферы, а также государственного управления, участвующих в расчете'
        }>
        <div className={s.DigitalMaturity__symbol}>5</div>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <div className={s.DigitalMaturity__info}>
      <p>
        Итоговая оценка показателя определяется в баллах, где 1 балл равен 1/10 от процента достижения цифровой зрелости
        по каждой из отраслей
      </p>
      <p>Максимальное количество баллов по показателю – 10</p>
    </div>
  );
}

export function DigitalMaturity() {
  return (
    <IndicatorPage
      regionScope
      download={'Цифровая зрелость.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
