import React from 'react';
import { RatingCommentScopeEnum } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { CommentDraftType, CommentType } from './_utils/types';
import { CommentsControl } from './CommentsControl';
import { CommentContext } from './context/CommentContext';
import { useCommentContext } from './context/useCommentContext';
import { LastPublishedComment } from './LastPublishedComment';

export interface CommentsProps {
  scope: RatingCommentScopeEnum;
  scopeId: number;
  scopeName?: string | null;
  scopeIndicatorId?: number | null;
  draft?: CommentDraftType | null;
  comments?: CommentType[] | null;
  departmentRefetch?: () => void;
  regionRefetch?: () => void;
}

export function Comments({
  scope,
  scopeId,
  scopeName,
  scopeIndicatorId,
  comments,
  draft,
  regionRefetch,
  departmentRefetch
}: CommentsProps) {
  const { allowedToComment } = useProfile();
  const context = useCommentContext();

  // загружаем данные о текущем скоупе - ФОИВ / РОИВ, id ФОИВ-а или РОИВ-а и черновик в контекст
  context.scope = scope;
  context.scopeId = scopeId;
  context.scopeIndicatorId = scopeIndicatorId;
  context.commentId = draft?.id ?? undefined;
  context.commentText = draft?.commentText ?? undefined;
  context.initialDraftText = draft?.commentText ?? undefined;
  if (departmentRefetch) {
    context.departmentRefetch = departmentRefetch;
  }
  if (regionRefetch) {
    context.regionRefetch = regionRefetch;
  }

  return (
    <>
      {allowedToComment ? (
        <CommentContext.Provider value={context}>
          <CommentsControl comments={comments} entityName={scopeName} />
        </CommentContext.Provider>
      ) : (
        <LastPublishedComment comment={comments?.[0]} />
      )}
    </>
  );
}
