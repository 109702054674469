import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const eld = components[0]?.value;
  const k1 = components[1]?.value;
  const k2 = components[2]?.value;
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={eld}
        description={
          'Значение показателя «Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью»'
        }>
        <Symbol color={'#55D666'} square>
          ИЛД
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k1}
        roundUp
        description={
          <>
            Количество электронных личных дел сотрудников РОИВ <br />с привязкой к учетным записям пользователей ФГИС
            «ЕИСУКС»
          </>
        }>
        <Symbol>К</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k2}
        roundUp
        description={
          'Общее количество электронных личных дел сотрудников РОИВ, назначенных на соответствующую должность в РОИВ'
        }>
        <Symbol>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <AdvancedTable
      // prettier-ignore
      rows={[
        { cells: ['Оценка, балл', 'Значение ИЛД, %'], isHeader: true },
        { cells: ['0', 'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет  0 – 20,09 %'] },
        { cells: ['0,5', 'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 20,10 – 40,09 %'] },
        { cells: ['1,0', 'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 40,10 – 60,09 %' ] },
        { cells: ['1,5', 'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 60,10 – 80,09 %' ] },
        { cells: ['2,0', 'Доля сотрудников государственных органов, электронное личное дело которых связано с учетной записью составляет 80,10 – 100,00 %' ] },
        // ----
        { cells: ['Значение для субъектов РФ, использующих собственные ГИС'], isInTableCustomHeader: true },
        { cells: ['0', 'ГИС субъекта не использует отечественные решения для ведения кадровой работы' ] },
        { cells: ['1,0', 'ГИС субъекта использует отечественные решения для ведения кадровой работы'] }
      ]}
    />
  );
}

export function EisuksEldShare() {
  return (
    <IndicatorPage
      download={'Доля ЭЛД связанных с УЗ ФГИС ЕИСУКС РРЦТ.pdf'}
      regionScope
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      dynamicChartPreventGrow
    />
  );
}
