import clsx from 'clsx';
import { IconButton } from 'components/Button';
import { DropdownList, DropdownListItem } from 'components/DropdownList';
import { DotsIcon, EditIcon, UnlinkIcon } from 'components/Icon';
import { useDropdown } from 'hooks/useDropdown';
import { useRemoveSecondPersonFromList } from '../../../_hooks';
import { SecondPersonType } from '../../../_utils';
import s from './SecondPerson.module.scss';

export interface SecondPersonProps {
  isFirst?: boolean;
  handleSwitchToEditMode: () => void;
  secondPerson: SecondPersonType;
}

export function SecondPerson({ isFirst, secondPerson, handleSwitchToEditMode }: SecondPersonProps) {
  const { showDropdown, setDropdownElement, handleDropdownToggle, handleDropdownClose } = useDropdown();

  const handleEditing = () => {
    handleDropdownClose();
    handleSwitchToEditMode();
  };

  const { removeSecondPersonFromList } = useRemoveSecondPersonFromList({
    userIdToRemove: secondPerson.userId
  });

  const handleRemove = () => {
    handleDropdownClose();
    removeSecondPersonFromList();
  };

  return (
    <>
      <div className={clsx(s.SecondPerson__entry, isFirst && s.SecondPerson__entry__first)}>
        <div className={s.SecondPerson__info}>
          <div className={s.SecondPerson__name}>{secondPerson.fullName}</div>
          {secondPerson.additionalInfo && (
            <div className={s.SecondPerson__additionalInfo}>{secondPerson.additionalInfo}</div>
          )}
        </div>
        <div className={s.SecondPerson__dotsIcon} ref={setDropdownElement}>
          <IconButton icon={DotsIcon} onClick={handleDropdownToggle} />
          {showDropdown && (
            <DropdownList className={s.SecondPerson__actions}>
              <DropdownListItem>
                <div className={s.SecondPerson__action} onClick={handleEditing}>
                  <EditIcon />
                  <div>Редактировать</div>
                </div>
              </DropdownListItem>
              <DropdownListItem>
                <div className={s.SecondPerson__action} onClick={handleRemove}>
                  <UnlinkIcon style={{ width: 16, height: 16 }} />
                  <div>Отвязать</div>
                </div>
              </DropdownListItem>
            </DropdownList>
          )}
        </div>
      </div>
    </>
  );
}
