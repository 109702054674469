import React, { useRef } from 'react';
import clsx from 'clsx';
import s from './Input.module.scss';

export interface InputProps {
  type?: string;
  placeholder?: string;
  dimmedPlaceHolder?: boolean;
  disabled?: boolean;
  iconLeft?: React.ElementType;
  iconLeftHandler?: () => void;
  iconRight?: React.ElementType;
  iconRightHandler?: () => void;
  value?: string;
  onChange?: (value: string) => void;
}

export function Input({
  type = 'text',
  placeholder,
  dimmedPlaceHolder,
  disabled,
  iconLeft: IconLeft,
  iconLeftHandler,
  iconRight: IconRight,
  iconRightHandler,
  value,
  onChange
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={s.Input__wrapper}>
      {IconLeft && (
        <IconLeft
          className={clsx(s.Input__iconLeft, disabled && s.Input__iconLeft_disabled)}
          onClick={() => (iconLeftHandler ? iconLeftHandler() : !disabled && inputRef.current?.focus())}
        />
      )}

      <input
        ref={inputRef}
        className={clsx(
          s.Input,
          !!IconLeft && s.Input_hasIconLeft,
          value && s.Input_activated,
          dimmedPlaceHolder && s.Input__dimmedPlaceholder
        )}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        value={value ?? undefined}
        onChange={(e) => onChange?.(e.target.value)}
      />

      {IconRight && (
        <IconRight
          className={clsx(s.Input__iconRight, disabled && s.Input__iconRight_disabled)}
          onClick={() => (iconRightHandler ? iconRightHandler() : !disabled && inputRef.current?.focus())}
        />
      )}
    </div>
  );
}
