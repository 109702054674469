import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DATE_DAY_MONTH_SHORT, DATE_FULL, formatDate, formatNumber, formatNumberIntl } from '@digital-gov/ui-utils';
import { RegionIndicatorType } from 'store/graphql';
import s from './styled.module.scss';

const Card = ({ linkTo, indicator }: { linkTo: string; indicator: RegionIndicatorType }) => {
  const leadYear = '2021';

  const data = indicator.history.map((historyValue) => ({
    year: formatDate(historyValue?.date, DATE_DAY_MONTH_SHORT).slice(0, 6),
    grade: historyValue?.value,
    date: historyValue.date
  }));

  const content = (
    <>
      <div className={s.RawStart}>
        <p title={indicator.name}>{indicator.name}</p>
        <div className={clsx(s.Raw, s.IndicatorNameGap)}>
          <span className={s.Scores}>Макс. балл {formatNumber(indicator.pointsMax)}</span>
          <span className={s.Scores_value}>{formatNumber(indicator.pointsTotal)}</span>
        </div>
      </div>

      <div className={s.GraphWrap}>
        <ResponsiveContainer height={150}>
          <LineChart data={data} margin={{ top: 10, left: -20 }}>
            <CartesianGrid strokeDasharray={'5 5'} style={{ stroke: '#504a69' }} vertical={false} />

            <XAxis
              dataKey={'date'}
              padding={{ left: 40, right: 40 }}
              axisLine={{ stroke: '#504a69' }}
              tickFormatter={(val) => formatDate(val, DATE_DAY_MONTH_SHORT).slice(0, 6)}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: 400, fill: '#d2d3dd' }}
              tickMargin={8}
            />

            <YAxis
              domain={[
                0,
                Number((indicator.pointsMax / 4).toFixed(1)),
                Number((indicator.pointsMax / 2).toFixed(1)),
                Number((indicator.pointsMax * 0.75).toFixed(1)),
                Number(indicator.pointsMax.toFixed(1))
              ]}
              type={'number'}
              axisLine={{ stroke: '#504a69' }}
              style={{ fontWeight: 400, fontSize: 12 }}
              tick={{ fill: '#787a92' }}
              tickLine={false}
              interval={0}
              tickMargin={8}
              ticks={[
                0,
                Number((indicator.pointsMax / 4).toFixed(1)),
                Number((indicator.pointsMax / 2).toFixed(1)),
                Number((indicator.pointsMax * 0.75).toFixed(1)),
                Number(indicator.pointsMax.toFixed(1))
              ]}
            />
            <Line
              legendType={'none'}
              isAnimationActive={false}
              dataKey={'grade'}
              stroke={'#55d666'}
              activeDot={{ fill: '#4c77fe', r: 6, strokeWidth: 0 }}
              dot={({ cx, cy, payload }) => (
                <circle
                  key={payload.year}
                  r={6}
                  fill={!!leadYear && payload.year < leadYear ? '#39509d' : '#4c77fe'}
                  cx={cx}
                  cy={cy}
                />
              )}
            />

            <ReferenceLine x={leadYear || 0} stroke={'#4c77fe'} strokeDasharray={'3 3'} />

            <Tooltip
              cursor={{ stroke: '#4c77fe' }}
              labelFormatter={(label) => formatDate(label, DATE_FULL)}
              labelStyle={{ color: '#787a92', fontSize: 12, lineHeight: '16px' }}
              wrapperStyle={{ outline: 0, boxShadow: '0px 2px 16px rgba(10, 9, 17, 0.45)', borderRadius: 8 }}
              separator={''}
              formatter={(value) => [formatNumberIntl(value as number), '']}
              itemStyle={{ color: '#d2d3dd', fontSize: 12, lineHeight: '16px' }}
              contentStyle={{
                display: 'flex',
                flexFlow: 'column-reverse',
                backgroundColor: '#27283d',
                border: 0,
                borderRadius: 8
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );

  return indicator.availableForDetails ? (
    <Link to={linkTo} className={s.Wrap}>
      {content}
    </Link>
  ) : (
    <div className={s.Wrap}>{content}</div>
  );
};

export default Card;
