var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { AuthScope } from '../AuthScope';
import { GET_TOKEN, LOGOUT, REFRESH_TOKEN } from './_internal/graphql';
import { parseAuthResponse } from './_internal/parseAuthResponse';
import { ShowAfter } from './_internal/ShowAfter';
import { singleton } from './_internal/singleton';
import { AuthContext } from './AuthContext';
import { saveAuthTarget } from './authTarget';
const clientIdMap = {
    [AuthScope.Dashboard]: 'lkrtc',
    [AuthScope.Rating]: 'rating'
};
export class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.refreshTokenStorageKey = `refreshToken-${this.props.authScope}`;
        this.logInUrl = 'https://login.eskigov.ru/blitz/oauth/ae' +
            '?response_type=code&scope=openid+email+sbj' +
            `&client_id=${clientIdMap[this.props.authScope]}` +
            `&redirect_uri=${window.location.origin}${this.props.redirectPathname}`;
        this.authClient = new ApolloClient({
            uri: this.props.apiUrl,
            cache: new InMemoryCache()
        });
        this.handleStorageChange = () => __awaiter(this, void 0, void 0, function* () {
            const refreshToken = localStorage.getItem(this.refreshTokenStorageKey);
            if (this.state.refreshToken !== refreshToken) {
                this.setState({ refreshToken, authCode: null });
            }
        });
        this.setToken = (tokenData) => new Promise((resolve) => {
            const { accessToken, expiresIn, refreshToken } = tokenData;
            const authData = { accessToken, expires: Date.now() + expiresIn * 1000 * 0.85 };
            this.setState({ authData, refreshToken, authCode: null }, () => {
                localStorage.setItem(this.refreshTokenStorageKey, refreshToken);
                // для удобства разработки и тестирования
                sessionStorage.setItem(`accessToken-${this.props.authScope}`, accessToken);
                resolve(authData.accessToken);
            });
        });
        this.requestToken = (authCode) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.authClient.query({
                    fetchPolicy: 'no-cache',
                    query: GET_TOKEN,
                    variables: {
                        input: {
                            code: authCode,
                            redirectUri: window.location.origin + this.props.redirectPathname,
                            scope: this.props.authScope
                        }
                    }
                });
                return this.setToken(data.getTokens);
            }
            catch (error) {
                if (!!(error === null || error === void 0 ? void 0 : error.networkError)) {
                    yield this.logOut({ error: 'Ошибка соединения с API-сервисом', noBlitz: true });
                }
                else {
                    yield this.logOut({ error: 'Не удалось получить маркер доступа' });
                }
                return null;
            }
        });
        this.refreshToken = (refreshToken) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            try {
                const { data } = yield this.authClient.query({
                    fetchPolicy: 'no-cache',
                    query: REFRESH_TOKEN,
                    variables: { input: { refreshToken, scope: this.props.authScope } }
                });
                return this.setToken(data.refreshTokens);
            }
            catch (error) {
                // проверила, что с сервера возвращается именно такая ошибка
                if (((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c[0]) === 'refreshTokens')
                    yield this.logOut({ error: 'Сессия истекла', noBlitz: true });
                return null;
            }
        });
        this.getToken = singleton(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (this.state.authCode && !this.state.refreshToken) {
                return this.requestToken(this.state.authCode);
            }
            else if (this.state.refreshToken && (!this.state.authData || this.state.authData.expires < Date.now())) {
                return this.refreshToken(this.state.refreshToken);
            }
            else {
                return (_b = (_a = this.state.authData) === null || _a === void 0 ? void 0 : _a.accessToken) !== null && _b !== void 0 ? _b : null;
            }
        }));
        this.isLoggedIn = () => !!this.state.refreshToken || !!this.state.authCode;
        this.logIn = () => {
            // иногда выдает Forbidden при повторной попытке перейти по ссылке
            // какой-то rate limiting, завязанный на сессии (private-режим помогает)
            window.location.href = this.logInUrl;
        };
        this.logOut = (options) => __awaiter(this, void 0, void 0, function* () {
            try {
                if (!(options === null || options === void 0 ? void 0 : options.noBlitz) && this.state.refreshToken) {
                    yield this.authClient.mutate({
                        fetchPolicy: 'no-cache',
                        mutation: LOGOUT,
                        variables: { input: { token: this.state.refreshToken, scope: this.props.authScope } }
                    });
                }
                if (options === null || options === void 0 ? void 0 : options.error)
                    this.setState({ error: options.error });
            }
            catch (error) {
                this.setState({ error: 'Не удалось осуществить полноценный выход' });
            }
            finally {
                this.setState({ authData: null, refreshToken: null, authCode: null }, () => {
                    localStorage.removeItem(this.refreshTokenStorageKey);
                });
            }
        });
        const state = {
            authData: null,
            refreshToken: localStorage.getItem(this.refreshTokenStorageKey),
            authCode: null,
            error: null,
            getToken: this.getToken,
            isLoggedIn: this.isLoggedIn,
            logIn: this.logIn,
            logOut: this.logOut
        };
        // Blitz response
        if (window.location.pathname === this.props.redirectPathname) {
            const { code, error, errorDescription } = parseAuthResponse();
            if (code || error) {
                state.authCode = code;
                state.refreshToken = null;
                localStorage.removeItem(this.refreshTokenStorageKey);
                if (error) {
                    console.error(error, errorDescription);
                    state.error = 'Ошибка авторизации от Blitz';
                }
            }
        }
        this.autoLogIn = !state.authCode && !state.refreshToken && !state.error;
        this.state = state;
    }
    componentDidMount() {
        // подписка на изменения в localStorage, чтобы отслеживать изменения из других вкладок
        window.addEventListener('storage', this.handleStorageChange);
        if (this.autoLogIn) {
            saveAuthTarget(window.location.pathname);
            this.logIn();
        }
        else {
            // pre-fetch
            void this.getToken();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }
    render() {
        if (this.autoLogIn) {
            return (React.createElement(ShowAfter, { delay: 2000 },
                React.createElement("a", { style: { display: 'block', margin: 12, lineHeight: 1, opacity: 0.75 }, href: this.logInUrl }, this.logInUrl)));
        }
        else {
            return React.createElement(AuthContext.Provider, { value: this.state }, this.props.children);
        }
    }
}
