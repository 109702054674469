/**
 * Pluralizes noun according to count
 * @param {number} count Number of items
 * @param {string} one E.g. «балл»
 * @param {string} two E.g. «балла»
 * @param {string} many E.g. «баллов»
 * @returns {string}
 */
export const pluralize = (count, one, two, many) => {
    count = Math.floor(Math.abs(count)) % 100;
    if (count > 10 && count < 20)
        return many;
    count = count % 10;
    if (count === 1)
        return one;
    if (count >= 2 && count <= 4)
        return two;
    return many;
};
