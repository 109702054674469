import React, { useState } from 'react';
import clsx from 'clsx';
import { Icon, IconChevronDown, IconChevronUp, IconRecommendation, IconSize } from 'componentsL/Icon';
import { isIndicatorScope } from './_utils/helpers';
import { ControlViewState, CommentType } from './_utils/types';
import { useCommentContext } from './context/useCommentContext';
import { CommentError } from './modals/CommentError';
import { CommentSection } from './modals/CommentSection';
import { CommentSuccess } from './modals/CommentSuccess';
import { ConfirmPublishing } from './modals/ConfirmPublishing';
import { DraftAlert } from './modals/DraftAlert';
import s from './CommentsControl.module.scss';

export interface CommentsControlProps {
  entityName?: string | null;
  comments?: CommentType[] | null;
}

export function CommentsControl({ entityName, comments }: CommentsControlProps) {
  const [state, setState] = useState(ControlViewState.None);
  const [lastCommentOpened, setLastCommentOpened] = useState(false);
  const context = useCommentContext();

  const handleLastCommentOpened = () => {
    setLastCommentOpened(!lastCommentOpened);
  };
  const handleCommentSection = () => {
    setState(ControlViewState.CommentSection);
  };
  const handleClose = () => {
    context.commentText = context.initialDraftText;
    setState(ControlViewState.None);
  };
  const handleDraftAlert = () => {
    setState(ControlViewState.DraftAlert);
  };
  const handleConfirmPublishing = () => {
    setState(ControlViewState.ConfirmPublishing);
  };
  const handleCommentSuccess = () => {
    setState(ControlViewState.CommentSuccess);
  };
  const handleCommentError = () => {
    setState(ControlViewState.CommentError);
  };

  let lastPublishedComment: CommentType | undefined | null = comments?.[0];
  lastPublishedComment = lastPublishedComment?.commentText.trim() === '' ? null : lastPublishedComment;

  const indicatorsScope = isIndicatorScope(context.scope);

  const labelText = indicatorsScope ? 'Рекомендация' : 'Комментарий';
  const styleScope = indicatorsScope ? 'indicators' : 'common';
  return (
    <div className={clsx(indicatorsScope && s.CommentsControl__wrapper)}>
      <div className={clsx(s.CommentsControl, s[`CommentsControl_${styleScope}`])}>
        {indicatorsScope && (
          <div
            className={clsx(
              s.CommentsControl__recommendation,
              !lastPublishedComment && s.CommentsControl__recommendation_dimmed
            )}>
            <Icon icon={IconRecommendation} iconSize={IconSize.Small} />
          </div>
        )}
        {lastPublishedComment ? (
          <div className={s.CommentsControl__link} onClick={handleLastCommentOpened}>
            <div className={s.CommentsControl__lastComment}>
              <div>{labelText}</div>
              <Icon icon={lastCommentOpened ? IconChevronUp : IconChevronDown} size={IconSize.Small} />
            </div>
          </div>
        ) : (
          <>
            {indicatorsScope ? (
              <>
                <div className={clsx(s.CommentsControl__lastComment, s.CommentsControl__lastComment_special)}>
                  <div>{labelText}</div>
                  <Icon icon={IconChevronDown} size={IconSize.Small} />
                </div>
              </>
            ) : (
              <div className={s.CommentsControl__lastComment_empty}>{labelText}</div>
            )}
          </>
        )}
        <div className={s.CommentsControl__divider}></div>
        <div className={s.CommentsControl__link} onClick={handleCommentSection}>
          {lastPublishedComment ? 'Редактировать' : 'Добавить'}
        </div>
        {state === ControlViewState.CommentSection && (
          <CommentSection
            {...{
              entityName,
              comments,
              onError: handleClose,
              onBack: handleClose,
              onConfirmPublishing: handleConfirmPublishing,
              onDraftAlert: handleDraftAlert
            }}
          />
        )}
        {state === ControlViewState.CommentError && <CommentError onBack={handleClose} />}
        {state === ControlViewState.DraftAlert && (
          <DraftAlert onBack={handleCommentSection} onError={handleCommentError} onClose={handleClose} />
        )}
        {state === ControlViewState.ConfirmPublishing && (
          <ConfirmPublishing
            onBack={handleCommentSection}
            onSuccess={handleCommentSuccess}
            onError={handleCommentError}
          />
        )}
        {state === ControlViewState.CommentSuccess && <CommentSuccess onClose={handleClose} />}
      </div>
      {lastCommentOpened && lastPublishedComment && (
        <div className={clsx(s.CommentsControl__lastCommentText, s[`CommentsControl__lastCommentText_${styleScope}`])}>
          {lastPublishedComment.commentText}
        </div>
      )}
    </div>
  );
}
