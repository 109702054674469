import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { tryParseISO } from './tryParseISO';
export const DATE_FULL = 'dd MMMM yyyy';
export const DATE_FULL_DAY_SHORT = 'd MMMM yyyy';
export const DATE_DAY_MONTH = 'd MMMM';
export const DATE_DAY_MONTH_SHORT = 'dd MMM';
export const DATETIME_FULL = 'dd MMMM yyyy, HH:mm';
export const DATETIME_SHORT = 'dd.MM.yyyy HH:mm';
export const DATETIME_SHORT_YEAR_PRECISE = 'dd.MM.yyyy, HH:mm:ss';
export const DATE_SHORT = 'dd.MM.yy';
export const DATE_SHORT_YEAR = 'dd.MM.yyyy';
export const DATE_SHORT_WEEK_DATE = 'dd.MM.yyyy EEEEEE';
export const DATE_MONTH_YEAR_FULL = 'LLLL yyyy';
export const DATE_MONTH_FULL = 'LLLL';
export const DATE_MONTH_SHORT = 'MM';
export const DATE_YEAR_FULL = 'yyyy';
export const TIME = 'HH:mm:ss';
export function formatDate(dateIso, formatString = DATETIME_FULL, options) {
    const date = typeof dateIso === 'string' ? tryParseISO(dateIso) : dateIso || null;
    return (date && format(date, formatString, Object.assign({ locale: ru }, options))) || null;
}
