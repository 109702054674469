import React from 'react';
import { IndicatorPage } from '../../../_components/IndicatorPage';
import { Symbol } from '../../../_components/Symbol';
import { SymbolDetails } from '../../../_components/SymbolDetails';
import { SymbolsGrid } from '../../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';
import s from './GovDataControl.module.scss';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const k1 = components[0]?.value; // 223 - K1
  const k2 = components[1]?.value; // 225 - K2
  const k3 = components[2]?.value; // 227 - K3
  const wk2 = components[3]?.value; // 224 - Wk2
  const wk3 = components[4]?.value; // 226 - Wk3
  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails value={k1} description={'Значение критерия ведения модели государственных данных'}>
        <Symbol sub={'1'}>K</Symbol>
      </SymbolDetails>
      <SymbolDetails value={k2} description={'Значение критерия функционирования витрин данных'}>
        <Symbol sub={'2'}>K</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k3}
        description={<>Значение критерия предоставления доступа к данным по запросам потребителей</>}>
        <Symbol sub={'3'}>K</Symbol>
      </SymbolDetails>
      <SymbolDetails value={wk2} description={'Вес критерия функционирования витрин данных'}>
        <Symbol sub={'k2'}>W</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={wk3}
        description={<>Вес критерия предоставления доступа к данным по запросам потребителей</>}>
        <Symbol sub={'k3'}>W</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <>
      <p className={s.GovDataControl__p}>Максимальное количество баллов по показателю – 4,0</p>
    </>
  );
}

export function GovDataControl() {
  return (
    <IndicatorPage
      download={'НСУД_v2.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
