import { formatNumberIntl, joinNonEmpty } from '@digital-gov/ui-utils';
import { RatingGroupEnum } from 'store/graphql';
import { budgetGroups } from './constants';
import { RatingDataType } from './types';

export class DepartmentChartUtils {
  static ratingCompletenessLabels = {
    [RatingGroupEnum.Low]: 'Низкая',
    [RatingGroupEnum.Average]: 'Средняя',
    [RatingGroupEnum.High]: 'Высокая'
  };

  static getChartTicksByValues = (data: RatingDataType[]): number[] => {
    if (!data?.length) return [0, 1];

    const valMin = Math.min(...data.map((item) => item.degree));
    const valMax = Math.max(...data.map((item) => item.degree));
    const tickMin = Math.floor(valMin) - 2;
    const tickMax = Math.ceil(valMax) + 2;

    return [tickMin, tickMax];
  };

  static getBudgetGroupByValue = (value: number): number => {
    let group = 0;

    budgetGroups.forEach(([min, max], i) => {
      if (value > min && value <= max) {
        group = i;
        return;
      }
    });

    return group;
  };

  static getBudgetGroupLabel = (group?: [number, number]): string => {
    if (!group) return '';

    const min = group[0] === 0 ? null : group[0];
    const max = group[1] === Infinity ? null : group[1];

    return joinNonEmpty(
      [
        min && `${max ? 'от' : 'свыше'} ${formatNumberIntl(min / 1_000_000_000)}`,
        max && `до ${formatNumberIntl(max / 1_000_000_000)}`,
        'млрд'
      ],
      ' '
    );
  };

  static checkItemIsSearched = (name: string, searchValue: string): boolean => {
    return name.toLowerCase().includes(searchValue.toLowerCase());
  };

  static checkItemIsSelected = (item: RatingDataType, selectedViceChairman: string): boolean => {
    const itemViceChairmans = item.viceChairmans.map((v) => v.fullName);
    /**
     * TODO, особенности отображения ВП на странице ФОИВ-а
     * Правильное определение следующее (т.к. у одного ФОИВ-а может быть несколько ВП:
     *    for (const vc of itemViceChairmans) {
     *       if (vc.includes(selectedViceChairman)) return true;
     *     }
     * Но, т.к. на странице ФОИВ-а всегда отображается только _первый_ ВП, проверка иная:
     */
    return itemViceChairmans[0]?.includes(selectedViceChairman);
  };

  static checkItemIsFiltered = (item: RatingDataType, searchValue: string, selectedViceChairman: string): boolean => {
    return (
      (!!searchValue && !DepartmentChartUtils.checkItemIsSearched(item.departmentName, searchValue)) ||
      (!!selectedViceChairman && !DepartmentChartUtils.checkItemIsSelected(item, selectedViceChairman))
    );
  };
}
