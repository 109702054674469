import { FilteredSelect } from 'components/FilteredSelect';
import { useRctControlContext } from '../../../_store/useRctControlContext';

export interface RctListProps {
  disabled?: boolean;
}

export function RctList({ disabled }: RctListProps) {
  const { setCurrentRct, rctList } = useRctControlContext();

  const rctOptions = rctList.map((rct) => {
    return {
      label: rct.fullName,
      value: rct.userId
    };
  });

  const handleRctPick = (userId: number) => {
    const newRct = rctList.find((rct) => rct.userId === userId);
    if (newRct) {
      setCurrentRct(newRct);
    }
  };

  return <FilteredSelect disabled={disabled} options={rctOptions} placeholder={'Выбрать'} onChange={handleRctPick} />;
}
