import { memo } from 'react';
import clsx from 'clsx';
import { RegionRatingGroupEnum } from 'store/graphql';
import s from './TableCellRatingGroup.module.scss';

export interface TableCellRatingGroupProps {
  title?: string | null;
  ratingGroup?: RegionRatingGroupEnum;
  className?: string;
}

export const TableCellRatingGroup = memo((props: TableCellRatingGroupProps) => {
  const { title, className, ratingGroup = RegionRatingGroupEnum.Average } = props;

  return (
    <div className={clsx(className, s.TableCellRatingGroup)}>
      <div
        className={clsx(s.TableCellRatingGroup__line, s[`TableCellRatingGroup__line_${ratingGroup.toLowerCase()}`])}
      />
      {title && <p className={s.TableCellRatingGroup__title}>{title}</p>}
    </div>
  );
});

TableCellRatingGroup.displayName = 'TableCellRatingGroup';
