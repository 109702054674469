import { useCallback } from 'react';
import { RctScopeEnum, useLinkSecondPersonsToScopeMutation } from 'store/graphql';
import { useRctControlContext } from '../_store/useRctControlContext';
import { RctControlScopeEnum } from '../_utils';

export interface UseLinkSecondPersonListToScopeProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export function useLinkSecondPersonsToScope({ onError, onSuccess }: UseLinkSecondPersonListToScopeProps) {
  // Мутация обновления списка ВП\ПП у ФОИВ-а\РОИВ-а
  const [linkSecondPersonsToScopeMutation, { loading }] = useLinkSecondPersonsToScopeMutation();
  const context = useRctControlContext();

  // Хендлер привяки списка ВП\ПП
  const handleLinkSecondPersonsToScope = useCallback(async () => {
    try {
      const isFrct = context.scope === RctControlScopeEnum.ViceChairman;
      const mutationScope: RctScopeEnum = isFrct ? RctScopeEnum.Frct : RctScopeEnum.Rrct;
      const scopeId = context.currentScopeObject.scopeId;

      await linkSecondPersonsToScopeMutation({
        variables: {
          scopeId,
          scope: mutationScope,
          secondPersons: context.currentSecondPersons.map((sp) => {
            return {
              id: sp.userId,
              description: sp.additionalInfo && sp.additionalInfo !== '' ? sp.additionalInfo : null
            };
          })
        }
      });
      if (context.refetchScopeData) {
        await context.refetchScopeData();
      }
      if (onSuccess) onSuccess();
    } catch (e) {
      if (onError) onError();
    }
  }, [onError, onSuccess, linkSecondPersonsToScopeMutation, context]);

  return { linkSecondPersonsToScope: handleLinkSecondPersonsToScope, loading };
}
