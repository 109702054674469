import React from 'react';
import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const kpa = components[1]?.value; // КПа
  const kpp = components[2]?.value; // КПп
  const kpmes = components[4]?.value; // КПмес
  const kpzvon = components[5]?.value; // КПзвон
  const kp2fa = components[7]?.value;
  return (
    <SymbolsGrid columns={3} largeGap>
      <SymbolDetails
        value={kpa}
        description={
          'Активность пользователей в части ведения почтовой переписки, организации дня, работы с документами'
        }>
        <Symbol sub={'а'}>КП</Symbol>
      </SymbolDetails>
      <SymbolDetails value={kpp} description={'Количество переведенных рабочих мест на КС «АРМ ГС»'}>
        <Symbol sub={'п'}>КП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={kpmes}
        description={
          'Достижение минимальной среднедневной интенсивности переписки в мессенджере на одного пользователя'
        }>
        <Symbol sub={'мес'}>КП</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={kpzvon}
        description={'Достижение минимальной среднедневной интенсивности АКС/ВКС на одного пользователя'}>
        <Symbol sub={'звон'}>КП</Symbol>
      </SymbolDetails>
      <SymbolDetails value={kp2fa} description={'Перевод пользователей на использование двухфакторной аутентификации'}>
        <Symbol sub={'2фа'}>КП</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <AdvancedTable
      // prettier-ignore
      rows={[
          { cells: ['Оценка, балл', 'Значение компонентов КПа, КПп'], isHeader: true },
          { cells: ['0', 'Переведено менее 30% рабочих мест на КС «АРМ ГС» ФОИВ и их территориальных органов'] },
          { cells: ['0,5', 'Переведено 30% и более рабочих мест на КС «АРМ ГС»'] },
          { cells: ['1,0', 'Переведено 60% и более рабочих мест на КС «АРМ ГС», достигнут показатель активности не менее 40%' ] },
          { cells: ['1,5', 'Переведено 75% и более рабочих мест на КС «АРМ ГС», достигнут показатель активности не менее 50%' ] },
          // ----
          { cells: ['Значения компонентов КПмес, КПзвон'], isInTableCustomHeader: true },
          { cells: ['0,5', 'Средняя интенсивность переписки в мессенджере 6 и более сообщений на одного пользователя в день' ] },
          { cells: ['0,5', 'Средняя интенсивность АКС и ВКС более 1 на пользователя в день'] },
          // ---
          { cells: ['Значение компонента КП2фа'], isInTableCustomHeader: true },
          { cells: ['0', 'Переведено менее 50% сотрудников на использование двухфакторной аутентификации'] },
          { cells: ['0,5', 'Переведено более 50% сотрудников на использование двухфакторной аутентификации'] },
          { cells: ['1,0', 'Переведено более 80% сотрудников на использование двухфакторной аутентификации'] }
        ]}
    />
  );
}

export function UsingArmGsIndex() {
  return (
    <IndicatorPage
      download={'Индекс использования КС АРМ ГС.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
      dynamicChartPreventGrow
    />
  );
}
