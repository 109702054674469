import React, { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { EMDASH } from '@digital-gov/ui-utils';
import { PageError } from 'components/PageError';
import { PageLoader } from 'components/PageLoader';
import { Icon, IconChevronDown, IconChevronUp, IconSize } from 'componentsL/Icon';
import { PageHeader } from 'componentsL/PageHeader';
import { Comments } from 'features/Comments';
import { CardFeedbackButton } from 'routes/indicatorL/_components/CardFeedbackButton';
import { RatingCommentScopeEnum, RatingFeedbackScope, useIndicatorWithComponentsPageQuery } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { IndicatorComponentsProps } from '../../_utils/types';
import { Card } from './Card';
import { CardsLayout } from './CardsLayout';
import { Detalization } from './Detalization';
import { IndicatorDynamicsChart } from './IndicatorDynamicsChart';
import { PersonResponsible } from './PersonResponsible';
import s from './IndicatorPage.module.scss';

export enum UpdateDays {
  AtLeast15 = 'не позднее 15 числа',
  AtLeast15EveryMonth = 'не позднее 15 числа каждого месяца',
  InFirstAnd15 = '1 и 15 числа',
  InFirstDay = 'не позднее 1 числа'
}

export interface IndicatorPageProps {
  regionScope?: boolean;
  formula?: React.ElementType;
  indicatorComponents?: React.ElementType<IndicatorComponentsProps>;
  indicatorTable?: React.ElementType;
  download?: string;
  additionalInfo?: string | React.ReactNode;
  updateDays?: UpdateDays;
  dynamicChartPreventGrow?: boolean;
}

export function IndicatorPage({
  regionScope,
  formula,
  indicatorComponents,
  indicatorTable,
  download,
  additionalInfo,
  updateDays,
  dynamicChartPreventGrow
}: IndicatorPageProps) {
  const params = useParams<{ departmentId: string; regionId: string }>();
  const location = useLocation();

  // Контроль раскрытия детализации
  const [detalizationOpened, setDetalizationOpened] = useState(true);

  // scope - ФОИВ или РОИВ
  const scopeId = regionScope ? +(params.regionId as string) : +(params.departmentId as string);
  // наличие id гарантируется роутером
  const indicatorId = +location.pathname.split('/')[4];
  const isRegion = regionScope ?? false;

  const { isObserver } = useProfile();

  const { loading, error, data, refetch } = useIndicatorWithComponentsPageQuery({
    variables: { isRegion, scopeId, indicatorId }
  });
  if (loading) return <PageLoader />;
  if (!!error || !data) return <PageError />;

  const department = data.rating.departmentRating?.departments[0];
  const region = data.rating.regionRating?.regions[0];
  if ((!isRegion && !department) || (isRegion && !region)) return <Navigate to={'/'} replace />;
  const indicator = isRegion ? region?.indicators[0] : department?.indicators[0];
  if (!indicator) return <Navigate to={'/'} replace />;

  const backTo = isRegion ? `/region/${scopeId}` : `/department/${scopeId}`;

  // полезные данные
  const scopeLabel = isRegion ? '' : 'Оперативный рейтинг ФРЦТ';
  const scopeName = isRegion ? region?.regionName : department?.departmentName;
  const allowedToTotalFeedback = isRegion ? region?.allowedToFeedback : department?.allowedToFeedback;
  const recommendations = isRegion
    ? region?.indicators[0]?.recommendations
    : department?.indicators[0]?.recommendations;
  const { methodologies, specifications } = indicator;
  const useDownloadBlock = specifications.length > 0;

  const { name, updateFrequency, components, responsible, history, pointsMax } = indicator;
  const allowedToIndicatorFeedback = indicator.allowedToFeedback;

  // детализация показателя (только для ФРЦТ)
  const detalization = isRegion ? null : department?.indicators[0].detalization;

  // проброс компонентов из уровня выше
  const Formula = formula as React.ElementType;
  const IndicatorComponents = indicatorComponents as React.ElementType<IndicatorComponentsProps>;
  const IndicatorTable = indicatorTable as React.ElementType;

  const handleOpened = () => {
    setDetalizationOpened((v) => !v);
  };

  return (
    <React.Fragment>
      <PageHeader
        backTo={backTo}
        pageTitle={`${name} ${EMDASH} ${scopeName}`}
        title={`${scopeLabel} ${scopeName}`}
        descriptor={`Показатель «${name}»`}
        slotRight={
          responsible ? (
            <PersonResponsible
              responsible={responsible}
              responsibleControl={
                isObserver
                  ? {
                      scope: regionScope ? 'rrct' : 'frct',
                      scopeId,
                      indicatorName: name,
                      indicatorId,
                      currentResponsibleRaw: responsible,
                      refetch
                    }
                  : undefined
              }
            />
          ) : (
            <></>
          )
        }
      />

      <CardsLayout>
        <Card
          title={`Методика расчета показателя «${name}»`}
          additionalInfo={additionalInfo}
          descriptor={`${updateFrequency || 'Частота обновления неизвестна'}, ${updateDays ?? UpdateDays.AtLeast15}`}
          headerControls={
            allowedToTotalFeedback || allowedToIndicatorFeedback ? (
              <CardFeedbackButton
                hasFeedback={indicator.haveFeedback}
                scope={isRegion ? RatingFeedbackScope.RegionIndicator : RatingFeedbackScope.DepartmentIndicator}
                scopeId={scopeId}
                indicatorId={indicatorId}
              />
            ) : undefined
          }
          downloadPath={isRegion ? 'regions' : 'departments'}
          download={download}
          methodologies={methodologies}
          specifications={specifications}
          useDownloadBlock={useDownloadBlock}
          scopeId={scopeId}
          indicatorId={indicatorId}>
          <Comments
            scope={isRegion ? RatingCommentScopeEnum.RegionIndicator : RatingCommentScopeEnum.DepartmentIndicator}
            scopeId={scopeId}
            scopeIndicatorId={indicatorId}
            scopeName={`${scopeName}. ${name}`}
            draft={recommendations?.draft}
            comments={recommendations?.entries}
            departmentRefetch={refetch}
            regionRefetch={refetch}
          />

          {formula && (
            <div style={{ marginTop: 8 }}>
              <Formula />
            </div>
          )}
          <div className={s.IndicatorPageComponentsData}>
            {indicatorComponents && <IndicatorComponents components={components} />}
            {detalization && detalizationOpened && <div className={s.IndicatorPageComponentsData__line}></div>}
            {detalization && detalizationOpened && (
              <Detalization indicatorId={indicatorId} detalizationTable={detalization} />
            )}
          </div>

          {detalization && (
            <div className={s.IndicatorPageComponentsData__detalization} onClick={handleOpened}>
              <div>{'Детализация'}</div>
              <Icon icon={detalizationOpened ? IconChevronUp : IconChevronDown} size={IconSize.Small} />
            </div>
          )}
        </Card>

        {history.length > 0 && (
          <Card
            isChart
            title={'Динамика показателя по периодам'}
            style={dynamicChartPreventGrow ? { alignSelf: 'flex-start' } : undefined}>
            <IndicatorDynamicsChart maxInChart={pointsMax} chart={history} />
          </Card>
        )}

        {indicatorTable && (
          <Card title={`Расчет показателя «${name}»`} isTable>
            <IndicatorTable />
          </Card>
        )}
      </CardsLayout>
    </React.Fragment>
  );
}
