import { useCallback, useEffect, useState } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import {
  RatingFeedbackScope,
  RequestDepartmentFeedbackDocument,
  RequestDepartmentFeedbackQuery,
  RequestDepartmentFeedbackQueryVariables,
  RequestRegionFeedbackDocument,
  RequestRegionFeedbackQuery,
  RequestRegionFeedbackQueryVariables
} from 'store/graphql';
import { normalizeFeedbackList } from 'utils/feedback/normalizeFeedbackList';
import { normalizeIndicatorValues } from 'utils/feedback/normalizeIndicatorValues';

export interface Indicator {
  id: number;
  name: string;
}

export function useFeedbackAndIndicatorsList(
  scope: RatingFeedbackScope,
  scopeId: number
): [boolean, ApolloError | null, Feedback[], Indicator[]] {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApolloError | null>(null);
  const [data, setData] = useState<{ feedback: Feedback[]; indicators: Indicator[] }>({ feedback: [], indicators: [] });

  const getRating = useCallback(async () => {
    try {
      const { data } = await apolloClient.query<RequestRegionFeedbackQuery, RequestRegionFeedbackQueryVariables>({
        query: RequestRegionFeedbackDocument,
        variables: {
          regionId: scopeId
        }
      });

      const region = data.rating.regionRating.regions[0];

      if (region) {
        const allowed = new Set<number>();
        region.indicators.forEach(({ indicatorId, allowedToFeedback }) => {
          if (allowedToFeedback) {
            allowed.add(indicatorId);
          }
        });
        setData({
          feedback: normalizeFeedbackList(region.feedback),
          indicators: normalizeIndicatorValues(region.indicatorsValues as any).filter((indicator) =>
            allowed.has(indicator.id)
          )
        });
      }
      setError(null);
    } catch (error) {
      setError(error as ApolloError);
    } finally {
      setLoading(false);
    }
  }, [scopeId, setError, setLoading, setData, apolloClient]);

  const getDepartment = useCallback(async () => {
    try {
      const { data } = await apolloClient.query<
        RequestDepartmentFeedbackQuery,
        RequestDepartmentFeedbackQueryVariables
      >({
        query: RequestDepartmentFeedbackDocument,
        variables: {
          departmentId: scopeId
        }
      });

      const department = data.rating.departmentRating.departments[0];

      if (department) {
        const allowed = new Set<number>();
        department.indicators.forEach(({ indicatorId, allowedToFeedback }) => {
          if (allowedToFeedback) {
            allowed.add(indicatorId);
          }
        });
        setData({
          feedback: normalizeFeedbackList(department.feedback),
          indicators: normalizeIndicatorValues(department.indicatorsValues as any).filter((indicator) =>
            allowed.has(indicator.id)
          )
        });
      }
      setError(null);
    } catch (error) {
      setError(error as ApolloError);
    } finally {
      setLoading(false);
    }
  }, [scopeId, setError, setLoading, setData, apolloClient]);

  useEffect(() => {
    if (scope === RatingFeedbackScope.Region || scope === RatingFeedbackScope.RegionIndicator) {
      getRating();
    } else {
      getDepartment();
    }

    return () => apolloClient.stop();
  }, [scope, getRating, getDepartment, apolloClient]);

  return [loading, error, data.feedback, data.indicators];
}
