import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useClickOutside, useToggle } from '@digital-gov/ui-utils';
import { DropdownList } from 'componentsL/DropdownList';
import { DropdownItem } from 'componentsL/DropdownList/DropdownItem';
import { IconBurger } from 'componentsL/Icon';
import { IconButton, IconButtonKind } from 'componentsL/IconButton';
import { Tooltip } from 'componentsL/Tooltip';
import { isProduction } from 'store/config';
import { useProfile } from 'store/profile/useProfile';
import s from './Nav.module.scss';

export const Nav = () => {
  const { isFRCT, isRRCT, isObserver } = useProfile();

  const MENU = [
    {
      text: 'Рейтинг ФРЦТ',
      link: '/'
    },
    {
      text: 'Рейтинг РРЦТ',
      link: '/regions'
    },
    {
      text: 'Лучшие показатели ФРЦТ',
      link: '/mvi/frct',
      hidden: !isFRCT && !isObserver
    },
    {
      text: 'Лучшие показатели РРЦТ',
      link: '/mvi/rrct',
      hidden: !isRRCT && !isObserver
    },
    {
      text: 'Вопросы и ответы',
      link: '/faq/frct'
    }
  ];

  const [menuIsOpen, { toggle, unset }] = useToggle();

  const [navNode, setNavNode] = useState<HTMLDivElement | null>(null);

  useClickOutside(navNode, unset);

  return (
    <>
      <div ref={setNavNode}>
        <IconButton className={s.Nav__burger} kind={IconButtonKind.Secondary} isActive={menuIsOpen} onClick={toggle}>
          <IconBurger />
        </IconButton>
      </div>

      {menuIsOpen && (
        <Tooltip
          classes={{
            content: s.Nav__tooltipContent
          }}
          targetNode={navNode}
          showArrow={false}
          tooltipOptions={{
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4]
                }
              }
            ]
          }}>
          <DropdownList classes={{ items: s.Nav__dropdownItems }}>
            {MENU.map(
              ({ text, link, hidden }, index) =>
                !hidden && (
                  <Link to={link} key={index}>
                    <DropdownItem value={text} />
                  </Link>
                )
            )}

            {isProduction && (
              <React.Fragment>
                <div style={{ backgroundColor: '#7c749a', height: 1, margin: '0 8px' }} />
                <a href={'/rating/year'}>
                  <DropdownItem value={'Годовой рейтинг 2023'} />
                </a>
              </React.Fragment>
            )}
          </DropdownList>
        </Tooltip>
      )}
    </>
  );
};
