import React, { useCallback, useState } from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Loader } from 'componentsL/Loader';
import { Modal } from 'componentsL/Modal';
import { useUpsertCommentDraftMutation } from 'store/graphql';
import { isDepartmentScope, isIndicatorScope, isRegionScope } from '../_utils/helpers';
import { useCommentContext } from '../context/useCommentContext';
import s from './DraftAlert.module.scss';

export interface DraftAlertProps {
  onBack: () => void;
  onError: () => void;
  onClose: () => void;
}

export function DraftAlert({ onBack, onError, onClose }: DraftAlertProps) {
  const context = useCommentContext();

  const [upsertCommentDraftMutation, { loading }] = useUpsertCommentDraftMutation();
  const [refetchLoading, setRefetchLoading] = useState(false);

  const handleSaveDraft = async () => {
    await saveDraft();
  };

  const saveDraft = useCallback(async () => {
    if (context.commentText) {
      try {
        await upsertCommentDraftMutation({
          variables: {
            scope: context.scope,
            scopeId: context.scopeId,
            scopeIndicatorId: context.scopeIndicatorId,
            commentId: context.commentId ?? null,
            commentText: context.commentText
          }
        });
        // перезапрос данных для обновления счётчика и черновика
        setRefetchLoading(true);
        if (isDepartmentScope(context.scope) && context.departmentRefetch) {
          await context.departmentRefetch();
        }
        if (isRegionScope(context.scope) && context.regionRefetch) {
          await context.regionRefetch();
        }
        setRefetchLoading(false);
        onClose();
      } catch (e) {
        setRefetchLoading(false);
        onError();
      }
    } else {
      onError();
    }
  }, [upsertCommentDraftMutation, context, onError, onClose]);

  return (
    <Modal title={'Несохраненный черновик'} width={456} onClose={onBack}>
      <div className={s.DraftAlert__text}>
        {isIndicatorScope(context.scope)
          ? 'Вы уверены, что хотите выйти без сохранения черновика?'
          : 'Вы не сохранили черновик комментария.'}
      </div>
      <div className={s.DraftAlert__buttons}>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Выйти без сохранения
        </Button>
        <Button variant={ButtonVariant.Primary} disabled={loading || refetchLoading} onClick={handleSaveDraft}>
          {loading || refetchLoading ? (
            <Loader spinnerWidth={20} spinnerHeight={20} transparentBgColor={true} />
          ) : (
            <>Сохранить</>
          )}
        </Button>
      </div>
    </Modal>
  );
}
