import React, { ReactNode } from 'react';
import clsx from 'clsx';
import s from './AdvancedTable.module.scss';

export interface IAdvancedTableRowTextPart {
  text: string;
  align?: 'sub' | 'top';
}

export interface IAdvancedTableRow {
  isHeader?: boolean;
  isInTableCustomHeader?: boolean;
  reactNodes?: boolean;
  cells:
    | [
        // сценарий с двумя ячейками:
        ReactNode | string | (IAdvancedTableRowTextPart | string)[],
        ReactNode | string | (IAdvancedTableRowTextPart | string)[]
      ]
    | [
        // в случае если isInTableCustomHeader - один заголовок на строку
        ReactNode | string | (IAdvancedTableRowTextPart | string)[]
      ];
}

export interface IAdvancedTableProps {
  rows: IAdvancedTableRow[];
}

type TextBuilderRow = undefined | null | string | (IAdvancedTableRowTextPart | string)[];

function textBuilder(parts: TextBuilderRow) {
  if (parts === undefined || parts === null) {
    return <></>;
  }
  if (typeof parts === 'string') {
    return <>{parts}</>;
  }
  return (
    <>
      {parts.map((p, index) => {
        if (typeof p === 'string') {
          return <span key={index}>{p}</span>;
        }
        return p.align ? (
          <span
            key={index}
            style={{
              fontSize: 8,
              alignContent: 'end',
              verticalAlign: `${p.align}`,
              lineHeight: '8px'
            }}>
            {p.text}
          </span>
        ) : (
          <span key={index}>{p.text}</span>
        );
      })}
    </>
  );
}

export function AdvancedTableRow({ row }: { row: IAdvancedTableRow }) {
  return (
    <>
      {/* Левая часть */}
      <div
        className={clsx(
          s.AdvancedTable__cell,
          row.isHeader && s.AdvancedTable__cell_header,
          !row.isHeader && s.AdvancedTable__cell_value
        )}>
        {row.reactNodes ? <>{row.cells[0]}</> : textBuilder(row.cells[0] as TextBuilderRow)}
      </div>
      {/* Правая часть */}
      <div className={clsx(s.AdvancedTable__cell, row.isHeader && s.AdvancedTable__cell_header)}>
        {row.reactNodes ? <>{row.cells[1]}</> : textBuilder(row.cells[1] as TextBuilderRow)}
      </div>
    </>
  );
}

export function AdvancedTable({ rows }: IAdvancedTableProps) {
  return (
    <div className={s.AdvancedTable}>
      {rows.map((r, index) => {
        // особый заголовок внутри таблицы занимающий собой все ячейки
        if (r.isInTableCustomHeader) {
          return (
            <div
              key={index}
              className={clsx(s.AdvancedTable__cell, s.AdvancedTable__cell_header)}
              style={{ gridColumn: '1/3' }}>
              <div style={{ paddingLeft: 94 }}>
                {r.reactNodes ? <>{r.cells[0]}</> : textBuilder(r.cells[0] as TextBuilderRow)}
              </div>
            </div>
          );
        }
        return <AdvancedTableRow key={index} row={r} />;
      })}
    </div>
  );
}
