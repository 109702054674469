import React, { useState } from 'react';
import clsx from 'clsx';
import { Icon, IconChevronDown, IconChevronUp, IconRecommendation, IconSize } from 'componentsL/Icon';
import { isIndicatorScope } from './_utils/helpers';
import { CommentType } from './_utils/types';
import { useCommentContext } from './context/useCommentContext';
import s from './LastPublishedComment.module.scss';

export interface LastPublishedCommentProps {
  comment?: CommentType;
}

export function LastPublishedComment({ comment }: LastPublishedCommentProps) {
  const [opened, setOpened] = useState(false);
  const context = useCommentContext();

  const handleOpened = () => {
    setOpened(!opened);
  };
  // если последний комментарий был "пробелом" - значит это затирание, и тогда рендерить его не нужно
  const validCommentary: CommentType | null = comment && comment.commentText.trim() !== '' ? comment : null;

  // в зависимости от Scope - либо "Подробнее", либо "Рекомендация"
  const indicatorsScope = isIndicatorScope(context.scope);
  const controlText = isIndicatorScope(context.scope) ? 'Рекомендация' : 'Подробнее';
  return (
    <>
      {validCommentary && (
        <div
          className={clsx(
            s.LastPublishedComment__wrapper,
            indicatorsScope ? s.LastPublishedComment__wrapper__indicators : s.LastPublishedComment__wrapper_common
          )}>
          <div className={s.LastPublishedComment__controlWrapper}>
            {indicatorsScope && (
              <div className={clsx(s.LastPublishedComment__recommendation)}>
                <Icon icon={IconRecommendation} iconSize={IconSize.Small} />
              </div>
            )}
            <div className={s.LastPublishedComment__control} onClick={handleOpened}>
              <div>{controlText}</div>
              <Icon icon={opened ? IconChevronUp : IconChevronDown} size={IconSize.Small} />
            </div>
          </div>
          {opened && <div className={s.LastPublishedComment__text}>{validCommentary.commentText}</div>}
        </div>
      )}
    </>
  );
}
