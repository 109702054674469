import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { EMDASH, formatNumber } from '@digital-gov/ui-utils';
import { ReactComponent as BadIcon } from './assets/bad.svg';
import { ReactComponent as GoodIcon } from './assets/good.svg';
import { ReactComponent as NeutralIcon } from './assets/neutral.svg';
import s from './ProgressDynamic.module.scss';

export interface ProgressDynamicProps {
  className?: string;
  degree?: number | null;
  dynamic?: number | null;
  withIcon?: boolean;
}

export const ProgressDynamic: FC<ProgressDynamicProps> = (props) => {
  const { className, dynamic, degree, withIcon = true } = props;

  const { dynamicVariant, icon, prefix } = useMemo(() => {
    if (dynamic && dynamic > 0) {
      return {
        prefix: '+ ',
        dynamicVariant: 'good',
        icon: <GoodIcon />
      };
    }

    if (dynamic && dynamic < 0) {
      return {
        prefix: '– ',
        dynamicVariant: 'bad',
        icon: <BadIcon />
      };
    }

    return {
      prefix: null,
      dynamicVariant: 'neutral',
      icon: <NeutralIcon />
    };
  }, [dynamic]);

  return (
    <div className={clsx(s.ProgressDynamic, s[`ProgressDynamic_${dynamicVariant}`], className)}>
      {withIcon && icon}
      <p className={s.ProgressDynamic__text}>
        {prefix}
        {degree === null ? EMDASH : formatNumber(dynamic && Math.abs(dynamic))}
      </p>
    </div>
  );
};
