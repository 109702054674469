import React, { useCallback, useRef, useState } from 'react';
import { Placement } from '@popperjs/core';
import { IconNotification } from 'componentsL/Icon';
import { Tooltip } from 'componentsL/Tooltip';
import s from './FeedbackIcon.module.scss';

export interface FeedbackIconProps {
  // Было ли обращение в рамках текущего периода
  badgeOn: boolean;
  title?: string | void;
  tooltipPosition?: Placement;
  onClick: () => void;
}

export const FeedbackIcon = ({ badgeOn, title, tooltipPosition = 'top-end', onClick }: FeedbackIconProps) => {
  const ref = useRef(null);

  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

  const hideTooltip = useCallback(() => {
    setIsTooltipShown(false);
  }, [setIsTooltipShown]);

  const showTooltip = useCallback(() => {
    if (Boolean(title?.trim())) {
      setIsTooltipShown(true);
    }
  }, [title, setIsTooltipShown]);

  return (
    <div ref={ref} className={s.FeedbackIcon} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} onClick={onClick}>
      {isTooltipShown && (
        <Tooltip
          className={s.FeedbackIcon__tooltip}
          targetNode={ref.current}
          tooltipOptions={{
            placement: tooltipPosition
          }}>
          {/* isTooltipShown может быть true только если title НЕ пуст */}
          {title!}
        </Tooltip>
      )}
      <IconNotification className={s.FeedbackIcon__icon} />
      {badgeOn && <div className={s.FeedbackIcon__badge} />}
    </div>
  );
};
