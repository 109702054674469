import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { InputPropsWithoutRef } from '@digital-gov/ui-utils';
import { Hint, HintVariant } from 'componentsL/Hint/Hint';
import { Icon, IconSize } from 'componentsL/Icon';
import { IconProps } from 'componentsL/Icon/Icon';
import s from './TextInput.module.scss';

export interface TextInputClasses {
  root?: string;
  input?: string;
  inputWrapper?: string;
}

export interface TextInputProps
  extends Pick<
    InputPropsWithoutRef,
    | 'className'
    | 'placeholder'
    | 'value'
    | 'disabled'
    | 'readOnly'
    | 'onChange'
    | 'onFocus'
    | 'onFocusCapture'
    | 'onBlur'
    | 'onBlurCapture'
    | 'onClick'
    | 'onClickCapture'
    | 'onMouseDown'
    | 'onMouseDownCapture'
    | 'onKeyDown'
    | 'onKeyDownCapture'
  > {
  label?: string;
  classes?: TextInputClasses;
  type?: 'text' | 'search';
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
  iconLeftProps?: Pick<IconProps, 'className' | 'classes' | 'size' | 'iconSize'>;
  iconRightProps?: Pick<IconProps, 'className' | 'classes' | 'size' | 'iconSize'>;
  onIconLeftClick?: MouseEventHandler;
  onIconRightClick?: MouseEventHandler;
  hint?: React.ReactNode;
  hintVariant?: HintVariant;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      className,
      classes,
      type = 'text',
      value,
      iconLeft,
      iconRight,
      iconLeftProps,
      iconRightProps,
      onIconLeftClick,
      onIconRightClick,
      disabled,
      hint,
      hintVariant,
      ...inputProps
    },
    inputRef
  ) => {
    return (
      <label
        className={clsx(s.TextInput, className, classes?.root, {
          [s.TextInput_disabled]: disabled
        })}>
        {label && <span className={s.TextInput__label}>{label}</span>}
        <div className={clsx(s.TextInput__inputWrapper, classes?.inputWrapper)}>
          {iconLeft && (
            <Icon
              className={clsx(s.TextInput__icon, s.TextInput__iconLeft, iconLeftProps?.className)}
              icon={iconLeft}
              size={IconSize.Small}
              onClick={onIconLeftClick}
              {...iconLeftProps}
            />
          )}
          <input
            ref={inputRef}
            className={clsx(s.TextInput__input, classes?.input, {
              [s.TextInput__input_leftIcon]: !!iconLeft,
              [s.TextInput__input_rightIcon]: !!iconRight
            })}
            type={type}
            value={value || ''}
            disabled={disabled}
            {...inputProps}
          />
          {iconRight && (
            <Icon
              className={clsx(s.TextInput__icon, s.TextInput__iconRight, iconRightProps?.className)}
              icon={iconRight}
              size={IconSize.Small}
              onClick={onIconRightClick}
              {...iconRightProps}
            />
          )}
        </div>
        <Hint className={s.TextInput__hint} variant={hintVariant} disabled={disabled}>
          {hint}
        </Hint>
      </label>
    );
  }
);
