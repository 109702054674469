import React from 'react';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const digitalizationByVc = components[0]?.value;
  const vcServicesCount = components[1]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={digitalizationByVc}
        roundUp
        unit={'шт.'}
        description={'Значение показателя цифровизации услуг в электронный вид через ВКУ субъекта i'}>
        <Symbol sub={'i'}>F</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={vcServicesCount}
        roundUp
        unit={'шт.'}
        description={
          'Количество услуг, которые субъект i перевел в электронный вид на ЕПГУ с использованием самостоятельной разработки на ВКУ'
        }>
        <Symbol sub={'i'}>S</Symbol>
      </SymbolDetails>
      <SymbolDetails
        description={
          <>
            Количество заявлений по услуге в статусе «Услуга оказана» <br />с момента публикации интерактивной формы
            подачи заявления на ЕПГУ
          </>
        }>
        <Symbol>К</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,5', '0 < Pi ≤ N/4']} />
      <TableRow values={['1,0', 'N/4 < Pi ≤ N/2']} />
      <TableRow values={['1,5', 'N/2 < Pi ≤ 3 N/4']} />
      <TableRow values={['2,0', '3 N/4 < Pi ≤ N']} />
    </Table>
  );
}

export function ServicesDigitalizationByVC() {
  return (
    <IndicatorPage
      regionScope
      download={'Цифровизация услуг посредством ВКУ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
