import React from 'react';
import clsx from 'clsx';
import { EMDASH } from '@digital-gov/ui-utils';
import s from './StatisticsBlockItem.module.scss';

export interface StatisticsBlockItemProps {
  label: string | React.ReactNode;
  content?: number | string | React.ReactNode;
}

export function StatisticsBlockItem({ label, content }: StatisticsBlockItemProps) {
  return (
    <div className={s.StatisticsBlockItem}>
      <div className={clsx(s.StatisticsBlockItem__label)}>{label}</div>
      <div className={s.StatisticsBlockItem__content}>{!content ? EMDASH : content}</div>
    </div>
  );
}
