export enum RegionsTableHeaderFields {
  ratingPosition = 'ratingPosition',
  representatives = 'representatives',
  regionName = 'regionName',
  rrct = 'rrct',
  degree = 'degree',
  indicatorsValues = 'indicatorsValues'
}

export type SelectedIndicatorType = {
  indicatorId: number | string;
  indicatorName: string;
  maxValue?: number | null;
};

export interface ISortedColumn {
  field: RegionsTableHeaderFields | null;
  sort: 'asc' | 'desc' | null;
}
