import React from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { Modal } from 'componentsL/Modal';
import { isIndicatorScope } from '../_utils/helpers';
import { useCommentContext } from '../context/useCommentContext';
import s from './CommentSuccess.module.scss';

export interface CommentSuccessProps {
  onClose: () => void;
}

export function CommentSuccess({ onClose }: CommentSuccessProps) {
  const context = useCommentContext();

  return (
    <Modal
      title={isIndicatorScope(context.scope) ? 'Рекомендация опубликована' : 'Комментарий опубликован'}
      width={456}
      onClose={onClose}>
      <div className={s.CommentSuccess__text}>
        {isIndicatorScope(context.scope)
          ? 'Рекомендация успешно опубликована и доступна другим пользователям.'
          : 'Комментарий успешно опубликован и доступен другим пользователям'}
      </div>
      <div className={s.CommentSuccess__button}>
        <Button variant={ButtonVariant.Primary} onClick={onClose}>
          Закрыть
        </Button>
      </div>
    </Modal>
  );
}
