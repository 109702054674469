import { useCallback, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
const defaultSetter = (value) => String(value || '');
export const useQueryParams = (key, defaultValue, options = {
    setter: defaultSetter
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsRef = useRef(searchParams);
    searchParamsRef.current = searchParams;
    const { getter, setter, navigateOptions } = options;
    const searchParam = searchParams.get(key);
    const transformedDefaultValue = useMemo(() => (defaultValue !== undefined ? setter(defaultValue) : null), [defaultValue, setter]);
    const paramValue = searchParam || transformedDefaultValue;
    const value = useMemo(() => (getter ? getter(paramValue) : paramValue), [getter, paramValue]);
    const setValue = useCallback((value) => {
        const transformedValue = setter(value);
        const params = Object.fromEntries(Array.from(searchParamsRef.current));
        if (transformedValue) {
            setSearchParams(Object.assign(Object.assign({}, params), { [key]: transformedValue }), navigateOptions);
        }
        else {
            delete params[key];
            setSearchParams(params, navigateOptions);
        }
    }, [key, setSearchParams, setter, navigateOptions]);
    return [value, setValue];
};
