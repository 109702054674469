import React from 'react';
import { formatNumber } from '@digital-gov/ui-utils';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import s from './AppealsInMszu.module.scss';

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const appeals = components[0]?.value;

  return (
    <>
      <h4 className={s.AppealsInMszu__heading}>Компоненты</h4>
      <div className={s.AppealsInMszu__content}>
        <span className={s.AppealsInMszu__symbol}>X</span>
        <span className={s.AppealsInMszu__equality}>=</span>
        <span className={s.AppealsInMszu__value}>{formatNumber(appeals)}%</span>
        <span className={s.AppealsInMszu__text}>
          данные из подсистемы Управление национальными проектами ГИИС «ЭБ», рассчитанные субъектом Российской Федерации
          (в паспортах региональных проектов)
        </span>
      </div>
    </>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0,0', 'Показатель доли обращений составляет < 40%']} />
      <TableRow values={['2,0', 'Показатель доли обращений составляет ≥ 40%']} />
    </Table>
  );
}

export function AppealsInMszu() {
  return (
    <IndicatorPage
      regionScope
      download={'Доля обращений МСЗУ.pdf'}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}
