import React from 'react';
import { ReactComponent as IndicatorBadIcon } from './icons/indicator-bad.svg';
import { ReactComponent as IndicatorGoodIcon } from './icons/indicator-good.svg';
import { ReactComponent as IndicatorNoneIcon } from './icons/indicator-none.svg';

export enum IndicatorColorScheme {
  Primary,
  Soft,
  List
}

export const primaryColors = ['#55d666', '#cf3434', '#787a92'];
export const softColors = ['#4aba58', '#c8a354', '#787a92'];
export const listColors = ['#4aba58', '#4aba58', '#787a92'];

type IndicatorMapper = Record<IndicatorColorScheme, string[]>;

const IndicatorColorMap: IndicatorMapper = {
  [IndicatorColorScheme.Primary]: primaryColors,
  [IndicatorColorScheme.Soft]: softColors,
  [IndicatorColorScheme.List]: listColors
};

export function Indicator({
  dynamic,
  colorScheme = IndicatorColorScheme.Primary
}: {
  dynamic?: number | null;
  colorScheme?: IndicatorColorScheme;
}) {
  if (dynamic === null || dynamic === undefined) return null;

  const colors = IndicatorColorMap[colorScheme];
  if (dynamic > 0) {
    return <IndicatorGoodIcon style={{ color: colors[0] }} />;
  } else if (dynamic < 0) {
    return <IndicatorBadIcon style={{ color: colors[1] }} />;
  } else {
    return <IndicatorNoneIcon style={{ color: colors[2] }} />;
  }
}
