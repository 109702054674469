import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { PageLoader } from 'components/PageLoader';
import { useSecondPersonListQuery } from 'store/graphql';
import { useLinkSecondPersonsToScope } from '../_hooks';
import { useRctControlContext } from '../_store/useRctControlContext';
import { RctControlScopeEnum, SecondPersonType } from '../_utils';
import { Divider, SecondPerson, SecondPersonList, Scope } from './_components';
import { SecondPersonEditor } from './_components/SecondPersonEditor';
import s from './ChangeSecondPerson.module.scss';

export interface ChangeSecondPersonProps {
  scope: RctControlScopeEnum;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
  initViewMode?: ChangeSecondPersonViewMode;
}

enum ChangeSecondPersonViewMode {
  'base' = 'base',
  'edit' = 'edit',
  'add' = 'add'
}

export function ChangeSecondPerson({
  initViewMode = ChangeSecondPersonViewMode.base,
  scope,
  onClose,
  onSuccess,
  onError
}: ChangeSecondPersonProps) {
  // Query загрузки списка ВП \ ПП
  const { loading: secondPersonsLoading, data, error, refetch } = useSecondPersonListQuery();
  const [viewMode, setViewMode] = useState<ChangeSecondPersonViewMode>(initViewMode);
  const [personIdToEdit, setPersonIdToEdit] = useState<number | null>(null);
  const { currentScopeObject, currentSecondPersons, setCurrentSecondPersons, setSecondPersonsList } =
    useRctControlContext();
  const context = useRctControlContext();

  if (error) onError();

  const [loading, setLoading] = useState(false);

  const secondPersonList = useMemo(() => {
    return data?.rating.viceChairmansAndRepresentatives ?? [];
  }, [data]);

  useEffect(() => {
    setLoading(secondPersonsLoading);
  }, [secondPersonsLoading]);

  useEffect(() => {
    if (secondPersonList && secondPersonList.length > 0) {
      setSecondPersonsList(secondPersonList);
    }
  }, [secondPersonList, setSecondPersonsList]);

  context.refetchSecondPersonsList = refetch;

  // хендлеры переключения режима редактирования РЦТ
  const handleSwitchToEditMode = (personId: number) => {
    setPersonIdToEdit(personId);
    setViewMode(ChangeSecondPersonViewMode.edit);
  };
  const handleSwitchToAddMode = () => {
    setPersonIdToEdit(null);
    setViewMode(ChangeSecondPersonViewMode.add);
  };
  const handleSwitchToBaseMode = () => {
    setPersonIdToEdit(null);
    setViewMode(ChangeSecondPersonViewMode.base);
  };

  const sideControlsDisabled =
    viewMode === ChangeSecondPersonViewMode.edit || viewMode === ChangeSecondPersonViewMode.add;

  // хендлер обновления данных из Editor
  const handleSecondPersonUpdate = useCallback(
    (person: SecondPersonType, mode: 'add' | 'edit') => {
      if (mode === 'add') {
        const _secondPersons = currentSecondPersons.map((p) => ({ ...p }));
        _secondPersons.push({
          ...person
        });
        setCurrentSecondPersons(_secondPersons);
      } else if (mode === 'edit') {
        const updatedPersonIndex = currentSecondPersons.findIndex((p) => p.userId === person.userId);
        if (updatedPersonIndex !== undefined) {
          const _secondPersons = currentSecondPersons.map((p) => ({ ...p }));
          _secondPersons[updatedPersonIndex] = {
            ...person
          };
          setCurrentSecondPersons(_secondPersons);
        }
      }
    },
    [currentSecondPersons, setCurrentSecondPersons]
  );

  // хендлер прикрепления списка Вице-премьеров\полномочных представителей к ФОИВ-у или РОИВ-у
  const { linkSecondPersonsToScope } = useLinkSecondPersonsToScope({
    onError,
    onSuccess
  });
  const handleLinkSecondPersonsToScope = useCallback(async () => {
    setLoading(true);
    await linkSecondPersonsToScope();
    setLoading(false);
  }, [linkSecondPersonsToScope, setLoading]);

  const kind =
    scope === RctControlScopeEnum.ViceChairman ? RctControlScopeEnum.ViceChairman : RctControlScopeEnum.Representative;

  return (
    <Modal
      title={
        scope === RctControlScopeEnum.ViceChairman ? 'Изменение Вице-премьера' : 'Изменение полномочного представителя'
      }
      onClose={onClose}
      width={624}>
      <div>
        {loading && <PageLoader />}
        {!loading && (
          <div className={s.ChangeSecondPerson}>
            <Scope name={currentScopeObject.name} />
            <Divider />
            <div className={s.ChangeSecondPerson__controlGroup}>
              <SecondPersonList disabled={sideControlsDisabled} />
              {viewMode === ChangeSecondPersonViewMode.base && (
                <>
                  {currentSecondPersons &&
                    currentSecondPersons.length > 0 &&
                    currentSecondPersons.map((p, index) => {
                      return (
                        <SecondPerson
                          key={index}
                          isFirst={index === 0 && currentSecondPersons.length !== 1}
                          handleSwitchToEditMode={() => {
                            handleSwitchToEditMode(p.userId);
                          }}
                          secondPerson={p}
                        />
                      );
                    })}
                  {currentSecondPersons.length > 1 && (
                    <div className={s.ChangeSecondPerson__disclaimer}>
                      * - На страницах ФРЦТ и РРЦТ отображается только первая позиция в списке
                    </div>
                  )}
                </>
              )}
              {viewMode === ChangeSecondPersonViewMode.edit && (
                <SecondPersonEditor
                  mode={'edit'}
                  kind={kind}
                  secondPerson={currentSecondPersons.find((p) => p.userId === personIdToEdit)}
                  onError={onError}
                  onSuccess={handleSwitchToBaseMode}
                  onBack={handleSwitchToBaseMode}
                  handleUpdate={handleSecondPersonUpdate}
                />
              )}
              {viewMode === ChangeSecondPersonViewMode.add && (
                <SecondPersonEditor
                  mode={'add'}
                  kind={kind}
                  onError={onError}
                  onSuccess={handleSwitchToBaseMode}
                  onBack={handleSwitchToBaseMode}
                  handleUpdate={handleSecondPersonUpdate}
                />
              )}
              <Button
                disabled={sideControlsDisabled}
                className={s.ChangeSecondPerson__createButton}
                onClick={handleSwitchToAddMode}>
                Создать +
              </Button>
            </div>
            <Button
              className={s.ChangeSecondPerson__addButton}
              disabled={sideControlsDisabled}
              onClick={handleLinkSecondPersonsToScope}>
              Сохранить
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
