import { useCallback } from 'react';
import { RctScopeEnum, useLinkRctToScopeMutation } from 'store/graphql';
import { useRctControlContext } from '../_store/useRctControlContext';
import { RctControlScopeEnum } from '../_utils';

export interface UseLinkRctToScopeProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export function useLinkRctToScope({ onError, onSuccess }: UseLinkRctToScopeProps) {
  // Мутация прикрепления РЦТ к ФОИВ-у / РОИВ-у
  const [linkRctToScopeMutation, { loading }] = useLinkRctToScopeMutation();
  const context = useRctControlContext();

  // Хендлер привязки РЦТ
  const handleLinkRctToScope = useCallback(async () => {
    try {
      const isFrct = context.scope === RctControlScopeEnum.DepartmentRct;
      const mutationScope: RctScopeEnum = isFrct ? RctScopeEnum.Frct : RctScopeEnum.Rrct;
      const scopeId = context.currentScopeObject.scopeId;
      const rctId = context.currentRct?.userId ?? null; // null означает отвязывание (т.е. ФОИВ\РОИВ без РЦТ)
      await linkRctToScopeMutation({
        variables: {
          scopeId,
          rctId: rctId,
          scope: mutationScope
        }
      });
      if (context.refetchScopeData) {
        await context.refetchScopeData();
      }
      if (onSuccess) onSuccess();
    } catch (e) {
      if (onError) onError();
    }
  }, [onError, onSuccess, linkRctToScopeMutation, context]);

  return { linkRctToScope: handleLinkRctToScope, loading };
}
