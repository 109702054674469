import React from 'react';
import { InfoProperty, InfoPropertyIndicator } from '../InfoProperty/InfoProperty';
import s from './Info.module.scss';

export interface InfoEffectGroupProps {
  properties: { name: string; amount: number; color: string }[];
}

export const InfoEffectGroup = ({ properties }: InfoEffectGroupProps) => {
  return (
    <>
      {properties.map((item, iItem) => (
        <InfoProperty
          key={iItem}
          classes={{
            label: s.Info__regionLabel
          }}
          label={
            <>
              <InfoPropertyIndicator color={item.color} />
              {item.name}
            </>
          }>
          {item.amount}
        </InfoProperty>
      ))}
    </>
  );
};
