import { ActionModal } from 'components/Modal';
import { RctControlScopeEnum } from '../_utils';

export interface ViceChairmanOrRepresentativeAddedProps {
  kind: RctControlScopeEnum.ViceChairman | RctControlScopeEnum.Representative;
  handleForward: () => void;
  handleClose: () => void;
}

export function SecondPersonChanged({ handleForward, handleClose, kind }: ViceChairmanOrRepresentativeAddedProps) {
  return (
    <ActionModal
      title={
        kind === RctControlScopeEnum.ViceChairman ? 'Изменение Вице-премьера' : 'Изменение полномочного представителя'
      }
      message={
        kind === RctControlScopeEnum.ViceChairman
          ? 'Вице-премьер был успешно изменен'
          : 'Полномочный представитель был успешно изменен'
      }
      width={624}
      onClose={handleClose}
      actions={[
        {
          name: 'Продолжить',
          onAction: handleForward
        }
      ]}
    />
  );
}
