import React from 'react';
import { Icon, IconSize, IconZoom } from 'componentsL/Icon';
import { RatingListItem } from '../_components/RatingItem/RatingItem';
import { useDepartmentChartContext } from '../ChartProvider/useDepartmentChartContext';
import { DepartmentChartUtils } from '../DepartmentChartUtils';
import { RatingDataType } from '../types';
import s from './ChartTableTotal.module.scss';

export interface ChartCellDataProps {
  elementsPerColumn: number;
  data: RatingDataType[];
  onGroupEnterClick: () => void;
}

export const ChartCellData = ({ data, elementsPerColumn, onGroupEnterClick }: ChartCellDataProps) => {
  const { searchValue, viceChairman } = useDepartmentChartContext();

  return (
    <div className={s.ChartCellData}>
      <div
        className={s.ChartCellData__cell}
        style={{ gridTemplateRows: `repeat(${elementsPerColumn}, 1fr)`, columnGap: '20px' }}>
        {data.map((item) => (
          <RatingListItem
            key={item.departmentId}
            label={item.departmentName}
            filtered={DepartmentChartUtils.checkItemIsFiltered(item, searchValue, viceChairman)}
            {...item}
          />
        ))}
      </div>
      {data.length > 0 && (
        <Icon
          className={s.ChartCellData__zoomButton}
          icon={IconZoom}
          size={IconSize.Large}
          iconSize={16}
          onClick={onGroupEnterClick}
        />
      )}
    </div>
  );
};
