import React from 'react';
import clsx from 'clsx';
import s from './DropdownList.module.scss';

export function DropdownList({
  children,
  className,
  style
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div className={clsx(s.DropdownList, className)} style={style}>
      {children}
    </div>
  );
}
