import { IndicatorDetalizationCellType } from 'store/graphql';

/**
 * Справедливо для Формы 1
 */
export const isLinkCell = (cellIndex: number, columnCount: number, cell: IndicatorDetalizationCellType): boolean => {
  return cellIndex >= 4 && cellIndex < columnCount - 2 && !!cell.detalizationFileMetadata;
};

export const isFirstCell = (cellIndex: number) => {
  return cellIndex === 0;
};

export const isLastCell = (cellIndex: number, columnCount: number) => {
  return cellIndex === columnCount - 1;
};

export const isPreLastCell = (cellIndex: number, columnCount: number) => {
  return cellIndex === columnCount - 2;
};
