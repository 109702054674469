import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabType } from 'componentsL/Tabs/Tabs';

export enum ScopeEnum {
  Frct = 'frct',
  Rrct = 'rrct'
}

export interface ScopeTabType<T> extends TabType<T> {
  path: string;
}

const defaultScopeTabs: ScopeTabType<ScopeEnum>[] = [
  {
    label: 'ФРЦТ',
    value: ScopeEnum.Frct,
    path: '/'
  },
  {
    label: 'РРЦТ',
    value: ScopeEnum.Rrct,
    path: '/regions'
  }
];

export interface ScopeSwitchProps {
  scope: ScopeEnum;
  scopeTabs?: ScopeTabType<ScopeEnum>[];
}

export function ScopeSwitch({ scope, scopeTabs }: ScopeSwitchProps) {
  const navigate = useNavigate();

  const tabs = scopeTabs ?? defaultScopeTabs;

  const handleTabsChange = (scope: ScopeEnum) => {
    const scopeTab = tabs.find((t) => t.value === scope);
    if (scope === ScopeEnum.Frct) {
      navigate(scopeTab?.path ?? '/');
    }
    if (scope === ScopeEnum.Rrct) {
      navigate(scopeTab?.path ?? '/regions');
    }
  };
  return (
    <>
      <Tabs tabs={tabs} value={scope} onChange={handleTabsChange} />
    </>
  );
}
