import { ActionModal } from 'components/Modal';

export interface ResponsibleControlErrorProps {
  errorMessage?: string;
  handleBack: () => void;
  handleClose: () => void;
}

export function ResponsibleControlError({
  errorMessage = 'Произошла ошибка. Попробуйте повторить операцию позднее.',
  handleBack,
  handleClose
}: ResponsibleControlErrorProps) {
  return (
    <ActionModal
      title={'Изменение ответственного по показателю'}
      width={624}
      message={errorMessage}
      onClose={handleClose}
      actions={[
        {
          name: 'Назад',
          onAction: handleBack
        }
      ]}
    />
  );
}
