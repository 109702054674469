import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { formatFileSize } from '@digital-gov/ui-utils';
import { Icon, IconSize, IconDismiss, IconWarning } from 'componentsL/Icon';
import { FileIcon } from 'componentsL/Icon/FileIcon';
import { Loader } from 'componentsL/Loader';
import { Tooltip } from 'componentsL/Tooltip';
import s from './FileUploadItem.module.scss';

export interface FileUploadItemProps {
  disabled?: boolean;
  loading: boolean;
  filename: string;
  fileID: number;
  fileSize: number;
  error?: boolean;
  errorMessage?: string;
  onRemove: (fileId: number) => void;
}

export const FileUploadItem = ({
  disabled,
  loading,
  filename,
  fileID,
  fileSize,
  error,
  errorMessage,
  onRemove
}: FileUploadItemProps) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const onRemoveClick = useCallback(() => disabled || onRemove(fileID), [fileID, disabled, onRemove]);
  const size = useMemo<string>(() => formatFileSize(fileSize), [fileSize]);

  const mouseEnter = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);
  const mouseLeave = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  return (
    <div className={s.FileUploadItem} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <div
        className={s.FileUploadItem__close + (disabled ? ' ' + s.FileUploadItem__close_disabled : '')}
        onClick={onRemoveClick}>
        <Icon icon={IconDismiss} size={IconSize.Small} />
      </div>

      <div ref={ref} className={clsx(s.FileUploadItem__icon, !loading && error ? s.FileUploadItem__icon_error : null)}>
        {loading && <Loader className={s.FileUploadItem__loader} />}
        {!loading && error && <Icon icon={IconWarning} size={40} />}
        {!loading && !error && <FileIcon filename={filename} size={40} fit={false} />}
      </div>

      {isHovered && (
        <Tooltip
          targetNode={ref.current}
          tooltipOptions={{
            placement: 'top',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 12]
                }
              }
            ]
          }}>
          {filename}
        </Tooltip>
      )}
      <div className={clsx(s.FileUploadItem__filename, isHovered && s.FileUploadItem__filename_hover)}>{filename}</div>

      {loading && <div className={s.FileUploadItem__progress}>Загрузка</div>}
      {!loading && !error && <div className={s.FileUploadItem__fileSize}>{size}</div>}
      {!loading && error && <div className={s.FileUploadItem__error}>{errorMessage || 'Ошибка'}</div>}
    </div>
  );
};
